import {useCallback} from 'react';

import {doLogin} from 'core/session';

import {
  getIdToken,
  getAuth,
  signInWithPopup,
  OAuthProvider,
} from 'firebase/auth';

import Button from 'components/Common/Button';

import {ReactComponent as AppleLogo} from './Assets/AppleLogo.svg';

const AppleSignInButton = () => {
  const onPress = useCallback(async () => {
    try {
      const auth = getAuth();
      const provider = new OAuthProvider('apple.com');
      provider.addScope('email');
      provider.addScope('name');

      const result = await signInWithPopup(auth, provider);
      const accessToken = await getIdToken(result.user);
      await doLogin(accessToken);
    } catch (e) {
      console.error(e);
    }
  }, []);

  return (
    <Button
      className="login-button apple"
      onPress={onPress}
      label="login.apple_button"
      icon={<AppleLogo className="icon" />}
    />
  );
};

export default AppleSignInButton;
