import {Route, Switch} from 'react-router-dom';

import NotFound from 'pages/NotFound';

import Home from './Home';
import Login from './Login';

import Admin from './Admin';
import Appointments from './Appointments';
import Businesses from './Businesses';
import BusinessRedirect from './Businesses/Redirect';
import Settings from './Settings';
import Categories from './Categories';
import Explore from './Explore';
import ForBusiness from './Landings/ForBusiness';

const User = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/admin*" component={Admin} />

      <Route path="/login" component={Login} />
      <Route path="/settings" component={Settings} />

      <Route path="/appointments" component={Appointments} />

      <Route path="/b/" component={Businesses} />
      <Route path="/r/" component={BusinessRedirect} />

      <Route path="/c/" component={Categories} />

      <Route exact path="/for-business" component={ForBusiness} />
      <Route exact path="/explore" component={Explore} />

      <Route component={NotFound} />
    </Switch>
  );
};

export default User;
