import './BusinessType.scss';

import {useEffect} from 'react';

import {getStore} from 'core/hooks';

import Source from 'components/Common/Source';
import View from 'components/Common/View';

import {BusinessTypeResponse} from 'types/BusinessType';

import BusinessTypeItem from './BusinessTypeItem';

const businessTypesStore = getStore<BusinessTypeResponse[]>('businessTypes');

type ListRenderProps = {
  types: BusinessTypeResponse[];
};

const List = () => {
  return (
    <Source<BusinessTypeResponse[]>
      data={businessTypesStore.length ? businessTypesStore : undefined}
      source="businesses/types">
      {types => <ListRender types={types} />}
    </Source>
  );
};

const ListRender = ({types}: ListRenderProps) => {
  useEffect(() => {
    if (!businessTypesStore.length) {
      businessTypesStore.push(...types);
    }
  }, [types]);

  return (
    <View className="business-types-list">
      {types
        .sort((a, b) => a.order - b.order)
        .map(type => (
          <BusinessTypeItem key={type.name} type={type} />
        ))}
    </View>
  );
};

export default List;
