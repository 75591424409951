import {DateTime} from 'luxon';

import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';

import Form from 'components/Form';
import Actions from 'components/Form/Actions';
import Field from 'components/Form/Field';
import Label from 'components/Form/Label';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import Calendar from 'components/Form/Calendar';
import Submit from 'components/Form/Submit';
import PhoneFields from 'components/User/PhoneFields';
import Spacer from 'components/Common/Spacer';

import {UserGender, UserProfileInfo} from 'types/User';

const {Female, Male, They, Other} = UserGender;

export const renderFirstNameField = (
  required = true,
  defaultValue?: string,
) => (
  <Field name="first_name" label="settings.field_first_name">
    <Label />
    <Input
      required={required}
      minLength={2}
      maxLength={32}
      defaultValue={defaultValue}
    />
  </Field>
);

export const renderLastNameField = (required = true, defaultValue?: string) => (
  <Field name="last_name" label="settings.field_last_name">
    <Label />
    <Input
      required={required}
      minLength={2}
      maxLength={32}
      defaultValue={defaultValue}
    />
  </Field>
);

export const renderEmailField = (required = true) => (
  <Field name="email" label="settings.field_email">
    <Label />
    <Input required={required} minLength={4} maxLength={60} />
  </Field>
);

export const renderGenderField = (required = true) => (
  <Field name="gender" label="settings.field_gender">
    <Label />
    <Select
      required={required}
      options={[
        {
          name: 'form.select',
          value: 'select',
        },
        {
          name: 'settings.gender_male',
          value: Male,
        },
        {
          name: 'settings.gender_female',
          value: Female,
        },
        {
          name: 'settings.gender_they',
          value: They,
        },
        {
          name: 'settings.gender_other',
          value: Other,
        },
      ]}
    />
  </Field>
);

export const renderDobField = (required = true) => (
  <Field name="dob" label="settings.field_dob">
    <Label />
    <Calendar
      required={required}
      defaultView={'decade'}
      minDate={DateTime.now().minus({years: 80})}
      maxDate={DateTime.now().minus({years: 11})}
    />
  </Field>
);

export const renderPhoneField = (required = true) => (
  <PhoneFields required={required} />
);

export const renderPhoneFakeFields = () => <PhoneFields useFakeFields />;

export const renderNotifications = () => (
  <>
    <Field
      className="inline"
      name="notifications_email"
      label="settings.field_notifications_email">
      <Input type="checkbox" optional />
      <Label />
    </Field>
    <Field
      className="inline"
      name="notifications_sms"
      label="settings.field_notifications_sms">
      <Input type="checkbox" optional />
      <Label />
    </Field>
  </>
);

const General = () => {
  return (
    <Page title="settings.general_title" padding>
      <PageTitle className="center" />

      <Form<UserProfileInfo>
        source="user/profile"
        onSuccess={({goToUrl}) => goToUrl({to: '/'})}>
        {({data: {phone}}) => (
          <>
            {renderFirstNameField()}
            {renderLastNameField()}
            {renderEmailField()}
            {renderGenderField()}
            {renderDobField()}
            {renderPhoneFakeFields()}

            <Spacer size={30} />

            {renderNotifications()}

            <Actions>
              <Submit />
            </Actions>
          </>
        )}
      </Form>
    </Page>
  );
};

export default General;
