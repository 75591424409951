import './DatesList.scss';

import {useState, useEffect, useRef, useCallback} from 'react';
import {DateTime} from 'luxon';
import classNames from 'classnames';

//DateTimeRange
import {useIsMobile} from 'hooks';

import View from 'components/Common/View';
import Text from 'components/Common/Text';
import Locale from 'components/Common/Locale';
import Day from 'components/Common/DateTime/Day';
import Month from 'components/Common/DateTime/Month';

import {getUTCFromTimezone} from 'utils/DateTime';

import {DATE_FORMAT} from 'types/Date';

type DatesListProps = {
  onDateChange: (date: DateTime) => void;
  date: DateTime;
  timezone: string;
  fromDate: DateTime;
  toDate: DateTime;
};

type DayButtonProps = {
  id: string;
  onDateChange: (date: DateTime) => void;
  date: DateTime;
  today?: boolean;
  selected: boolean;
  className: string;
  available?: boolean;
};

const DayButton = ({
  id,
  onDateChange,
  date,
  selected,
  available = true,
  className,
  today,
}: DayButtonProps) => {
  const onClick = useCallback(
    e => {
      if (!selected) {
        onDateChange(date);
      }

      /*const container = document.querySelector('.dates-list');
      const currentTarget = e.currentTarget;

      if (container) {
        container.scrollTo({
          left: currentTarget.offsetLeft,
          behavior: 'smooth',
        });
      }*/
    },
    [selected, onDateChange, date],
  );

  return (
    <View
      id={id}
      onClick={onClick}
      className={classNames('date-day', {
        selected,
        available,
        className,
      })}>
      <Text className="weekday">
        <Day date={date} short />
      </Text>
      <Text className="day">{date.day.toString()}</Text>
      {today && (
        <Text className="today-label">
          <Locale text="staff.calendar.today_label" />
        </Text>
      )}
    </View>
  );
};

const DatesList = ({
  fromDate,
  toDate,
  onDateChange,
  timezone,
  date,
}: DatesListProps) => {
  const isMobile = useIsMobile();
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [initialDate] = useState(date);

  const onPressLeft = useCallback(() => {
    if (!scrollRef.current) {
      return;
    }

    scrollRef.current.scrollBy({
      top: 0,
      left: -500,
      behavior: 'smooth',
    });
  }, [scrollRef]);
  const onPressRight = useCallback(() => {
    if (!scrollRef.current) {
      return;
    }

    scrollRef.current.scrollBy({
      top: 0,
      left: 500,
      behavior: 'smooth',
    });
  }, [scrollRef]);

  // Initial scroll
  useEffect(() => {
    const selectorId = `#day-${initialDate.toFormat(DATE_FORMAT)}`;
    const container = document.querySelector('.dates-list');
    const element: HTMLDivElement | null = document.querySelector(selectorId);

    if (element && container) {
      container.scrollTo({
        left: element.offsetLeft - element.clientWidth * 2,
      });
    }
  }, [initialDate]);

  const renderDays = () => {
    const items = [];
    let days = [];
    let cursor = fromDate;
    let lastCursor = fromDate;

    const nowDate = getUTCFromTimezone(timezone).startOf('day');

    do {
      const dateId = cursor.toFormat(DATE_FORMAT);

      if (!cursor.hasSame(lastCursor, 'month')) {
        items.push(
          <View key={`${dateId}-month`} className={classNames('date-month')}>
            <Text className="month">
              <Month key={`${dateId}-month-name`} date={lastCursor} />
            </Text>
            <View className="days">{days}</View>
          </View>,
        );
        lastCursor = cursor;
        days = [];
      }

      days.push(
        <DayButton
          key={dateId}
          date={cursor}
          className={classNames({
            future: nowDate >= cursor,
            past: nowDate > cursor,
            today: nowDate.hasSame(cursor, 'day'),
          })}
          today={nowDate.hasSame(cursor, 'day')}
          id={`day-${dateId}`}
          selected={date.hasSame(cursor, 'day')}
          onDateChange={onDateChange}
        />,
      );

      cursor = cursor.plus({
        days: 1,
      });
    } while (cursor < toDate);

    return items;
  };

  return (
    <View className="staff-dates-list">
      {!isMobile && (
        <View onClick={onPressLeft} className={classNames('arrow', 'left')} />
      )}
      <View ref={scrollRef} className="dates-list">
        {renderDays()}
      </View>
      {!isMobile && (
        <View onClick={onPressRight} className={classNames('arrow', 'right')} />
      )}
    </View>
  );
};

export default DatesList;
