import './Explorer.scss';

import View from 'components/Common/View';

import SearchInput from './SearchInput';
import LocationSelector from './LocationSelector';

const SearchForm = () => {
  return (
    <View className="explorer-form">
      <SearchInput />
      <LocationSelector />
    </View>
  );
};

export default SearchForm;
