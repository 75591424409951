import './InvoicesList.scss';

import View from 'components/Common/View';
import Text from 'components/Common/Text';
import Locale from 'components/Common/Locale';
import ItemsList from 'components/Common/ItemsList';
import DateTime from 'components/Common/DateTime';
import Price from 'components/Common/Price';

import BusinessInfo from 'types/Business';
import MembershipInvoiceInfo from 'types/MembershipInvoice';

type InvoicesListProps = {
  business: BusinessInfo;
};

type InvoiceItemProps = {
  invoice: MembershipInvoiceInfo;
};

const InvoicesList = ({business}: InvoicesListProps) => {
  const {id: businessId} = business;

  const headerContent = (
    <Text className="membership-invoices-title">
      <Locale text="membership.payments_history" />
    </Text>
  );

  return (
    <>
      <ItemsList<MembershipInvoiceInfo>
        headerContent={headerContent}
        className="membership-invoices-list"
        source={`invoices/${businessId}`}
        hideWhenNoItems
        useInfiniteScroll>
        {invoice => <InvoiceItem invoice={invoice} />}
      </ItemsList>
    </>
  );
};

const InvoiceItem = ({invoice}: InvoiceItemProps) => {
  const {card, amount, currency, created_at} = invoice;

  const renderCard = () => {
    if (!card) {
      return null;
    }

    const {brand, last4} = card;

    return (
      <Text className="card">
        {brand} {last4}
      </Text>
    );
  };

  return (
    <View className="membership-invoice-item">
      {renderCard()}

      <Text className="date">
        <DateTime shortMonth timezone="local" date={created_at} />
      </Text>

      <Text className="price">
        <Price amount={amount} currency={currency} />
      </Text>
    </View>
  );
};

export default InvoicesList;
