import {ReactNode} from 'react';

export type NodeType = ReactNode | JSX.Element | null | undefined;

export type NodeRenderProps = {
  node: NodeType;
};
const NodeRender = ({node: NodeElement}: NodeRenderProps) => {
  if (typeof NodeElement === 'undefined' || NodeElement === null) {
    return null;
  }

  if (typeof NodeElement === 'function') {
    return <NodeElement />;
  }

  return <>{NodeElement}</>;
};

export default NodeRender;
