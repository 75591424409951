import './Checkbox.scss';

import classNames from 'classnames';

import Button, {ButtonProps} from 'components/Common/Button';

type Props = ButtonProps & {
  className: string;
  active?: boolean;
};

const Checkbox = ({className, active, ...props}: Props) => {
  return (
    <Button
      className={classNames('custom-check-box', {active}, className)}
      {...props}
    />
  );
};

export default Checkbox;
