import './Appointment.scss';

import classNames from 'classnames';

import Locale from 'components/Common/Locale';
import View from 'components/Common/View';
import Text from 'components/Common/Text';

import AppointmentInfo, {AppointmentStatus} from 'types/Appointment';

import {ReactComponent as StatusPending} from './Assets/StatusPending.svg';
import {ReactComponent as StatusConfirmed} from './Assets/StatusConfirmed.svg';
import {ReactComponent as StatusCancelled} from './Assets/StatusCancelled.svg';
import {ReactComponent as StatusCompleted} from './Assets/StatusCompleted.svg';

const {Pending, Confirmed, Cancelled, Completed} = AppointmentStatus;

type Props = {
  appointment: AppointmentInfo;
  excludeLabel?: boolean;
  excludeIcon?: boolean;
  showAsTag?: boolean;
};

const Status = ({appointment, excludeLabel, excludeIcon, showAsTag}: Props) => {
  const {status} = appointment;
  let Icon;

  switch (status) {
    case Pending: {
      Icon = StatusPending;
      break;
    }
    case Confirmed: {
      Icon = StatusConfirmed;
      break;
    }
    case Cancelled: {
      Icon = StatusCancelled;
      break;
    }
    case Completed: {
      Icon = StatusCompleted;
      break;
    }
  }

  return (
    <View
      className={classNames('appointment-status', {
        [status]: status,
        tag: showAsTag,
      })}>
      {!excludeLabel && (
        <Text className="status-label">
          <Locale text={`appointments.status_${status}`} />
        </Text>
      )}
      {!excludeIcon && <Icon className="status-icon" />}
    </View>
  );
};

export default Status;
