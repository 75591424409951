import './Loading.scss';

import classNames from 'classnames';

import View from 'components/Common/View';

type Props = {
  className?: string;
  label?: string;
  size?: 'small' | 'medium' | 'large';
};

const Loading = ({className, size = 'small', label}: Props) => {
  return (
    <View
      className={classNames(
        'loading',
        {
          [`size-${size}`]: size,
        },
        className,
      )}>
      {label && <View className="label">{label}</View>}
      {size && <View className="circle" />}
    </View>
  );
};

export default Loading;
