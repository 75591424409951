import {Route, Switch} from 'react-router-dom';

import NotFound from 'pages/NotFound';

import {STAFF_PATH} from 'types/Business';

import Items from './Items';
import Appointment from './Appointment';

const Appointments = () => {
  return (
    <Switch>
      <Route exact path={`${STAFF_PATH}/appointments`} component={Items} />
      <Route
        path={`${STAFF_PATH}/appointments/:appointment_id`}
        component={Appointment}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Appointments;
