import {useState, useCallback} from 'react';
import {DateTime} from 'luxon';

import {
  useBusiness,
  useBusinessUrl,
  useStaffUrl,
  useFormatUserFullName,
  goToUrl,
  useLocation,
} from 'hooks';

import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';
import View from 'components/Common/View';
import Text from 'components/Common/Text';
import Locale from 'components/Common/Locale';
import DateTimeComponent from 'components/Common/DateTime';
import Form from 'components/Form';
import Field from 'components/Form/Field';
import Label from 'components/Form/Label';
import Autocomplete, {ItemValue} from 'components/Form/Autocomplete';
import Spacer from 'components/Common/Spacer';

import BusinessClientInfo from 'types/BusinessClient';

const New = () => {
  const {id: businessId} = useBusiness();
  const businessUrl = useBusinessUrl();
  const staffUrl = useStaffUrl();
  const formatUserFullName = useFormatUserFullName();
  const {state} = useLocation<{date: string}>();
  const [currentValue, setCurrentValue] = useState('');
  const {date} = state;

  const onPressItem = useCallback(
    ({id, value, client}: BusinessClientInfo & Partial<ItemValue>) => {
      if (value === 'add-name') {
        goToUrl({
          base: staffUrl({to: 'clients/new'}),
          state: {
            isFromQuickBooking: true,
            initialName: currentValue,
            date,
          },
        });
        return;
      }

      const {type, id: userId} = client;

      goToUrl({
        base: businessUrl({to: 'booking'}),
        state: {
          isFromStaff: true,
          userName: formatUserFullName(client),
          [type === 'user' ? 'userId' : 'clientId']: userId || id,
          directDate: date,
        },
      });
    },
    [businessUrl, staffUrl, formatUserFullName, date, currentValue],
  );

  const sourceParser = useCallback(items => {
    return [
      {
        name: 'add-name',
        value: 'add-name',
      },
      ...items,
    ];
  }, []);

  const renderAddName = useCallback(() => {
    return (
      <View className="new-item">
        <Text>
          {currentValue.trim() ? (
            <Locale
              text="staff.appointments.new.add_client"
              params={{name: currentValue}}
            />
          ) : (
            <Locale text="staff.appointments.new.add_new_client" />
          )}
        </Text>
      </View>
    );
  }, [currentValue]);

  const onChangeValue = useCallback(
    value => {
      setCurrentValue(value);
    },
    [setCurrentValue],
  );

  const itemRender = useCallback(
    (item: BusinessClientInfo & Partial<ItemValue>, idx: number) => {
      if (item.value === 'add-name') {
        return renderAddName();
      }

      const {id, client} = item;
      const {email, phone_prefix, phone} = client;

      const description: string[] = [];

      if (phone) {
        description.push(`${phone_prefix} ${phone}`);
      }

      if (email) {
        description.push(email);
      }

      return (
        <>
          <Text key={id} className="item">
            <Text className="name">{formatUserFullName(item.client)}</Text>
            {!!description.length && (
              <Text className="desc">{description.join(' - ')}</Text>
            )}
          </Text>
        </>
      );
    },
    [formatUserFullName, renderAddName],
  );

  return (
    <Page title="staff.appointments.new.title" horizontalPadding>
      <PageTitle className="staff-title center" />

      <Spacer size={20} />

      <Form>
        {() => {
          return (
            <>
              <Field name="search" label="staff.appointments.new.client_field">
                <Label />
                <Autocomplete<BusinessClientInfo>
                  source={`clients/${businessId}`}
                  sourceParser={sourceParser}
                  onPressItem={onPressItem}
                  onChangeValue={onChangeValue}
                  itemRender={itemRender}
                  placeholder="staff.appointments.new.client_placeholder"
                  maxLength={40}
                />
              </Field>

              <Field name="date" label="staff.appointments.new.date_field">
                <Label />
                <View className="expand">
                  <DateTimeComponent
                    date={DateTime.fromISO(date, {zone: 'utc'})}
                  />
                </View>
              </Field>

              <Spacer size={150} />
            </>
          );
        }}
      </Form>
    </Page>
  );
};

export default New;
