import {useMemo, useState} from 'react';
import {RegisterOptions} from 'react-hook-form';

import {useTranslation} from 'hooks';

type CustomValidations = {
  [name: string]: (value: any) => boolean;
};

const defaultValidationsProps = [
  'required',
  'min',
  'max',
  'minLength',
  'maxLength',
  'pattern',
];

const validateTrim = (value: any): boolean => {
  return typeof value === 'string' ? value.trim() : value;
};

export const customValidations: CustomValidations = {};

export const useRules = (
  label: string,
  name: string,
  initRules?: any,
): RegisterOptions => {
  const {t} = useTranslation();
  const {trim, required, setValueAs: setValueAsProp, ...allRules} = initRules;
  const setValueAs = (value: any) => {
    if (typeof trim === 'undefined' || trim) {
      value = validateTrim(value);
    }

    if (setValueAsProp) {
      return setValueAsProp(value);
    }

    return value;
  };

  const [rules] = useState<any>({
    required: typeof required === 'undefined' ? true : required,
    validate: {},
    setValueAs,
    ...allRules,
  });

  return useMemo(() => {
    const formatMessage = (
      inline: boolean,
      name: string,
      type: string,
      value: any,
      message: string,
    ) => {
      return inline
        ? t(
            [
              message,
              `form.errors.${type}_inline`,
              `form.errors.${type}`,
            ].filter(Boolean),
            {
              type,
              label: label || t(`field_${name}`),
              value,
            },
          )
        : t([message, `form.errors.${type}`].filter(Boolean), {
            name,
            label: t(`field_${name}`),
          });
    };

    const newValidations: any = {};

    Object.keys(rules).forEach(type => {
      const rule = rules[type];

      if (customValidations[type]) {
        rules[type] = customValidations[type];
        newValidations[type] = rules[type];
        return;
      }

      if (defaultValidationsProps.indexOf(type) === -1) {
        return;
      }

      if (typeof rule !== 'object') {
        rules[type] = {
          value: rule,
          message: (inline: boolean) =>
            formatMessage(inline, name, type, rule, ''),
        };
      } else if (typeof rule.message === 'undefined') {
        rules[type].message = (inline: boolean) =>
          formatMessage(inline, name, type, rule.value, rule.message);
      }
    });

    if (Object.keys(newValidations).length) {
      Object.keys(newValidations).forEach(key => delete rules[key]);

      if (!rules.validate) {
        rules.validate = {};
      }

      rules.validate = {...rules.validate, ...newValidations};
    }

    return rules;
  }, [rules, label, name, t]);
};
