import './Complete.scss';

import {useState, useCallback} from 'react';
import classNames from 'classnames';

import {PUT} from 'utils/Http';

import {goToUrl, useStaffUrl, useBusiness, useSetBusiness} from 'hooks';

import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';
import Source from 'components/Common/Source';
import View from 'components/Common/View';
import Link from 'components/Common/Link';
import Button from 'components/Common/Button';
import Text from 'components/Common/Text';
import Locale from 'components/Common/Locale';
import Spacer from 'components/Common/Spacer';
import InfoBox from 'components/Common/InfoBox';

import {UrlProps} from 'types/Url';
import {BusinessStatus} from 'types/Business';

import {ReactComponent as Completed} from './Assets/Completed.svg';

const {Pending, Active} = BusinessStatus;

type BusinessStatusesProps = {
  types: boolean;
  photos: boolean;
  hours: boolean;
};

type CompleteRenderProps = {
  status: BusinessStatusesProps;
};

type CompleteOptionProps = {
  label: string;
  completed: boolean;
  url: UrlProps;
};

const Complete = () => {
  const {id, name} = useBusiness();

  return (
    <Page title={['staff.complete.title', {name}]} padding>
      <PageTitle gradient="gradient-50 dark-gray" />

      <Spacer size={30} />

      <InfoBox type="info">
        <Locale text="staff.complete.warning" />
      </InfoBox>

      <Spacer size={20} />

      <Source<BusinessStatusesProps> source={`businesses/${id}/status`}>
        {status => <CompleteRender status={status} />}
      </Source>
    </Page>
  );
};

const CompleteOption = ({label, completed, url}: CompleteOptionProps) => {
  return (
    <View className={classNames('complete-option', {completed})}>
      <Text className="label">
        <Locale text={label} />
      </Text>
      <View className="status">
        {completed ? (
          <Completed className="completed" />
        ) : (
          <Link
            className="btn primary"
            {...url}
            label="staff.complete.configure_label"
          />
        )}
      </View>
    </View>
  );
};

const CompleteRender = ({
  status: {types, photos, hours},
}: CompleteRenderProps) => {
  const staffUrl = useStaffUrl();
  const setBusiness = useSetBusiness();
  const {status, id} = useBusiness();
  const [working, setWorking] = useState(false);

  const isReady = () => {
    return hours && types && photos;
  };

  const onPressActivate = useCallback(async () => {
    if (working) {
      return;
    }

    setWorking(true);

    if (status === Pending) {
      await PUT(`businesses/${id}/status`, {
        status: Active,
      });
      setBusiness(business => ({...business, status: Active}));
    }

    goToUrl({goBack: true});
  }, [id, status, working, setWorking, setBusiness]);

  return (
    <View className="staff-complete-options">
      <CompleteOption
        label="staff.complete.hours_title"
        url={{base: staffUrl({to: 'settings/hours'})}}
        completed={hours}
      />

      <CompleteOption
        label="staff.complete.types_title"
        url={{base: staffUrl({to: 'settings/types'})}}
        completed={types}
      />

      <CompleteOption
        label="staff.complete.photos_title"
        url={{base: staffUrl({to: 'settings/photos'})}}
        completed={photos}
      />

      <Spacer size={20} />

      {isReady() && (
        <View className="activate-container">
          <Button
            working={working}
            className="btn dark-blue"
            onPress={onPressActivate}
            label="staff.complete.activate_label"
          />
        </View>
      )}
    </View>
  );
};

export default Complete;
