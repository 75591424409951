import './Notifications.scss';

import {useCallback, useState, useEffect} from 'react';
import classNames from 'classnames/bind';
import {useSelector} from 'react-redux';

import useTranslation from 'hooks/useTranslation';
import {useAppDispatch} from 'store/Hooks';

import View from 'components/Common/View';

import {add, remove, selectNotifications} from 'store/Slices/Notifications';

export type NotificationType = 'success' | 'error';

export type NotificationProps = {
  id: number | string;
  type: NotificationType;
  msg: string | string[];
  params?: any;
  duration?: number;
};

export type UseSetNotification = (
  notification: Partial<NotificationProps>,
) => void;

//export type UseSetModalStatus = (status: boolean) => void;
type SingleNotificationProps = NotificationProps;

const Notification = (notification: SingleNotificationProps) => {
  const dispatch = useAppDispatch();
  const {id, type, msg, duration, params} = notification;

  const {t} = useTranslation();
  const msgArray = Array.isArray(msg) ? msg : [msg, msg];

  const totalLength = msgArray.reduce((count, msg) => count + msg.length, 0);

  const [hiding, setHiding] = useState(false);
  useEffect(() => {
    const baseDuration = totalLength * 20;

    setTimeout(() => {
      setHiding(true);
      setTimeout(() => {
        dispatch(remove(notification));
      }, 300);
    }, duration || (baseDuration > 1500 ? baseDuration : 1500));
  }, [id, setHiding, duration, totalLength, notification, dispatch]);

  return (
    <View className={classNames('notification', type, {hiding})}>
      {t(msgArray, params)}
    </View>
  );
};

const Notifications = () => {
  const {
    notifications: {list: notifications},
  } = useSelector(selectNotifications);

  if (!notifications.length) {
    return null;
  }

  return (
    <View className={classNames('notifications', 'padding')}>
      {notifications.map(notification => (
        <Notification key={notification.id} {...notification} />
      ))}
    </View>
  );
};

export const useSetNotification = (): UseSetNotification => {
  const dispatch = useAppDispatch();

  const setNotification = useCallback(
    (notification: Partial<NotificationProps>) => {
      const newNotification: NotificationProps = {
        id: Date.now() + Math.random(),
        type: 'success',
        msg: '',
        ...notification,
      };

      dispatch(add(newNotification));
    },
    [dispatch],
  );

  return setNotification;
};

export default Notifications;
