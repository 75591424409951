import {Route, Switch} from 'react-router-dom';

import NotFound from 'pages/NotFound';

import {STAFF_PATH} from 'types/Business';

import Home from './Home';
import General from './General';
import Hours from './Hours';
import Types from './Types';
import Photos from './Photos';
import Membership from './Membership';
import SpecialDates from './SpecialDates';

const Settings = () => {
  return (
    <Switch>
      <Route exact path={`${STAFF_PATH}/settings`} component={Home} />
      <Route
        exact
        path={`${STAFF_PATH}/settings/general`}
        component={General}
      />
      <Route exact path={`${STAFF_PATH}/settings/hours`} component={Hours} />
      <Route exact path={`${STAFF_PATH}/settings/types`} component={Types} />
      <Route exact path={`${STAFF_PATH}/settings/photos`} component={Photos} />
      <Route
        path={`${STAFF_PATH}/settings/special-dates`}
        component={SpecialDates}
      />
      <Route
        path={`${STAFF_PATH}/settings/membership`}
        component={Membership}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Settings;
