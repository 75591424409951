import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import resources from 'locales';

import appConfig from 'config/app';

const DEFAULT_LANGUAGE = 'en';

i18n.use(initReactI18next).init({
  resources: resources,

  lng: appConfig.lang || DEFAULT_LANGUAGE,
  fallbackLng: DEFAULT_LANGUAGE,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
