import {DateTime} from 'luxon';

import {useTranslation} from 'hooks';

import BusinessInfo from 'types/Business';
import {DateTimeRange, DATE_TIME_FORMAT} from 'types/Date';

import View from 'components/Common/View';
import Loading from 'components/Common/Loading';
import Text from 'components/Common/Text';
import Time from 'components/Common/DateTime/Time';

type HoursListProps = {
  business: BusinessInfo;
  slots: DateTimeRange[] | null;
  setTime: (date: DateTime) => void;
};

type HourButtonProps = {
  business: BusinessInfo;
  date: DateTimeRange;
  setTime: (date: DateTime) => void;
};

const HourButton = ({business, date, setTime}: HourButtonProps) => {
  return (
    <View
      onClick={() => setTime(date.from.plus({}))}
      className="date-hour horizontal-padding">
      <Text className="time">
        <Time date={date.from} />
        {/* — <Time date={date.to} />*/}
      </Text>
    </View>
  );
};

const HoursList = ({business, slots, setTime}: HoursListProps) => {
  const {t} = useTranslation();

  if (!slots) {
    return (
      <View className="center padding">
        <Loading size="small" />
      </View>
    );
  }

  if (!slots.length) {
    return (
      <Text className="not-available center padding">
        {t('booking.time_not_avaialble')}
      </Text>
    );
  }

  return (
    <View className="hours-list">
      {slots.map(date => {
        return (
          date && (
            <HourButton
              key={date.from.toFormat(DATE_TIME_FORMAT)}
              setTime={setTime}
              business={business}
              date={date}
            />
          )
        );
      })}
    </View>
  );
};

export default HoursList;
