import classNames from 'classnames';

import {useAdminUrl, useLocation} from 'hooks';

import Link, {LinkProps} from 'components/Common/Link';

type TabbarButtonProps = LinkProps & {
  to: string;
};

const TabbarButton = ({to: toProp, ...rest}: TabbarButtonProps) => {
  const adminUrl = useAdminUrl();
  const to = adminUrl({to: `${toProp === '/' ? '' : `/${toProp}`}`});
  const {pathname} = useLocation();

  const isActive = () => {
    const isMatch = pathname.indexOf(to) === 0;

    if (isMatch) {
      if (toProp === '/') {
        return pathname === to;
      }

      return true;
    }

    return false;
  };

  return (
    <Link
      to={to}
      className={classNames('tabbar-button', {
        active: isActive(),
      })}
      {...rest}
    />
  );
};

export default TabbarButton;
