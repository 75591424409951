import {useRef, useCallback} from 'react';
import classNames from 'classnames';
import {useFormState} from 'react-hook-form';

import {useIsFormCreation} from 'hooks';

import Button, {ButtonProps} from 'components/Common/Button';

type Props = ButtonProps;

const Submit = ({className, label, ...props}: Props) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const isCreation = useIsFormCreation();
  const {isSubmitting} = useFormState();

  const onPress = useCallback(
    e => {
      if (isSubmitting) {
        e.preventDefault();
      }
    },
    [isSubmitting],
  );

  return (
    <Button
      ref={buttonRef}
      onPress={onPress}
      className={classNames('form-button', 'submit-button', className)}
      label={
        label ? label : isCreation ? 'form.button_add' : 'form.button_save'
      }
      working={isSubmitting}
      type="submit"
      {...props}
    />
  );
};

export default Submit;
