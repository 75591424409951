import './Autocomplete.scss';

import {
  useCallback,
  useRef,
  useState,
  useEffect,
  ReactNode,
  HTMLProps,
} from 'react';
import classNames from 'classnames';

import {useIsMounted, useSourceRequester, useOnClickOutside} from 'hooks';

import View from 'components/Common/View';
import Text from 'components/Common/Text';
import Locale from 'components/Common/Locale';

import InputRender, {InputRenderProps} from '../Input/Render';

export type ItemValue = {
  name: string;
  description: string;
  value: any;
  showAsLabel: boolean;
};

type AnyProps = {
  [name: string]: any;
};

type ItemType<D> = D & Partial<ItemValue>;

type AutocompleteProps<D, T> = HTMLProps<HTMLInputElement> &
  InputRenderProps<T> & {
    children?: ReactNode;
    source?: string;
    sourceParams?: AnyProps;
    sourceParser?: (items: ItemType<D>[]) => ItemType<D>[];
    items?: ItemType<D>[];
    itemRender?: (item: ItemType<D>, index: number) => ReactNode;
    onPressItem?: (item: ItemType<D>) => void;
    onChangeValue?: (value: string) => void;
    noTrans?: boolean;
  };

const Autocomplete = <D, T = any>({
  children,
  className = 'default',
  items: initItems,
  itemRender,
  onPressItem,
  noTrans,
  source,
  sourceParams,
  sourceParser,
  onFocus,
  onChange,
  onChangeValue,

  ...props
}: AutocompleteProps<D, T>) => {
  const isMounted = useIsMounted();
  const {request, setCallback} = useSourceRequester<ItemType<D>>();
  const [items, setItems] = useState<ItemType<D>[]>([]);
  const [active, setActive] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const firstLoad = useRef(false);

  const onClickOutside = useCallback(() => {
    setActive(false);
  }, [setActive]);

  useOnClickOutside(containerRef, onClickOutside);

  useEffect(() => {
    if (source || !initItems) {
      return;
    }

    setItems(initItems);

    if (!firstLoad.current) {
      firstLoad.current = true;
      return;
    }

    setActive(true);
  }, [firstLoad, source, initItems]);

  const onResults = useCallback(
    (results: ItemType<D>[]) => {
      if (!isMounted()) {
        return;
      }

      if (source && sourceParser) {
        setItems(sourceParser(results));
      } else {
        setItems(results);
      }

      setActive(true);
    },
    [source, sourceParser, isMounted],
  );

  const _onChange = useCallback(
    e => {
      if (onChangeValue) {
        onChangeValue(e.target.value);
      }

      if (onChange) {
        onChange(e);
        return;
      }

      const {name, value} = e.target;
      const parsedValue = value.trim();

      if (!parsedValue) {
        onResults([]);
        return;
      }

      if (source) {
        request(source, {
          [name]: parsedValue,
          ...sourceParams,
        });
      }
    },
    [source, onChange, onResults, onChangeValue, sourceParams, request],
  );

  useEffect(() => {
    if (source) {
      setCallback(onResults);
    }
  }, [source, onResults, setCallback]);

  const _onFocus = useCallback(
    e => {
      setActive(true);

      if (onFocus) {
        onFocus(e);
      }
    },
    [onFocus, setActive],
  );

  const renderRawItem = (item: ItemType<D>) => {
    const name = (item as ItemValue).name || '';
    const description = (item as ItemValue).description || '';

    return (
      <>
        {noTrans ? (
          <Text className="item">
            <Text className="name">{name}</Text>
            {description && <Text className="desc">{description}</Text>}
          </Text>
        ) : (
          <Text className="item">
            <Text className="name">
              <Locale text={name} />
            </Text>
            {description && (
              <Text className="desc">
                <Locale text={description} />
              </Text>
            )}
          </Text>
        )}
      </>
    );
  };

  const _itemRender = (item: ItemType<D>, idx: number) => {
    return <>{itemRender ? itemRender(item as D, idx) : renderRawItem(item)}</>;
  };

  const renderResults = () => {
    if (!active) {
      return null;
    }

    if (!items?.length) {
      return null;
    }

    return (
      <View className="results-container">
        <View className="results">
          {items.map((item: ItemType<D>, idx) => (
            <View
              onClick={e => {
                if (item.showAsLabel) {
                  return;
                }

                setActive(false);

                if (onPressItem) {
                  onPressItem(item as D);
                }

                if (containerRef.current) {
                  const input =
                    containerRef.current.querySelector<HTMLInputElement>(
                      'input',
                    );

                  if (input) {
                    const itemAny = item as any;
                    input.value = itemAny.name || itemAny.display;
                  }
                }
              }}
              className={classNames(
                item.showAsLabel ? 'item-label' : 'item-value',
              )}
              key={idx}>
              {_itemRender(item, idx)}
            </View>
          ))}
        </View>
      </View>
    );
  };

  return (
    <View
      ref={containerRef}
      className={classNames('autocomplete-container', className)}>
      <InputRender<T>
        onFocus={_onFocus}
        onChange={_onChange}
        {...props}
        render={props => <input {...props} />}
      />
      {children}
      {renderResults()}
    </View>
  );
};

export default Autocomplete;
