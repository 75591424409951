import {useEffect, useCallback} from 'react';
import {DateTime} from 'luxon';

import {PUT} from 'utils/Http';

import {parseErrorInfo} from 'utils/Error';

import {
  setFooterStatus,
  useSourceData,
  useSourceLoadData,
  goToUrl,
} from 'hooks';

import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';
import View from 'components/Common/View';
import Locale from 'components/Common/Locale';
import TimeList from 'components/Booking/Time';
import Redirect from 'components/Common/Redirect';

import AppointmentInfo, {AppointmentStatus} from 'types/Appointment';
import {DATE_TIME_FORMAT} from 'types/Date';

import {convertToDateTime} from 'utils/DateTime';
import {useSetNotification} from 'components/App/Notifications';

const {Confirmed} = AppointmentStatus;

const Time = () => {
  const setNotification = useSetNotification();
  const appointment = useSourceData<AppointmentInfo>();
  const sourceLoadData = useSourceLoadData();
  const {
    id,
    date,
    status,
    business,
    minutes_total,
    business: {name},
  } = appointment;

  useEffect(() => {
    setFooterStatus(true);
    return setFooterStatus;
  }, []);

  const setDate = useCallback(
    async (newDate: DateTime) => {
      const {minutes} = newDate.diff(convertToDateTime(date), 'minutes');

      if (!minutes) {
        setNotification({
          type: 'error',
          msg: 'appointments.time_error_same_date_msg',
          duration: 4000,
        });
        return;
      }

      try {
        await PUT(`appointments/${id}`, {
          date: newDate.toFormat(DATE_TIME_FORMAT),
        });

        setNotification({
          type: 'success',
          msg: 'appointments.time_success_msg',
          duration: 4000,
        });

        goToUrl({goBack: true});
        sourceLoadData();
      } catch (e) {
        const {msg} = parseErrorInfo(e);

        setNotification({
          type: 'error',
          msg,
        });
      }
    },
    [id, date, sourceLoadData, setNotification],
  );

  if (status !== Confirmed) {
    return <Redirect goBack />;
  }

  return (
    <Page title={['appointments.time_title', {name}]}>
      <View className="vertical-padding">
        <PageTitle gradient="gradient-40 dark-gray horizontal-padding">
          <Locale text="appointments.time_subtitle" />
        </PageTitle>

        <View className="horizontal-padding">
          <TimeList
            selectedDate={date}
            setDate={setDate}
            minutes={minutes_total}
            business={business}
          />
        </View>
      </View>
    </Page>
  );
};

export default Time;
