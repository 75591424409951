import {useEffect, useState, useCallback} from 'react';
import {DateTime} from 'luxon';
import classNames from 'classnames';

import {GET} from 'utils/Http';

import {useIsMounted} from 'hooks';

import View from 'components/Common/View';

import BusinessInfo from 'types/Business';
import {DateTimeRange, DATE_TIME_FORMAT} from 'types/Date';

import {convertToDateTime, getUTCFromTimezone} from 'utils/DateTime';

import {HOUR_HEIGHT, dateToPosition} from './index';

type BackgroundGridProps = {
  date: DateTime;
  business: BusinessInfo;
};

type TimeRangeProps = {
  range: DateTimeRange;
};

const BackgroundGrid = ({date, business}: BackgroundGridProps) => {
  const isMounted = useIsMounted();
  const {id, timezone} = business;
  const [availability, setAvailability] = useState<DateTimeRange[]>([]);

  const loadAvailability = useCallback(async () => {
    const response = await GET<DateTimeRange[]>(`availability/${id}`, {
      from_date: date.toFormat(DATE_TIME_FORMAT),
    });

    if (!isMounted()) {
      return;
    }

    //convertToDateTime
    setAvailability(response);
  }, [id, date, setAvailability, isMounted]);

  useEffect(() => {
    loadAvailability();
  }, [loadAvailability]);

  const nowDate = getUTCFromTimezone(timezone);
  const isSameDay = nowDate.hasSame(date, 'day');

  useEffect(() => {
    if (!isSameDay) {
      if (!availability.length) {
        window.scrollTo({
          top: 0,
        });
      }

      const startTimeEl: HTMLDivElement | null = document.querySelector(
        `.time-range:first-child`,
      );

      if (startTimeEl) {
        window.scrollTo({
          top: startTimeEl.offsetTop - HOUR_HEIGHT,
        });
      }

      return;
    }

    const nowTimeEl: HTMLDivElement | null = document.querySelector(
      '.calendar-day .now-time',
    );

    if (nowTimeEl) {
      window.scrollTo({
        top: nowTimeEl.offsetTop - HOUR_HEIGHT,
      });
    }
  }, [date, isSameDay, availability]);

  return (
    <View className="calendar-day-background">
      {availability.map(range => (
        <TimeRange key={`${range.from}-${range.to}`} range={range} />
      ))}
    </View>
  );
};

const TimeRange = ({range}: TimeRangeProps) => {
  const {from, to} = range;

  const fromDate = convertToDateTime(from);
  const toDate = convertToDateTime(to);

  const top = dateToPosition(fromDate);
  const height = dateToPosition(toDate) - top;

  return (
    <View
      style={{
        top,
        height,
      }}
      className={classNames('time-range')}
    />
  );
};

export default BackgroundGrid;
