import axios from 'axios';

import {getToken} from 'core/session';

interface Config {
  [name: string]: any;
}

const env = process.env;
const app: Config = {
  dev: env.REACT_APP_DEV === 'true',
  endpoint: env.REACT_APP_ENDPOINT || 'http://localhost',
  firebase: JSON.parse(process.env.REACT_APP_FIREBASE || ''),
  lang: env.REACT_APP_LANG,
  stripe_key: env.REACT_APP_STRIPE_KEY || '',
  google_api_key: env.REACT_APP_GOOGLE_API_KEY || '',
  sentry_ingest: env.REACT_APP_SENTRY_INGEST || '',
};

axios.defaults.baseURL = app.endpoint;

axios.interceptors.request.use(config => {
  const token = getToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export default app;
