import {ReactNode} from 'react';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import appConfig from 'config/app';

import {ErrorBoundary as ErrorBoundaryBase} from 'react-error-boundary';

import Error from 'components/Common/Error';

const enableErrors = !appConfig.dev;

if (enableErrors) {
  console.info(`[Sentry] Starting sentry.io`, !!appConfig.sentry_ingest);

  if (appConfig.sentry_ingest) {
    Sentry.init({
      dsn: appConfig.sentry_ingest,
      integrations: [new Integrations.BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      //tracesSampleRate: 1.0,
      //tracesSampleRate: 0.1,
    });
  }
}

type Props = {
  children?: ReactNode;
};

const ErrorCatcher = ({error, resetErrorBoundary}: any) => {
  if (enableErrors) {
    Sentry.withScope(scope => {
      Sentry.captureException(error);
    });
  }

  return <Error info={error} />;
};

const ErrorBoundary = ({children}: Props) => {
  return (
    <ErrorBoundaryBase FallbackComponent={ErrorCatcher}>
      {children}
    </ErrorBoundaryBase>
  );
};

export default ErrorBoundary;
