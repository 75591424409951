import './Menu.scss';

import {ReactNode} from 'react';

import View from 'components/Common/View';
import Spacer from 'components/Common/Spacer';
import WithLove from 'components/App/Footer/WithLove';

import BusinessMenu from './Business';

type MenuProps = {
  children: ReactNode;
};

export const BaseMenu = ({children}: MenuProps) => {
  return (
    <View className="staff-menu-container-shadow">
      <View className="staff-menu-container">
        {children}

        <Spacer size={20} />

        <View className="footer">
          <WithLove theme="light" />
        </View>
      </View>
    </View>
  );
};

export const Menu = () => {
  return (
    <BaseMenu>
      <BusinessMenu />
    </BaseMenu>
  );
};

export default Menu;
