import {ReactNode} from 'react';
import {DateTime} from 'luxon';

import {NodeType} from 'components/Common/NodeRender';
import {UseCalendarLoadData} from 'components/Staff/Calendar/CalendarDay';

import {BookingPages} from 'types/Booking';
import ServiceInfo from 'types/Service';
import {UrlProps} from 'types/Url';
import {UserInfoExtra, UserStatus} from 'types/User';
import Notification from 'types/Notification';
import {
  ExplorerLocation,
  ExplorerSearchCallback,
  ExplorerListStatusCallback,
  ExplorerSearchTextCallback,
} from 'types/Explorer';
import {BusinessTypeResponse} from 'types/BusinessType';

import {BusinessContextProps} from 'pages/Businesses/Business';

export class UserStore {
  id: string | null = null;
  group: number | null = null;

  is_new = false;
  logged = false;
  loaded = false;

  userData: UserInfoExtra | null = null;
  userStatus: Partial<UserStatus> = {};

  currentLocation: ExplorerLocation | null = null;
  currentCity: string | null = null;
}

export class BookingStore {
  view: BookingPages = null;
  date: DateTime | null = null;
  forcedDate = false;
  notes: string | null = null;
  services: ServiceInfo[] = [];
  isFromStaff = false;
  userName: string | null = null;
  clientId: string | null = null;
  userId: string | null = null;
}

export class BusinessStore {
  context: BusinessContextProps | null = null;
}

export class UIStore {
  redirect: UrlProps | null = null;
  hideHeaderCount = 0;
  hideFooterCount = 0;

  headerContent: NodeType = null;
  headerLeft: NodeType = null;
  headerRight: NodeType = null;
  headerGradientContent: NodeType = null;

  headerTransparent = false;
  headerClassName = '';
  headerTheme: 'dark' | 'light' = 'dark';
  headerGradient: string | null = null;
  headerNotifications: Notification[] = [];

  modals: Map<string, ReactNode> = new Map();

  loadCalendar: UseCalendarLoadData = async () => {
    /* do nothing */
  };

  explorerIs = false;
  explorerSearch: ExplorerSearchCallback = () => false;
  explorerListStatus: ExplorerListStatusCallback = () => 'loaded';
  explorerSearchText: ExplorerSearchTextCallback = () => '';
}

export class CacheStore {
  businessTypes: BusinessTypeResponse[] = [];
}
