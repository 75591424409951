import './Details.scss';

import classNames from 'classnames';

import View from 'components/Common/View';
import Image from 'components/Common/Image';
import Text from 'components/Common/Text';
import Locale from 'components/Common/Locale';
import Spacer from 'components/Common/Spacer';
import RatingInfo from 'components/Business/RatingInfo';
import OpenAddressButton from 'components/Business/OpenAddressButton';
import BusinessInfo, {BUSINESS_PHOTO_LIST_SIZE} from 'types/Business';

type Props = {
  showPhoto?: boolean;
  showRating?: boolean;
  showAddress?: boolean;
  showDescription?: boolean;
  showOpenAddressButton?: boolean;
  inline?: boolean;
  business: BusinessInfo;
};

const Details = ({
  business,
  inline = false,
  showPhoto = false,
  showOpenAddressButton = false,
  showRating = true,
  showAddress = true,
  showDescription = false,
}: Props) => {
  const {name, address, description, type, photo} = business;

  return (
    <View className={classNames('business-details', {inline})}>
      <View className="container">
        <Text className="name">{name}</Text>
        {type && (
          <Text className="type">
            <Locale text={`business_types.${type.type}`} />
          </Text>
        )}

        {showRating && (
          <RatingInfo
            className={classNames('business-page', {inline})}
            business={business}
          />
        )}

        {showAddress && address && <Text className="address">{address}</Text>}
        {showOpenAddressButton && (
          <>
            <Spacer size={5} />
            <OpenAddressButton business={business} />
          </>
        )}

        {showDescription && description && (
          <>
            <Spacer size={10} />
            <Text className="description">{description}</Text>
          </>
        )}
      </View>
      {(showPhoto || inline) && photo && (
        <View className="image">
          <Image
            className="source"
            src={photo.url}
            params={BUSINESS_PHOTO_LIST_SIZE}
          />
        </View>
      )}
    </View>
  );
};

export default Details;
