import {useCallback} from 'react';
import {generatePath} from 'react-router';

import {useBusiness, useUrlFormatter} from 'hooks';

import BusinessInfo, {STAFF_PATH} from 'types/Business';
import {UrlProps} from 'types/Url';

export const useStaffUrl = (business?: BusinessInfo) => {
  const {slug} = useBusiness();
  const urlFormatter = useUrlFormatter();
  const id = business?.slug || slug;
  const path = slug
    ? generatePath(STAFF_PATH, {
        id: id || slug,
      })
    : '';

  return useCallback(
    (props?: UrlProps, business?: BusinessInfo) => {
      let basePath = path;

      if (business) {
        basePath = generatePath(STAFF_PATH, {
          id: business.slug,
        });
      }

      if (!props || Object.keys(props).length === 0) {
        return basePath;
      }

      const finalUrl = urlFormatter(props);

      if (finalUrl.indexOf(basePath) !== 0) {
        return `${basePath}${finalUrl}`;
      }

      return finalUrl;
    },
    [urlFormatter, path],
  );
};
