import {
  useEffect,
  useMemo,
  KeyboardEvent,
  FocusEvent,
  ChangeEvent,
} from 'react';
import {useFormState} from 'react-hook-form';
import classNames from 'classnames';

import {useFormData, useForm, useTranslation, useSetFieldMetadata} from 'hooks';

import {useRules} from 'utils/Form';

import {useField} from '../Field';

import {FieldRelation} from '../index';

type RenderType = {render: (props: any) => JSX.Element};

export type AnyProps = {[name: string]: any};

export type InputRenderProps<T> = {
  name?: string;
  type?: string;
  trim?: boolean;
  noTrans?: boolean;
  optional?: boolean;
  required?: boolean;
  defaultValue?: any;
  readOnly?: boolean;
  disabled?: boolean;
  className?: string;
  rules?: any;
  onChange?: (
    event: ChangeEvent,
    value: T,
  ) => void | boolean | Promise<boolean | void>;
  onFocus?: (event: FocusEvent, value: T) => void | Promise<void>;
  onBlur?: (
    event: FocusEvent,
    value: T,
  ) => void | boolean | Promise<boolean | void>;
  onKeyDown?: (event: KeyboardEvent) => void | Promise<void>;
  onKeyUp?: (event: KeyboardEvent) => void | Promise<void>;
  asNumber?: boolean;
  setValueAs?: (value: T) => void;
  valueAsBoolean?: boolean;
  valueAsDate?: boolean;
  valueAsNumber?: boolean;
  valueAsObject?: boolean;
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  pattern?: RegExp;
  validate?: any;
  label?: string;
  labelParams?: AnyProps;
  placeholder?: string;
  placeholderParams?: AnyProps;
  fieldRelation?: string[] | FieldRelation;
};

export const InputRender = <T,>({
  name: nameProp,
  type = 'text',
  defaultValue: defaultValueProp,
  className,
  noTrans,
  label: labelProp,
  labelParams: labelParamsProp,
  placeholder: placeholderProp,
  placeholderParams: placeholderParamsProp,
  rules,
  onChange: onChangeProp,
  onFocus: onFocusProp,
  onBlur: onBlurProp,
  onKeyDown,
  onKeyUp,
  setValueAs,
  valueAsDate: valueAsDateProp,
  valueAsBoolean: valueAsBooleanProp,
  valueAsNumber: valueAsNumberProp,
  valueAsObject,
  required: requiredProp,
  readOnly: readOnlyProp,
  disabled,
  optional,
  min,
  max,
  minLength,
  maxLength,
  pattern,
  validate,
  render,
  fieldRelation,
  ...props
}: InputRenderProps<T> & RenderType) => {
  const {t} = useTranslation();
  const setFieldMetadata = useSetFieldMetadata();
  const {setFieldRelation, register, getValues, control} = useForm();
  const {
    name: fieldName,
    label: fieldLabel,
    labelParams: fieldLabelParams,
    placeholder: fieldPlaceholder,
    placeholderParams: fieldPlaceholderParams,
  } = useField<{
    name: string;
    label: string;
    labelParams: AnyProps;
    placeholder: string;
    placeholderParams: AnyProps;
  }>();

  const name = nameProp || fieldName;

  if (!name) {
    throw new Error('form/undefined-input-name');
  }

  const {[name]: fieldValue} = useFormData<{[name: string]: T}>();
  const defaultValue = useMemo(() => {
    if (typeof defaultValueProp !== 'undefined') {
      return defaultValueProp;
    }

    if (fieldValue && typeof fieldValue === 'object' && 'id' in fieldValue) {
      return (fieldValue as any).id;
    }

    return fieldValue;
  }, [defaultValueProp, fieldValue]);

  useEffect(() => {
    if (Array.isArray(fieldRelation)) {
      setFieldRelation({[name]: [...fieldRelation]});
    } else if (typeof fieldRelation === 'object') {
      setFieldRelation(fieldRelation);
    }
  }, [name, setFieldRelation, fieldRelation]);

  useEffect(() => {
    if (!valueAsObject) {
      return;
    }

    setFieldMetadata(name, {valueAsObject});
  }, [name, setFieldMetadata, valueAsObject]);

  const label = typeof labelProp !== 'undefined' ? labelProp : fieldLabel;
  const placeholder =
    typeof placeholderProp !== 'undefined'
      ? placeholderProp
      : fieldPlaceholder || label;

  const required = optional ? false : requiredProp;

  const valueAsDate = valueAsDateProp || type === 'date';
  const valueAsNumber = valueAsNumberProp || type === 'number';
  const valueAsBoolean = valueAsNumberProp || type === 'checkbox';

  const allRules = useMemo(
    () => ({
      noTrans,
      setValueAs,
      valueAsDate,
      valueAsNumber,
      required,
      min,
      max,
      minLength,
      maxLength,
      pattern,
      validate,
      ...rules,
    }),
    [
      noTrans,
      setValueAs,
      valueAsDate,
      valueAsNumber,
      required,
      min,
      max,
      minLength,
      maxLength,
      pattern,
      validate,
      rules,
    ],
  );

  const parsedRules = useRules(
    label ? t(label, labelParamsProp || fieldLabelParams) : label,
    name,
    allRules,
  );

  const field = useMemo(
    () => register(name, parsedRules),
    [name, register, parsedRules],
  );

  const {onBlur, onChange} = field;

  const {errors, isSubmitting} = useFormState({name, control});
  const fieldError = errors[name];

  const _onBlur = useMemo(
    () =>
      onBlurProp
        ? async (e: FocusEvent) => {
            if ((await onBlurProp(e, getValues(name) as T)) === false) {
              return;
            }
            onBlur(e);
          }
        : onBlur,
    [name, getValues, onBlurProp, onBlur],
  );

  const _onFocus = useMemo(
    () =>
      onFocusProp
        ? (e: FocusEvent) => {
            onFocusProp(e, getValues(name) as T);
          }
        : undefined,
    [name, getValues, onFocusProp],
  );

  const _onChange = useMemo(
    () =>
      onChangeProp || valueAsObject
        ? async (e: ChangeEvent) => {
            if (
              onChangeProp &&
              (await onChangeProp(e, getValues(name) as T)) === false
            ) {
              return;
            }

            if (valueAsObject) {
              const target = e.target as any;
              target.value = JSON.stringify(e);
            }

            onChange(e);
          }
        : onChange,
    [name, getValues, onChangeProp, valueAsObject, onChange],
  );

  const readOnly = readOnlyProp || isSubmitting;

  const content = useMemo(() => {
    const finalDefaultValue =
      defaultValue && valueAsObject
        ? JSON.stringify(defaultValue)
        : defaultValue;

    const params = {
      ...field,
      //...props,

      ...(valueAsBoolean
        ? {defaultChecked: finalDefaultValue}
        : {defaultValue: finalDefaultValue}),

      id: name,
      type,
      onBlur: _onBlur,
      onChange: _onChange,
      onFocus: _onFocus,
      onKeyDown,
      onKeyUp,
      maxLength,
      readOnly,
      disabled: readOnly || disabled,
      placeholder: placeholder
        ? t(
            placeholder,
            placeholderParamsProp ||
              fieldPlaceholderParams ||
              fieldLabelParams ||
              labelParamsProp,
          )
        : placeholder,
      className: classNames(
        className,
        'input',
        {
          [`input-${type}`]: type,
          [`input-${name}`]: true,
          readonly: readOnly,
          disabled: disabled,
        },
        {error: fieldError},
      ),
    };

    return render(params);
  }, [
    _onBlur,
    _onChange,
    _onFocus,
    onKeyDown,
    onKeyUp,
    className,
    defaultValue,
    fieldError,
    field,
    valueAsObject,
    valueAsBoolean,
    //props,
    fieldLabelParams,
    fieldPlaceholderParams,
    labelParamsProp,
    maxLength,
    name,
    placeholder,
    placeholderParamsProp,
    render,
    t,
    type,
    readOnly,
    disabled,
  ]);

  return <>{content}</>;
};

export default InputRender;
