import {useState, useCallback} from 'react';

import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';

import View from 'components/Common/View';
import Form from 'components/Form';
import Spacer from 'components/Common/Spacer';
import Submit from 'components/Form/Submit';
import Field from 'components/Form/Field';
import Input from 'components/Form/Input';
import Label from 'components/Form/Label';
import PhoneFields from 'components/User/PhoneFields';

const ChangePhoneForm = () => {
  const [waitingInfo, setWaitingInfo] = useState<{phone: string} | null>(null);

  const onSuccessCode = useCallback(async ({goToUrl}) => {
    goToUrl({to: 'settings'});
  }, []);

  const onSuccess = useCallback(
    async ({data: {phone, phone_prefix}}) => {
      setWaitingInfo({phone: `${phone_prefix} ${phone}`});
    },
    [setWaitingInfo],
  );

  return (
    <>
      {!waitingInfo && (
        <>
          <Form
            creation
            method="PUT"
            source="user/profile/send-verification-code"
            successMsg={false}
            onSuccess={onSuccess}>
            {() => {
              return (
                <>
                  <PhoneFields />

                  <Spacer size={25} />

                  <Submit label="settings.change_phone.send_code_button" />
                </>
              );
            }}
          </Form>
          <View id="recaptcha-container"></View>
        </>
      )}
      {waitingInfo && (
        <Form
          onSuccess={onSuccessCode}
          creation
          successMsg="settings.change_phone.changed_msg"
          method="PUT"
          source="user/profile/confirm-verification-code">
          {() => {
            return (
              <>
                <View>
                  <Field name="code">
                    <Label
                      label="settings.change_phone.field_code_label"
                      labelParams={{phone: waitingInfo.phone}}
                    />
                    <Input
                      label="settings.change_phone.field_code"
                      name="code"
                    />
                  </Field>
                </View>
                <br />
                <Submit label="settings.change_phone.verify_code_button" />
              </>
            );
          }}
        </Form>
      )}
    </>
  );
};

const ChangePhone = () => {
  return (
    <Page title="settings.change_phone.title" padding>
      <PageTitle className="center" />

      <ChangePhoneForm />
    </Page>
  );
};

export default ChangePhone;
