import {useRef, useCallback} from 'react';

import {GET} from 'utils/Http';

type AnyProps = {
  [name: string]: any;
};

type SourceSearchCallback<T> = (results: T[]) => void;
type SourceSearchSearch = (endpoint: string, query?: AnyProps) => Promise<void>;

type SourceSearchReturn<T> = {
  request: SourceSearchSearch;
  setCallback: (callback: SourceSearchCallback<T>) => void;
};

const useSourceRequester = <T>(
  callback?: SourceSearchCallback<T>,
): SourceSearchReturn<T> => {
  const callbackRef = useRef<SourceSearchCallback<T> | null>(callback || null);
  const lastRequestRef = useRef<number>(0);

  const request: SourceSearchSearch = useCallback(
    async (endpoint: string, query?: AnyProps) => {
      const currentRequest = Date.now();
      lastRequestRef.current = currentRequest;

      const results = await GET<T[]>(endpoint, query);

      if (lastRequestRef.current !== currentRequest) {
        return;
      }

      if (callbackRef.current) {
        callbackRef.current(results);
      }
    },
    [callbackRef, lastRequestRef],
  );

  const setCallback = useCallback(
    (callback: SourceSearchCallback<T>) => {
      callbackRef.current = callback;
    },
    [callbackRef],
  );

  return {request, setCallback};
};

export default useSourceRequester;
