import classNames from 'classnames';

import ButtonBase, {ButtonOptionProps} from './Button';

export type LeftButtonProps = ButtonOptionProps;

const LeftButton = ({className, ...props}: LeftButtonProps) => {
  return (
    <ButtonBase
      iconPosition="before"
      className={classNames('left', className)}
      {...props}
    />
  );
};

export default LeftButton;
