import {DateTime} from 'luxon';
import {
  ResponsiveContainer,
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Area,
  Tooltip,
} from 'recharts';

import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';
import Source from 'components/Common/Source';
import View from 'components/Common/View';

import {
  DateValue,
  formatDailyDateColumns,
  formatMonthlyDateColumns,
} from 'utils/Charts';

import {DATE_FORMAT} from 'types/Date';
import Text from 'components/Common/Text';

const Home = () => {
  return (
    <Page title="Admin panel" padding>
      <PageTitle className="staff-title" />

      <DailyAppointmentsChart />
      <MonthlyAppointmentsChart />
    </Page>
  );
};

const DailyAppointmentsChart = () => {
  const fromDate = DateTime.now().minus({days: 30});
  const toDate = DateTime.now().plus({days: 1});

  return (
    <>
      <Text large bold>
        Daily appointments
      </Text>
      <Source<DateValue[]>
        source="appointments/count"
        sourceParams={{
          from_date: fromDate.toFormat(DATE_FORMAT),
          to_date: toDate.toFormat(DATE_FORMAT),
        }}>
        {dates => {
          const data = formatDailyDateColumns({
            fromDate,
            toDate,
            dates,
          });

          return (
            <View className="expand">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={data}>
                  <defs>
                    <linearGradient id="baseColor" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="value"
                    stroke="#82ca9d"
                    fillOpacity={1}
                    fill="url(#baseColor)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </View>
          );
        }}
      </Source>
    </>
  );
};

const MonthlyAppointmentsChart = () => {
  const fromDate = DateTime.now().minus({days: 365});
  const toDate = DateTime.now().plus({days: 1});

  return (
    <>
      <Text large bold>
        Monthly appointments
      </Text>
      <Source<DateValue[]>
        source="appointments/count"
        sourceParams={{
          from_date: fromDate.toFormat(DATE_FORMAT),
          to_date: toDate.toFormat(DATE_FORMAT),
        }}>
        {dates => {
          const data = formatMonthlyDateColumns({
            fromDate,
            toDate,
            dates,
          });

          return (
            <View className="expand">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={data}>
                  <defs>
                    <linearGradient id="baseColor" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="value"
                    stroke="#82ca9d"
                    fillOpacity={1}
                    fill="url(#baseColor)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </View>
          );
        }}
      </Source>
    </>
  );
};

export default Home;
