import {useParams} from 'hooks';

import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';

import Form from 'components/Form';
import Actions from 'components/Form/Actions';
import Field from 'components/Form/Field';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import Label from 'components/Form/Label';
import Submit from 'components/Form/Submit';
import Remove from 'components/Form/Remove';

import {UserProfileInfo, UserGroups} from 'types/User';

import {
  //renderFirstNameField,
  //renderLastNameField,
  renderEmailField,
} from 'pages/Settings/General';

const Edit = () => {
  const {id} = useParams<{id: string}>();

  console.log('user?', `users/${id}`);
  return (
    <Page title="Edit" padding>
      <PageTitle className="staff-title center" />
      <Form<UserProfileInfo>
        onSuccess={({goToUrl}) => goToUrl({goBack: true})}
        source={`users/${id}`}>
        {() => (
          <>
            <Field name="group" label="User Group">
              <Label />
              <Select
                options={[
                  {
                    name: 'User',
                    value: UserGroups.User,
                  },
                  {
                    name: 'Admin',
                    value: UserGroups.Admin,
                  },
                ]}
              />
            </Field>

            {renderEmailField(false)}

            <Field name="phone" label="settings.field_phone">
              <Label />
              <Input optional minLength={6} maxLength={32} />
            </Field>

            <Actions>
              <Submit />
              <Remove label="Disable user" />
            </Actions>
          </>
        )}
      </Form>
    </Page>
  );
};

export default Edit;
