import {useStaffUrl} from 'hooks';

import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';
import Form from 'components/Form';
import Actions from 'components/Form/Actions';
import Submit from 'components/Form/Submit';

import {
  renderNameField,
  renderDescriptionField,
  AddressField,
} from 'pages/Businesses/Business/Staff/Settings/General';

import BusinessInfo from 'types/Business';

const General = () => {
  const staffUrl = useStaffUrl();

  return (
    <Page title="business.create.title" padding>
      <PageTitle gradient="gradient-40 dark-gray" />

      <Form<BusinessInfo>
        onSuccess={({goToUrl, data}) => goToUrl({base: staffUrl({}, data)})}
        successMsg={false}
        creation
        source={`businesses`}>
        {() => {
          return <RenderForm />;
        }}
      </Form>
    </Page>
  );
};

const RenderForm = () => {
  return (
    <>
      <AddressField creation />

      {renderNameField()}
      {renderDescriptionField()}

      <Actions>
        <Submit label="business.create.button" />
      </Actions>
    </>
  );
};

export default General;
