import {useEffect, ReactNode} from 'react';

import {useStore} from 'core/hooks';
import {goToUrl, useLocation} from 'hooks';

export type PageProps = {
  children?: ReactNode;
};

const Auth = ({children}: PageProps) => {
  const {pathname} = useLocation();
  const {loaded, logged} = useStore('loaded', 'logged');

  useEffect(() => {
    if (loaded && !logged) {
      goToUrl({
        to: 'login',
        replace: true,
        redirect: {to: pathname, replace: true},
      });
    }
  }, [loaded, logged, pathname]);

  if (!loaded || !logged) {
    return null;
  }

  return <>{children}</>;
};

export default Auth;
