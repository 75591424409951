import {useRef} from 'react';

import {goToUrl, useUrl} from 'hooks';

import ModalPage from 'components/App/Modal/Page';

import AppointmentView from '../Appointments/Appointment';

const Appointment = () => {
  const baseUrl = useUrl({goBack: 2});
  const startUrl = useRef(baseUrl);

  return (
    <ModalPage setClosed={() => goToUrl({to: startUrl.current})}>
      <AppointmentView />
    </ModalPage>
  );
};

export default Appointment;
