import {useBusiness, useSourceData, goToUrl} from 'hooks';

import Title from 'components/Page/Title';
import PageTitle from 'components/Page/PageTitle';
import View from 'components/Common/View';
import PaymentForm from 'components/Membership/PaymentForm';

import MembershipInfo from 'types/Membership';

const Checkout = () => {
  const business = useBusiness();
  const membership = useSourceData<MembershipInfo>();

  return (
    <>
      <Title title="staff.settings.membership.change_payment_title" />
      <PageTitle className="staff-title center" />

      <View className="membership-container">
        <PaymentForm
          usePaymentUpdate
          onPaymentUpdated={() => goToUrl({goBack: true})}
          business={business}
          membership={membership}
        />
      </View>
    </>
  );
};

export default Checkout;
