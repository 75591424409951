import {useEffect, useCallback} from 'react';

import {useStore, getStore} from 'core/hooks';

import {loadUserStatus} from 'utils/User';
import {getCity} from 'utils/City';
import {getLocation} from 'utils/Location';
import {doRedirect} from 'utils/Url';

import {UserStore} from 'types/Store';

const userStore = getStore<UserStore>('user');

const StatusCheck = () => {
  const logged = useStore<boolean>('logged');

  const loadStatus = useCallback(async () => {
    if (!logged) {
      userStore.userStatus = {};
      return;
    }

    await loadUserStatus();

    doRedirect();
  }, [logged]);

  // Load status
  useEffect(() => {
    loadStatus();
  }, [loadStatus]);

  // Set initial currentCity and currentLocation
  useEffect(() => {
    userStore.currentCity = getCity();
    userStore.currentLocation = getLocation();
  }, []);

  return null;
};

export default StatusCheck;
