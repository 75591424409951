import './Icon.scss';

import className from 'classnames';

import NodeRender, {NodeType} from 'components/Common/NodeRender';

import View from 'components/Common/View';

export type IconType = string | NodeType;

export type IconProps = {
  icon: IconType;
  className?: string;
};

const Icon = ({icon}: IconProps) => {
  if (typeof icon === 'string') {
    return <View className={className('icon', `icon-${icon}`, className)} />;
  } else if (icon) {
    return <NodeRender node={icon} />;
  }

  return <>{icon}</>;
};

export default Icon;
