import {useCallback} from 'react';
import classNames from 'classnames';

import {goToUrl} from 'hooks';

import {UrlProps} from 'types/Url';

import LeftButton, {LeftButtonProps} from './LeftButton';

import {ReactComponent as ArrowLeftDark} from './Assets/ArrowLeftDark.svg';
import {ReactComponent as ArrowLeftLight} from './Assets/ArrowLeftLight.svg';

type BackButtonProps = LeftButtonProps & {
  urlParams?: UrlProps;
};

const BackButton = ({
  className,
  onPress: onPressProp,
  urlParams,
  ...props
}: BackButtonProps) => {
  const onPress = useCallback(
    e => {
      if (onPressProp) {
        onPressProp(e);
        return;
      }

      if (urlParams) {
        goToUrl(urlParams);
      } else {
        goToUrl({goBack: true});
      }
    },
    [onPressProp, urlParams],
  );

  return (
    <LeftButton
      onPress={onPress}
      icon={
        <>
          <ArrowLeftDark className="image dark" />
          <ArrowLeftLight className="image light" />
        </>
      }
      iconPosition="before"
      className={classNames('left', className)}
      {...props}
    />
  );
};

export default BackButton;
