import DateTime from 'components/Common/DateTime';
import Text from 'components/Common/Text';
import Locale from 'components/Common/Locale';

import MembershipInfo, {MembershipStatus} from 'types/Membership';

const {Cancelled} = MembershipStatus;
type NextPaymentProps = {
  membership: MembershipInfo;
};

const NextPayment = ({membership}: NextPaymentProps) => {
  const {is_free, status, expiration} = membership;

  if (is_free || status === Cancelled || !expiration) {
    return null;
  }

  return (
    <Text className="membership-next-payment">
      <Text>
        <Text className="label">
          <Locale text={'membership.next_payment'} />{' '}
        </Text>
        <Text className="date">
          <DateTime excludeTime shortMonth date={expiration} />
        </Text>
      </Text>
    </Text>
  );
};

export default NextPayment;
