import {useCallback} from 'react';

import {isGroup} from 'core/session';

import {goToUrl, useBusiness, useSourceData} from 'hooks';

import Title from 'components/Page/Title';
import PageTitle from 'components/Page/PageTitle';
import View from 'components/Common/View';
import Spacer from 'components/Common/Spacer';
import MembershipInfoComponent from 'components/Membership/Info';
import CancelButton from 'components/Membership/CancelButton';
import ActivateButton from 'components/Membership/ActivateButton';
import CardOnFile from 'components/Membership/CardOnFile';
import MembershipInfo, {MembershipStatus} from 'types/Membership';
import InvoicesList from 'components/Membership/InvoicesList';
import FreeButton from 'components/Membership/FreeButton';
import NextPayment from 'components/Membership/NextPayment';
import CancellationWarning from 'components/Membership/CancellationWarning';

const {Active} = MembershipStatus;

const Home = () => {
  const business = useBusiness();
  const membership = useSourceData<MembershipInfo>();
  const {status, is_free} = membership;

  //const setBusiness = useSetBusiness();
  const onPressChangeCard = useCallback(() => {
    goToUrl({follows: 'change-payment'});
  }, []);

  return (
    <>
      <Title title="staff.settings.membership.title" />
      <PageTitle className="staff-title" />

      <View className="membership-container">
        <CancellationWarning membership={membership} />

        <MembershipInfoComponent business={business} membership={membership}>
          <ActivateButton business={business} membership={membership} />
          <CancelButton business={business} membership={membership} />
        </MembershipInfoComponent>

        {!is_free && status === Active && (
          <>
            <View className="card-and-date">
              <CardOnFile
                onPressChangeCard={onPressChangeCard}
                membership={membership}
              />
              <NextPayment membership={membership} />
            </View>
            <Spacer size={30} />
          </>
        )}

        <InvoicesList business={business} />

        {isGroup('admin') && (
          <>
            <Spacer size={100} />
            <CancelButton
              useHardDelete
              business={business}
              membership={membership}
            />
            <Spacer size={10} />
            <FreeButton business={business} membership={membership} />
            <Spacer size={100} />
          </>
        )}
      </View>
    </>
  );
};

export default Home;
