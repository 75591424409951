import './List.scss';

import {useMemo, ReactNode} from 'react';

import ItemsList from 'components/Common/ItemsList';
import {NodeType} from 'components/Common/NodeRender';

import {BusinessType} from 'types/BusinessType';
import BusinessInfo, {BusinessStatus} from 'types/Business';

import BusinessItem from './BusinessItem';

type Props = {
  noItemsLabel?: string;
  noItemsContent?: NodeType;
  type?: BusinessType;
  lat?: number;
  lng?: number;
  headerRender?: (items: BusinessInfo[] | null) => ReactNode;
};

const List = ({
  type,
  lat,
  lng,
  noItemsLabel,
  noItemsContent,
  headerRender,
}: Props) => {
  const content = useMemo(
    () => (
      <ItemsList<BusinessInfo>
        className="businesses-list"
        source="businesses"
        useInfiniteScroll
        noItemsContent={noItemsContent}
        noItemsLabel={noItemsLabel || 'business.no_results'}
        headerRender={headerRender}
        sourceParams={{
          status: BusinessStatus.Active,
          ...(type && {type}),
          ...(lat && lng && {lat, lng}),
        }}>
        {business => <BusinessItem business={business} />}
      </ItemsList>
    ),
    [type, lat, lng, noItemsLabel, noItemsContent, headerRender],
  );

  return content;
};

export default List;
