import CardInfo from './Card';
import {CurrencyTag} from './Currency';

export const MembershipPrices: {[key in CurrencyTag]: number} = {
  USD: 10,
  MXN: 199,
  PEN: 39,
  COL: 39999,
  CLP: 8499,
  EUR: 10,
};

export enum MembershipType {
  Free = 'free',
  Premium = 'premium',
}

export enum MembershipStatus {
  Inactive = 'inactive',
  Active = 'active',
  Cancelled = 'Cancelled',
}

export interface MembershipInfo {
  id: string;
  type: MembershipType;
  status: MembershipStatus;
  expiration: Date;
  card: CardInfo;
  price: number;
  is_free: boolean;
  is_trial: boolean;
  used_trial: boolean;
}

export default MembershipInfo;
