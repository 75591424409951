import {useEffect, useState, useCallback, useMemo} from 'react';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';

import {useBusiness, useIsMobile, useLoadBusiness} from 'hooks';

import {PUT} from 'utils/Http';

import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';
import Source from 'components/Common/Source';
import Link from 'components/Common/Link';
import Text from 'components/Common/Text';
import Locale from 'components/Common/Locale';
import View from 'components/Common/View';
import Spacer from 'components/Common/Spacer';
import Price from 'components/Common/Price';
import Icon from 'components/Common/Icon';
import TimeTag from 'components/Common/DateTime/TimeTag';
import DropdownButton, {
  LinkOption as DropdownLinkOption,
} from 'components/Common/DropdownButton';
import FloatingButtons, {LinkOption} from 'components/Common/FloatingButton';

import BusinessInfo from 'types/Business';
import CategoryInfo from 'types/Category';
import {useSetNotification} from 'components/App/Notifications';

type CategoriesRenderProps = {
  business: BusinessInfo;
  categories: CategoryInfo[];
};

type CategotiresCallToActionProps = {
  business: BusinessInfo;
};

const Items = () => {
  const isMobile = useIsMobile();
  const business = useBusiness();
  const {id} = business;

  return (
    <Page title="staff.services.title">
      <View className="staff-categories">
        <Source<CategoryInfo[]> source={`categories/${id}`}>
          {categories => (
            <>
              <PageTitle className="staff-title horizontal-padding">
                <View className="expand">
                  <Locale text="staff.services.title" />
                </View>
                {!!categories.length && !isMobile && (
                  <>
                    <Link
                      follows="add/category"
                      className="btn dark-blue rounded header"
                      label="staff.services.add_category"
                    />
                    <Link
                      follows="add/service"
                      className="btn dark-blue rounded header"
                      label="staff.services.add_service"
                    />
                  </>
                )}
              </PageTitle>

              {!!categories.length && isMobile && (
                <FloatingButtons>
                  <LinkOption icon="plus" follows="add/category" />
                </FloatingButtons>
              )}

              {!categories.length ? (
                <CategotiresCallToAction business={business} />
              ) : (
                <CategoriesRender business={business} categories={categories} />
              )}
            </>
          )}
        </Source>
        <Spacer size={100} />
      </View>
    </Page>
  );
};

const CategotiresCallToAction = ({business}: CategotiresCallToActionProps) => {
  return (
    <View className="base-size padding categories-cta">
      <Text className="no-services">
        <Locale text="staff.services.no_categories" />
        <Text className="subtitle">
          <Locale text="staff.services.no_categories_help" />
        </Text>
      </Text>
      <Link
        follows="add/category"
        className="btn rounded dark-blue"
        label="staff.services.add_category"
      />
    </View>
  );
};

const CategoriesRender = ({
  business,
  categories: categoriesInit,
}: CategoriesRenderProps) => {
  const setNotification = useSetNotification();
  const loadBusiness = useLoadBusiness();
  const [isDragDisabled, setIsDragDisabled] = useState(false);
  const {id: businessId, services: initServices} = business;
  const categoriesInitMemo = useMemo(() => {
    const categories = categoriesInit.sort((a, b) => a.order - b.order);

    categories.forEach(category => {
      category.services = category.services.sort((a, b) => a.order - b.order);
    });

    return categories;
  }, [categoriesInit]);
  const [categories, setCategories] = useState(categoriesInitMemo);

  const onDragEnd = useCallback(
    async result => {
      const {destination, source} = result;

      if (!destination || !source || destination.index === source.index) {
        return;
      }

      const newItems = [...categories];
      const [category] = newItems.splice(source.index, 1);
      newItems.splice(destination.index, 0, category);

      setCategories(newItems);

      setIsDragDisabled(true);
      await PUT(`categories/${businessId}/${category.id}/order`, {
        order: destination.index,
      });
      setIsDragDisabled(false);
      setNotification({
        type: 'success',
        msg: 'form.changes_saved',
      });
    },
    [categories, setCategories, businessId, setNotification],
  );

  const onDragEndService = useCallback(
    async result => {
      const {destination, source, draggableId} = result;

      if (!destination || !source || destination.index === source.index) {
        return;
      }

      const category = categories.find(
        category =>
          !!category.services.find(service => service.id === draggableId),
      );

      if (!category) {
        return;
      }

      const {services} = category;

      const newItems = [...services];
      const [service] = newItems.splice(source.index, 1);
      newItems.splice(destination.index, 0, service);

      category.services = newItems;
      setCategories([...categories]);

      setIsDragDisabled(true);
      await PUT(`services/${businessId}/${service.id}/order`, {
        order: destination.index,
      });
      setIsDragDisabled(false);
      setNotification({
        type: 'success',
        msg: 'form.changes_saved',
      });
    },
    [categories, setCategories, businessId, setNotification],
  );

  const servicesCount = categories.reduce(
    (current, category) => current + category.services.length,
    0,
  );

  useEffect(() => {
    if (!initServices.length && servicesCount) {
      loadBusiness();
    }
  }, [initServices.length, servicesCount, loadBusiness]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="categories">
        {provided => (
          <View
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="categories-container">
            {categories.map(({id, name, services}, idx) => (
              <Draggable
                isDragDisabled={isDragDisabled}
                key={id}
                draggableId={id}
                index={idx}>
                {provided => (
                  <View
                    className="category"
                    ref={provided.innerRef}
                    {...provided.draggableProps}>
                    <View className="info row" {...provided.dragHandleProps}>
                      <Icon icon="grab" />
                      <View className="content expand">
                        <Text className="name expand">{name}</Text>
                        <DropdownButton icon="options">
                          <DropdownLinkOption
                            label="staff.services.edit_category"
                            follows={`category/${id}`}
                          />
                          <DropdownLinkOption
                            label="staff.services.add_service"
                            follows={`add/${id}`}
                            state={{name}}
                          />
                        </DropdownButton>
                      </View>
                    </View>

                    {!!services.length && (
                      <DragDropContext onDragEnd={onDragEndService}>
                        <Droppable droppableId="services">
                          {provided => (
                            <View
                              className="services"
                              {...provided.droppableProps}
                              ref={provided.innerRef}>
                              {services.map(
                                ({id, name, price, minutes}, idx) => (
                                  <Draggable
                                    isDragDisabled={isDragDisabled}
                                    key={id}
                                    draggableId={id}
                                    index={idx}>
                                    {provided => (
                                      <View
                                        key={id}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className="service row">
                                        <Link
                                          className="expand row"
                                          follows={`service/${id}`}>
                                          <Icon icon="grab" />
                                          <View className="expand column content">
                                            <View className="top expand">
                                              <Text className="name expand">
                                                {name}
                                              </Text>
                                              <Text className="price">
                                                <Price useFree amount={price} />
                                              </Text>
                                            </View>
                                            <Text className="minutes expand">
                                              <TimeTag minutes={minutes} />
                                            </Text>
                                          </View>
                                        </Link>
                                      </View>
                                    )}
                                  </Draggable>
                                ),
                              )}
                              {provided.placeholder}
                            </View>
                          )}
                        </Droppable>
                      </DragDropContext>
                    )}
                  </View>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </View>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Items;
