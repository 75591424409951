import './Menu.scss';

import {ReactNode} from 'react';

import View from 'components/Common/View';

type Props = {
  children: ReactNode;
};

const List = ({children}: Props) => {
  return <View className="menu-list">{children}</View>;
};

export default List;
