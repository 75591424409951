import {useTranslation} from 'hooks';

import {DateTimeAny, MonthsList} from 'types/Date';
import {convertToDateTime} from 'utils/DateTime';

type Props = {
  date?: DateTimeAny;
  timezone?: string;
  month?:
    | 'january'
    | 'february'
    | 'march'
    | 'april'
    | 'may'
    | 'june'
    | 'july'
    | 'august'
    | 'september'
    | 'october'
    | 'november'
    | 'december';
  short?: boolean;
};

const Month = ({date, timezone, month, short}: Props) => {
  const {t} = useTranslation();

  if (date) {
    date = convertToDateTime(date, timezone);
    month = MonthsList[date.month - 1];
  }

  return <>{t(`months${short ? '_short' : ''}.${month}`)}</>;
};

export default Month;
