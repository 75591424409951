import {useBusiness, useSourceData} from 'hooks';

import Title from 'components/Page/Title';
import PageTitle from 'components/Page/PageTitle';
import View from 'components/Common/View';
import Redirect from 'components/Common/Redirect';
import MembershipInfoComponent from 'components/Membership/Info';
import PaymentForm from 'components/Membership/PaymentForm';

import MembershipInfo, {
  MembershipStatus,
  MembershipType,
} from 'types/Membership';

const {Active, Cancelled} = MembershipStatus;
const {Premium} = MembershipType;

const Checkout = () => {
  const business = useBusiness();
  const membership = useSourceData<MembershipInfo>();
  const {status} = membership;

  if (status === Active) {
    return <Redirect goBack />;
  }

  const title = `staff.settings.membership.${
    status === Cancelled ? 'checkout_reactivate_title' : 'checkout_title'
  }`;

  return (
    <>
      <Title title={title} />
      {<PageTitle className="staff-title center" />}

      <View className="membership-container">
        <MembershipInfoComponent
          type={Premium}
          business={business}
          membership={membership}
        />
        <PaymentForm business={business} membership={membership} />
      </View>
    </>
  );
};

export default Checkout;
