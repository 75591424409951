import './CheckoutDetails.scss';

import {useStore} from 'core/hooks';

import BusinessInfo from 'types/Business';
import {BookingStore} from 'types/Store';

import View from 'components/Common/View';
import Text from 'components/Common/Text';
import Hr from 'components/Common/Hr';
import Spacer from 'components/Common/Spacer';
import BlockInfo from 'components/Common/Display/BlockInfo';
import DateTime from 'components/Common/DateTime';

import BusinessDetails from 'components/Business/Details';
import ServicesList from 'components/Appointment/ServicesList';

type CheckoutDetailsProps = {
  business: BusinessInfo;
};

const CheckoutDetails = ({business}: CheckoutDetailsProps) => {
  const booking = useStore<BookingStore>('booking');
  const {date, services} = booking;

  return (
    <View className="booking-checkout-details padding">
      <BusinessDetails business={business} inline />

      <Spacer size={25} />

      <Hr />

      <Spacer size={25} />

      {date && (
        <BlockInfo label="booking.date_label">
          <Text className="block-text">
            <DateTime date={date} />
          </Text>
        </BlockInfo>
      )}

      <Spacer size={15} />

      {!!services.length && (
        <BlockInfo label="booking.services_label">
          <ServicesList services={services} />
        </BlockInfo>
      )}
    </View>
  );
};

export default CheckoutDetails;
