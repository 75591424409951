import {useRef, useState, useEffect, useMemo, HTMLProps} from 'react';

import {useTranslation, useIsMounted} from 'hooks';

import {GET} from 'utils/Http';

import InputRender, {InputRenderProps} from '../Input/Render';

type AnyProps = {[name: string]: any};

type OptionValueType = string | number;

type OptionInit = OptionValueType | Option;

type Option = {
  name: string;
  value: OptionValueType;
};

type Props<T> = HTMLProps<HTMLSelectElement> &
  InputRenderProps<T> & {
    source?: string;
    sourceParser?: (options: any) => Option[];
    sourceParams?: AnyProps;
    options?: OptionInit[];
  };

const parseOptions = (options: OptionInit[]): Option[] => {
  return options.map(option => {
    if (typeof option === 'string' || typeof option === 'number') {
      return {name: option.toString(), value: option};
    }

    return option;
  });
};

const Select = <T,>(props: Props<T>) => {
  const {
    source,
    sourceParser: sourceParserProp,
    sourceParams: sourceParamsProp,
    options: optionsProp,
    noTrans,
  } = props;
  const sourceParser = useRef(sourceParserProp);
  const [sourceParams] = useState(sourceParamsProp);

  const isMounted = useIsMounted();
  const {t} = useTranslation();
  const initOptions = useMemo(
    () => (source ? null : parseOptions(optionsProp || [])),
    [source, optionsProp],
  );
  const [options, setOptions] = useState<Option[] | null>(initOptions);

  useEffect(() => {
    if (options || !source) {
      return;
    }

    const loadOptions = async () => {
      const data = await GET<OptionValueType[]>(source, sourceParams);

      if (!isMounted()) {
        return;
      }

      setOptions(
        sourceParser.current ? sourceParser.current(data) : parseOptions(data),
      );
    };

    loadOptions();
  }, [source, sourceParams, setOptions, options, isMounted]);

  const content = useMemo(
    () =>
      options && (
        <InputRender<T>
          {...props}
          type="select"
          render={props => {
            return (
              <select {...props}>
                {options.map(({name, value}) => (
                  <option key={value} value={value}>
                    {noTrans ? name : t([name, name])}
                  </option>
                ))}
              </select>
            );
          }}
        />
      ),
    [options, noTrans, t, props],
  );

  return <>{content}</>;
};

export default Select;
