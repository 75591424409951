import './InfoBox.scss';

import {ReactNode} from 'react';
import classNames from 'classnames';

import View from 'components/Common/View';

export type InfoBoxProps = {
  type: 'info';
  noMargin?: boolean;
  className?: string;
  children?: ReactNode;
};

const InfoBox = ({className, type, noMargin, children}: InfoBoxProps) => {
  return (
    <View
      className={classNames('info-box', type, className, {
        'no-margin': noMargin,
      })}>
      {children}
    </View>
  );
};

export default InfoBox;
