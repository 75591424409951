import classNames from 'classnames';

import Text, {TextProps} from 'components/Common/Text';

import {useField} from '../Field';

type Props = TextProps;

const Description = ({className, children, ...props}: Props) => {
  const {name} = useField();

  const params = {
    className: classNames(className, 'description', {
      [`description-${name}`]: name,
    }),
    ...props,
  };

  return <Text {...params}>{children}</Text>;
};

export default Description;
