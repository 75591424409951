import './CalendarDay.scss';

import {DateTime} from 'luxon';

import View from 'components/Common/View';

import BusinessInfo from 'types/Business';

import TimeColumn from './TimeColumn';
import BackgroundGrid from './BackgroundGrid';
import Appointments from './Appointments';

export type UseCalendarLoadData = (clear?: boolean) => Promise<void>;

type CalendarDayProps = {
  date: DateTime;
  business: BusinessInfo;
};

export const HOUR_HEIGHT = 55;

export const dateToPosition = (date: DateTime): number => {
  const {hours} = date.diff(date.startOf('day'), 'hours');
  return hours * HOUR_HEIGHT;
};

export const positionToDate = (date: DateTime, position: number): DateTime => {
  return date.startOf('day').plus({hours: position / HOUR_HEIGHT});
};

const CalendarDay = ({date, business}: CalendarDayProps) => {
  return (
    <View className="calendar-day">
      <TimeColumn date={date} business={business} />
      <BackgroundGrid date={date} business={business} />
      <View className="appointments-container">
        <Appointments date={date} business={business} />
      </View>
    </View>
  );
};

export default CalendarDay;
