import DateTime from 'components/Common/DateTime';
import Text from 'components/Common/Text';
import Locale from 'components/Common/Locale';
import InfoBox from 'components/Common/InfoBox';

import MembershipInfo, {MembershipStatus} from 'types/Membership';

const {Cancelled} = MembershipStatus;

type CancellationWarningProps = {
  membership: MembershipInfo;
};

const CancellationWarning = ({membership}: CancellationWarningProps) => {
  const {status, expiration} = membership;

  if (status !== Cancelled) {
    return null;
  }

  return (
    <InfoBox type="info">
      <Text>
        <Locale text="membership.cancellation_warning" />{' '}
        <Text bold>
          {expiration && <DateTime excludeTime date={expiration} />}
        </Text>
      </Text>
    </InfoBox>
  );
};

export default CancellationWarning;
