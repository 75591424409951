import './Dialog.scss';

import {useState, useCallback, ReactNode} from 'react';
import classNames from 'classnames';

import View from 'components/Common/View';
import Button from 'components/Common/Button';
import Text from 'components/Common/Text';
import Locale from 'components/Common/Locale';
import Modal from 'components/App/Modal';

import {UseSetModalClosed} from 'components/App/Modal';

import {useSetModalClosed} from 'hooks';

type AnyProps = {[name: string]: any};

type UseSetClosed = UseSetModalClosed;

export type DialogProps = {
  className?: string;
  title?: string;
  titleParams?: AnyProps;
  subtitle?: string;
  subtitleParams?: AnyProps;
  labelClass?: string;
  label?: string;
  labelParams?: AnyProps;
  onConfirm?: () => void | Promise<void>;
  children?: ReactNode;
};

const Dialog = ({
  setClosed: setClosedProp,
  ...props
}: DialogProps & {setClosed: UseSetClosed}) => {
  const [visible, setVisible] = useState(true);

  const setClosed = useCallback(() => {
    setVisible(false);

    if (setClosedProp) {
      setClosedProp(false);
    }
  }, [setClosedProp, setVisible]);

  return (
    <>
      {visible && (
        <Modal setClosed={setClosed}>
          <DialogRender {...props} />
        </Modal>
      )}
    </>
  );
};

const DialogRender = ({
  className,
  title,
  titleParams,
  subtitle,
  subtitleParams,
  children,
  onConfirm,
  label,
  labelClass,
  labelParams,
}: DialogProps) => {
  const setModalClosed = useSetModalClosed();

  const onPressClose = useCallback(() => {
    if (setModalClosed) {
      setModalClosed();
    }
  }, [setModalClosed]);

  const onPressConfirm = useCallback(() => {
    if (onConfirm) {
      onConfirm();
    }

    if (setModalClosed) {
      setModalClosed();
    }
  }, [onConfirm, setModalClosed]);

  //default-actions
  const renderDefault = () => {
    return (
      <View className="default-actions">
        <Button
          onPress={onPressConfirm}
          className={labelClass || 'btn primary'}
          label={label || 'common.dialog_confirm'}
          labelParams={labelParams}
        />
      </View>
    );
  };

  const renderTitles = () => {
    if (!title && !subtitle) {
      return null;
    }

    return (
      <View className="titles">
        {title && (
          <Text className="title">
            <Locale text={title} params={titleParams} />
          </Text>
        )}
        {subtitle && (
          <Text className="subtitle">
            <Locale text={subtitle} params={subtitleParams} />
          </Text>
        )}
      </View>
    );
  };

  return (
    <View className={classNames('dialog', 'horizontal-padding', className)}>
      <View className="header">
        <Button
          onPress={onPressClose}
          className="close-button"
          label="common.dialog_close"
        />
      </View>

      <View className="bottom">
        {renderTitles()}
        <View className="content bottom-padding">
          {children || renderDefault()}
        </View>
      </View>
    </View>
  );
};

export default Dialog;
