import {
  createContext,
  useRef,
  useCallback,
  useContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  ReactNode,
} from 'react';

import {GET} from 'utils/Http';

import {useIsMounted} from 'hooks';

import Error from 'components/Common/Error';
import Loading from 'components/Common/Loading';

export type UseSourceSetData<T> = Dispatch<SetStateAction<T>>;

export type SourceProps<T> = {
  data?: T;
  source?: string;
  hideLoading?: boolean;
  sourceParams?: any;
  children: (
    data: T,
    setData: UseSourceSetData<T>,
    loadData: () => void,
  ) => ReactNode;
};

export type SourceContextProps<T> = {
  data: T;
  setData: Dispatch<SetStateAction<T>>;
  loadData: () => void;
};

const SourceContext = createContext<SourceContextProps<any>>({
  data: null,
  setData: () => {
    /* do nothing */
  },
  loadData: () => {
    /* do nothing */
  },
});

const Source = <T,>({
  data: dataInit,
  source,
  sourceParams,
  hideLoading = false,
  children,
}: SourceProps<T>) => {
  const isMounted = useIsMounted();
  const loadedRef = useRef(!!dataInit);
  const [error, setError] = useState<any | null>(null);
  const [data, setData] = useState<T>((dataInit || {}) as T);

  const loadData = useCallback(async () => {
    if (!source) {
      return;
    }

    try {
      const data = await GET<T>(source, sourceParams);

      if (!isMounted()) {
        return;
      }

      loadedRef.current = true;
      setData(data);
    } catch (e) {
      setError(e);
    }
  }, [source, sourceParams, isMounted]);

  useEffect(() => {
    if (loadedRef.current) {
      return;
    }

    loadData();
  }, [loadedRef, dataInit, loadData]);

  const renderContent = () => {
    if (!loadedRef.current || !data) {
      return !hideLoading && <Loading />;
    }

    return children(data, setData, loadData);
  };

  useEffect(() => {
    if (!data && loadedRef.current) {
      loadData();
    }
  }, [data, loadData]);

  return (
    <SourceContext.Provider value={{data, setData, loadData}}>
      {error ? <Error info={error} /> : renderContent()}
    </SourceContext.Provider>
  );
};

export const useSourceLoadData = () => {
  return useContext(SourceContext).loadData;
};

export const useSourceData = <T,>(): T => {
  return useContext(SourceContext).data as T;
};

export const useSourceSetData = <T,>(): UseSourceSetData<T> => {
  return useContext(SourceContext).setData;
};

export const useSource = <T,>(): SourceContextProps<T> => {
  return useContext(SourceContext);
};

export default Source;
