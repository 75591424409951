import {useFormatUserFullName} from 'hooks';

import {UserFullNameType, UseFormatUserFullNameProps} from 'utils/User';

type FullNameProps = UseFormatUserFullNameProps & {
  user?: UserFullNameType;
};

const FullName = ({user, ...props}: FullNameProps) => {
  const formatUserFullName = useFormatUserFullName();

  const fullName = formatUserFullName(user, props);

  return <>{fullName}</>;
};

export default FullName;
