import {useBusiness, useParams} from 'hooks';

import {isGroup, isUserId} from 'core/session';

import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';
import Locale from 'components/Common/Locale';
import Br from 'components/Common/Br';
import Text from 'components/Common/Text';
import Form from 'components/Form';
import Field from 'components/Form/Field';
import Label from 'components/Form/Label';
import Select from 'components/Form/Select';
import Description from 'components/Form/Description';
import Actions from 'components/Form/Actions';
import Submit from 'components/Form/Submit';
import InfoBox from 'components/Common/InfoBox';
import Spacer from 'components/Common/Spacer';

import StaffInfo from 'types/Staff';
import {StaffRole} from 'types/Staff';

import {
  renderFirstNameField,
  renderLastNameField,
  renderPhoneField,
} from 'pages/Settings/General';

const {Owner, Manager, Employee} = StaffRole;

const renderRoleField = (readOnly = false) => {
  return (
    <Field name="role" optional label="staff.staff.role_field">
      <Label />
      <Select
        readOnly={readOnly}
        options={[
          {name: 'form.select', value: 'select'},
          {
            name: `staff.staff.role_${Owner}`,
            value: Owner,
          },
          {
            name: `staff.staff.role_${Manager}`,
            value: Manager,
          },
          {
            name: `staff.staff.role_${Employee}`,
            value: Employee,
          },
        ]}
      />
      <Description>
        <InfoBox type="info">
          <Text bold className="center">
            <Locale text="staff.staff.role_desc" />
          </Text>
          <Spacer size={10} />
          <Text>
            <Text bold>
              <Locale text={`staff.staff.role_${Owner}`} />:
            </Text>
            <Br />
            <Text>
              <Locale text="staff.staff.role_desc_owner" />
            </Text>
          </Text>
          <Spacer size={10} />
          <Text>
            <Text bold>
              <Locale text={`staff.staff.role_${Manager}`} />:
            </Text>
            <Br />
            <Text>
              <Locale text="staff.staff.role_desc_manager" />
            </Text>
          </Text>
          <Spacer size={10} />
          <Text>
            <Text bold>
              <Locale text={`staff.staff.role_${Employee}`} />:
            </Text>
            <Br />
            <Text>
              <Locale text="staff.staff.role_desc_employee" />
            </Text>
          </Text>
        </InfoBox>
      </Description>
    </Field>
  );
};

const StaffForm = () => {
  const {id} = useBusiness();
  const {staff_id} = useParams<{staff_id: string}>();

  return (
    <Page title={`staff.staff.${staff_id ? 'edit' : 'add'}_staff`} padding>
      <PageTitle className="staff-title center" />

      <Form<StaffInfo>
        onSuccess={({goToUrl}) => goToUrl({goBack: true})}
        sourceId={staff_id}
        source={`staff/${id}`}>
        {({data}) => {
          return (
            <>
              {!staff_id &&
                renderRoleField(
                  !!staff_id && isUserId(data.member.id) && !isGroup('admin'),
                )}

              {renderFirstNameField()}
              {renderLastNameField()}

              {!staff_id && (
                <>
                  {renderPhoneField(false)}

                  <Spacer size={20} />

                  <InfoBox type="info">
                    <Locale text="staff.staff.warning" />
                  </InfoBox>
                </>
              )}

              {staff_id &&
                renderRoleField(
                  !!staff_id && isUserId(data.member.id) && !isGroup('admin'),
                )}

              <Actions>
                <Submit />
              </Actions>
            </>
          );
        }}
      </Form>
    </Page>
  );
};

export default StaffForm;
