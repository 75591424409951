import {Route, Switch} from 'react-router-dom';

import NotFound from 'pages/NotFound';

import {BUSINESS_PATH} from 'types/Business';

import Business from './Business';
import Create from './Create';
import Me from './Me';

const Businesses = () => {
  return (
    <Switch>
      <Route exact path="/b/create" component={Create} />
      <Route exact path="/b/me" component={Me} />

      <Route path={BUSINESS_PATH} component={Business} />

      <Route component={NotFound} />
    </Switch>
  );
};

export default Businesses;
