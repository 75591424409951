import './Appointment.scss';

import {ReactNode} from 'react';

import {useSourceData, useFormatUserFullName} from 'hooks';

import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';
import Text from 'components/Common/Text';
import DateTime from 'components/Common/DateTime';
import View from 'components/Common/View';
import Spacer from 'components/Common/Spacer';
import BlockInfo from 'components/Common/Display/BlockInfo';
import UserFullName from 'components/User/FullName';
import CancelButton from 'components/Appointment/CancelButton';
import CompleteButton from 'components/Appointment/CompleteButton';
import ChangeTime from 'components/Appointment/ChangeTime';
import ServicesList from 'components/Appointment/ServicesList';
import Status from 'components/Appointment/Status';

import AppointmentInfo from 'types/Appointment';

export const renderAppointmentContent = (appointment: AppointmentInfo) => {
  const {notes, client, date, price_total, snapshots} = appointment;
  const {email, phone, phone_prefix} = client;

  const renderButton = (button: ReactNode) => {
    if (!button) {
      return null;
    }

    return (
      <>
        {button}
        <Spacer size={10} />
      </>
    );
  };

  return (
    <View className="max-500 center column fill">
      <View className="block-gradient padding fill">
        <View className="row">
          <BlockInfo
            className="expand column"
            label="staff.appointments.client_label">
            <View className="column">
              <Text className="name">
                <UserFullName includeGender user={client} />
              </Text>
              {phone && (
                <Text className="phone" small color="#6f7283">
                  {phone_prefix} {phone}
                </Text>
              )}
              {email && (
                <Text className="email" small color="#6f7283">
                  {email}
                </Text>
              )}
            </View>
          </BlockInfo>
          <BlockInfo className="expand column align-items-end">
            <Status appointment={appointment} showAsTag excludeIcon />
          </BlockInfo>
        </View>

        {notes && (
          <>
            <Spacer size={15} />
            <BlockInfo label="booking.notes_label">
              <Text className="block-desc">{notes}</Text>
            </BlockInfo>
          </>
        )}

        <Spacer size={15} />

        <BlockInfo label="booking.date_label">
          <Text className="block-text">
            <DateTime date={date} />
          </Text>
        </BlockInfo>

        <Spacer size={15} />

        <BlockInfo label="booking.services_label">
          <ServicesList totalPrice={price_total} services={snapshots} />
        </BlockInfo>
      </View>

      <Spacer size={20} />

      <View className="appointment-actions">
        {renderButton(<CompleteButton appointment={appointment} />)}
        {renderButton(<ChangeTime appointment={appointment} />)}
        {renderButton(<CancelButton appointment={appointment} />)}
      </View>
    </View>
  );
};

const ViewPage = () => {
  const appointment = useSourceData<AppointmentInfo>();
  const formatUserFullName = useFormatUserFullName();
  const {client} = appointment;

  return (
    <Page
      title={[
        'staff.appointments.appointment_title',
        {name: formatUserFullName(client)},
      ]}
      padding>
      <PageTitle className="staff-title center" />

      {renderAppointmentContent(appointment)}
    </Page>
  );
};

export default ViewPage;
