import './RatingInfo.scss';

import classNames from 'classnames';

import View from 'components/Common/View';
import Text from 'components/Common/Text';
import Locale from 'components/Common/Locale';

import BusinessInfo from 'types/Business';

import {ReactComponent as Star} from 'components/Review/Assets/Star.svg';

type Props = {
  className?: string;
  business: BusinessInfo;
};

const RatingsView = ({business, className}: Props) => {
  const {rating, rating_count} = business;

  if (!rating || !rating_count) {
    return null;
  }

  return (
    <View className={classNames('ratings-info', className)}>
      <Star className="star" />
      <Text className="score">{rating.toFixed(1)}</Text>
      <Text className="count">
        - {rating_count}{' '}
        <Locale text="business.reviews" params={{count: rating_count}} />
      </Text>
    </View>
  );
};

export default RatingsView;
