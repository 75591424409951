import {useEffect} from 'react';

import {getStore, useStore} from 'core/hooks';

import {UrlProps} from 'types/Url';
import {UIStore} from 'types/Store';

import Redirect from 'components/Common/Redirect';

const uiStore = getStore<UIStore>('ui');

export const GoToUrl = () => {
  const redirectStore = useStore<UrlProps | null>('redirect');

  useEffect(() => {
    uiStore.redirect = null;
  }, [redirectStore]);

  if (redirectStore) {
    return <Redirect {...redirectStore} />;
  }

  return null;
};

export default GoToUrl;
