import 'styles/global.scss';
import 'styles/Form/Theme.scss';

import AppBase from 'components/App';

const App = () => {
  return <AppBase />;
};

export default App;
