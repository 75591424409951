import Page from 'components/Page';
//import PageTitle from 'components/PageTitle';

import {useBusiness, useStaffUrl} from 'hooks';

import Calendar from 'components/Staff/Calendar';
import Redirect from 'components/Common/Redirect';

import {BusinessStatus} from 'types/Business';

const {Pending} = BusinessStatus;

const Home = () => {
  const staffUrl = useStaffUrl();
  const business = useBusiness();
  const {status} = business;

  if (status === Pending) {
    return <Redirect replace base={staffUrl({to: 'complete'})} />;
  }

  return (
    <Page
      title="staff.calendar.title"
      className="staff-calendar-container base-size">
      <Calendar business={business} />
    </Page>
  );
};

export default Home;
