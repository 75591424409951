import {Route, Switch} from 'react-router-dom';

import NotFound from 'pages/NotFound';

import Category from './Category';

const Categories = () => {
  return (
    <Switch>
      <Route exact path="/c/:type_id" component={Category} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Categories;
