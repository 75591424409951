import AppointmentInfo, {AppointmentStatus} from 'types/Appointment';

import Link from 'components/Common/Link';

const {Confirmed} = AppointmentStatus;

type Props = {
  appointment: AppointmentInfo;
};

const ChangeTime = ({appointment}: Props) => {
  const {status} = appointment;

  return (
    <>
      {status === Confirmed && (
        <Link
          className="btn normal"
          follows="time"
          label="appointments.time_button"
        />
      )}
    </>
  );
};

export default ChangeTime;
