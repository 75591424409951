import './Staff.scss';

import {useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import classNames from 'classnames';

import {
  useBusiness,
  useHasRole,
  useIsMobile,
  setFooterStatus,
  setHeaderTheme,
  setHeaderClassName,
} from 'hooks';

import NotFound from 'pages/NotFound';

import Redirect from 'components/Common/Redirect';
import View from 'components/Common/View';
import StaffMenu from 'components/Staff/Menu';
import StaffTabbar from 'components/Staff/Tabbar';

import {BusinessStatus, STAFF_PATH} from 'types/Business';

import Complete from './Complete';
import Calendar from './Calendar';
import Appointments from './Appointments';
import Clients from './Clients';
import StaffPage from './Staff';
import Settings from './Settings';
import Services from './Services';
import Share from './Share';

const {Pending} = BusinessStatus;

const Staff = () => {
  const hasRole = useHasRole();

  if (!hasRole) {
    return <Redirect replace goBack />;
  }

  return (
    <Switch>
      <Route exact path={`${STAFF_PATH}/complete`} component={Complete} />
      <Route path={STAFF_PATH} component={PanelRender} />
    </Switch>
  );
};

const PanelRender = () => {
  const {status} = useBusiness();
  const isMobile = useIsMobile();

  useEffect(() => {
    setFooterStatus(true);
    return setFooterStatus;
  }, []);

  useEffect(() => {
    if (isMobile) {
      return;
    }

    setHeaderTheme('light');
    setHeaderClassName('forced staff-header');

    return () => {
      setHeaderTheme();
      setHeaderClassName();
    };
  }, [isMobile]);

  const showMenu = status !== Pending;
  return (
    <View className={classNames('staff-panel', status)}>
      <View className="base-size">
        {showMenu && !isMobile && <StaffMenu />}
        <View className="staff-container">
          <Switch>
            <Route
              path={`${STAFF_PATH}/appointments`}
              component={Appointments}
            />
            <Route path={`${STAFF_PATH}/clients`} component={Clients} />
            <Route path={`${STAFF_PATH}/staff`} component={StaffPage} />
            <Route path={`${STAFF_PATH}/settings`} component={Settings} />
            <Route path={`${STAFF_PATH}/services`} component={Services} />
            <Route exact path={`${STAFF_PATH}/share`} component={Share} />
            <Route path={STAFF_PATH} component={Calendar} />
            <Route component={NotFound} />
          </Switch>
        </View>
      </View>
      {showMenu && isMobile && <StaffTabbar />}
    </View>
  );
};

export default Staff;
