import {DateTime} from 'luxon';

export const DATE_TIME_FORMAT = 'LL-dd-yyyy HH:mm';

export const DATE_FORMAT = 'LL-dd-yyyy';

export const TIME_DISPLAY = 'h:mm a';
export const TIME_DISPLAY_SHORT = 'h:mm';

export enum Days {
  Sunday = 'sunday',
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
}

export enum Months {
  January = 'january',
  February = 'february',
  March = 'march',
  April = 'april',
  May = 'may',
  June = 'june',
  July = 'july',
  August = 'august',
  September = 'september',
  October = 'october',
  November = 'november',
  December = 'december',
}

export const DaysList: Days[] = [
  Days.Monday,
  Days.Tuesday,
  Days.Wednesday,
  Days.Thursday,
  Days.Friday,
  Days.Saturday,
  Days.Sunday,
];

export const MonthsList: Months[] = [
  Months.January,
  Months.February,
  Months.March,
  Months.April,
  Months.May,
  Months.June,
  Months.July,
  Months.August,
  Months.September,
  Months.October,
  Months.November,
  Months.December,
];

export interface DateTimeRange {
  from: DateTime;
  to: DateTime;
}

export type DateTimeAny = DateTime | Date | string;
