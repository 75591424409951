/*
 * A non-predictable state management, simple and consistent
 *
 * Objects, Array and Map support
 */

import initStore from 'config/store';

const getContext = (store = undefined) => {
  const globalContext =
    (typeof global !== 'undefined'
      ? global
      : typeof window !== 'undefined'
      ? window
      : false) || {};

  if (typeof globalContext.__store === 'undefined') {
    globalContext.__store = {};
    initStore();
  }

  return typeof store === 'undefined'
    ? globalContext.__store
    : globalContext.__store[store];
};

const defineProp = Object.defineProperty;

const processMethod = (object, value) => {
  if (object.parent) {
    object.parent.__notify({
      [object.idx]: object,
    });
  }

  return value;
};

const defineHiddenProp = (object, name, value, writable = false) => {
  defineProp(object, name, {
    configurable: false,
    enumerable: false,
    writable: writable,
    value: value,
  });
};

const checkArrayValue = (object, name, value) => {
  value = new StoreArray(...value);

  defineHiddenProp(value, 'idx', name);
  defineHiddenProp(value, 'parent', object);

  return value;
};

const checkArrayMap = (object, name, value) => {
  value = new StoreMap(...value);

  defineHiddenProp(value, 'idx', name);
  defineHiddenProp(value, 'parent', object);

  return value;
};

/*const checkFunction = (object, name, value) => {
  return object.__action.bind(object, value);
};*/

const checkValueType = (object, name, value) => {
  if (value instanceof Array) {
    return checkArrayValue(object, name, value);
  } else if (value instanceof Map) {
    return checkArrayMap(object, name, value);
  } /* else if (typeof value === 'function') {
    return checkFunction(object, name, value);
  }  else if (
    value !== null &&
    typeof value === 'object' &&
    value.constructor === Object
  ) {
    return new Store(name, value, object);
  }*/

  return value;
};

const getter = function (name) {
  if (this.__store_checks[name] === undefined) {
    this.__store_checks[name] = true;
    this.__store[name] = checkValueType(this, name, this.__store[name]);
  }

  return this.__store[name];
};

const setter = function (name, value) {
  this.__store[name] = checkValueType(this, name, value);

  if (this.__notifyState === 0) {
    this.__notify({
      [name]: this.__store[name],
    });
  }
};

class StoreMap extends Map {
  __notify(values) {
    return this.parent.__notify(values);
  }
  hookMethod(method, params) {
    return processMethod(this, super[method].apply(this, params));
  }

  clear(...args) {
    return this.hookMethod('clear', args);
  }
  delete(...args) {
    return this.hookMethod('delete', args);
  }
  set(...args) {
    return this.hookMethod('set', args);
  }
}

class StoreArray extends Array {
  constructor(...args) {
    super(...args);

    for (let i = 0, max = this.length; i < max; i++) {
      this[i] = checkValueType(this, i, this[i]);
    }
  }

  __notify(values) {
    return this.parent.__notify(values);
  }
  hookMethod(method, params) {
    return processMethod(this, super[method].apply(this, params));
  }

  // Extended methods
  clear() {
    return processMethod(this.splice(0, this.length));
  }

  push(...args) {
    return this.hookMethod('push', args);
  }
  shift(...args) {
    return this.hookMethod('shift', args);
  }
  pop(...args) {
    return this.hookMethod('pop', args);
  }
  unshift(...args) {
    return this.hookMethod('unshift', args);
  }
  slice(...args) {
    return this.hookMethod('slice', args);
  }
  splice(...args) {
    return this.hookMethod('splice', args);
  }
  concat(...args) {
    return this.hookMethod('concat', args);
  }
  reverse(...args) {
    return this.hookMethod('reverse', args);
  }
  sort(...args) {
    return this.hookMethod('sort', args);
  }
}

class Store {
  constructor(name, props, reference = false) {
    defineHiddenProp(this, '__store', props, true);
    defineHiddenProp(this, '__store_checks', {}, true);

    if (name) {
      defineHiddenProp(this, '__store_name', name);
    }

    if (reference !== false) {
      defineHiddenProp(this, '__store_ref', reference, true);
    } else {
      defineHiddenProp(this, '__store_refs', [], true);
    }

    defineHiddenProp(this, '__notifyState', 0, true);

    Object.keys(props).forEach(key => {
      this.configureProp(key);
    });
  }

  configureProp(name) {
    defineProp(this, name, {
      enumerable: false,
      get: getter.bind(this, name),
      set: setter.bind(this, name),
    });
  }

  setState(state) {
    this.__notifyState++;
    Object.assign(this, state);
    this.__notifyState--;

    const newState = {};

    for (const i in state) {
      newState[i] = checkValueType(this, i, this[i]);
    }

    this.__notify(state, newState);
  }

  __action(...args) {
    const [action, ...rest] = args;
    return action.apply(this, [this, ...rest]);
  }

  __notify(values) {
    if (this.__store_ref) {
      this.__store_ref.__notify({
        [this.__store_name]: this,
      });
    } else {
      this.__store_refs.forEach(parent => {
        parent.__store_notify(this, values);
      });
    }
  }
}

const calculateName = (component, name) => {
  if (name) {
    return name;
  }

  name = component.storeName || component.constructor.name;
  return name.substr(0, 1).toLowerCase() + name.substr(1);
};

const createStore = (props, name, context = false) => {
  name = calculateName(props, name);

  context = context || getContext();

  if (context[name] !== undefined) {
    return context[name];
  }

  const store = new Store(name, props);

  if (name !== false) {
    context[name] = store;
  }

  return store;
};

export {createStore, getContext};

export default createStore;
