import {
  useBusiness,
  useStaffUrl,
  useIsMobile,
  useIsBusinessDisabled,
} from 'hooks';

import View from 'components/Common/View';
import Locale from 'components/Common/Locale';
import Link from 'components/Common/Link';
import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';
import AppointmentsList from 'components/Appointment/List';
import FloatingButtons, {LinkOption} from 'components/Common/FloatingButton';

const Items = () => {
  const isBusinessDisabled = useIsBusinessDisabled();
  const staffUrl = useStaffUrl();
  const {id: businessId} = useBusiness();
  const isMobile = useIsMobile();

  return (
    <Page title="staff.appointments.title" padding>
      <PageTitle className="staff-title">
        <View className="expand">
          <Locale text="staff.appointments.title" />
        </View>
        {!isMobile && !isBusinessDisabled && (
          <Link
            base={staffUrl({to: 'clients'})}
            state={{isFromStaff: true}}
            className="btn dark-blue rounded header"
            label="staff.appointments.new_appointment"
          />
        )}
      </PageTitle>
      {isMobile && !isBusinessDisabled && (
        <FloatingButtons>
          <LinkOption
            icon="plus"
            base={staffUrl({to: 'clients'})}
            state={{isFromStaff: true}}
          />
        </FloatingButtons>
      )}

      <AppointmentsList
        showAsStaff
        source={`businesses/${businessId}/appointments`}
      />
    </Page>
  );
};

export default Items;
