export const locales = {
  pages: {
    not_found: '404 - Page not found',
    not_found_msg: 'The page you were looking for is not found.',
    not_found_button: 'Back to home',
  },
  free: 'Gratis',
  form: {
    errors: {
      required: '{{label}} is required',
      min: '{{label}} must be greater than {{value}}',
      max: '{{label}} must be less than {{value}}',
      minLength: '{{label}} is too short (minimum is {{value}} characters)',
      maxLength: '{{label}} is too long (maximum is {{value}} characters)',
      pattern: '{{label}} Pattern error',
      validate: '{{label}} Validation error',

      required_inline: 'This field is required',
    },
  },
  days: {
    sunday: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
  },
  days_short: {
    sunday: 'Sun',
    monday: 'Mon',
    tuesday: 'Tue',
    wednesday: 'Wed',
    thursday: 'Thu',
    friday: 'Fri',
    saturday: 'Sat',
  },
  months: {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
  },
  months_short: {
    january: 'Jan',
    february: 'Feb',
    march: 'Mar',
    april: 'Apr',
    may: 'May',
    june: 'Jun',
    july: 'Jul',
    august: 'Aug',
    september: 'Sep',
    october: 'Oct',
    november: 'Nov',
    december: 'Dec',
  },
};

export default locales;
