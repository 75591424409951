import {useBusinessUrl} from 'hooks';

import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';
import Link from 'components/Common/Link';
import {Table} from 'components/Common/ItemsList';
import Text from 'components/Common/Text';
import * as Grid from 'components/Common/Grid';
import Form from 'components/Form';
import Input from 'components/Form/Input';
import Connect from 'components/Form/Connect';

import BusinessInfo from 'types/Business';

import FloatingButtons, {LinkOption} from 'components/Common/FloatingButton';

const Businesses = () => {
  const businessUrl = useBusinessUrl();

  return (
    <Page title="Businesses" padding>
      <PageTitle className="staff-title" />

      <FloatingButtons>
        <LinkOption to="b/create" icon="plus" />
      </FloatingButtons>

      <Connect>
        <Form className="filter-form">
          {() => {
            return (
              <Input
                optional
                name="search"
                placeholder="Search..."
                maxLength={40}
              />
            );
          }}
        </Form>

        <Table<BusinessInfo>
          className="table-data"
          headerLabels={['ID', 'Name', 'Timezone', 'Status']}
          source="businesses">
          {business => {
            const {id, name, timezone, status} = business;
            return (
              <Link className="expand" base={businessUrl({}, business)}>
                <Grid.Field>
                  <Text>{id.substr(0, 4)}</Text>
                </Grid.Field>
                <Grid.Field>
                  <Text semiBold>{name}</Text>
                </Grid.Field>
                <Grid.Field>
                  <Text semiBold>{timezone}</Text>
                </Grid.Field>
                <Grid.Field>
                  <Text semiBold>{status}</Text>
                </Grid.Field>
              </Link>
            );
          }}
        </Table>
      </Connect>
    </Page>
  );
};

export default Businesses;
