import './Page.scss';

import {
  useRef,
  useEffect,
  useState,
  createContext,
  useMemo,
  Dispatch,
  SetStateAction,
  ReactNode,
} from 'react';
import classNames from 'classnames';

import {useStore} from 'core/hooks';

import {useIsModal, useTranslation, goToUrl, useLocation} from 'hooks';

import View from 'components/Common/View';

export type TitleParams = undefined | string | [string, {[name: string]: any}];

type AnyProps = {
  [name: string]: any;
};

export type PageProps = {
  title?: TitleParams;
  noTrans?: boolean;
  className?: string;
  noClassName?: boolean;
  padding?: boolean;
  horizontalPadding?: boolean;
  verticalPadding?: boolean;
  noAuth?: boolean;
  authParams?: AnyProps;
  children?: ReactNode;
};

export type TitleContextProps = {
  title?: string;
  setTitle: Dispatch<SetStateAction<TitleParams>>;
};

export const PageContext = createContext<TitleContextProps>({
  title: '',
  setTitle: () => {
    /* do nothing */
  },
});

const Page = ({
  title: initTitle = '',
  noTrans,
  noClassName,
  className = 'base-size',
  padding,
  horizontalPadding,
  verticalPadding = false,
  noAuth = false,
  authParams,
  children,
}: PageProps) => {
  const {t} = useTranslation();
  const isModal = useIsModal();
  const {pathname, key} = useLocation();
  const {loaded, logged} = useStore('loaded', 'logged');
  const [title, setTitle] = useState<TitleParams>(initTitle);
  const initRef = useRef(false);

  const currentTitle = useMemo(
    () =>
      (!noTrans
        ? Array.isArray(title)
          ? t(title[0], title[1])
          : t(title + '')
        : title + ''
      ).toString(),
    [t, title, noTrans],
  );

  // Restore scroll
  useEffect(() => {
    if (initRef.current) {
      return;
    }

    initRef.current = true;

    const keyId = `page_${key}`;

    const scrollData = sessionStorage.getItem(keyId);

    if (scrollData) {
      sessionStorage.removeItem(keyId);

      // Allow the browser to handle it automatically
      /*try {
        const scrollDataParsed = JSON.parse(scrollData);
        window.scrollTo(scrollDataParsed.scrollX, scrollDataParsed.scrollY);
      } catch (e) {
        console.warn(e);
      }*/
    } else if (!isModal) {
      window.scrollTo(0, 0);
    }

    return () => {
      sessionStorage.setItem(
        keyId,
        JSON.stringify({
          scrollX: window.scrollX,
          scrollY: window.scrollY,
        }),
      );
    };
  }, [key, initRef, isModal]);

  useEffect(() => {
    if (!noAuth && loaded && !logged) {
      goToUrl({
        to: 'login',
        replace: true,
        redirect: {to: pathname, replace: true},

        ...(authParams && {state: {...authParams}}),
      });
    }

    document.title = currentTitle || '';
  }, [noAuth, loaded, logged, currentTitle, pathname, authParams]);

  if (!loaded || (!noAuth && !logged)) {
    return null;
  }

  return (
    <PageContext.Provider value={{title: currentTitle, setTitle} as any}>
      <View
        className={classNames('page', {
          [className]: !noClassName,
          padding: padding,
          'vertical-padding': verticalPadding,
          'horizontal-padding': horizontalPadding,
        })}>
        {children}
      </View>
    </PageContext.Provider>
  );
};

export default Page;
