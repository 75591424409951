import './Dropdown.scss';

import {
  useRef,
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
} from 'react';
import classNames from 'classnames';

import {useIsMobile, useSetModalClosed, useOnClickOutside} from 'hooks';

import View from 'components/Common/View';
import Modal from 'components/App/Modal';
import Button, {ButtonProps} from 'components/Common/Button';
import Link, {LinkProps} from 'components/Common/Link';

import {UseSetModalClosed} from 'components/App/Modal';

type ButtonOptionProps = ButtonProps;

type UseSetClosed = UseSetModalClosed;

type DropdownButtonProps = ButtonOptionProps & {
  closeLabel?: string;
  setClosed?: UseSetClosed;
  children: ReactNode;
};

type DropdownContextProps = {
  setClosed: (() => void) | null;
};

const DropdownContext = createContext<DropdownContextProps>({
  setClosed: null,
});

export const ButtonOption = ({
  onPress: onPressProp,
  className,
  ...props
}: ButtonOptionProps & ButtonProps) => {
  const setDropdownClosed = useSetDropdownClosed();
  const setModalClosed = useSetModalClosed();

  const onPress = useCallback(
    e => {
      if (onPressProp) {
        onPressProp(e);
      }

      if (setDropdownClosed) {
        setDropdownClosed();
      } else if (setModalClosed) {
        setModalClosed();
      }
    },
    [onPressProp, setDropdownClosed, setModalClosed],
  );

  return (
    <Button
      iconPosition="after"
      className={classNames('option-button', className)}
      onPress={onPress}
      {...props}
    />
  );
};

export const LinkOption = ({
  onPress: onPressProp,
  className,
  ...props
}: ButtonOptionProps & LinkProps) => {
  const setDropdownClosed = useSetDropdownClosed();
  const setModalClosed = useSetModalClosed();

  const onPress = useCallback(
    e => {
      if (onPressProp) {
        onPressProp(e);
      }

      if (setDropdownClosed) {
        setDropdownClosed();
      } else if (setModalClosed) {
        setModalClosed();
      }
    },
    [onPressProp, setDropdownClosed, setModalClosed],
  );

  return (
    <Link
      iconPosition="after"
      className={classNames('option-button', className)}
      onPress={onPress}
      {...props}
    />
  );
};

export const DropdownButton = ({
  className,
  closeLabel,
  children,
  setClosed: setClosedProp,
  ...props
}: DropdownButtonProps) => {
  const isMobile = useIsMobile();
  const ref = useRef(null);

  const [visible, setVisible] = useState(false);

  const setClosed = useCallback(() => {
    setVisible(false);

    if (setClosedProp) {
      setClosedProp(false);
    }
  }, [setClosedProp, setVisible]);

  const onClickOutside = useCallback(
    e => {
      if (isMobile || e.target.closest('.modals-list')) {
        return;
      }

      setVisible(false);
    },
    [setVisible, isMobile],
  );

  useOnClickOutside(ref, onClickOutside);

  const onPress = useCallback(e => {
    setVisible(visible => !visible);
  }, []);

  const renderContent = () => (
    <View className="dropdown-menu">
      <View className="options top">{children}</View>
      <View className="options close">
        <ButtonOption label={closeLabel || 'common.dropdown_close'} />
      </View>
    </View>
  );

  return (
    <>
      <View className="dropdown-container" ref={ref}>
        <Button
          icon="three-dots"
          onPress={onPress}
          className={classNames('dropdown-button', className)}
          {...props}
        />
        {!isMobile && visible && (
          <DropdownContext.Provider value={{setClosed}}>
            {renderContent()}
          </DropdownContext.Provider>
        )}
      </View>
      {isMobile && visible && (
        <Modal setClosed={setClosed}>{renderContent()}</Modal>
      )}
    </>
  );
};

const useSetDropdownClosed = (): (() => void) | null => {
  return useContext(DropdownContext).setClosed;
};

export default DropdownButton;
