import './PhotosSlider.scss';

// TODO: Create a slider with pure react-spring
import {Pagination, Navigation} from 'swiper';

import SwiperBase from 'swiper';
import {Swiper} from 'swiper/react';
import {SwiperSlide} from 'swiper/react';

import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/navigation/navigation.min.css';

import {useIsMobile} from 'hooks';

import View from 'components/Common/View';

import BusinessInfo from 'types/Business';

import PhotoItem from './PhotoItem';

SwiperBase.use([Pagination, Navigation]);

type Props = {
  business: BusinessInfo;
};

const PhotosSlider = ({business}: Props) => {
  const isMobile = useIsMobile();
  const {photos} = business;

  return (
    <View className="business-photos-slider">
      <View className="slider-container">
        <Swiper pagination={{clickable: true}} navigation={!isMobile} loop>
          {photos.map(photo => (
            <SwiperSlide key={photo.id}>
              <PhotoItem photo={photo} />
            </SwiperSlide>
          ))}
        </Swiper>
      </View>
    </View>
  );
};

export default PhotosSlider;
