import './Membership.scss';

import {useState, useCallback} from 'react';

import {PUT} from 'utils/Http';

import {goToUrl, useSourceLoadData} from 'hooks';

import Button from 'components/Common/Button';

import BusinessInfo from 'types/Business';
import MembershipInfo, {MembershipStatus} from 'types/Membership';
import {useSetNotification} from 'components/App/Notifications';

const {Active, Cancelled} = MembershipStatus;

type ActivateButtonProps = {
  business: BusinessInfo;
  membership: MembershipInfo;
};

const ActivateButton = ({business, membership}: ActivateButtonProps) => {
  const setNotification = useSetNotification();
  const sourceLoadData = useSourceLoadData();
  const {id: businessId} = business;
  const {card, status} = membership;
  const [working, setWorking] = useState(false);
  const isCardPresent = !!card;

  const onPressActivate = useCallback(async () => {
    if (!isCardPresent || status !== Cancelled) {
      goToUrl({follows: 'checkout'});
      return;
    }

    setWorking(true);

    try {
      await PUT(`memberships/${businessId}/activate`);

      setNotification({
        type: 'success',
        msg: 'membership.reactivated_msg',
        duration: 4000,
      });
    } catch (e) {
      console.warn(e);
      setNotification({
        type: 'error',
        msg: 'membership.reactivated_error',
      });
    }

    await sourceLoadData();

    setWorking(false);
  }, [
    status,
    businessId,
    isCardPresent,
    setWorking,
    sourceLoadData,
    setNotification,
  ]);

  if (status === Active) {
    return null;
  }

  return (
    <Button
      working={working}
      onPress={onPressActivate}
      className={status === Cancelled ? 'btn normal no-border' : 'btn primary'}
      label={
        status === Cancelled
          ? 'membership.reactivate_button'
          : 'membership.activate_button'
      }
    />
  );
};

export default ActivateButton;
