import {useIsMobile, useHasRole} from 'hooks';

import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';
import MenuList from 'components/Menu/List';
import LinkButton from 'components/Menu/LinkButton';

import {StaffRole} from 'types/Staff';

const {Owner, Manager} = StaffRole;

const Home = () => {
  const isOwner = useHasRole([Owner]);
  const isManager = useHasRole([Manager]);
  const isMobile = useIsMobile();

  return (
    <Page title="staff.settings.title" padding>
      <PageTitle className="staff-title center" />

      <MenuList>
        <LinkButton
          follows="general"
          label="staff.settings.general.title"
          description="staff.settings.general.description"
        />
        <LinkButton
          follows="hours"
          label="staff.settings.hours.title"
          description="staff.settings.hours.description"
        />
        <LinkButton
          follows="special-dates"
          label="staff.settings.special_dates.title"
          description="staff.settings.special_dates.description"
        />
        <LinkButton
          follows="types"
          label="staff.settings.types.title"
          description="staff.settings.types.description"
        />
        <LinkButton
          follows="photos"
          label="staff.settings.photos.title"
          description="staff.settings.photos.description"
        />
        {isMobile && (isOwner || isManager) && (
          <LinkButton
            goBack
            follows="services"
            label="staff.services.title"
            description="staff.services.description"
          />
        )}
        {isMobile && isOwner && (
          <LinkButton
            goBack
            follows="staff"
            label="staff.staff.title"
            description="staff.staff.description"
          />
        )}
        {isMobile && (
          <LinkButton
            goBack
            follows="share"
            label="staff.share.title"
            description="staff.share.description"
          />
        )}
        <LinkButton
          follows="membership"
          label="staff.settings.membership.title"
          description="staff.settings.membership.description"
        />
      </MenuList>
    </Page>
  );
};

export default Home;
