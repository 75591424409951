export type ErrorInfo = {
  msg: string | string[];
  error: string | string[];
};

export const parseErrorInfo = (error: any): ErrorInfo => {
  if (typeof error === 'string') {
    return {
      msg: 'errors.unknown',
      error: error,
    };
  }

  if (typeof error === 'object') {
    if (error?.request) {
      const request = error.request;
      const errorId = error.error || error.msg;

      if (request?.response?.status) {
        const statusCode = request.response.status;
        return {
          msg: [
            `errors.${errorId}`,
            `errors.${errorId}_${statusCode}`,
            `errors.error_${statusCode}`,
          ],
          error: errorId,
        };
      }
    }
  }

  return {
    msg: 'errors.unknown',
    error: 'errors.unknown',
  };
};
