import './Type.scss';

import classNames from 'classnames';

import View from 'components/Common/View';
import Text from 'components/Common/Text';
import Locale from 'components/Common/Locale';

import {MembershipType} from 'types/Membership';

import {ReactComponent as FreeIcon} from './Assets/Free.svg';
import {ReactComponent as PremiumIcon} from './Assets/Premium.svg';

const {Free, Premium} = MembershipType;

type TypeProps = {
  useLarge?: boolean;
  type: MembershipType;
};

const Type = ({type, useLarge}: TypeProps) => {
  return (
    <View className={classNames('membership-type', type, {large: useLarge})}>
      {type === Free && <FreeIcon className="icon" />}
      {type === Premium && <PremiumIcon className="icon" />}
      <Text className="type">
        <Locale text={`membership.type_${type}`} />
      </Text>
    </View>
  );
};

export default Type;
