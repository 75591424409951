import './Membership.scss';

import {Route, Switch} from 'react-router-dom';

import NotFound from 'pages/NotFound';

import {useBusiness} from 'hooks';

import Source from 'components/Common/Source';
import Page from 'components/Page';

import {STAFF_PATH} from 'types/Business';
import MembershipInfo from 'types/Membership';

import Home from './Home';
import Checkout from './Checkout';
import ChangePayment from './ChangePayment';

const Membership = () => {
  const {id: businessId} = useBusiness();
  return (
    <Page title="..." padding>
      <Source<MembershipInfo> source={`memberships/${businessId}`}>
        {() => (
          <Switch>
            <Route
              exact
              path={`${STAFF_PATH}/settings/membership`}
              component={Home}
            />
            <Route
              exact
              path={`${STAFF_PATH}/settings/membership/checkout`}
              component={Checkout}
            />
            <Route
              exact
              path={`${STAFF_PATH}/settings/membership/change-payment`}
              component={ChangePayment}
            />
            <Route component={NotFound} />
          </Switch>
        )}
      </Source>
    </Page>
  );
};

export default Membership;
