import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {RootState} from 'store';

import type {NotificationProps as Notification} from 'components/App/Notifications';

type NotificationsState = {
  list: Notification[];
};

const initialState: NotificationsState = {
  list: [],
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    add: (state, {payload}: PayloadAction<Notification>) => {
      const {list} = state;

      const exists = list.find(n => n.id === payload.id);

      if (exists) {
        return;
      }

      state.list = [{...payload}, ...state.list];
    },
    remove: (state, {payload: {id: idCheck}}: PayloadAction<Notification>) => {
      state.list = [...state.list.filter(({id}) => id !== idCheck)];
    },
  },
});

export const {add, remove} = notificationsSlice.actions;

export const selectNotifications = (state: RootState) => state;

export default notificationsSlice.reducer;
