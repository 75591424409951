import {useSourceData} from 'hooks';

import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';
import View from 'components/Common/View';
import Locale from 'components/Common/Locale';
import Redirect from 'components/Common/Redirect';
import ReviewForm from 'components/Appointment/ReviewForm';

import AppointmentInfo, {AppointmentStatus} from 'types/Appointment';

const {Completed} = AppointmentStatus;

const Review = () => {
  const appointment = useSourceData<AppointmentInfo>();
  const {
    status,
    business: {name},
  } = appointment;

  if (status !== Completed) {
    return <Redirect goBack />;
  }

  return (
    <Page title={['appointments.review_title', {name}]}>
      <View className="padding">
        <PageTitle className="center">
          <Locale text="appointments.review_subtitle" />
        </PageTitle>

        <View className="center">
          <ReviewForm />
        </View>
      </View>
    </Page>
  );
};

export default Review;
