import './NotesInput.scss';

import {useCallback} from 'react';

import {getStore} from 'core/hooks';

import {BookingStore} from 'types/Store';

import Form from 'components/Form';
import Field from 'components/Form/Field';
import TextArea from 'components/Form/TextArea';
import Label from 'components/Form/Label';

const bookingStore = getStore<BookingStore>('booking');

const NotesInput = () => {
  const onChange = useCallback(e => {
    bookingStore.notes = e.target.value.trim();
  }, []);

  return (
    <Form className="center expand padding">
      {() => (
        <Field className="fill" name="notes" label="booking.notes_field">
          <Label />
          <TextArea
            name="notes"
            onChange={onChange}
            minLength={1}
            maxLength={1024}
            placeholder="booking.notes_placeholder"
            defaultValue={bookingStore.notes || ''}
          />
        </Field>
      )}
    </Form>
  );
};

export default NotesInput;
