import {useParams} from 'hooks';

import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';

import Locale from 'components/Common/Locale';
import Text from 'components/Common/Text';
import View from 'components/Common/View';
import Spacer from 'components/Common/Spacer';
import Redirect from 'components/Common/Redirect';

import BusinessesList from 'components/Business/List';
import {BusinessType} from 'types/BusinessType';

const Home = () => {
  const {type_id} = useParams<{type_id: string}>();
  const typeId = type_id.split('-').join('_') as BusinessType;

  if (!(typeId in BusinessType)) {
    return <Redirect />;
  }

  const name = `business_types.${typeId}_plural`;

  return (
    <Page title={name} padding noAuth>
      <PageTitle gradient="gradient-40 gray">
        <View className="row expand">
          <Text className="expand">
            <Locale text={name} />
          </Text>
        </View>
      </PageTitle>

      <Spacer size={20} />

      <BusinessesList noItemsLabel="business_types.no_results" type={typeId} />
    </Page>
  );
};

export default Home;
