import {useTranslation} from 'hooks';

import {CurrencyTag} from 'types/Currency';

export type FormatAmountProps = {
  amount: number;
  useFree?: boolean;
  currency?: CurrencyTag;
  explicit?: boolean;
  minimumFractionDigits?: number;
};

export const useFormatAmount = () => {
  const {t} = useTranslation();

  return ({
    amount,
    useFree,
    currency: currencyProp,
    explicit,
    minimumFractionDigits = 0,
  }: FormatAmountProps) => {
    if (useFree && !amount) {
      return t('common.free');
    }

    const currency = currencyProp || CurrencyTag.USD;
    const numberFormat = new Intl.NumberFormat('en', {
      style: 'currency',
      currency: currency,
      currencyDisplay: 'narrowSymbol',
      minimumFractionDigits,
      maximumFractionDigits: 2,
    });

    return `${numberFormat.format(amount || 0)}${
      explicit && currency ? ` ${currency}` : ''
    }`;
  };
};
