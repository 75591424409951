import {useMemo, useState, useCallback, useEffect} from 'react';

import {doLogin} from 'core/session';

import {
  getIdToken,
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from 'firebase/auth';

import View from 'components/Common/View';
import Spacer from 'components/Common/Spacer';
import Form from 'components/Form';
import Field from 'components/Form/Field';
import Input from 'components/Form/Input';
import Label from 'components/Form/Label';
import PhoneFields from 'components/User/PhoneFields';
import Submit from 'components/Form/Submit';

const PhoneSignInForm = () => {
  const [waitingInfo, setWaitingInfo] = useState<{
    phone: string;
    result: any;
  } | null>(null);
  const auth = useMemo(() => {
    const auth = getAuth();
    auth.useDeviceLanguage();
    return auth;
  }, []);

  const setupRecaptcha = useCallback(async () => {
    (window as any).recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
        callback: (response: any) => {
          console.log('setupRecaptcha completed');
          Promise.resolve();
        },
        'expired-callback': () => {
          console.log('expired-callback');
        },
      },
      auth,
    );
  }, [auth]);

  const onSubmitCode = useCallback(
    async ({setError, values: {code}}) => {
      //console.log('submit code', code);

      if (!waitingInfo) {
        return;
      }

      try {
        const result = await waitingInfo.result.confirm(code);
        const accessToken = await getIdToken(result.user);
        await doLogin(accessToken);
      } catch (e: any) {
        //console.log('onSubmitCode:error', e, e.code);
        setError(
          'code',
          {
            type: 'local',
            message: e.code || e.error || e,
          },
          {
            shouldFocus: true,
          },
        );
      }
    },
    [waitingInfo],
  );

  useEffect(() => {
    setupRecaptcha();
  }, [setupRecaptcha]);

  const onSubmit = useCallback(
    async ({setError, values: {phone, phone_prefix}}) => {
      const phoneNumber = `${phone_prefix}${phone}`;

      try {
        const appVerifier = (window as any).recaptchaVerifier;

        const result = await signInWithPhoneNumber(
          auth,
          phoneNumber,
          appVerifier,
        );

        const displayPhone = `${phone_prefix} ${phone}`;
        console.log('setWaitingInfo', {phone: displayPhone, result});
        setWaitingInfo({phone: displayPhone, result});
      } catch (e: any) {
        console.log('onSubmit:error', e);

        setError(
          'phone',
          {
            type: 'local',
            message: e.code || e.error || e,
          },
          {
            shouldFocus: true,
          },
        );
      }
    },
    [setWaitingInfo, auth],
  );

  return (
    <>
      {!waitingInfo && (
        <>
          <Form onSubmit={onSubmit}>
            {() => {
              return (
                <>
                  <PhoneFields />

                  <Spacer size={25} />

                  <Submit
                    id="sign-in-phone-button"
                    label="login.send_code_button"
                  />
                </>
              );
            }}
          </Form>
          <View id="recaptcha-container"></View>
        </>
      )}
      {waitingInfo && (
        <Form onSubmit={onSubmitCode}>
          {() => {
            return (
              <>
                <View>
                  <Field name="code">
                    <Label
                      label="login.field_code_label"
                      labelParams={{phone: waitingInfo.phone}}
                    />
                    <Input
                      type="number"
                      minLength={6}
                      maxLength={10}
                      label="login.field_code"
                      name="code"
                    />
                  </Field>
                </View>
                <br />
                <Submit label="login.verify_code_button" />
              </>
            );
          }}
        </Form>
      )}
    </>
  );
};

export default PhoneSignInForm;
