import {useCallback} from 'react';

import {GET} from 'utils/Http';
import {useStore, getStore} from 'core/hooks';

import {useTranslation} from 'hooks';

import {
  UserInfo,
  UserProfileInfo,
  UserStaffInfo,
  UserInfoExtra,
  UserStatus,
} from 'types/User';
import {UserStore} from 'types/Store';

export type UseFormatUserFullNameProps = {
  includeGender?: boolean;
};

export type UserFullNameType =
  | UserInfo
  | UserInfoExtra
  | UserProfileInfo
  | UserStaffInfo
  | null;

const userStore = getStore<UserStore>('user');

export const useFormatUserFullName = (): ((
  user?: UserFullNameType,
  props?: UseFormatUserFullNameProps,
) => string) => {
  const {t} = useTranslation();

  return useCallback(
    (user?: UserFullNameType, props?: UseFormatUserFullNameProps) => {
      if (!user) {
        return t('user.no_name');
      }

      const {first_name} = user;
      const last_name = 'last_name' in user ? user.last_name : null;

      let genderText = '';

      if (props) {
        const {includeGender} = props;

        genderText = `${
          includeGender && 'gender' in user && user.gender
            ? ` (${t(`settings.gender_${user.gender}`)})`
            : ''
        }`;
      }

      if (first_name && last_name) {
        return `${first_name} ${last_name}${genderText}`;
      }

      if (first_name) {
        return `${first_name}${genderText}`;
      }

      return t('user.no_name');
    },
    [t],
  );
};

export const formatUserFullNameInitials = (user: UserFullNameType) => {
  if (user) {
    const {first_name} = user;
    const last_name = 'last_name' in user ? user.last_name : null;

    if (first_name && last_name) {
      return `${first_name[0]}${last_name[0]}`;
    }

    if (first_name) {
      return first_name[0];
    }
  }

  return '??';
};

export const loadUserStatus = async () => {
  const userStatus = await GET<UserStatus>('user/status');
  userStore.userStatus = userStatus;
};

export const useIsUserProfileCompleted = () => {
  const userStatus = useStore<UserStatus>('userStatus');

  return userStatus?.fields_to_update && !userStatus.fields_to_update.length;
};
