import './Membership.scss';

import {useState, useCallback} from 'react';

import {DELETE} from 'utils/Http';

import {useSourceLoadData} from 'hooks';

import Button from 'components/Common/Button';

import BusinessInfo from 'types/Business';
import MembershipInfo, {MembershipStatus} from 'types/Membership';
import {useSetNotification} from 'components/App/Notifications';

const {Active} = MembershipStatus;

type CancelButtonProps = {
  buttonLabel?: string;
  useHardDelete?: boolean;
  business: BusinessInfo;
  membership: MembershipInfo;
};

const CancelButton = ({
  useHardDelete,
  buttonLabel,
  business,
  membership,
}: CancelButtonProps) => {
  const setNotification = useSetNotification();
  const sourceLoadData = useSourceLoadData();
  const {id: businessId} = business;
  const {status} = membership;
  const [working, setWorking] = useState(false);

  const onPressCancel = useCallback(async () => {
    setWorking(true);

    try {
      await DELETE(`memberships/${businessId}${useHardDelete ? '/hard' : ''}`);

      setNotification({
        type: 'error',
        msg: 'membership.cancelled_msg',
        duration: 4000,
      });

      sourceLoadData();
    } catch (e) {
      console.warn(e);
      setNotification({
        type: 'error',
        msg: 'membership.cancelling_error',
      });
    }

    setWorking(false);
  }, [businessId, useHardDelete, setWorking, sourceLoadData, setNotification]);

  if (status !== Active) {
    return null;
  }

  return (
    <Button
      working={working}
      onPress={onPressCancel}
      className={useHardDelete ? 'btn remove' : 'btn normal no-border'}
      dialog={{
        title: 'membership.cancel_dialog_title',
        subtitle: 'membership.cancel_dialog_subtitle',
        label: 'membership.cancel_dialog_button',
      }}
      label={
        useHardDelete ? 'membership.delete_button' : 'membership.cancel_button'
      }
    />
  );
};

export default CancelButton;
