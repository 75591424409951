import {Route, Switch} from 'react-router-dom';

import NotFound from 'pages/NotFound';

import {STAFF_PATH} from 'types/Business';

import Items from './Items';
import ClientForm from './ClientForm';

const Clients = () => {
  return (
    <Switch>
      <Route exact path={`${STAFF_PATH}/clients`} component={Items} />
      <Route exact path={`${STAFF_PATH}/clients/new`} component={ClientForm} />
      <Route
        exact
        path={`${STAFF_PATH}/clients/:client_id`}
        component={ClientForm}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Clients;
