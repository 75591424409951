import {useTranslation} from 'hooks';

import {DateTimeAny, DaysList} from 'types/Date';

import {convertToDateTime} from 'utils/DateTime';

import {Days} from 'types/Date';

type Props = {
  date?: DateTimeAny;
  day?: Days;
  short?: boolean;
};

const Day = ({date, day, short}: Props) => {
  const {t} = useTranslation();

  if (date) {
    date = convertToDateTime(date);
    day = DaysList[date.weekday - 1];
  }

  return <>{t(`days${short ? '_short' : ''}.${day}`)}</>;
};

export default Day;
