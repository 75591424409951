import './Appointment.scss';

import {useState, MouseEvent} from 'react';
import classNames from 'classnames';

import {useSourceData, useForm, useSourceLoadData} from 'hooks';

import View from 'components/Common/View';
import Form from 'components/Form';
import Field from 'components/Form/Field';
import Input from 'components/Form/Input';
import TextArea from 'components/Form/TextArea';
import Submit from 'components/Form/Submit';

import ReviewInfo from 'types/Review';

import AppointmentInfo from 'types/Appointment';
import {useSetNotification} from 'components/App/Notifications';

type ReviewRenderProps = {
  rating: number;
};

const ReviewForm = () => {
  const setNotification = useSetNotification();

  const appointment = useSourceData<AppointmentInfo>();
  const sourceLoadData = useSourceLoadData();
  const {id, review} = appointment;

  return (
    <Form<ReviewInfo>
      data={review}
      creation={!review}
      source={`reviews/${id}`}
      successMsg="appointments.review_success_msg"
      onSuccess={async ({goToUrl}) => {
        sourceLoadData();

        goToUrl({goBack: true});
      }}
      onSubmit={({values: {rating}}) => {
        console.log('values', rating);

        if (!rating) {
          setNotification({
            type: 'error',
            msg: 'appointments.review_error_msg',
          });
          return false;
        }
      }}>
      {({data: {rating}}) => <ReviewRender rating={rating} />}
    </Form>
  );
};

const ReviewRender = ({rating: ratingProp}: ReviewRenderProps) => {
  const {setValue} = useForm();
  const [rating, setRating] = useState(ratingProp);

  const onPress = (e: MouseEvent<HTMLDivElement>) => {
    const index = e.currentTarget.dataset.index;

    if (index) {
      setRating(+index);
      setValue('rating', +index);
    }
  };

  const renderRatingSelector = () => {
    const items = [];
    const reverseRating = 6 - rating;

    for (let i = 1; i <= 5; i++) {
      items.push(
        <View
          key={i}
          data-index={6 - i}
          onClick={onPress}
          className={classNames('star', {active: reverseRating === i})}
        />,
      );
    }

    return <View className="appointment-rating-items">{items}</View>;
  };

  return (
    <>
      {renderRatingSelector()}

      <Field name="rating">
        <Input optional type="hidden" />
      </Field>

      <Field name="comment" label="appointments.review_comment_field">
        <TextArea
          placeholder="appointments.review_comment_placeholder"
          optional
          minLength={4}
          maxLength={2048}
        />
      </Field>

      <Submit label="appointments.review_button" />
    </>
  );
};

export default ReviewForm;
