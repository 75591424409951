import appConfig from 'config/app';

export const GOOGLE_MAPS_URL = `https://maps.googleapis.com/maps/api/js?key=${appConfig.google_api_key}&libraries=places`;

export type MapAddress = {
  address: string;
  lat: number;
  lng: number;
};

export default MapAddress;
