import './HoursSelector.scss';

import {useState, useCallback, useMemo} from 'react';

import {goToUrl, useBusiness} from 'hooks';
import {POST, PUT, DELETE} from 'utils/Http';

import View from 'components/Common/View';
import Source from 'components/Common/Source';
import Spacer from 'components/Common/Spacer';
import Form from 'components/Form';
import Actions from 'components/Form/Actions';
import Submit from 'components/Form/Submit';

import BusinessHoursInfo from 'types/BusinessHours';
import BusinessInfo, {BusinessStatus} from 'types/Business';

import {DaysList} from 'types/Date';

import Day from './Day';
import {useSetNotification} from 'components/App/Notifications';

const {Pending} = BusinessStatus;

type HoursSelectorRenderProps = {
  business: BusinessInfo;
  hours: BusinessHoursInfo[];
};

const HoursSelector = () => {
  const business = useBusiness();
  const {id: businessId} = business;

  return (
    <Source<BusinessHoursInfo[]> source={`hours/${businessId}`}>
      {hours => <HoursSelectorRender business={business} hours={hours} />}
    </Source>
  );
};

const HoursSelectorRender = ({
  business,
  hours: hoursProp,
}: HoursSelectorRenderProps) => {
  const setNotification = useSetNotification();
  const {id: businessId, status} = business;
  const initialHours = useMemo(() => {
    const hours: BusinessHoursInfo[] = JSON.parse(JSON.stringify(hoursProp));

    return hours.sort((a, b) => a.start_minute - b.start_minute);
  }, [hoursProp]);
  const [hours, setHours] = useState<BusinessHoursInfo[]>(initialHours);

  const setHour = useCallback(
    (hour: BusinessHoursInfo) => {
      setHours(hours => {
        const newHours = [...hours];
        const idx = newHours.findIndex(h => h.id === hour.id);

        if (idx === -1) {
          return [...hours, hour];
        }

        newHours.splice(idx, 1, hour);

        return [...newHours];
      });
    },
    [setHours],
  );

  const removeHour = useCallback(
    (hour: BusinessHoursInfo) => {
      setHours(hours => {
        const newHours = [...hours];
        newHours.splice(
          newHours.findIndex(h => h.id === hour.id),
          1,
        );
        return [...newHours];
      });
    },
    [setHours],
  );

  return (
    <Form
      onSaveValues={async (): Promise<void> => {
        const hoursToRemove: BusinessHoursInfo[] = [];
        const hoursToCreate: BusinessHoursInfo[] = [];
        const hoursToUpdate: BusinessHoursInfo[] = [];

        hours.forEach(hour => {
          const found = initialHours.find(h => h.id === hour.id);

          if (!found) {
            hoursToCreate.push(hour);
            return;
          }

          if (
            found.start_minute !== hour.start_minute ||
            found.end_minute !== hour.end_minute
          ) {
            hoursToUpdate.push(hour);
          }
        });

        initialHours.forEach(hour => {
          const found = hours.find(h => h.id === hour.id);

          if (!found) {
            hoursToRemove.push(hour);
          }
        });

        //console.log(hoursToCreate, hoursToUpdate, hoursToRemove);

        await Promise.all(
          hoursToRemove.map(async ({id, ...hour}) =>
            DELETE(`hours/${businessId}/${id}`, hour),
          ),
        );

        await Promise.all(
          hoursToUpdate.map(async ({id, ...hour}) =>
            PUT(`hours/${businessId}/${id}`, hour),
          ),
        );

        await Promise.all(
          hoursToCreate.map(async ({id, ...hour}) =>
            POST(`hours/${businessId}`, hour),
          ),
        );

        setNotification({
          type: 'success',
          msg: 'staff.settings.hours.success_msg',
        });

        if (status === Pending) {
          goToUrl({goBack: 2, follows: 'complete'});
        } else {
          goToUrl({goBack: true});
        }

        return Promise.resolve();
      }}>
      {() => (
        <>
          <View className="hours-selector">
            {DaysList.map(day => (
              <Day
                setHour={setHour}
                removeHour={removeHour}
                key={day}
                day={day}
                hours={hours.filter(hour => hour.day === day)}
              />
            ))}
          </View>

          <Spacer size={20} />

          <Actions>
            <Submit />
          </Actions>
        </>
      )}
    </Form>
  );
};

export default HoursSelector;
