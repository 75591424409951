import './Explorer.scss';

import {useCallback} from 'react';

import {useStore} from 'core/hooks';

import {goToUrl} from 'hooks';

import Button from 'components/Common/Button';

const SearchButton = () => {
  const explorerIs = useStore<boolean>('explorerIs');

  const onPress = useCallback(() => {
    goToUrl({to: 'explore'});
  }, []);

  return (
    <>
      {!explorerIs && (
        <Button onPress={onPress} className="explorer-search-button" />
      )}
    </>
  );
};

export default SearchButton;
