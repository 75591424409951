import './Explorer.scss';

import {useRef, useEffect, useState, useCallback} from 'react';

import {uri} from 'hooks/useUrl';
import {getStore, useStore} from 'core/hooks';

import {useTranslation, useIsMounted, useHistory, useLocation} from 'hooks';

import BusinessList from 'components/Business/List';

import {
  ExplorerLocation,
  ExplorerSearchCallback,
  ExplorerListStatusCallback,
  ExplorerSearchTextCallback,
} from 'types/Explorer';
import {BusinessType} from 'types/BusinessType';
import {UserStore, UIStore} from 'types/Store';

import ListHeader from './ListHeader';

const uiStore = getStore<UIStore>('ui');
const userStore = getStore<UserStore>('user');

const List = () => {
  const renderStatus = useRef<'init' | 'loading' | 'loaded'>('init');
  const {t} = useTranslation();
  const isMounted = useIsMounted();
  const history = useHistory();
  const {pathname, search} = useLocation();
  const currentLocation = useStore<ExplorerLocation>('currentLocation');
  const [initParams] = useState(new URLSearchParams(search));

  //console.log('rendering???', currentLocation);

  const initialType = initParams.get('type') as BusinessType;

  const [type, setType] = useState<BusinessType | null>(
    initialType in BusinessType ? initialType : null,
  );

  let useLat: number | null = null;
  let useLng: number | null = null;

  if (renderStatus.current === 'init') {
    renderStatus.current = 'loaded';

    const tempLat = parseFloat(initParams.get('lat') as string);
    const tempLng = parseFloat(initParams.get('lng') as string);

    if (tempLat >= -90 && tempLat <= 90 && tempLng >= -180 && tempLng <= 180) {
      useLat = tempLat;
      useLng = tempLng;
      renderStatus.current = 'loading';
    }
  }

  const explorerSearch: ExplorerSearchCallback = useCallback(
    search => {
      if (!isMounted()) {
        return false;
      }

      if (!search) {
        setType(null);
        return true;
      }

      const {type, identifier} = search;

      if (type === 'type') {
        setType(identifier as BusinessType);
      }

      return true;
    },
    [setType, isMounted],
  );

  const explorerListStatus: ExplorerListStatusCallback = useCallback(() => {
    if (!isMounted()) {
      return 'loaded';
    }

    return renderStatus.current;
  }, [renderStatus, isMounted]);

  const explorerSearchText: ExplorerSearchTextCallback = useCallback(() => {
    if (!isMounted()) {
      return '';
    }

    if (type) {
      return t(`business_types.${type}_plural`);
    }

    return '';
  }, [t, isMounted, type]);

  // Set handler
  useEffect(() => {
    uiStore.explorerIs = true;
    uiStore.explorerSearch = explorerSearch;
    uiStore.explorerListStatus = explorerListStatus;
    uiStore.explorerSearchText = explorerSearchText;

    return () => {
      uiStore.explorerIs = false;
    };
  }, [explorerSearch, explorerListStatus, explorerSearchText]);

  let display: string | null = null;
  let lat: number | null = useLat;
  let lng: number | null = useLng;
  let loaded = false;

  if (renderStatus.current !== 'loading' && currentLocation) {
    display = currentLocation.display;
    lat = currentLocation.lat;
    lng = currentLocation.lng;
    loaded = currentLocation.loaded || false;
  }

  useEffect(() => {
    if (renderStatus.current !== 'loading' || !lat || !lng) {
      return;
    }

    userStore.currentLocation = {
      display: '...',
      lat,
      lng,
      loaded: false,
    };
    renderStatus.current = 'loaded';
  }, [renderStatus, lat, lng]);

  // Update URL
  useEffect(() => {
    if (renderStatus.current !== 'loaded') {
      return;
    }

    const params: any = {
      ...(type && {type}),
      ...(lat && lng && {lat: lat.toFixed(6), lng: lng.toFixed(6)}),
    };

    const newUri = uri(
      pathname,
      undefined,
      Object.keys(params).length ? params : undefined,
    );

    const currentPath = `${pathname}${search}`;
    if (currentPath !== newUri) {
      history.replace(newUri);
    }
  }, [renderStatus, type, history, pathname, search, lat, lng]);

  if (
    renderStatus.current === 'loading' ||
    !loaded ||
    !display ||
    !lat ||
    !lng
  ) {
    return null;
  }

  return (
    <BusinessList
      {...(type && {type})}
      headerRender={items => (
        <ListHeader location={currentLocation} items={items} />
      )}
      noItemsContent={() => null}
      lat={lat}
      lng={lng}
    />
  );
};

export default List;
