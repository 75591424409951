import {useMemo, useContext, createContext, HTMLProps} from 'react';
import classNames from 'classnames';
import {useFormContext, useFormState} from 'react-hook-form';

import View from 'components/Common/View';

import Errors from '../Errors';

type AnyProps = {[name: string]: any};

type FieldProps = {
  name: string;
  type?: string;
};

type Props = HTMLProps<HTMLDivElement> & FieldProps & AnyProps;

const FieldContext = createContext<AnyProps>({});

const Field = (props: Props) => {
  const {name, type} = props;
  const {control} = useFormContext();

  const {errors} = useFormState({name, control});
  const fieldError = errors[name];

  const content = useMemo(() => {
    const {className, children, ...rest} = props;
    return (
      <FieldContext.Provider value={{...rest} as AnyProps}>
        <View
          className={classNames(
            'field',
            className,
            {[`field-${name}`]: name},
            {[`field-${type}`]: type},
            {error: fieldError},
          )}>
          {children}
          {<Errors error={fieldError} />}
        </View>
      </FieldContext.Provider>
    );
  }, [name, type, props, fieldError]);

  return <>{content}</>;
};

export const useField = <T,>(): T => {
  return useContext(FieldContext) as T;
};

export default Field;
