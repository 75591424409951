import './Tabbar.scss';

import {useHasRole} from 'hooks';

import View from 'components/Common/View';

import {StaffRole} from 'types/Staff';

import Button from './Button';

const {Owner} = StaffRole;

const Tabbar = () => {
  const isOwner = useHasRole([Owner]);

  return (
    <View className="tabbar-container">
      <Button icon="calendar" to="calendar" label="staff.menu.calendar" />
      <Button
        icon="appointments"
        to="appointments"
        label="staff.menu.appointments"
      />
      <Button icon="clients" to="clients" label="staff.menu.clients" />
      {isOwner && (
        <Button icon="settings" to="settings" label="staff.menu.settings" />
      )}
    </View>
  );
};

export default Tabbar;
