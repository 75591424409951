import Link from 'components/Common/Link';

import {ReactComponent as PhoneLogo} from './Assets/PhoneLogo.svg';

const PhoneSignInButton = () => {
  return (
    <Link
      className="login-button phone"
      to="login/phone"
      label="login.phone_button"
      icon={<PhoneLogo className="icon" />}
    />
  );
};

export default PhoneSignInButton;
