import {DateTime} from 'luxon';

import {DateTimeAny, DATE_FORMAT, DATE_TIME_FORMAT} from 'types/Date';

export const formatTimeTag = (minutes: number): string => {
  const parts: string[] = [];

  const hours = Math.floor(minutes / 60);
  if (hours > 0) {
    parts.push(`${hours}h`);
  }

  const mins = Math.floor(minutes % 60);
  if (mins > 0) {
    parts.push(`${mins}min`);
  }

  return parts.join(' ');
};

export const convertToDateTime = (
  date: DateTimeAny,
  timezone = 'utc',
): DateTime => {
  if (date instanceof DateTime) {
    return date;
  }

  if (date instanceof Date) {
    return DateTime.fromJSDate(date);
  }

  if (date.indexOf('T') !== -1) {
    return DateTime.fromISO(date, {
      zone: timezone,
    });
  }

  return DateTime.fromFormat(
    date,
    date.indexOf(' ') !== -1 ? DATE_TIME_FORMAT : DATE_FORMAT,
  );
};

export const getUTCFromTimezone = (timezone = 'utc'): DateTime => {
  return DateTime.now().setZone(timezone).setZone('utc', {
    keepLocalTime: true,
  });
};
