import {useEffect} from 'react';

import {useBusiness, setBookingView} from 'hooks';

import Title from 'components/Page/Title';
import ServicesList from 'components/Booking/Services';

const Services = () => {
  const business = useBusiness();

  useEffect(() => {
    setBookingView('services');
    return setBookingView;
  }, []);

  return (
    <>
      <Title title="booking.step_services" />
      <ServicesList business={business} />
    </>
  );
};

export default Services;
