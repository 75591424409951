import './Home.scss';

import classNames from 'classnames';

import {useIsMobile} from 'hooks';

import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';
import Locale from 'components/Common/Locale';
import Text from 'components/Common/Text';
import View from 'components/Common/View';
import Br from 'components/Common/Br';
import Spacer from 'components/Common/Spacer';
import BusinessTypeList from 'components/BusinessType/List';

const Home = () => {
  const isMobile = useIsMobile();

  const renderHeader = () => {
    if (isMobile) {
      return (
        <>
          <Locale text="home.mobile.page_title_1" />
          <Br size={3} />
          <Locale text="home.mobile.page_title_2" />
          <Br size={3} />
          <Locale text="home.mobile.page_title_3" />
          <Br size={20} />
        </>
      );
    }

    return (
      <>
        <Locale text="home.desktop.page_title_1" />
        <Br size={3} />
        <Locale text="home.desktop.page_title_2" />
      </>
    );
  };

  return (
    <Page title="Reserby" noAuth padding>
      <PageTitle
        className="show-in"
        gradient={classNames(
          {
            'gradient-100': isMobile,
            center: !isMobile,
            big: !isMobile,
            'gradient-140': !isMobile,
          },
          'blue',
        )}>
        <Text>{renderHeader()}</Text>
      </PageTitle>

      <View className="row expand">
        <Text contentTitle className="expand">
          <Locale text="common.categories" />
        </Text>
      </View>

      {!isMobile && <Spacer size={20} />}

      <BusinessTypeList />
    </Page>
  );
};

export default Home;
