export enum UserGroups {
  Any = 0, // Any user (included non-logged)
  User = 1,

  Admin = 255,
}

export enum UserGender {
  Male = 'male',
  Female = 'female',
  They = 'they',
  Other = 'other',
}

export interface UserInfo {
  id: string;
  first_name: string;
  photo: string;
}

export type UserStaffInfo = UserInfo &
  UserPhonePrefix & {
    last_name: string;
    email: string;
    gender: UserGender;
    dob: Date;
    notifications_email: boolean;
    notifications_sms: boolean;
  };

export interface UserInfoExtra extends UserInfo {
  id: string;
  user_id: string;
  group: number;
  username: string;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  photo: string;
  created_at: Date;
  inactive: boolean;

  dob: Date;
  gender: UserGender;
}

export type UserPhonePrefix = {
  phone: string;
  phone_prefix: string;
};

export type UserProfileInfo = UserInfoExtra & UserPhonePrefix;

export type UserFieldsToUpdate = keyof UserInfoExtra;

export type UserStatus = {
  businesses_count: number;
  fields_to_update: UserFieldsToUpdate[];
};

export default UserInfo;
