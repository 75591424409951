import {PUT} from 'utils/Http';

import {useSourceLoadData, useLoadCalendar} from 'hooks';

import AppointmentInfo, {AppointmentStatus} from 'types/Appointment';

import Button from 'components/Common/Button';
import {useSetNotification} from 'components/App/Notifications';

const {Confirmed, Completed} = AppointmentStatus;

type Props = {
  appointment: AppointmentInfo;
};

const CompleteButton = ({appointment}: Props) => {
  const setNotification = useSetNotification();
  const loadCalendar = useLoadCalendar();
  const sourceLoadData = useSourceLoadData();
  const {id, status} = appointment;

  const onPress = async () => {
    await PUT<AppointmentInfo>(`appointments/${id}`, {
      status: Completed,
    });

    sourceLoadData();
    loadCalendar();

    setNotification({
      type: 'success',
      msg: 'appointments.complete_success_msg',
      duration: 3000,
    });
  };

  return (
    <>
      {status === Confirmed && (
        <Button
          dialog={{
            title: 'appointments.complete_dialog_title',
            subtitle: 'appointments.complete_dialog_subtitle',
            label: 'appointments.complete_dialog_button',
            labelClass: 'btn blue',
          }}
          className="btn blue"
          onPress={onPress}
          label="appointments.complete_button"
        />
      )}
    </>
  );
};

export default CompleteButton;
