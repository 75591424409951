import {useRef} from 'react';

import {goToUrl, useUrl} from 'hooks';

import ModalPage from 'components/App/Modal/Page';

import NewAppointmentView from '../Appointments/New';

const NewAppointment = () => {
  const baseUrl = useUrl({goBack: true});
  const startUrl = useRef(baseUrl);

  return (
    <ModalPage setClosed={() => goToUrl({to: startUrl.current})}>
      <NewAppointmentView />
    </ModalPage>
  );
};

export default NewAppointment;
