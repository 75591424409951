import {useTranslation} from 'hooks';

export type LocaleTextProp = any;

export type LocaleParamsProp = {
  [name: string]: any;
};

export type LocaleProps = {
  text: LocaleTextProp;
  params?: LocaleParamsProp;
};

const Locale = ({text, params}: LocaleProps) => {
  const {t} = useTranslation();
  return <>{t(Array.isArray(text) ? text : [text, text], params)}</>;
};

export default Locale;
