import './Explorer.scss';

import {useRef, useEffect, useMemo, useCallback, useState} from 'react';
import classNames from 'classnames';

import {GET} from 'utils/Http';
import {getStore, useStore} from 'core/hooks';

import {
  useOnClickOutside,
  useIsMounted,
  useTranslation,
  useScript,
} from 'hooks';

import View from 'components/Common/View';
import Text from 'components/Common/Text';
import Form from 'components/Form';
import Locale from 'components/Common/Locale';
import Autocomplete from 'components/Form/Autocomplete';

import {UserStore, UIStore} from 'types/Store';
import {ExplorerLocation} from 'types/Explorer';
import {GOOGLE_MAPS_URL} from 'types/MapAddress';

import {getLocation, setLocation} from 'utils/Location';

type ExplorerLocationItem = {
  display: string;
  placeId?: string;
  lat?: number;
  lng?: number;
  isNearMe?: boolean;
};

const userStore = getStore<UserStore>('user');
const uiStore = getStore<UIStore>('ui');

const LocationSelector = () => {
  return (
    <Form
      className="location-selector-form"
      onSubmitHandler={e => {
        e.preventDefault();
      }}>
      {() => {
        return <LocationSelectorRender />;
      }}
    </Form>
  );
};

const LocationSelectorRender = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const fetchingRef = useRef(false);
  const isMounted = useIsMounted();
  const placesReady = useScript(GOOGLE_MAPS_URL);
  const {t} = useTranslation();
  const currentLocationState = useStore<ExplorerLocation>('currentLocation');
  const currentLocation = currentLocationState || getLocation();

  const [loadingState, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const nearMeItem = useMemo(
    () => ({
      display: t('explorer.near_me_label'),
      isNearMe: true,
    }),
    [t],
  );
  const [items, setItems] = useState<ExplorerLocationItem[]>([nearMeItem]);

  const loading = !currentLocation || !currentLocation.loaded || loadingState;

  const onClickOutside = useCallback(() => {
    setActive(false);
  }, [setActive]);
  useOnClickOutside(containerRef, onClickOutside);

  const onResults = useCallback(
    (results: ExplorerLocationItem[]) => {
      const items: ExplorerLocationItem[] = [nearMeItem, ...results];

      setItems(items);
    },
    [setItems, nearMeItem],
  );

  const google = (window as any).google;

  const onPlacesAutocomplete = useCallback(
    async (predictions: any, status: any) => {
      if (!isMounted()) {
        return;
      }

      if (
        status !== google.maps.places.PlacesServiceStatus.OK ||
        !predictions
      ) {
        onResults([]);
        return;
      }

      const resultItems: ExplorerLocationItem[] = [];

      predictions.forEach((prediction: any) => {
        const {description, place_id} = prediction;

        //console.log('prediction', prediction);

        if (!place_id) {
          return;
        }

        resultItems.push({
          display: description,
          placeId: place_id,
        });
      });

      onResults(resultItems);
    },
    [onResults, google, isMounted],
  );

  const onChange = useCallback(
    e => {
      if (!placesReady) {
        return;
      }

      const {value} = e.target;

      if (!value.trim()) {
        onResults([]);
        return;
      }

      const options = {
        input: value,
        strictBounds: false,
        types: ['(cities)'],
      };

      const service = new google.maps.places.AutocompleteService();
      service.getPlacePredictions(options, onPlacesAutocomplete);
    },
    [onResults, onPlacesAutocomplete, placesReady, google],
  );

  const itemRender = useCallback(
    ({display, isNearMe}: ExplorerLocationItem) => {
      if (isNearMe) {
        return <Text className="near-me">{display}</Text>;
      }

      return <Text>{display}</Text>;
    },
    [],
  );

  const saveLocation = useCallback((location: ExplorerLocation) => {
    const newLocation = {loaded: true, ...location};
    userStore.currentLocation = {...newLocation};
    setLocation({...newLocation});
  }, []);

  const tryToLoadDeviceLocation = useCallback(async () => {
    //navigator.geolocation.getCurrentPosition(success, error, options);

    navigator.geolocation.getCurrentPosition(
      async geo => {
        if (!isMounted()) {
          return;
        }

        const lat = geo.coords.latitude;
        const lng = geo.coords.longitude;

        const location = await GET<ExplorerLocation>('explorer/location', {
          lat,
          lng,
        });

        if (!isMounted()) {
          return;
        }

        //console.log('location width permission', location);

        saveLocation(location);
        setLoading(false);
      },
      async err => {
        if (!isMounted()) {
          return;
        }

        //console.warn('location rejected', err);

        const location = await GET<ExplorerLocation>('explorer/location');

        if (!isMounted()) {
          return;
        }

        //console.log('location without permission', location);

        saveLocation(location);
        setLoading(false);
      },
      {
        enableHighAccuracy: true,
        timeout: 6000,
        maximumAge: 0,
      },
    );
  }, [isMounted, saveLocation]);

  const onPressItem = useCallback(
    async ({display, lat, lng, placeId, isNearMe}: ExplorerLocationItem) => {
      setLoading(true);
      setActive(false);

      if (isNearMe) {
        tryToLoadDeviceLocation();
        return;
      }

      if (lat && lng) {
        saveLocation({
          display,
          lat,
          lng,
        });
      }

      if (placeId) {
        const input = document.querySelector('#search_location');
        const placesService = new google.maps.places.PlacesService(input);

        placesService.getDetails(
          {
            placeId: placeId,
            fields: [
              'name',
              'geometry',
              'address_components',
              'formatted_address',
            ],
          },
          (place: any, status: any) => {
            if (!isMounted()) {
              return;
            }

            if (
              status !== google.maps.places.PlacesServiceStatus.OK ||
              !place?.geometry
            ) {
              return;
            }

            const newLat = place.geometry.location.lat();
            const newLng = place.geometry.location.lng();

            saveLocation({
              display,
              lat: newLat,
              lng: newLng,
            });
            setLoading(false);
          },
        );
      }
    },
    [google, setLoading, saveLocation, isMounted, tryToLoadDeviceLocation],
  );

  // Load from lat and lng
  useEffect(() => {
    if (fetchingRef.current || !currentLocation || currentLocation.loaded) {
      return;
    }

    if (!isMounted()) {
      return;
    }

    // Prevent unnecessary location update
    /*const storedLocation = getLocation();

    if (
      currentLocation?.lat.toFixed(6) === storedLocation?.lat.toFixed(6) &&
      currentLocation?.lng.toFixed(6) === storedLocation?.lng.toFixed(6)
    ) {
      setTimeout(() => {
        saveLocation(storedLocation);
        setLoading(false);
      }, 100);
      return;
    }*/

    fetchingRef.current = true;
    const loadLocation = async () => {
      const lat = currentLocation.lat;
      const lng = currentLocation.lng;

      //console.log('fetching location', {...currentLocation});

      fetchingRef.current = true;
      try {
        const location = await GET<ExplorerLocation>('explorer/location', {
          lat,
          lng,
        });

        if (!isMounted()) {
          return;
        }

        //console.log('loading new location', location);

        saveLocation(location);
        setLoading(false);
      } catch (e) {
        if (!isMounted()) {
          return;
        }
      }

      fetchingRef.current = false;
    };

    loadLocation();
  }, [fetchingRef, saveLocation, isMounted, currentLocation, setLoading]);

  // Load init location
  useEffect(() => {
    const {explorerListStatus} = uiStore;
    if (currentLocation || explorerListStatus() !== 'loaded') {
      return;
    }

    const loadInitLocation = async () => {
      setTimeout(async () => {
        if (getLocation() || fetchingRef.current || !isMounted()) {
          return;
        }

        //tryToLoadDeviceLocation();

        const location = await GET<ExplorerLocation>('explorer/location');

        if (!isMounted()) {
          return;
        }

        saveLocation(location);
        setLoading(false);
      }, 100);
    };

    loadInitLocation();
  }, [
    fetchingRef,
    saveLocation,
    currentLocation,
    tryToLoadDeviceLocation,
    isMounted,
  ]);

  // Init focus
  useEffect(() => {
    if (!active) {
      return;
    }

    const input = document.querySelector<HTMLInputElement>('#search_location');

    if (input) {
      input.focus();
    }
  }, [active]);

  const onPressChange = useCallback(() => {
    setActive(true);
  }, [setActive]);

  const renderSelector = () => {
    // Loading
    if (loading) {
      return (
        <View key="finding" className="finding show-in">
          <View className="loader">
            <View className="pulse" />
            <View className="pulse" />
          </View>
          <Text className="name">
            <Locale text="explorer.finding_location_label" />
          </Text>
        </View>
      );
    }

    // Input
    if (active) {
      return (
        <Autocomplete<ExplorerLocationItem>
          items={items}
          name="search_location"
          className="default"
          onChange={onChange}
          itemRender={itemRender}
          onPressItem={onPressItem}
          placeholder="explorer.location_placeholder"
          maxLength={64}
        />
      );
    }

    // Current location
    return (
      <View key="location" onClick={onPressChange} className="location show-in">
        <Text className="name">{currentLocation.display}</Text>
      </View>
    );
  };

  return (
    <View
      ref={containerRef}
      className={classNames('location-selector', {
        'is-loading': loading,
        active,
      })}>
      {renderSelector()}
    </View>
  );
};

export default LocationSelector;
