import {useCallback} from 'react';
import {generatePath} from 'react-router';

import {useLocation} from 'hooks';

import {UrlProps} from 'types/Url';

type Params = {[name: string]: any};

export const uri = (
  path?: string | string[],
  params?: Params,
  query?: Params,
): string => {
  let basePath = '';

  path = path || '/';

  if (Array.isArray(path)) {
    basePath = path.map(encodeURIComponent).join('/');
  } else if (typeof path === 'string') {
    basePath = path;
  }

  if (basePath[0] !== '/') {
    basePath = `/${basePath}`;
  }

  const validatedPath = generatePath(basePath, params);

  const queryParams =
    query && Object.keys(query)
      ? '?' +
        new URLSearchParams(
          (() => {
            const newQuery: any = {};
            Object.keys(query).forEach(key => {
              if (query[key]) {
                newQuery[key] = query[key];
              }
            });
            return newQuery;
          })(),
        )
      : '';

  return `${validatedPath}${queryParams}`;
};

export const formatGoBack = (path: string, goBack: boolean | number) => {
  let goBackCount = typeof goBack === 'boolean' ? 1 : goBack;

  do {
    path = path.substr(0, path.lastIndexOf('/'));
    goBackCount--;
  } while (goBackCount > 0);

  return path;
};

export const useUrl = (props: UrlProps): string => {
  const {pathname} = useLocation();
  try {
    return formatUrl(props, pathname);
  } catch (e) {
    console.log(props);
    console.warn(e);
    return '/';
  }
};

export const useUrlFormatter = (): ((props: UrlProps) => string) => {
  const {pathname} = useLocation();
  return useCallback(
    (props: UrlProps) => {
      return formatUrl(props, pathname);
    },
    [pathname],
  );
};

export const formatUrl = (
  {base, goBack, full, follows, to, params, query}: UrlProps,
  pathname: string,
) => {
  const basePathName =
    !follows && !goBack
      ? to || ''
      : pathname.slice(-1) === '/'
      ? pathname.slice(0, -1)
      : pathname;

  const finalUrlPathBase = goBack
    ? formatGoBack(basePathName, goBack)
    : basePathName;

  const baseUri = (base ? base : '') + (follows ? finalUrlPathBase : '');

  const pathUri = uri(follows ? follows : finalUrlPathBase, params, query);

  const fullUri = `${baseUri}${baseUri && pathUri === '/' ? '' : pathUri}`;

  if (full) {
    return `${window.location.origin}${fullUri}`;
  }

  return fullUri;
};

export default useUrl;
