import {ReactNode} from 'react';

import View from 'components/Common/View';
import Text from 'components/Common/Text';
import Locale from 'components/Common/Locale';

import {parseErrorInfo} from 'utils/Error';

type Props = {
  msg?: string | string[] | ReactNode;
  error?: string | string[] | ReactNode;
  info?: any;
  children?: ReactNode;
};

const Error = ({msg, error, info, children}: Props) => {
  if (info) {
    console.warn(info);
    const errorInfo = parseErrorInfo(info);
    msg = errorInfo.msg;
    error = errorInfo.error;
  }
  return (
    <View className="center column padding">
      <Text bold large center>
        {(typeof msg === 'string' || Array.isArray(msg) ? (
          <Locale text={msg} />
        ) : (
          msg
        )) || <Locale text="errors.unknown" />}
      </Text>
      {error &&
        (typeof error === 'string' || Array.isArray(error) ? (
          <>
            <br />
            <Text xsmall center color="#9d9fa5">
              <Locale text={error} />
            </Text>
          </>
        ) : (
          error
        ))}
      {children}
    </View>
  );
};

export default Error;
