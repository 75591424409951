import BusinessHoursInfo from './BusinessHours';
import BusinessSpecialDateInfo from './BusinessSpecialDate';
import BusinessTypeInfo from './BusinessType';
import ServiceInfo from './Service';
import CategoryInfo from './Category';
import PhotoInfo from './Photo';
import {CurrencyTag} from './Currency';
import StaffInfo from './Staff';
import {CountryTag} from './Country';
import {MembershipType} from './Membership';
import MapAddress from 'types/MapAddress';

export const BUSINESS_PHOTO_SIZE = ['1500x1200/cover'];
export const BUSINESS_PHOTO_LIST_SIZE = ['700x500/cover'];

export const STAFF_PATH = '/b/:id/staff';
export const BUSINESS_PATH = '/b/:id';

export enum BusinessStatus {
  Pending = 'pending',
  Active = 'active',
  Inactive = 'inactive',
  Disabled = 'disabled',
}

export interface BusinessInfo {
  id: string;
  name: string;
  description: string;
  slug: string;
  address: string;
  map_address: MapAddress;
  hours: BusinessHoursInfo[];
  country: CountryTag;
  type: BusinessTypeInfo;
  types: BusinessTypeInfo[];
  special_dates: BusinessSpecialDateInfo[];
  categories: CategoryInfo[];
  services: ServiceInfo[];
  photo: PhotoInfo;
  photos: PhotoInfo[];
  currency: CurrencyTag;
  timezone: string;
  concurrency: number;
  rating: number;
  rating_count: number;
  membership_type: MembershipType;
  active: boolean;
  status: BusinessStatus;
  status_bool: boolean;
  role: StaffInfo;
}

export default BusinessInfo;
