import {useBusiness, useParams} from 'hooks';

import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';
import Form from 'components/Form';
import Actions from 'components/Form/Actions';
import Field from 'components/Form/Field';
import Label from 'components/Form/Label';
import Input from 'components/Form/Input';
import Submit from 'components/Form/Submit';
import Remove from 'components/Form/Remove';

import CategoriesInfo from 'types/Category';

const CategoryForm = () => {
  const {id} = useBusiness();
  const {category_id} = useParams<{category_id: string}>();

  return (
    <Page
      title={`staff.services.${category_id ? 'edit' : 'add'}_category`}
      padding>
      <PageTitle className="staff-title center" />

      <Form<CategoriesInfo>
        onSuccess={({goToUrl, data: {id, name}}) => {
          if (!category_id) {
            goToUrl({goBack: 2, follows: `add/${id}`, state: {name}});
          } else {
            goToUrl({goBack: 2});
          }
        }}
        successMsg={false}
        onRemove={({goToUrl}) => goToUrl({goBack: 2})}
        sourceId={category_id}
        source={`categories/${id}`}>
        {() => {
          return (
            <>
              <Field name="name" label="staff.services.field_category_name">
                <Label />
                <Input minLength={4} maxLength={128} />
              </Field>

              <Actions>
                <Submit />
                <Remove />
              </Actions>
            </>
          );
        }}
      </Form>
    </Page>
  );
};

export default CategoryForm;
