import {Route, Switch} from 'react-router-dom';

import NotFound from 'pages/NotFound';

import Items from './Items';
import Appointment from './Appointment';

const Appointments = () => {
  return (
    <Switch>
      <Route exact path="/appointments" component={Items} />
      <Route path="/appointments/:id" component={Appointment} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Appointments;
