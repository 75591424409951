import './StaffList.scss';

import {useState, useCallback} from 'react';

import {isUserId, isGroup} from 'core/session';
import {DELETE} from 'utils/Http';

import {
  useFormatUserFullName,
  useBusiness,
  useStaffUrl,
  useIsBusinessDisabled,
} from 'hooks';

import View from 'components/Common/View';
import Text from 'components/Common/Text';
import ItemsList from 'components/Common/ItemsList';
import * as Grid from 'components/Common/Grid';
import Form from 'components/Form';
import Input from 'components/Form/Input';
import Connect from 'components/Form/Connect';
import Locale from 'components/Common/Locale';
import DropdownButton, {
  ButtonOption,
  LinkOption,
} from 'components/Common/DropdownButton';

import BusinessInfo from 'types/Business';
import StaffInfo from 'types/Staff';
import {useSetNotification} from 'components/App/Notifications';

type StaffListRenderProps = {
  business: BusinessInfo;
  staff: StaffInfo;
};

const StaffList = () => {
  const business = useBusiness();
  const {id: businessId} = business;

  return (
    <Connect>
      <Form className="filter-form">
        {() => {
          return (
            <>
              <Input
                name="search"
                className="search"
                optional
                label="staff.staff.search_input"
                placeholder="staff.staff.search_placeholder"
                maxLength={40}
              />
            </>
          );
        }}
      </Form>
      <ItemsList<StaffInfo>
        noItemsLabel="staff.staff.no_results"
        className="staff-staff-list"
        useInfiniteScroll
        source={`staff/${businessId}`}>
        {staff => <StaffListRender business={business} staff={staff} />}
      </ItemsList>
    </Connect>
  );
};

const StaffListRender = ({
  business,
  staff: staffData,
}: StaffListRenderProps) => {
  const setNotification = useSetNotification();
  const isBusinessDisabled = useIsBusinessDisabled();
  const staffUrl = useStaffUrl();
  const formatUserFullName = useFormatUserFullName();
  const {id, role, member} = staffData;
  const {id: businessId} = business;
  const {id: userId, phone, phone_prefix} = member;
  const [removed, setRemoved] = useState(false);

  const onPressRemove = useCallback(async () => {
    if (!isGroup('admin') && isUserId(userId)) {
      setNotification({
        type: 'error',
        msg: 'staff.clients.delete_cannot_delete_error_msg',
      });
      return;
    }

    setRemoved(true);

    await DELETE(`staff/${businessId}/${id}`);

    setNotification({
      type: 'success',
      msg: 'staff.staff.delete_success_msg',
    });
  }, [id, userId, businessId, setRemoved, setNotification]);

  if (removed) {
    return null;
  }

  const staffName = formatUserFullName(member);

  return (
    <View className="staff-item">
      <Grid.Field className="staff">
        <Text className="name">{staffName}</Text>
        <Text className="role">
          <Locale text={`staff.staff.role_${role}`} />
        </Text>
        {phone && (
          <Text className="phone">
            {phone_prefix} {phone}
          </Text>
        )}
      </Grid.Field>

      <Grid.Field className="justify-content-end">
        <DropdownButton icon="options">
          {!isBusinessDisabled && (
            <LinkOption
              base={staffUrl({follows: id})}
              state={{isFromStaff: true, userId, userName: staffName}}
              label="staff.staff.edit_button"
            />
          )}
          <ButtonOption
            dialog={{
              title: 'staff.staff.delete_dialog_title',
              subtitle: 'staff.staff.delete_dialog_subtitle',
              label: 'staff.staff.delete_dialog_button',
            }}
            label="staff.staff.remove_button"
            onPress={onPressRemove}
          />
        </DropdownButton>
      </Grid.Field>
    </View>
  );
};

export default StaffList;
