import View from 'components/Common/View';
import Text from 'components/Common/Text';

import ServiceItem from './ServiceItem';

import {CategoryServices} from 'types/Category';

type CategoryItemProps = {
  category: CategoryServices;
};

const CategoryItem = ({
  category: {
    category: {id, name},
    services,
  },
}: CategoryItemProps) => {
  return (
    <View id={`category-item-${id}`} className="category-item">
      <View className="header">
        <Text className="name">{name}</Text>
      </View>

      <View className="services-list">
        {services.map(service => {
          return <ServiceItem key={service.id} service={service} />;
        })}
      </View>
    </View>
  );
};

export default CategoryItem;
