import './Membership.scss';

import {ReactNode} from 'react';
import classNames from 'classnames';

import View from 'components/Common/View';
import Text from 'components/Common/Text';
import Price from 'components/Common/Price';
import Locale from 'components/Common/Locale';

import BusinessInfo from 'types/Business';
import MembershipInfo, {MembershipType} from 'types/Membership';

const {Free} = MembershipType;

type InfoProps = {
  type?: MembershipType;
  business: BusinessInfo;
  membership: MembershipInfo;
  children?: ReactNode;
};

const Info = ({type: typeProp, business, membership, children}: InfoProps) => {
  const {currency} = business;
  const {is_free, type, price} = membership;
  const displayType = typeProp || type;

  return (
    <>
      <View className={classNames('membership-info', displayType || type)}>
        <View className="price-container">
          <Text className="type">
            <Locale text={`membership.type_${displayType || type}`} />
          </Text>

          <View className="price">
            {is_free ? (
              <Text className="no-payment">
                <Locale text="membership.no_payment" />
              </Text>
            ) : (
              <>
                <Text className="amount">
                  <Price
                    explicit
                    currency={currency}
                    amount={displayType === Free ? 0 : price}
                  />
                </Text>
                <Text className="monthly">
                  {' '}
                  / <Locale text="membership.monthly" />
                </Text>
              </>
            )}
          </View>
        </View>

        {children}
      </View>
    </>
  );
};

export default Info;
