import './Membership.scss';

import {useState, useCallback} from 'react';

import {PUT, DELETE} from 'utils/Http';

import {useSourceLoadData} from 'hooks';

import Button from 'components/Common/Button';

import BusinessInfo from 'types/Business';
import MembershipInfo from 'types/Membership';
import {useSetNotification} from 'components/App/Notifications';

type FreeButtonProps = {
  business: BusinessInfo;
  membership: MembershipInfo;
};

const FreeButton = ({business, membership}: FreeButtonProps) => {
  const setNotification = useSetNotification();
  const sourceLoadData = useSourceLoadData();
  const {id: businessId} = business;
  const {is_free} = membership;
  const [working, setWorking] = useState(false);

  const onPressCancel = useCallback(async () => {
    setWorking(true);

    try {
      if (is_free) {
        await DELETE(`memberships/${businessId}/activate-free`);
      } else {
        await PUT(`memberships/${businessId}/activate-free`);
      }

      setNotification({
        type: 'success',
        msg: 'Membresía gratuita activada',
      });

      sourceLoadData();
    } catch (e) {
      console.warn(e);
      setNotification({
        type: 'error',
        msg: 'Error de activación',
      });
    }

    setWorking(false);
  }, [businessId, is_free, setWorking, sourceLoadData, setNotification]);

  const title = is_free
    ? 'Remover membresía gratuita'
    : 'Habilitar membresía gratuita';

  return (
    <Button
      working={working}
      onPress={onPressCancel}
      className={is_free ? 'btn primary' : 'btn normal'}
      dialog={{
        title,
      }}
      label={title}
    />
  );
};

export default FreeButton;
