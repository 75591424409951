import {Route, Switch, Redirect} from 'react-router-dom';

import NotFound from 'pages/NotFound';

import {STAFF_PATH} from 'types/Business';

import Home from './Home';
import Appointment from './Appointment';
import NewAppointment from './NewAppointment';

const Calendar = () => {
  return (
    <>
      <Switch>
        <Redirect exact from={STAFF_PATH} to={`${STAFF_PATH}/calendar`} />
        <Route path={`${STAFF_PATH}/calendar`}>
          <Home />

          <Switch>
            <Route
              path={`${STAFF_PATH}/calendar/appointment/new`}
              component={NewAppointment}
            />
            <Route
              path={`${STAFF_PATH}/calendar/appointment/:appointment_id`}
              component={Appointment}
            />
          </Switch>
        </Route>
        <Route component={NotFound} />
      </Switch>
    </>
  );
};

export default Calendar;
