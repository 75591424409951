import './OpenAddressButon.scss';

import classNames from 'classnames';

import Locale from 'components/Common/Locale';

import BusinessInfo from 'types/Business';

type Props = {
  label?: string;
  className?: string;
  business: BusinessInfo;
};

const OpenAddressButton = ({business, label, className}: Props) => {
  const {address, map_address} = business;
  let finalAddress = address;

  if (map_address) {
    const {address: mapAddress} = map_address;
    finalAddress = mapAddress;
  }

  return (
    <a
      className={classNames('business-open-addres-link', className)}
      href={`https://maps.google.com/?daddr=${finalAddress}`}
      rel="noreferrer"
      target="_blank">
      <Locale text={label || 'business.open_address_label'} />
    </a>
  );
};

export default OpenAddressButton;
