import './Appointment.scss';

import classNames from 'classnames';

import PageTitle from 'components/Page/PageTitle';
import Locale from 'components/Common/Locale';
import View from 'components/Common/View';
import Text from 'components/Common/Text';

import AppointmentInfo, {AppointmentStatus} from 'types/Appointment';

import Status from './Status';

const {Pending, Confirmed, Cancelled, Completed} = AppointmentStatus;

type Props = {
  appointment: AppointmentInfo;
};

const Header = ({appointment}: Props) => {
  const {status} = appointment;
  let gradientColor = 'dark-gray';

  switch (status) {
    case Pending: {
      gradientColor = 'orange';
      break;
    }
    case Confirmed: {
      gradientColor = 'navy-blue';
      break;
    }
    case Cancelled: {
      gradientColor = 'dark-gray';
      break;
    }
    case Completed: {
      gradientColor = 'blue';
      break;
    }
  }

  return (
    <PageTitle gradient={`gradient-40 ${gradientColor}`}>
      <View className={classNames('appointment-title', status)}>
        <Text>
          <Locale text={`appointments.status_tagline_${status}`} />
        </Text>
        <Status excludeLabel appointment={appointment} />
      </View>
    </PageTitle>
  );
};

export default Header;
