import {getStore} from 'core/hooks';

import {UrlProps} from 'types/Url';
import {UIStore} from 'types/Store';

const REDIRECT_NAME = 'redirect';

export const goToUrl = (props: UrlProps) => {
  const uiStore = getStore<UIStore>('ui');
  uiStore.redirect = props;
};

export const doRedirect = (manual?: boolean): boolean => {
  const redirect = getRedirect();

  if (!redirect || (redirect.manual && !manual)) {
    return false;
  }

  removeRedirect();

  try {
    goToUrl(redirect);
  } catch (e) {
    console.warn(e);
    return false;
  }
  return true;
};

export const saveRedirect = (redirect: UrlProps) => {
  localStorage.setItem(REDIRECT_NAME, JSON.stringify(redirect));
};

export const removeRedirect = () => {
  localStorage.removeItem(REDIRECT_NAME);
};

export const getRedirect = (): UrlProps | null => {
  const redirect = localStorage.getItem(REDIRECT_NAME) as string;

  try {
    if (redirect) {
      return JSON.parse(redirect);
    }
  } catch (e) {
    console.warn(e);
  }

  return null;
};
