import {useCallback} from 'react';
import {generatePath} from 'react-router';

import {useUrlFormatter} from 'hooks';

import {ADMIN_PATH} from 'types/Admin';
import {UrlProps} from 'types/Url';

export const useAdminUrl = () => {
  const urlFormatter = useUrlFormatter();
  const path = generatePath(ADMIN_PATH);

  return useCallback(
    (props?: UrlProps) => {
      const basePath = path;

      if (!props || Object.keys(props).length === 0) {
        return basePath;
      }

      const finalUrl = urlFormatter(props);

      if (finalUrl.indexOf(basePath) !== 0) {
        return `${basePath}${finalUrl}`;
      }

      return finalUrl;
    },
    [urlFormatter, path],
  );
};
