import './Login.scss';

import {useLocation} from 'hooks';

import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';
import Spacer from 'components/Common/Spacer';
import View from 'components/Common/View';
import Br from 'components/Common/Br';
import Link from 'components/Common/Link';
import Text from 'components/Common/Text';
import Locale from 'components/Common/Locale';
import Redirect from 'components/Common/Redirect';
import LoginProviders from 'components/Login/Providers';

const Options = () => {
  const {state} =
    useLocation<{isFromBooking: boolean; isFromMyBusinesses: boolean}>();
  let pageTitle = 'login.title';

  if (state?.isFromBooking) {
    pageTitle = 'login.title_booking';
  }

  if (state?.isFromMyBusinesses) {
    return (
      <Redirect follows="phone" state={{isFromMyBusinesses: true}} replace />
    );
  }

  return (
    <Page title={pageTitle} noAuth padding>
      <Spacer size={30} />

      <PageTitle className="center" />

      <Spacer size={10} />

      <LoginProviders />

      <Spacer size={30} />
      <View className="login-terms">
        <Text>
          <Text>
            <Locale text="login.terms.line1" />{' '}
          </Text>
          <Link
            target="_blank"
            to="static/legal/terms.pdf"
            label="legal.terms_link"
          />
          <Br />
          <Text>
            <Locale text="login.terms.line2" />{' '}
          </Text>
          <Link
            target="_blank"
            to="static/legal/privacy.pdf"
            label="legal.privacy_link"
          />
          {'.'}
        </Text>
      </View>
    </Page>
  );
};

export default Options;
