import {useRef, useState, useEffect, useMemo} from 'react';
import {DateTime} from 'luxon';

import View from 'components/Common/View';
import Text from 'components/Common/View';

import BusinessInfo from 'types/Business';

import {getUTCFromTimezone} from 'utils/DateTime';

import {dateToPosition} from './index';

type TimeColumnProps = {
  date: DateTime;
  business: BusinessInfo;
};

const TimeColumn = ({date, business}: TimeColumnProps) => {
  const timerRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const {timezone} = business;
  const [, setUpdate] = useState(false);
  const nowDate = getUTCFromTimezone(timezone);

  const content = useMemo(() => {
    let start = date.startOf('day');
    const end = date.endOf('day');

    const items = [];

    while (start < end) {
      items.push(
        <View key={start.hour} className="hour">
          <Text className="hour-text">{start.toFormat('h a')}</Text>
        </View>,
      );

      start = start.plus({hours: 1});
    }

    return items;
  }, [date]);

  useEffect(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    timerRef.current = setInterval(() => {
      setUpdate(state => !state);
    }, 5000);

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [date]);

  const isSameDay = nowDate.hasSame(date, 'day');

  return (
    <>
      <View className="time-column">{content}</View>
      {isSameDay && (
        <View
          style={{top: `${dateToPosition(nowDate) - 8}px`}}
          className="now-time"
          key="now">
          <Text className="time">{nowDate.toFormat('h:mm a')}</Text>
        </View>
      )}
    </>
  );
};

export default TimeColumn;
