import './Title.scss';

import {ReactNode, useEffect} from 'react';
import classNames from 'classnames';

import {setHeaderTransparent, setHeaderTheme, setHeaderGradient} from 'hooks';

import View from 'components/Common/View';
import {useTitle} from 'hooks';

type PageTitleProps = {
  className?: string;
  gradient?: string;
  children?: ReactNode | JSX.Element | string;
  empty?: boolean;
};

const PageTitle = ({className, gradient, empty, children}: PageTitleProps) => {
  const {title, setTitle} = useTitle();

  useEffect(() => {
    if (gradient) {
      setHeaderTransparent(true);
      setHeaderTheme('light');
      setHeaderGradient(`${gradient} title-gradient`);
    }

    return () => {
      if (gradient) {
        setHeaderTransparent();
        setHeaderTheme();
        setHeaderGradient();
      }
    };
  }, [gradient]);

  useEffect(() => {
    if (typeof children === 'string') {
      setTitle(children);
    }
  }, [children, setTitle]);

  return (
    <View
      role="h3"
      className={classNames(
        'page-title',
        {gradient: !!gradient, [`${gradient}`]: !!gradient},
        className,
      )}>
      {!empty && (children || title)}
    </View>
  );
};

export default PageTitle;
