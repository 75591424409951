import './Explorer.scss';

import View from 'components/Common/View';
import Text from 'components/Common/Text';
import Locale from 'components/Common/Locale';

import {ExplorerLocation} from 'types/Explorer';
import BusinessInfo from 'types/Business';

type HeaderRenderProps = {
  location: ExplorerLocation;
  items: BusinessInfo[] | null;
};

const ListHeader = ({location, items}: HeaderRenderProps) => {
  if (!location) {
    return null;
  }

  const {display} = location;

  const renderResults = () => {
    if (!items) {
      return (
        <Text>
          <Locale text="explorer.searching_label" />
        </Text>
      );
    }

    if (!items.length) {
      return (
        <Text className="no-results">
          <Locale
            text="explorer.search_no_results"
            params={{location: display}}
          />
        </Text>
      );
    }

    return (
      <Text>
        <Locale
          text="explorer.search_results_label"
          params={{count: items.length, location: display}}
        />
      </Text>
    );
  };

  return <View className="explorer-list-header">{renderResults()}</View>;
};

export default ListHeader;
