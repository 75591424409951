import {CardElement} from '@stripe/react-stripe-js';

const CardForm = () => {
  return (
    <CardElement
      options={{
        style: {
          base: {
            fontSize: '20px',
            color: '#1D2650',
            fontWeight: '500',
            '::placeholder': {
              fontWeight: '300',
              color: '#747B9F',
            },
          },
          invalid: {
            color: '#9e2146',
          },
        },
      }}
    />
  );
};

export default CardForm;
