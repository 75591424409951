import './ReserveButton.scss';

import {useBusinessUrl} from 'hooks';

import View from 'components/Common/View';
import Text from 'components/Common/Text';
import Locale from 'components/Common/Locale';
import Link from 'components/Common/Link';

import BusinessInfo from 'types/Business';

type Props = {
  business: BusinessInfo;
};

const ReserveButton = ({business}: Props) => {
  const businessUrl = useBusinessUrl();
  const {services} = business;

  return (
    <View className="business-reserve-button">
      <View className="container">
        <Text className="services">
          <Locale
            text="business.available_services"
            params={{count: services.length}}
          />
        </Text>
        <Link
          className="btn primary"
          base={businessUrl({to: 'booking'})}
          label="business.book_now_button"
        />
      </View>
    </View>
  );
};

export default ReserveButton;
