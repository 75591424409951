import styles from './Calendar.module.scss';
import 'react-calendar/dist/Calendar.css';

import {useState, useRef, useCallback} from 'react';
import {DateTime} from 'luxon';
import ReactCalendar from 'react-calendar';

import {useTranslation, useForm, useOnClickOutside} from 'hooks';

import View from 'components/Common/View';

import {convertToDateTime} from 'utils/DateTime';

import {DateTimeAny, DATE_FORMAT} from 'types/Date';

import InputRender, {InputRenderProps} from '../Input/Render';
import {useField} from '../Field';
import {useFormData} from '../index';

//import CalendarComponent from './Calendar';

type Props<T> = InputRenderProps<T> & {
  defaultValue?: DateTimeAny;
  minDate?: DateTime;
  maxDate?: DateTime;
  dateFormat?: string;
  dateFormatDisplay?: string;
  defaultView?: 'month' | 'year' | 'decade' | 'century';
  minDetail?: 'month' | 'year' | 'decade' | 'century';
  maxDetail?: 'month' | 'year' | 'decade' | 'century';
};

const Calendar = ({
  defaultView,
  minDetail = 'century',
  maxDetail = 'month',
  minDate: minDateProp,
  maxDate: maxDateProp,
  defaultValue: defaultValueProp,
  dateFormat,
  dateFormatDisplay,
  ...props
}: Props<DateTimeAny>) => {
  const {setValue} = useForm();
  const {t, i18n} = useTranslation();
  const ref = useRef(null);
  const {name: fieldName} = useField<{
    name: string;
  }>();
  const [show, setShow] = useState(false);

  const onPress = useCallback(() => {
    setShow(show => !show);
  }, [setShow]);

  const {[fieldName]: fieldValue} = useFormData<{[name: string]: string}>();
  const [date, setDate] = useState<DateTime | null>(
    defaultValueProp || fieldValue
      ? convertToDateTime(defaultValueProp || fieldValue)
      : null,
  );

  const onClickOutside = useCallback(() => {
    setShow(false);
  }, [setShow]);

  useOnClickOutside(ref, onClickOutside);

  const currentDate = DateTime.now();
  const minDate = minDateProp ? minDateProp : currentDate.minus({days: 1});
  const maxDate = maxDateProp ? maxDateProp : currentDate.plus({years: 20});

  //const defaultValue = date ? date : maxDate || DateTime.now();

  const defaultValue = date
    ? date
    : minDate && maxDate && currentDate >= minDate && currentDate <= maxDate
    ? currentDate
    : maxDate < currentDate
    ? maxDate
    : minDate;

  const params = {
    inputRef: ref,
    className: `date-calendar ${styles['date-calendar']}`,
    locale: i18n.language,
    defaultView,
    minDetail,
    maxDetail,
    minDate: minDate ? new Date(minDate.toJSDate().toDateString()) : minDate,
    maxDate: maxDate ? new Date(maxDate.toJSDate().toDateString()) : maxDate,
    defaultValue: new Date(defaultValue.toJSDate().toDateString()),
    dateFormat,
    dateFormatDisplay,
  };

  //console.log('props1', DateTime?.now()?.toJSDate().toString());
  //console.log('props2', date?.toJSDate()?.toString());
  //console.log('props2', date?.toString());

  const initValue = date
    ? dateFormat
      ? date.toFormat(DATE_FORMAT)
      : date.setZone('utc').toISO()
    : date;

  return (
    <InputRender
      {...props}
      defaultValue={initValue}
      render={props => {
        const {name} = props;

        const onChange = (date: Date) => {
          const newDate = DateTime.fromJSDate(date);
          setShow(false);
          setDate(newDate);

          setValue(
            name,
            dateFormat
              ? newDate.toFormat(DATE_FORMAT)
              : newDate.setZone('utc').toISO(),
          );
        };
        return (
          <>
            <View className={props.className}>
              <View className="date-text" onClick={onPress}>
                {date
                  ? date.toFormat(dateFormatDisplay || DATE_FORMAT)
                  : t('form.select')}
              </View>
              {show && <ReactCalendar {...params} onChange={onChange} />}
              {/*{show && <CalendarComponent {...params} onChange={onChange} />}*/}
            </View>
            <input {...props} type="hidden" />
          </>
        );
      }}
    />
  );
};

export default Calendar;
