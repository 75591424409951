import {Route, Switch} from 'react-router-dom';

import {useParams} from 'hooks';

import Redirect from 'components/Common/Redirect';

import NotFound from 'pages/NotFound';

import {BUSINESS_PATH} from 'types/Business';

const BusinessRedirect = () => {
  return (
    <Switch>
      <Route exact path="/r/:slug" component={RenderRedirect} />

      <Route component={NotFound} />
    </Switch>
  );
};

const RenderRedirect = () => {
  const {slug} = useParams<{slug: string}>();
  return <Redirect to={`${BUSINESS_PATH}/booking`} params={{id: slug}} />;
};

export default BusinessRedirect;
