export enum BusinessType {
  beauty_salon = 'beauty_salon',
  hair_salon = 'hair_salon',
  nail_salon = 'nail_salon',
  eyebrows_lashes = 'eyebrows_lashes',
  massage = 'massage',
  spa = 'spa',
  barbershop = 'barbershop',
  therapy_center = 'therapy_center',
  dental_health = 'dental_health',
}

export type BusinessTypeResponse = {
  name: BusinessType;
  order: number;
};

export interface BusinessTypeInfo {
  type: BusinessType;
  main: boolean;
}

export default BusinessTypeInfo;
