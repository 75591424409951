import './Reviews.scss';

import UserFullName from 'components/User/FullName';
import View from 'components/Common/View';
import Text from 'components/Common/Text';
import DateTime from 'components/Common/DateTime';

import {ReactComponent as Star} from 'components/Review/Assets/Star.svg';
import {ReactComponent as StarGray} from 'components/Review/Assets/StarGray.svg';

import ReviewInfo from 'types/Review';

type Props = {
  review: ReviewInfo;
};

const ReviewItem = ({review}: Props) => {
  const {comment, rating, user, created_at} = review;

  const renderRating = () => {
    const items = [];

    for (let i = 1; i <= 5; i++) {
      items.push(
        rating >= i ? (
          <Star key={i} className="star" />
        ) : (
          <StarGray key={i} className="star" />
        ),
      );
    }

    return <View className="rating">{items}</View>;
  };

  return (
    <View className="review-item">
      <View className="top">
        <Text className="user">
          <UserFullName user={user} />
        </Text>
        <Text className="date">
          <DateTime shortMonth excludeTime date={created_at} />
        </Text>
        {renderRating()}
      </View>
      {comment && <Text className="comment">{comment}</Text>}
    </View>
  );
};

export default ReviewItem;
