import {useCallback} from 'react';
import classNames from 'classnames';

import {useStore} from 'core/hooks';

import View from 'components/Common/View';
import Text from 'components/Common/Text';
import Price from 'components/Common/Price';
import TimeTag from 'components/Common/DateTime/TimeTag';

import ServiceInfo from 'types/Service';

type ServiceItemProps = {
  selected?: boolean;
  onPress?: (service: ServiceInfo) => void;
  service: ServiceInfo;
};

const ServiceItem = ({service}: ServiceItemProps) => {
  const servicesStore = useStore<ServiceInfo[]>('services');
  const {name, price, minutes, notes} = service;
  const selected = servicesStore.some(s => s.id === service.id);

  const onClick = useCallback(() => {
    if (!selected) {
      servicesStore.push(service);
    } else {
      servicesStore.splice(
        servicesStore.findIndex(s => s.id === service.id),
        1,
      );
    }
  }, [selected, service, servicesStore]);

  return (
    <View onClick={onClick} className={classNames('service-item', {selected})}>
      <View className="check" />
      <View className="content">
        <View className="top">
          <Text className="name">{name}</Text>
          <Text className="price">
            <Price useFree amount={price} />
          </Text>
        </View>
        <Text className="time">
          <TimeTag minutes={minutes} />
        </Text>
        {notes && <Text className="notes">{notes}</Text>}
      </View>
    </View>
  );
};

export default ServiceItem;
