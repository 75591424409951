import {useEffect, useCallback} from 'react';

import {getStore} from 'core/hooks';
import {useBusiness, setBookingView, goToUrl} from 'hooks';

import Title from 'components/Page/Title';
import TimeList from 'components/Booking/Time';
import Redirect from 'components/Common/Redirect';
import View from 'components/Common/View';

import {BookingStore} from 'types/Store';

const booking = getStore<BookingStore>('booking');
const {services} = booking;

const Time = () => {
  const business = useBusiness();

  const minutes = services.reduce(
    (minutes, service) => minutes + service.minutes,
    0,
  );

  const setDate = useCallback(date => {
    booking.date = date;
    goToUrl({goBack: true, follows: 'confirm'});
  }, []);

  useEffect(() => {
    if (!minutes) {
      return;
    }

    setBookingView('time');
    return setBookingView;
  }, [minutes]);

  if (!minutes || !services.length) {
    return <Redirect goBack />;
  }

  return (
    <View className="horizontal-padding">
      <Title title="booking.step_time" />
      <TimeList business={business} setDate={setDate} minutes={minutes} />
    </View>
  );
};

export default Time;
