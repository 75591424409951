import './User.scss';

import classNames from 'classnames';

import {formatUserFullNameInitials, UserFullNameType} from 'utils/User';

import View from 'components/Common/View';

type PhotoProps = {
  className?: string;
  user?: UserFullNameType;
  name?: string;
  size?: number | 'small' | 'medium' | 'large';
};

const Photo = ({className, name, user, size = 'small'}: PhotoProps) => {
  const initals = user
    ? formatUserFullNameInitials(user)
    : name
    ? name[0]
    : null;

  let sizes: number[];
  if (typeof size === 'number') {
    sizes = [size, size];
  } else if (size === 'large') {
    sizes = [100, 100];
  } else if (size === 'medium') {
    sizes = [60, 60];
  } else {
    sizes = [30, 30];
  }

  return (
    <View
      style={{width: sizes[0], height: sizes[1]}}
      className={classNames(
        'user-photo',
        {[`size-${size}`]: typeof size === 'string'},
        className,
      )}>
      {initals}
    </View>
  );
};

export default Photo;
