import {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
  createContext,
  ReactNode,
  Dispatch,
  SetStateAction,
  FormEvent,
} from 'react';
import classNames from 'classnames';
import {
  useForm as useFormHook,
  useFormState,
  FormProvider,
  UseFormReturn,
} from 'react-hook-form';

import {useIsMounted, goToUrl} from 'hooks';

import {GET, POST, PUT} from 'utils/Http';

import Error from 'components/Common/Error';
import Loading from 'components/Common/Loading';

import Errors, {ErrorMessage} from './Errors';
import {useConnect} from './Connect';

import {UrlProps} from 'types/Url';
import {useSetNotification} from 'components/App/Notifications';

export type FieldRelation = {
  [name: string]: string[];
};

type SetValues<T> = (values: Partial<T>) => void;
type SetFieldRelation = (fields: FieldRelation) => void;

type FieldMetadata = {
  valueAsObject?: boolean;
};

type SetFieldMetadata = (field: string, metadata: FieldMetadata) => void;

const errorParamRegex = new RegExp(/^body\[(.+)\]:/);

const DEFAULT_PRIMARY_ID = 'id';

export type FormExtraProps<T, A> = {
  data: T;
  props: FormProps<T, A>;
  isCreation: boolean;
  setValue: (name: string, value: any) => void;
  setValues: SetValues<T>;
  setFieldRelation: SetFieldRelation;
  setFieldMetadata: SetFieldMetadata;
  setErrorInfo: Dispatch<SetStateAction<any>>;
  goToUrl: (params: UrlProps) => void;
};

type UserFormReturnProp<T, A> = UseFormReturn & FormExtraProps<T, A>;

type AnyProps = {[name: string]: any};

const FormContext = createContext<FormExtraProps<any, any>>({
  data: {},
  props: {},
  isCreation: true,
  setValue: () => {
    /* do nothing */
  },
  setValues: () => {
    /* do nothing */
  },
  setFieldRelation: () => {
    /* do nothing */
  },
  setFieldMetadata: () => {
    /* do nothing */
  },
  setErrorInfo: () => {
    /* do nothing */
  },
  goToUrl: () => {
    /*do nothing*/
  },
});

export type FormProps<T, A> = {
  className?: string;
  children?: (form: UserFormReturnProp<T, A>) => ReactNode;
  primaryId?: string;
  source?: string;
  sourceParams?: AnyProps;
  data?: T;
  options?: any;

  onSubmit?: (
    form: UserFormReturnProp<T, A> & {values: Partial<T>},
  ) => boolean | void | Promise<boolean | void>;
  onChange?: (name: keyof T, value: any) => void | Promise<void>;
  onSuccess?: (
    form: UserFormReturnProp<T, A>,
    values: Partial<T>,
  ) => void | Promise<void>;
  onRemove?: (
    form: UserFormReturnProp<T, A>,
    result: any,
  ) => void | Promise<void>;
  onRender?: () => void | Promise<void>;

  onSaveValues?: <T>(values: Partial<T>) => Promise<T | void>;

  creation?: boolean;
  sourceId?: string;

  alwaysSave?: boolean;
  showErrors?: boolean;

  headerContent?: ReactNode;
  footerContent?: ReactNode;

  successMsg?: string | boolean;
  removeMsg?: string | boolean;

  method?: 'PUT' | 'POST' | 'PATCH';

  sourceParser?: (data: A) => T | Promise<T>;

  onSubmitHandler?: (event: FormEvent) => void;
};

const Form = <T, A = any>(props: FormProps<T, A>) => {
  const setNotification = useSetNotification();
  const isMounted = useIsMounted();
  const formStatusRef = useRef(false);
  const {
    className = 'default',
    primaryId = DEFAULT_PRIMARY_ID,
    source,
    sourceId: sourceIdProp,
    sourceParams,
    data: initData,
    children,
    options,
    onRender,
    onChange,
    onSuccess,
    onSubmit,
    onSaveValues,
    showErrors,
    alwaysSave,
    headerContent,
    footerContent,
    creation,
    successMsg,
    method,
    sourceParser,
    onSubmitHandler,
  } = props;

  const [sourceId, setSourceId] = useState<string | undefined>(sourceIdProp);
  const isCreation = creation || (!sourceId && 'sourceId' in props);

  const [errorInfo, setErrorInfo] = useState<any | null>(null);
  const [data, setData] = useState<T | null>(
    initData ? {...initData} : !source || isCreation ? ({} as T) : null,
  );
  const form = useFormHook({
    mode: 'onSubmit',
    ...options,
  });
  const {
    watch,
    setValue: setValueProp,
    getValues,
    control,
    handleSubmit,
    setError,
  } = form;
  const {errors} = useFormState({control});
  const {setValues: setValuesConnect} = useConnect();
  const [fieldsRelation, setFieldRelationConnect] = useState<FieldRelation>({});
  const [fieldsMetadata, setFieldMetadataConnect] = useState<{
    [name: string]: FieldMetadata;
  }>({});

  if (onChange || setValuesConnect) {
    watch();
  }

  const setFieldMetadata = useCallback(
    (field: string, metadata: FieldMetadata) => {
      setFieldMetadataConnect(fields =>
        Object.assign(fields, {[field]: metadata}),
      );
    },
    [setFieldMetadataConnect],
  );

  const setFieldRelation = useCallback(
    (newFields: FieldRelation) => {
      setFieldRelationConnect(currentFields =>
        Object.assign(currentFields, newFields),
      );
    },
    [setFieldRelationConnect],
  );

  const setInitialConnectValues = useCallback(() => {
    if (setValuesConnect) {
      setValuesConnect(getValues());
    }
  }, [getValues, setValuesConnect]);

  useEffect(() => {
    if (onRender) {
      onRender();
    }
  }, [onRender]);

  useEffect(() => {
    if (!onChange && !setValuesConnect) {
      return;
    }

    const sub = watch((values: any, {name}) => {
      if (onChange) {
        onChange(name as keyof T, name && values[name]);
      }

      if (setValuesConnect) {
        setValuesConnect(values);
      }
    });

    return () => sub.unsubscribe();
  }, [watch, onChange, setValuesConnect]);

  useEffect(() => {
    if (!source || isCreation) {
      setInitialConnectValues();
      return;
    }

    const loadData = async () => {
      try {
        let data;

        if (sourceParser) {
          data = await sourceParser(
            await GET<A>(`${source}/${sourceId ?? ''}`, sourceParams),
          );
        } else {
          data = await GET<T>(`${source}/${sourceId ?? ''}`, sourceParams);
        }

        if (!isMounted()) {
          return;
        }

        if (Array.isArray(data)) {
          console.warn(
            '[Form] Loading array instead of object fro form, aborting... Use `creation` for creation forms.',
          );
          return;
        }

        setData(data);
        setInitialConnectValues();
      } catch (e) {
        setErrorInfo(e);
      }
    };

    loadData();
  }, [
    source,
    sourceParams,
    sourceParser,
    isCreation,
    sourceId,
    isMounted,
    setInitialConnectValues,
  ]);

  const saveSourceValues = useCallback(
    async (values: Partial<T>): Promise<T> => {
      let result;

      if (onSaveValues) {
        result = await onSaveValues<T>(values);

        if (!result) {
          return Promise.resolve({} as T);
        }
      } else {
        if (!source) {
          return Promise.resolve({} as T);
        }

        const requestUrl = `${source}/${sourceId ?? ''}`;

        if (method === 'PUT' || (data && primaryId in data)) {
          if (sourceParser) {
            result = sourceParser(await PUT<A>(requestUrl, values));
          } else {
            result = await PUT<T>(requestUrl, values);
          }
        } else {
          if (sourceParser) {
            result = sourceParser(await POST<A>(requestUrl, values));
          } else {
            result = await POST<T>(requestUrl, values);
          }

          if (isMounted() && DEFAULT_PRIMARY_ID in result) {
            setSourceId((result as any)[DEFAULT_PRIMARY_ID]);
          }
        }
      }

      return Promise.resolve(result);
    },
    [
      source,
      onSaveValues,
      method,
      data,
      primaryId,
      sourceId,
      setSourceId,
      sourceParser,
      isMounted,
    ],
  );

  const formStrucBase = useMemo(() => {
    return {
      ...form,
      props,
      isCreation,
      setFieldMetadata,
      setFieldRelation,
      setErrorInfo,
      goToUrl,
      data: data as T,
    };
  }, [
    data,
    form,
    props,
    isCreation,
    setFieldMetadata,
    setFieldRelation,
    setErrorInfo,
  ]);

  const sendSuccessMsg = useCallback(() => {
    if (!source) {
      return;
    }

    if (typeof successMsg === 'string' || typeof successMsg === 'undefined') {
      setNotification({
        type: 'success',
        msg:
          typeof successMsg === 'string'
            ? successMsg
            : isCreation
            ? 'form.changes_added'
            : 'form.changes_saved',
      });
    }
  }, [source, successMsg, isCreation, setNotification]);

  const isFieldObject = useCallback(
    (name: string): boolean => {
      const metadata = fieldsMetadata[name];
      if (metadata) {
        const {valueAsObject} = metadata;
        return !!valueAsObject;
      }

      return false;
    },
    [fieldsMetadata],
  );

  const setValue = useCallback(
    (name: string, value: any) => {
      if (isFieldObject(name)) {
        setValueProp(name, JSON.stringify(value));
        return;
      }

      setValueProp(name, value);
    },
    [setValueProp, isFieldObject],
  );

  const setValues = useCallback(
    async (values: Partial<T>) => {
      try {
        formStatusRef.current = true;

        let valuesToUpdate: Partial<T> = {};
        //console.log('_onSubmit', alwaysSave, valuesToUpdate);

        if (alwaysSave || !data || !(primaryId in data)) {
          valuesToUpdate = {...values};
        } else {
          // Check changed inputs
          for (const name in values as T) {
            if (values[name] !== data[name]) {
              // TODO: Improve deep check
              if (
                typeof values[name] === 'string' &&
                data[name] &&
                typeof data[name] === 'object' &&
                (data[name] as any).id === values[name]
              ) {
                continue;

                // Check object
              } else if (
                isFieldObject(name) &&
                JSON.stringify(values[name]) === JSON.stringify(data[name])
              ) {
                continue;
              }

              valuesToUpdate[name] = values[name];
            }
          }

          // Remove undefined values
          for (const i in valuesToUpdate) {
            if (typeof valuesToUpdate[i] === 'undefined') {
              delete valuesToUpdate[i];
            }
          }

          if (!Object.keys(valuesToUpdate).length) {
            sendSuccessMsg();
            if (onSuccess) {
              onSuccess({...formStrucBase, setValues, data}, values);
            }

            formStatusRef.current = false;
            return;
          }

          // Validate relations
          Object.keys(valuesToUpdate).forEach(fieldToUpdate => {
            const relations = fieldsRelation[fieldToUpdate];
            if (!relations) {
              Object.keys(fieldsRelation).forEach(key => {
                if (fieldsRelation[key].indexOf(fieldToUpdate) === -1) {
                  return;
                }

                const relations = fieldsRelation[key];

                if (key in values) {
                  valuesToUpdate[key as keyof T] = values[key as keyof T];
                }

                relations.forEach(relationName => {
                  if (relationName in values) {
                    valuesToUpdate[relationName as keyof T] =
                      values[relationName as keyof T];
                  }
                });
              });
              return;
            }

            relations.forEach(relationName => {
              if (relationName in values) {
                valuesToUpdate[relationName as keyof T] =
                  values[relationName as keyof T];
              }
            });
          });
        }

        const newData = await saveSourceValues(valuesToUpdate);

        if (!isMounted()) {
          formStatusRef.current = false;
          return;
        }

        const mergedData = Object.assign(data || {}, {
          ...valuesToUpdate,
          ...newData,
        });

        setData(mergedData);

        sendSuccessMsg();

        if (onSuccess) {
          await onSuccess(
            {...formStrucBase, setValues, data: mergedData},
            values,
          );
        }

        formStatusRef.current = false;
      } catch (e) {
        formStatusRef.current = false;

        if (!isMounted()) {
          return;
        }

        const {error, errors, field} = e as any;

        if (field && field in values) {
          setError(
            field,
            {
              type: 'remote',
              message: error,
            },
            {
              shouldFocus: true,
            },
          );
          return;
        } else if (errors && errors.length) {
          let found = false;
          errors.forEach((value: string) => {
            if (!errorParamRegex.test(value)) {
              return;
            }

            const fieldName = value.substring(
              value.indexOf('[') + 1,
              value.indexOf(']'),
            );

            if (fieldName && fieldName in values) {
              setError(
                fieldName,
                {
                  type: 'remote',
                  message: value.substr(value.indexOf(' ') + 1),
                },
                {
                  shouldFocus: true,
                },
              );
              found = true;
            }
          });

          if (found) {
            return;
          }
        }

        setErrorInfo(e);
      }
    },
    [
      formStrucBase,
      primaryId,
      alwaysSave,
      fieldsRelation,
      data,
      onSuccess,
      saveSourceValues,
      setError,
      sendSuccessMsg,
      isMounted,
      isFieldObject,
    ],
  );

  const formStruc = useMemo(() => {
    return {
      ...formStrucBase,
      setValues,
      setValue,
    };
  }, [setValues, setValue, formStrucBase]);

  const fieldsContent = useMemo(() => {
    if (errorInfo) {
      return <Error info={errorInfo} />;
    }

    if (!data) {
      return null;
    }

    if (children) {
      return children(formStruc);
    }
  }, [errorInfo, children, formStruc, data]);

  const checkValuesMetadata = useCallback(
    (values: Partial<T>) => {
      for (const name in values) {
        const metadata = fieldsMetadata[name];
        if (metadata) {
          const {valueAsObject} = metadata;

          if (valueAsObject && typeof values[name] === 'string') {
            try {
              values[name] = JSON.parse(values[name] as any);
            } catch (e) {
              values[name] = undefined;
            }
          }
        }
      }
      return values;
    },
    [fieldsMetadata],
  );

  const _onSubmit = useCallback(
    async (values: Partial<T>): Promise<void> => {
      if (formStatusRef.current) {
        return Promise.resolve();
      }

      checkValuesMetadata(values);

      formStatusRef.current = true;

      if (onSubmit && (await onSubmit({...formStruc, values})) === false) {
        formStatusRef.current = false;
        return Promise.resolve();
      }

      formStatusRef.current = false;
      await setValues(values);

      return new Promise(finish => {
        if (!isMounted()) {
          return;
        }

        finish();
      });
    },
    [
      formStatusRef,
      formStruc,
      onSubmit,
      setValues,
      checkValuesMetadata,
      isMounted,
    ],
  );

  const errorsContent = useMemo(() => {
    const errorsList: ErrorMessage[] = [];

    Object.keys(errors).forEach(name =>
      errorsList.push({name, ...errors[name]}),
    );

    if (!errorsList.length) {
      return null;
    }

    return showErrors && <Errors errors={errorsList} />;
  }, [errors, showErrors]);

  const renderedForm = useMemo(() => {
    return (
      <FormProvider {...form}>
        <FormContext.Provider value={formStruc}>
          <form
            className={classNames('form', className)}
            onSubmit={
              !onSubmitHandler
                ? handleSubmit(_onSubmit as any)
                : onSubmitHandler
            }>
            {headerContent}
            {errorsContent}
            {fieldsContent}
            {!data && !errorInfo && <Loading />}
            {footerContent}
          </form>
        </FormContext.Provider>
      </FormProvider>
    );
  }, [
    className,
    form,
    formStruc,
    errorInfo,
    errorsContent,
    fieldsContent,
    headerContent,
    footerContent,
    data,
    handleSubmit,
    _onSubmit,
    onSubmitHandler,
  ]);

  return <>{renderedForm}</>;
};

export const useForm = <T, A = any>(): UserFormReturnProp<T, A> => {
  return useContext(FormContext) as UserFormReturnProp<T, A>;
};

export const useFormData = <T,>(): T => {
  return useContext(FormContext).data as T;
};

export const useFormProps = <T, A = any>(): FormProps<T, A> => {
  return useContext(FormContext).props as FormProps<T, A>;
};

export const useIsFormCreation = (): boolean => {
  return useContext(FormContext).isCreation;
};

export const useSetFieldMetadata = () => {
  return useContext(FormContext).setFieldMetadata;
};

export const useSetFieldRelation = () => {
  return useContext(FormContext).setFieldRelation;
};

export default Form;
