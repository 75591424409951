import './TopBar.scss';

import {useEffect, useCallback} from 'react';

import {useStore} from 'core/hooks';
import {
  goToUrl,
  useStaffUrl,
  useBusinessUrl,
  setHeaderLeft,
  setHeaderRight,
} from 'hooks';

import {BookingPages} from 'types/Booking';

import PageTitle from 'components/Page/PageTitle';
import HeaderBackButton from 'components/App/Header/BackButton';

import Locale from 'components/Common/Locale';
import View from 'components/Common/View';
import Text from 'components/Common/Text';

const bookingSteps = ['services', 'time', 'confirm'];

const TopBar = () => {
  const staffUrl = useStaffUrl();
  const businessUrl = useBusinessUrl();
  const {userName, isFromStaff, view} = useStore<{
    view: BookingPages;
    isFromStaff: boolean;
    userName: string;
  }>('view', 'userName', 'isFromStaff');

  const getBackView = useCallback(() => {
    if (view === 'confirm') {
      return 'time';
    }

    if (view === 'time') {
      return 'services';
    }

    return 'services';
  }, [view]);

  const onPressBack = useCallback(() => {
    const backView = getBackView();
    const backUrl = {
      base:
        view === 'services'
          ? isFromStaff
            ? staffUrl({to: 'appointments'})
            : businessUrl()
          : businessUrl({
              to: `booking${backView !== 'services' ? `/${backView}` : ''}`,
            }),
    };

    goToUrl(backUrl);
  }, [getBackView, businessUrl, staffUrl, isFromStaff, view]);

  useEffect(() => {
    setHeaderLeft(<HeaderBackButton onPress={onPressBack} />);
    setHeaderRight(() => null);

    return () => {
      setHeaderLeft();
      setHeaderRight();
    };
  }, [onPressBack]);

  if (!view) {
    return null;
  }

  const titleTranslation = `booking.step_${view}`;
  const titleContent = <Locale text={titleTranslation} />;
  const currentBookingStep = bookingSteps.indexOf(view || 'services') + 1;
  const totalBookingSteps = bookingSteps.length;

  return (
    <PageTitle gradient="gradient-40 dark-gray horizontal-padding booking-top-bar">
      <View className="title-content">
        <Text className="step">
          <Text bold>{currentBookingStep}</Text>{' '}
          <Locale text="booking.steps_headline" />{' '}
          <Text bold>{totalBookingSteps}</Text>{' '}
          {userName && <Text>– Cliente: {userName}</Text>}
        </Text>
        <Text className="title">{titleContent}</Text>
      </View>
    </PageTitle>
  );
};

export default TopBar;
