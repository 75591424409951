import {HTMLProps} from 'react';
import classNames from 'classnames';

import {useTranslation} from 'hooks';

import {useField} from '../Field';

type AnyProps = {[name: string]: any};

type Props = HTMLProps<HTMLLabelElement> & {labelParams?: AnyProps} & {
  label?: string;
  labelParams?: AnyProps;
};

const Label = ({
  className,
  htmlFor,
  label: labelProp,
  labelParams: labelParamsProp,
  children,
  ...props
}: Props) => {
  const {t} = useTranslation();
  const {
    name,
    label: fieldLabel,
    labelParams: fieldLabelParams,
  } = useField<{
    name: string;
    label: string;
    labelParams: AnyProps;
  }>();
  const forName = htmlFor || name;
  const labelValue =
    labelProp || fieldLabel
      ? t(labelProp || fieldLabel, labelParamsProp || fieldLabelParams)
      : labelProp;

  const params = {
    className: classNames(className, 'label', {[`label-${forName}`]: forName}),
    ...props,
  };

  return (
    <label htmlFor={forName} {...params}>
      {children || labelValue}
    </label>
  );
};

export default Label;
