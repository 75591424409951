import {useBusinessUrl} from 'hooks';

import AppointmentInfo from 'types/Appointment';

import Link from 'components/Common/Link';

type Props = {
  appointment: AppointmentInfo;
};

const NewButton = ({appointment: {business}}: Props) => {
  const businessUrl = useBusinessUrl();

  return (
    <>
      <Link
        className="btn normal"
        base={businessUrl({to: 'booking'}, business)}
        label="appointments.new_button"
      />
    </>
  );
};

export default NewButton;
