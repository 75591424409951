import './List.scss';

import {useStaffUrl, useBusinessUrl} from 'hooks';

import View from 'components/Common/View';
import Link from 'components/Common/Link';
import Text from 'components/Common/Text';
import Image from 'components/Common/Image';

import BusinessInfo, {BUSINESS_PHOTO_LIST_SIZE} from 'types/Business';

import RatingInfo from 'components/Business/RatingInfo';

type Props = {
  business: BusinessInfo;
  useStaffUrl?: boolean;
};

const BusinessItem = ({business, useStaffUrl: useStaffUrlProp}: Props) => {
  const staffUrl = useStaffUrl(business);
  const businessUrl = useBusinessUrl(business);
  const {name, address, photo} = business;

  return (
    <Link
      className="business-item"
      base={
        useStaffUrlProp ? staffUrl({}, business) : businessUrl({}, business)
      }>
      <View className="image">
        {photo && (
          <Image
            className="source"
            src={photo.url}
            params={BUSINESS_PHOTO_LIST_SIZE}
          />
        )}
      </View>
      <View className="info">
        <View className="top">
          <Text className="name">{name}</Text>
          <RatingInfo business={business} />
        </View>
        {address && <Text className="address">{address}</Text>}
      </View>
    </Link>
  );
};

export default BusinessItem;
