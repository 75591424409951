import './Menu.scss';

import classNames from 'classnames';

import {useBusiness, useHasRole, useStaffUrl} from 'hooks';

import View from 'components/Common/View';
import Text from 'components/Common/Text';
import Locale from 'components/Common/Locale';
import Link from 'components/Common/Link';
import Image from 'components/Common/Image';
import Spacer from 'components/Common/Spacer';
import BusinessStatus from 'components/Business/Status';
import MembershipTypeComponent from 'components/Membership/Type';

import Button from './Button';

import {BUSINESS_PHOTO_LIST_SIZE} from 'types/Business';
import {StaffRole} from 'types/Staff';

const {Owner, Manager} = StaffRole;

const Business = () => {
  const isOwner = useHasRole([Owner]);
  const isManager = useHasRole([Manager]);

  const staffUrl = useStaffUrl();
  const business = useBusiness();
  const {name, photo, services, membership_type} = business;

  return (
    <>
      <Spacer size={30} />
      <Link base={staffUrl()}>
        <View className="image">
          {photo && (
            <Image
              className="source"
              src={photo.url}
              params={BUSINESS_PHOTO_LIST_SIZE}
            />
          )}
        </View>
      </Link>

      <Spacer size={15} />

      <Text className="name">{name}</Text>

      <Spacer size={20} />

      <View className="statuses">
        <Link className="status-link" base={staffUrl({to: 'settings/general'})}>
          <BusinessStatus business={business} />
        </Link>
        <Link
          className="membership-link"
          base={staffUrl({to: 'settings/membership'})}>
          <MembershipTypeComponent type={membership_type} />
        </Link>
      </View>

      <Spacer size={35} />

      <Text className="header-label">
        <Locale text="staff.menu.business_label" />
      </Text>

      <Button
        className="calendar no-border"
        to="calendar"
        label="staff.menu.calendar"
      />

      <Button
        className="appointments"
        to="appointments"
        label="staff.menu.appointments"
      />

      <Button className="clients" to="clients" label="staff.menu.clients" />

      {(isOwner || isManager) && (
        <Button
          className={classNames('services', {marked: !services.length})}
          to="services"
          label="staff.menu.services"
        />
      )}

      {isOwner && (
        <Button className="staff" to="staff" label="staff.menu.staff" />
      )}

      <Button className="share" to="share" label="staff.menu.share" />

      {isOwner && (
        <>
          <Spacer size={30} />

          <Text className="header-label">
            <Locale text="staff.menu.settings_label" />
          </Text>
          <Button
            className="settings no-border"
            to="settings"
            label="staff.menu.settings"
          />
        </>
      )}

      <Spacer size={20} />
    </>
  );
};

export default Business;
