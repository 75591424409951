import AppointmentInfo, {AppointmentStatus} from 'types/Appointment';

import Link from 'components/Common/Link';

type Props = {
  appointment: AppointmentInfo;
};

const ReviewButton = ({appointment}: Props) => {
  const {review, status} = appointment;

  return (
    <>
      {status === AppointmentStatus.Completed && (
        <Link
          className="btn primary"
          follows="review"
          label={`appointments.${
            review ? 'change_review_button' : 'review_button'
          }`}
        />
      )}
    </>
  );
};

export default ReviewButton;
