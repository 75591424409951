import {BusinessType} from 'types/BusinessType';

import hair_salon from './Assets/hair_salon.png';
import beauty_salon from './Assets/beauty_salon.png';
import nail_salon from './Assets/nail_salon.png';
import eyebrows_lashes from './Assets/eyebrows_lashes.png';
import massage from './Assets/massage.png';
import spa from './Assets/spa.png';
import barbershop from './Assets/barbershop.png';
import therapy_center from './Assets/therapy_center.png';
import dental_health from './Assets/dental_health.png';

export type BusinessTypeInfoInterface = {
  [key in BusinessType]: string;
};

const BusinessTypeImages: BusinessTypeInfoInterface = {
  hair_salon,
  beauty_salon,
  nail_salon,
  eyebrows_lashes,
  massage,
  spa,
  barbershop,
  therapy_center,
  dental_health,
};

export default BusinessTypeImages;
