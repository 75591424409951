import './Menu.scss';

import Link, {LinkProps} from 'components/Common/Link';
import Locale from 'components/Common/Locale';
import Text from 'components/Common/Text';

type Props = LinkProps & {
  label?: string;
  description?: string;
};

const LinkButton = ({label, description, ...props}: Props) => {
  return (
    <Link className="menu-button" {...props}>
      {label && (
        <Text className="label">
          <Locale text={label} />
        </Text>
      )}
      {description && (
        <Text className="description">
          <Locale text={description} />
        </Text>
      )}
    </Link>
  );
};

export default LinkButton;
