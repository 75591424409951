import {HTMLProps} from 'react';

import InputRender, {InputRenderProps} from '../Input/Render';

type TextAreaProps<T> = HTMLProps<HTMLTextAreaElement> & InputRenderProps<T>;

const TextArea = <T,>(props: TextAreaProps<T>) => {
  return (
    <InputRender<T> {...props} render={props => <textarea {...props} />} />
  );
};

export default TextArea;
