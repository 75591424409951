import './Header.scss';

import {useEffect, useState, useCallback} from 'react';
import {DateTime} from 'luxon';

import BusinessInfo from 'types/Business';

import {getUTCFromTimezone} from 'utils/DateTime';

import DatesList from '../DatesList';

type HeaderProps = {
  onDateChange: (date: DateTime) => void;
  business: BusinessInfo;
};

const Header = ({business, onDateChange: onDateChangeProp}: HeaderProps) => {
  const {timezone} = business;
  const initSelected = getUTCFromTimezone(timezone).startOf('day');

  const [date, setDate] = useState<DateTime>(initSelected);

  const fromDate = initSelected.minus({days: 7});
  const toDate = initSelected.plus({days: 60});

  const onDateChange = useCallback(
    (selectedDate: DateTime) => {
      setDate(selectedDate);
    },
    [setDate],
  );

  useEffect(() => {
    onDateChangeProp(date);
  }, [date, onDateChangeProp]);

  return (
    <DatesList
      onDateChange={onDateChange}
      date={date}
      fromDate={fromDate}
      toDate={toDate}
      timezone={timezone}
    />
  );
};

export default Header;
