import './Status.scss';

import classNames from 'classnames';

import View from 'components/Common/View';
import Text from 'components/Common/Text';
import Locale from 'components/Common/Locale';

import BusinessInfo from 'types/Business';

type Props = {
  business: BusinessInfo;
};

const Status = ({business: {status}}: Props) => {
  return (
    <View className={classNames('business-status', status)}>
      <Text className="status">
        <Locale text={`business.status_${status}`} />
      </Text>
    </View>
  );
};

export default Status;
