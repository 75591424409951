import './Button.scss';

import {
  useRef,
  useState,
  forwardRef,
  useCallback,
  ReactNode,
  MouseEvent,
} from 'react';
import classNames from 'classnames';

import Locale, {
  LocaleTextProp,
  LocaleParamsProp,
} from 'components/Common/Locale';
import Text from 'components/Common/Text';
import Icon, {IconType} from 'components/Common/Icon';
import Loading from 'components/Common/Loading';
import Dialog, {DialogProps} from 'components/Common/Dialog';

export type ClickCallback =
  | ((event: MouseEvent) => void)
  | ((event: MouseEvent) => Promise<void>);

export type ButtonBaseProps = {
  id?: string;
  children?: ReactNode;
  className?: string;
  iconPosition?: 'before' | 'after';
  icon?: IconType;
  label?: LocaleTextProp;
  labelParams?: LocaleParamsProp;
  disabled?: boolean;
  working?: boolean;
};

export type ButtonProps = ButtonBaseProps & {
  type?: 'submit' | 'button' | 'reset';
  onPress?: ClickCallback;
  onDialogOpen?: ClickCallback;
  dialog?: DialogProps;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      id,
      type = 'button',
      children,
      className,
      onPress,
      onDialogOpen,
      label,
      labelParams,
      disabled,

      icon,
      iconPosition = 'before',

      working,

      dialog,

      ...props
    },
    ref,
  ) => {
    const [visible, setVisible] = useState(false);
    const mouseEvent = useRef<MouseEvent | null>(null);
    const isDialog = !!dialog;

    const onPressDialog = useCallback(
      async e => {
        e.preventDefault();

        mouseEvent.current = e;
        setVisible(true);

        if (onDialogOpen) {
          onDialogOpen(e);
        }
      },
      [onDialogOpen, setVisible, mouseEvent],
    );

    const onConfirm = useCallback(() => {
      if (mouseEvent.current && onPress) {
        onPress(mouseEvent.current);
      }
    }, [onPress, mouseEvent]);

    const renderContent = () => {
      return (
        <>
          {iconPosition === 'before' && <Icon icon={icon} />}
          {children ||
            (label ? (
              <Text>
                <Locale text={label} params={labelParams} />
              </Text>
            ) : (
              label
            ))}
          {iconPosition === 'after' && <Icon icon={icon} />}
        </>
      );
    };

    return (
      <>
        {visible && (
          <Dialog onConfirm={onConfirm} setClosed={setVisible} {...dialog} />
        )}
        <button
          ref={ref}
          id={id}
          className={classNames(className, disabled, {working})}
          onClick={isDialog ? onPressDialog : onPress}
          type={type}
          disabled={disabled}
          {...props}>
          {renderContent()}
          {working && <Loading size="small" />}
        </button>
      </>
    );
  },
);

export default Button;
