import './Appointment.scss';

import ServicesInfo from 'types/Service';
import ServiceSnapshotInfo from 'types/ServiceSnapshot';

import View from 'components/Common/View';
import Text from 'components/Common/Text';
import Locale from 'components/Common/Locale';

import Price from 'components/Common/Price';
import TimeTag from 'components/Common/DateTime/TimeTag';

import {ReactComponent as Check} from './Assets/Check.svg';

type ServiceTypes = ServicesInfo | ServiceSnapshotInfo;

type ServicesListProps = {
  services: ServiceTypes[];
  totalPrice?: number;
  excludeTotal?: boolean;
};

type ServiceItemProps = {
  service: ServiceTypes;
};

const ServiceItem = ({service}: ServiceItemProps) => {
  const {name, price, minutes} = service;

  return (
    <View className="service-item">
      <Check className="check" />
      <View className="content">
        <View className="top">
          <Text className="name">{name}</Text>
          <Text className="price">
            <Price useFree amount={price} />
          </Text>
        </View>
        <Text className="time">
          <TimeTag minutes={minutes} />
        </Text>
        {/*{notes && <Text className="notes">{notes}</Text>}*/}
      </View>
    </View>
  );
};

const ServicesList = ({
  services,
  totalPrice,
  excludeTotal,
}: ServicesListProps) => {
  const renderTotal = () => {
    const totalAmount =
      typeof totalPrice !== 'undefined'
        ? totalPrice
        : services.reduce((price, service) => price + service.price, 0);

    return (
      <View className="total">
        <Text className="label">
          <Locale text="appointments.total_label" />
        </Text>
        <Text className="amount">
          <Price useFree amount={totalAmount} />
        </Text>
      </View>
    );
  };

  return (
    <View className="appointment-services-list">
      {services.map(service => (
        <ServiceItem key={service.id} service={service} />
      ))}
      {!excludeTotal && renderTotal()}
    </View>
  );
};

export default ServicesList;
