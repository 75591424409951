import './HoursSelector.scss';

import {useCallback} from 'react';
import classNames from 'classnames';

import View from 'components/Common/View';
import Locale from 'components/Common/Locale';
import Text from 'components/Common/Text';
import DayComponent from 'components/Common/DateTime/Day';

import BusinessHoursInfo from 'types/BusinessHours';

import {Days} from 'types/Date';

import Hour from './Hour';
import Checkbox from '../Checkbox';

export const MINUTES_IN_DAY = 1440 - 5;

type DayProps = {
  day: Days;
  hours: BusinessHoursInfo[];
  setHour: (hour: BusinessHoursInfo) => void;
  removeHour: (hour: BusinessHoursInfo) => void;
};

const Day = ({day, hours, setHour, removeHour}: DayProps) => {
  const active = !!hours.length;

  const onPress = useCallback(() => {
    if (active) {
      hours.forEach(removeHour);
    } else {
      setHour({
        id: 'new_' + Date.now(),
        day,
        start_minute: 480,
        end_minute: 1080,
      });
    }
  }, [day, active, hours, removeHour, setHour]);

  const getAction = (index: number) => {
    if (hours.length === 1) {
      return 'add';
    }

    return 'remove';
  };

  return (
    <View className={classNames('day', {active})}>
      <View className="left">
        <Checkbox className="checkbox" onPress={onPress} active={active} />
        <Text className="name">
          <DayComponent day={day} />
        </Text>
      </View>
      <View className="hours">
        {active ? (
          hours.map((hour, idx) => (
            <Hour
              day={day}
              setHour={setHour}
              removeHour={removeHour}
              key={hour.id}
              hour={hour}
              prevHour={hours[idx - 1]}
              nextHour={hours[idx + 1]}
              action={getAction(idx)}
            />
          ))
        ) : (
          <Text className="disabled">
            <Locale text="staff.settings.hours.disabled" />
          </Text>
        )}
      </View>
    </View>
  );
};

export default Day;
