import {Route, Switch} from 'react-router-dom';

import {useParams, useLocation} from 'hooks';

import Auth from 'components/Auth';
import Source from 'components/Common/Source';

import NotFound from 'pages/NotFound';

import View from './View';
import Time from './Time';
import Review from './Review';

const Appointment = () => {
  const {id} = useParams<{id: string}>();
  const {pathname} = useLocation();

  return (
    <Auth>
      <Source
        source={`appointments/${id}`}
        sourceParams={{
          claim: pathname.endsWith('/claim'),
        }}>
        {() => (
          <Switch>
            <Route exact path="/appointments/:id" component={View} />
            <Route exact path="/appointments/:id/time" component={Time} />
            <Route exact path="/appointments/:id/review" component={Review} />

            <Route exact path="/appointments/:id/claim" component={View} />
            <Route exact path="/appointments/:id/claim/time" component={Time} />
            <Route
              exact
              path="/appointments/:id/claim/review"
              component={Review}
            />

            <Route component={NotFound} />
          </Switch>
        )}
      </Source>
    </Auth>
  );
};

export default Appointment;
