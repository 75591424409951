import './Footer.scss';

import classNames from 'classnames';

import Text from 'components/Common/Text';
import Locale from 'components/Common/Locale';

import {ReactComponent as HeartDark} from './Assets/HeartDark.svg';
import {ReactComponent as HeartLight} from './Assets/HeartLight.svg';

type Props = {
  theme?: 'light' | 'dark';
};

const WithLove = ({theme = 'dark'}: Props) => {
  return (
    <Text className={classNames('footer-love', theme)}>
      <Text>
        <Locale text="footer.made" />
      </Text>
      {theme === 'dark' ? (
        <HeartDark className="heart" />
      ) : (
        <HeartLight className="heart" />
      )}
      <Text>
        <Locale text="footer.by" />
      </Text>
    </Text>
  );
};

export default WithLove;
