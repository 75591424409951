import './Login.scss';

import View from 'components/Common/View';
import Text from 'components/Common/Text';
import Locale from 'components/Common/Locale';

import GoogleSignInButton from './GoogleSignInButton';
import AppleSignInButton from './AppleSignInButton';
import PhoneSignInButton from './PhoneSignInButton';

const Providers = () => {
  return (
    <View className="login-providers">
      <Text className="login-with">
        <Locale text="login.continue_with" />
      </Text>
      <View className="options">
        <GoogleSignInButton />
        <AppleSignInButton />
        <PhoneSignInButton />
      </View>
    </View>
  );
};

export default Providers;
