import {useBusiness, useParams, useLocation} from 'hooks';

import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';
import Actions from 'components/Form/Actions';
import Form from 'components/Form';
import Field from 'components/Form/Field';
import Label from 'components/Form/Label';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import TextArea from 'components/Form/TextArea';
import Submit from 'components/Form/Submit';
import Remove from 'components/Form/Remove';

import CategoryInfo from 'types/Category';
import ServiceInfo from 'types/Service';

const ServiceForm = () => {
  const {id: businessId} = useBusiness();
  const {state} = useLocation<{name: string}>();
  const {category_id, service_id} = useParams<{
    category_id: string;
    service_id: string;
  }>();

  return (
    <Page
      title={
        !service_id
          ? category_id
            ? [`staff.services.add_service_title_category`, {name: state?.name}]
            : 'staff.services.add_service_title'
          : `staff.services.edit_service`
      }
      padding>
      <PageTitle className="staff-title center" />

      <Form<ServiceInfo>
        onSuccess={({goToUrl}) => goToUrl({goBack: 2})}
        onRemove={({goToUrl}) => goToUrl({goBack: 2})}
        sourceId={service_id}
        source={`services/${businessId}`}>
        {() => {
          return (
            <>
              {category_id ? (
                <Input
                  name="category_id"
                  type="hidden"
                  defaultValue={category_id}
                />
              ) : (
                <Field name="category_id" label="staff.services.field_category">
                  <Label />
                  <Select
                    source={`categories/${businessId}`}
                    sourceParser={(options: CategoryInfo[]) => {
                      return [
                        {name: 'form.select', value: 'select'},
                        ...options.map(category => {
                          return {
                            name: category.name,
                            value: category.id,
                          };
                        }),
                      ];
                    }}
                  />
                </Field>
              )}

              <Field name="name" label="staff.services.field_service_name">
                <Label />
                <Input minLength={4} maxLength={128} />
              </Field>

              <Field name="notes" label="staff.services.field_service_notes">
                <Label />
                <TextArea optional minLength={5} maxLength={1024} />
              </Field>

              <Field name="price" label="staff.services.field_service_price">
                <Label />
                <Input type="number" min={0} max={500000} />
              </Field>

              <Field
                name="minutes"
                label="staff.services.field_service_minutes">
                <Label />
                <Input type="number" min={4} max={700} />
              </Field>

              <Actions>
                <Submit />
                <Remove />
              </Actions>
            </>
          );
        }}
      </Form>
    </Page>
  );
};

export default ServiceForm;
