import './Time.scss';

import {useEffect, useState, useMemo, useRef} from 'react';
import {DateTime} from 'luxon';

import {GET} from 'utils/Http';

import {convertToDateTime, getUTCFromTimezone} from 'utils/DateTime';

import BusinessInfo from 'types/Business';
import {
  DateTimeRange,
  DateTimeAny,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
} from 'types/Date';

import View from 'components/Common/View';
import Loading from 'components/Common/Loading';

import DatesList from './DatesList';
import HoursList from './HoursList';

const MAX_DAYS = 93;
const LOAD_DAYS = 15;
const LOAD_DAYS_HALF = Math.floor(LOAD_DAYS);

type TimeProps = {
  business: BusinessInfo;
  selectedDate?: DateTimeAny;
  fromDate?: DateTimeAny;
  minutes?: number;
  appointmentId?: string;
  setDate?: (date: DateTime) => void;
};

type TimesResponse = [string, string];

const createInitMap = (
  from: DateTime,
  to: DateTime,
): Map<string, DateTimeRange[] | null> => {
  const slots = new Map<string, DateTimeRange[] | null>();

  do {
    slots.set(from.toFormat(DATE_FORMAT), null);
    from = from.plus({days: 1});
  } while (from < to);

  return slots;
};

const Time = ({
  business,
  minutes,
  appointmentId,
  selectedDate: selectedDateProp,
  fromDate: fromDateProp,
  setDate: setDateHook,
}: TimeProps) => {
  const {id: businessId, timezone} = business;
  const fromDate = useMemo(() => {
    const time = fromDateProp
      ? convertToDateTime(fromDateProp)
      : getUTCFromTimezone(timezone)
          /* TODO: Implement business custom time before to reserve */
          .plus({hours: 1});

    return time.plus({minutes: 30 - (time.get('minute') % 30)});
  }, [fromDateProp, timezone]);
  const toDate = useMemo(() => fromDate.plus({days: MAX_DAYS}), [fromDate]);

  const selectedDate = selectedDateProp
    ? convertToDateTime(selectedDateProp).startOf('day')
    : fromDate;

  const [date, setDate] = useState<DateTime>(
    selectedDate >= fromDate ? selectedDate : fromDate,
  );

  const [time, setTime] = useState<DateTime | null>(null);
  const firstLoadRef = useRef(false);

  const initMap = useMemo(
    () => createInitMap(fromDate, toDate),
    [fromDate, toDate],
  );
  const [slots, setSlots] =
    useState<Map<string, DateTimeRange[] | null>>(initMap);
  const isDateLoaded = slots.get(date.toFormat(DATE_FORMAT));

  // Restore scroll
  useEffect(() => {
    if (!setDateHook) {
      window.scrollTo(0, 0);
    }
  }, [setDateHook]);

  useEffect(() => {
    if (setDateHook && time) {
      setDateHook(time);
    }
  }, [time, setDateHook]);

  useEffect(() => {
    const loadSlots = async () => {
      if (!minutes || isDateLoaded) {
        return;
      }

      const firstLoad = firstLoadRef.current;

      const days = firstLoad ? LOAD_DAYS_HALF : LOAD_DAYS;

      let startDate = !firstLoad
        ? date
        : date.minus({days}) < fromDate
        ? date
        : date.minus({days});

      const endDate = !firstLoad
        ? date.plus({days})
        : date.plus({days}) > toDate
        ? date
        : date.plus({days});

      const params = {
        from_date: startDate.toFormat(DATE_TIME_FORMAT),
        to_date: (endDate > toDate ? toDate : endDate).toFormat(
          DATE_TIME_FORMAT,
        ),
        minutes,
        appointment_id: appointmentId,
      };

      //console.log('QueryParams', params);
      const data = await GET<TimesResponse[]>(`dates/${businessId}`, params);

      setSlots(slots => {
        const oldSlots = new Map(slots);

        data.forEach(date => {
          const fromDate = DateTime.fromFormat(date[0], DATE_TIME_FORMAT, {
            zone: 'utc',
          });
          const toDate = DateTime.fromFormat(date[1], DATE_TIME_FORMAT, {
            zone: 'utc',
          });
          const dateId = date[0].substr(0, date[0].indexOf(' '));

          if (!firstLoad || !oldSlots.get(dateId)) {
            const newDate = {
              from: fromDate,
              to: toDate,
            };

            if (!slots.get(dateId)) {
              slots.set(dateId, [newDate]);
            } else {
              slots.get(dateId)?.push(newDate);
            }
          }
        });

        do {
          const dateId = startDate.toFormat(DATE_FORMAT);
          if (!slots.get(dateId)) {
            slots.set(dateId, []);
          }

          startDate = startDate.plus({days: 1});
        } while (startDate < endDate);

        if (!firstLoad) {
          firstLoadRef.current = true;
        }

        return new Map(slots);
      });
    };

    loadSlots();
  }, [
    businessId,
    appointmentId,
    fromDate,
    minutes,
    toDate,
    date,
    isDateLoaded,
  ]);

  const datesList = useMemo(() => {
    if (!slots) {
      return null;
    }

    return (
      <DatesList
        setDate={setDate}
        fromDate={fromDate}
        toDate={toDate}
        date={date}
        slots={slots}
      />
    );
  }, [fromDate, toDate, date, setDate, slots]);

  const hoursList = useMemo(() => {
    if (!slots) {
      return null;
    }

    return (
      <HoursList
        business={business}
        slots={slots.get(date.toFormat(DATE_FORMAT)) || null}
        setTime={setTime}
      />
    );
  }, [business, slots, date]);

  return (
    <View className="booking-times-list expand column">
      {!slots && (
        <View className="padding">
          <Loading size="medium" />
        </View>
      )}
      {datesList}
      {hoursList}
    </View>
  );
};

export default Time;
