import View from 'components/Common/View';
import Button from 'components/Common/Button';

import MembershipInfo from 'types/Membership';

type CardOnFileProps = {
  onPressChangeCard?: () => void;
  useCheckout?: boolean;
  membership: MembershipInfo;
};

const CardOnFile = ({
  useCheckout,
  onPressChangeCard,
  membership,
}: CardOnFileProps) => {
  const {card} = membership;

  if (!card) {
    return null;
  }

  const {brand, last4} = card;

  return (
    <View className="card-on-file">
      <View className="info">
        {brand} {last4}
      </View>

      <Button
        className="change-button"
        onPress={onPressChangeCard}
        label="membership.change_card"
      />
    </View>
  );
};

export default CardOnFile;
