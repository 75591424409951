import './Booking.scss';

import {useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';

import {setFooterStatus, useBusiness, useIsMobile, useLocation} from 'hooks';

import {BUSINESS_PATH} from 'types/Business';

import Page from 'components/Page';
import View from 'components/Common/View';
import TopBar from 'components/Booking/TopBar';
import BottomBar from 'components/Booking/BottomBar';
import CheckoutDetails from 'components/Booking/CheckoutDetails';

import NotFound from 'pages/NotFound';

import Services from './Services';
import Time from './Time';
import Confirm from './Confirm';

const Booking = () => {
  const {pathname} = useLocation();
  const isMobile = useIsMobile();
  const business = useBusiness();

  useEffect(() => {
    setFooterStatus(true);
    return setFooterStatus;
  }, []);

  return (
    <Page className="booking-page" noAuth>
      <View className="column vertical-padding">
        <View className="base-size">
          <TopBar />
        </View>
        <View className="booking-page-container base-size">
          <View className="left-content">
            <Switch>
              <Route
                exact
                path={`${BUSINESS_PATH}/booking`}
                component={Services}
              />
              <Route
                exact
                path={`${BUSINESS_PATH}/booking/time`}
                component={Time}
              />
              <Route
                exact
                path={`${BUSINESS_PATH}/booking/confirm`}
                component={Confirm}
              />
              <Route component={NotFound} />
            </Switch>
          </View>
          {!isMobile && pathname.indexOf('/booking/confirm') === -1 && (
            <CheckoutDetails business={business} />
          )}
        </View>
      </View>
      <BottomBar />
    </Page>
  );
};

export default Booking;
