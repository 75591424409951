import './Admin.scss';

import {useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';

import {isGroup} from 'core/session';
import {useStore} from 'core/hooks';

import {
  useIsMobile,
  setHeaderTheme,
  setHeaderClassName,
  setFooterStatus,
} from 'hooks';

import View from 'components/Common/View';
import Redirect from 'components/Common/Redirect';
import AdminMenu from 'components/Admin/Menu';
import AdminTabbar from 'components/Admin/Tabbar';

import NotFound from 'pages/NotFound';

import Home from './Home';
import Users from './Users';

import Businesses from './Businesses';

import {ADMIN_PATH} from 'types/Admin';

const Admin = () => {
  const loaded = useStore('loaded');

  if (!loaded) {
    return null;
  }

  if (!isGroup('admin')) {
    return <Redirect />;
  }

  return (
    <Switch>
      <Route path={ADMIN_PATH} component={PanelRender} />
    </Switch>
  );
};

const PanelRender = () => {
  const isMobile = useIsMobile();

  useEffect(() => {
    setFooterStatus(true);
    return () => {
      setFooterStatus();
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      return;
    }

    setHeaderTheme('light');
    setHeaderClassName('forced staff-header');

    return () => {
      setHeaderTheme();
      setHeaderClassName();
    };
  }, [isMobile]);

  return (
    <View className="staff-panel">
      <View className="base-size">
        {!isMobile && <AdminMenu />}
        <View className="staff-container">
          <Switch>
            <Route exact path={ADMIN_PATH} component={Home} />
            <Route
              exact
              path={`${ADMIN_PATH}/businesses`}
              component={Businesses}
            />
            <Route path={`${ADMIN_PATH}/users`} component={Users} />
            <Route component={NotFound} />
          </Switch>
        </View>
      </View>
      {isMobile && <AdminTabbar />}
    </View>
  );
};

export default Admin;
