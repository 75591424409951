import './ItemsList.scss';

import {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
  ReactNode,
} from 'react';
import classNames from 'classnames';

import {useTranslation, useIsMounted, useHistory, useLocation} from 'hooks';
import {uri} from 'hooks/useUrl';

import NodeRender, {NodeType} from 'components/Common/NodeRender';

import {GETRaw} from 'utils/Http';
import {PaginationResponse} from 'core/response';

import View from 'components/Common/View';
import * as Grid from 'components/Common/Grid';
import Locale from 'components/Common/Locale';
import Error from 'components/Common/Error';
import Loading from 'components/Common/Loading';
import Text from 'components/Common/Text';
import Button from 'components/Common/Button';
import {useConnect} from 'components/Form/Connect';

type Params = {[name: string]: any};

type SharedProps = {
  labels?: string;
  nextLabel?: string;
  previousLabel?: string;
  loadMoreLabel?: string;
  useInfiniteScroll?: boolean;
};

export type ItemsListProps<T> = SharedProps & {
  noItemsLabel?: string;
  noItemsContent?: NodeType;
  className?: string;
  source?: string;
  sourceParams?: Params;
  data?: T[];
  mode?: 'vertical' | 'horizontal';
  showPagination?: boolean;
  enableSearchParams?: boolean;
  headerRender?: (items: T[] | null) => ReactNode;
  headerContent?: NodeType;
  footerContent?: NodeType;
  setLoaded?: (status: boolean) => void;
  children?: (item: T, index: number, sourceParams: Params) => ReactNode;

  useInfiniteScroll?: boolean;
  hideWhenNoItems?: boolean;

  page?: number;
  perPage?: number;
};

export type TableProps<T> = ItemsListProps<T> & {
  className?: string;
  headerLabels: string[] | ReactNode[];
};

export type PaginationProps = SharedProps & {
  loading?: boolean;
  currentPage?: number;
  nextPage?: number;
  total?: number;
  showing?: number;

  setPage: (page: number) => void;
};

const validateParam = (value: any, defaultValue: any, useDefault = false) => {
  if (value === null) {
    return defaultValue;
  }

  switch (typeof defaultValue) {
    case 'number': {
      if (Number.isInteger(+value)) {
        return +value;
      }
      break;
    }
    case 'string': {
      if (
        typeof value === 'string' &&
        value.length &&
        (!useDefault || !defaultValue.length)
      ) {
        return value;
      }
      break;
    }
  }

  return defaultValue;
};

const ItemsList = <T,>({
  labels,
  nextLabel,
  previousLabel,
  loadMoreLabel,
  noItemsLabel,
  noItemsContent,

  className = '',
  mode = 'horizontal',
  showPagination: showPaginationProp = false,
  enableSearchParams: enableSearchParamsProp = false,
  source,
  sourceParams: sourceParamsProp,
  data: dataInit,
  headerRender,
  headerContent,
  footerContent,
  children,
  setLoaded,

  useInfiniteScroll,
  hideWhenNoItems,

  page: initPage = 1,
  perPage: max = 40,
}: ItemsListProps<T>) => {
  const isMounted = useIsMounted();
  const history = useHistory();
  const {pathname, search} = useLocation();
  const sourceParamsRef = useRef<Params | null>(null);
  const sourceParams = useMemo(() => {
    if (!sourceParamsProp) {
      return null;
    }

    if (
      JSON.stringify(sourceParamsRef.current) ===
      JSON.stringify(sourceParamsProp)
    ) {
      return sourceParamsRef.current;
    }

    sourceParamsRef.current = sourceParamsProp;
    return sourceParamsRef.current;
  }, [sourceParamsRef, sourceParamsProp]);

  const enableSearchParams = !useInfiniteScroll && enableSearchParamsProp;
  const showPagination = showPaginationProp || useInfiniteScroll;

  const [loading, setLoading] = useState(false);
  const [errorInfo, setErrorInfo] = useState<any | null>(null);
  const [data, setData] = useState<PaginationResponse<T> | T[] | undefined>(
    dataInit,
  );
  const [initParams] = useState(new URLSearchParams(search));
  const [page, setPage] = useState<number>(
    data && !Array.isArray(data)
      ? data.current_page
      : enableSearchParams
      ? validateParam(initParams.get('page'), initPage)
      : initPage,
  );
  const {values} = useConnect();

  useEffect(() => {
    // Wait for connected form to load before load data
    if (!source || (values && Object.keys(values).length === 0)) {
      return;
    }

    const loadData = async () => {
      const defaultValues = {...sourceParams, ...values};
      const sourceFilters = {page, ...defaultValues};

      if (enableSearchParams) {
        Object.keys(sourceFilters).forEach(function (key) {
          if (key === 'page') {
            return;
          }

          if (initParams.get(key) !== null) {
            sourceFilters[key] = validateParam(
              initParams.get(key),
              defaultValues[key],
              true,
            );
          }
        });

        history.replace(uri(pathname, undefined, sourceFilters));
      }

      try {
        if (setLoaded) {
          setLoaded(false);
        }

        let resetData = false;

        if (!useInfiniteScroll || page === initPage) {
          resetData = true;
          setData(undefined);
        } else {
          setLoading(true);
        }

        const response = (await GETRaw<T[]>(source, {
          ...sourceFilters,
          max,
        })) as PaginationResponse<T>;

        if (!isMounted()) {
          return;
        }

        setData(data => {
          if (useInfiniteScroll) {
            setLoading(false);
          }

          if (!response.data.length && response.current_page > 1) {
            setPage(1);
            return undefined;
          } else {
            if (setLoaded) {
              setLoaded(true);
            }

            if (useInfiniteScroll && data && !Array.isArray(data)) {
              const {data: responseData, ...responseParams} = response;
              const {data: currentData} = data;

              return {
                ...responseParams,
                data: [...(resetData ? [] : currentData), ...responseData],
              };
            } else {
              return response as PaginationResponse<T>;
            }
          }
        });
      } catch (err) {
        setErrorInfo(err);
      }
    };

    loadData();
  }, [
    source,
    page,
    initPage,
    pathname,
    enableSearchParams,
    max,
    setLoaded,
    setErrorInfo,
    history,
    initParams,
    sourceParams,
    values,
    useInfiniteScroll,
    isMounted,
  ]);

  const renderItems = (items: T[]) => (
    <>
      {items?.map((item: any, idx: number) => {
        return (
          <Grid.Item key={item.id || item.value || item.label || idx}>
            {children && children(item as T, idx, {sourceParams, ...values})}
          </Grid.Item>
        );
      })}
    </>
  );

  const renderNoItems = (items: T[]) => (
    <>
      {!items?.length &&
        (!noItemsContent ? (
          <View className="no-items">
            <Locale text={noItemsLabel || 'pagination.no_results'} />
          </View>
        ) : (
          <NodeRender node={noItemsContent} />
        ))}
    </>
  );

  const renderContent = () => {
    const items = data ? (Array.isArray(data) ? data : data.data) : null;

    if (!items?.length && hideWhenNoItems) {
      return null;
    }

    const paginationProps = data &&
      items &&
      !Array.isArray(data) &&
      typeof data.total !== 'undefined' && {
        labels,
        loading,
        nextLabel,
        previousLabel,
        loadMoreLabel,
        useInfiniteScroll,
        currentPage: data.current_page,
        nextPage: data.next_page,
        total: data.total,
      };

    return (
      <View
        className={classNames('items-list', className, {
          'is-loading': loading,
          'is-no-items': !items?.length,
        })}>
        {headerRender && headerRender(items)}

        <Grid.Container mode={mode}>
          <NodeRender node={headerContent} />
          {!data && <Loading key="loading" />}
          {items && renderItems(items)}
          {items && renderNoItems(items)}
          <NodeRender node={footerContent} />
        </Grid.Container>
        {showPagination && paginationProps && items && (
          <Pagination
            setPage={setPage}
            {...paginationProps}
            showing={items.length}
          />
        )}
      </View>
    );
  };

  return (
    <>
      {errorInfo ? (
        <View className={classNames('items-list', className)}>
          {<Error info={errorInfo} />}
        </View>
      ) : (
        renderContent()
      )}
    </>
  );
};

export const Pagination = ({
  labels: initLabels,
  useInfiniteScroll,
  loading,
  nextLabel = 'pagination.next',
  previousLabel = 'pagination.previous',
  loadMoreLabel = 'pagination.load_more',
  currentPage = 1,
  nextPage = -1,
  total = 0,
  showing = 0,
  setPage,
}: PaginationProps) => {
  const {t} = useTranslation();
  const onPressPrevious = useCallback(
    () => setPage && setPage(currentPage - 1),
    [setPage, currentPage],
  );
  const onPressNext = useCallback(
    () => setPage && setPage(nextPage),
    [setPage, nextPage],
  );

  const labels = initLabels || 'pagination.result_count';

  /*const show = useInfiniteScroll || loading ? true : false;

  if (!show) {
    return null;
  }*/

  return (
    <View className="pagination">
      {!useInfiniteScroll && (
        <Text className="pagination-results" bold>
          {t(labels, {count: total})}
        </Text>
      )}
      <View
        className={classNames('pagination-buttons', {
          'infinite-scroll': useInfiniteScroll,
        })}>
        {!useInfiniteScroll && (
          <>
            <Button
              disabled={currentPage === 1}
              onPress={onPressPrevious}
              className="pagination-button button-previous"
              label={previousLabel}
            />
            <Button
              disabled={nextPage === -1}
              onPress={onPressNext}
              className="pagination-button button-next"
              label={nextLabel}
            />
          </>
        )}
        {useInfiniteScroll &&
          nextPage !== -1 &&
          (!loading ? (
            <Button
              onPress={onPressNext}
              className="pagination-button button-load-more"
              label={loadMoreLabel}
            />
          ) : (
            <Loading />
          ))}
      </View>
    </View>
  );
};

export const Table = <T,>({
  className,
  headerLabels,
  ...allProps
}: TableProps<T>) => {
  const renderHeader = () => {
    return (
      <Grid.Item className="header">
        {headerLabels.map((item: string | ReactNode, idx: number) => (
          <Grid.Field key={typeof item === 'string' ? item : idx}>
            {typeof item === 'string' ? <Text bold>{item}</Text> : item}
          </Grid.Field>
        ))}
      </Grid.Item>
    );
  };

  return (
    <ItemsList<T>
      className={classNames(`table`, className)}
      mode="horizontal"
      showPagination
      enableSearchParams
      headerContent={renderHeader()}
      {...allProps}
    />
  );
};

export default ItemsList;
