import './Services.scss';

import {Route, Switch} from 'react-router-dom';

import NotFound from 'pages/NotFound';

import {STAFF_PATH} from 'types/Business';

import Items from './Items';
import CategoryForm from './CategoryForm';
import ServiceForm from './ServiceForm';

const Services = () => {
  return (
    <Switch>
      <Route exact path={`${STAFF_PATH}/services`} component={Items} />
      <Route
        exact
        path={`${STAFF_PATH}/services/category/:category_id`}
        component={CategoryForm}
      />
      <Route
        exact
        path={`${STAFF_PATH}/services/add/category`}
        component={CategoryForm}
      />
      <Route
        exact
        path={`${STAFF_PATH}/services/add/service`}
        component={ServiceForm}
      />
      <Route
        exact
        path={`${STAFF_PATH}/services/add/:category_id`}
        component={ServiceForm}
      />
      <Route
        exact
        path={`${STAFF_PATH}/services/service/:service_id`}
        component={ServiceForm}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Services;
