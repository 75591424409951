import './Explorer.scss';

import {useEffect, useRef, useMemo, useCallback, useState} from 'react';
import classNames from 'classnames';

import {getStore, useStore} from 'core/hooks';

import {useSourceRequester, goToUrl, useTranslation} from 'hooks';

import View from 'components/Common/View';
import Form from 'components/Form';
import Autocomplete from 'components/Form/Autocomplete';
import Text from 'components/Common/Text';
import Image from 'components/Common/Image';

import {UIStore} from 'types/Store';
import {
  ExplorerLocation,
  ExplorerSearch,
  ExplorerSearchType,
  ExplorerSearchCallback,
} from 'types/Explorer';
import {BusinessStatus} from 'types/Business';
import {BusinessType} from 'types/BusinessType';
import BusinessTypeImages from 'components/BusinessType/BusinessTypeImages';

type ExplorerSearchItem = ExplorerSearch & {
  type: ExplorerSearchType | 'label';
};

const {Active} = BusinessStatus;

const uiStore = getStore<UIStore>('ui');

type BusinessTypeSearch = {
  name: string;
  type: BusinessType;
};

const MIN_CHARS_TO_SEARCH = 2;

const normalizeSearchText = (text: string) =>
  text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

const SearchInput = () => {
  const {t} = useTranslation();
  const filterValue = useRef('');
  const [isEmpty, setIsEmpty] = useState(true);
  const currentLocation = useStore<ExplorerLocation>('currentLocation');
  const [items, setItems] = useState<ExplorerSearchItem[]>([]);

  const businessesTypes: BusinessTypeSearch[] = useMemo(
    () =>
      Object.values(BusinessType).map(type => ({
        name: t(`business_types.${type}_plural`),
        type,
      })),
    [t],
  );

  const doSearch: ExplorerSearchCallback = useCallback(item => {
    const {explorerSearch} = uiStore;

    const searchResult = explorerSearch(item);

    if (searchResult === false) {
      if (!item) {
        return searchResult;
      }

      const {type, identifier} = item;
      goToUrl({
        to: 'explore',
        query: {
          [type]: identifier,
        },
      });
    }

    return searchResult;
  }, []);

  const onResults = useCallback(
    (results: ExplorerSearchItem[]) => {
      const currentValue = filterValue.current;
      const items: ExplorerSearchItem[] = [];

      if (currentValue.length < MIN_CHARS_TO_SEARCH) {
        setItems([]);
        return;
      }

      // Add businesses types
      const valueSearch = normalizeSearchText(currentValue.toLowerCase());
      const types = businessesTypes.filter(({name}) => {
        const parts = name.split(' ');

        return (
          normalizeSearchText(name).toLowerCase().indexOf(valueSearch) !== -1 ||
          parts.some(
            part =>
              normalizeSearchText(part.toLowerCase()).indexOf(valueSearch) !==
              -1,
          )
        );
      });

      if (types.length) {
        items.push({
          type: 'label',
          display: t('explorer.type_business_type'),
          showAsLabel: true,
        } as any);

        types.forEach(({type, name}) => {
          items.push({
            display: name,
            type: 'type',
            image: BusinessTypeImages[type],
            identifier: type,
          });
        });
      }

      // Add businesses
      const allBusinesses = results.filter(({type}) => type === 'business');

      if (allBusinesses.length) {
        items.push(
          {
            type: 'label',
            display: t('explorer.type_business'),
            showAsLabel: true,
          } as any,
          ...allBusinesses,
        );
      }

      setItems(items);
    },
    [t, setItems, businessesTypes],
  );
  const {request} = useSourceRequester<ExplorerSearchItem>(onResults);

  const checkChange = useCallback(
    (element: HTMLInputElement) => {
      const currentValue = element.value.trim();

      setIsEmpty(!currentValue);

      if (filterValue.current === currentValue) {
        return;
      }

      filterValue.current = currentValue;

      if (!currentValue) {
        doSearch(null);
      }

      if (currentValue.length < MIN_CHARS_TO_SEARCH) {
        onResults([]);
        return;
      }

      request('explorer', {
        status: Active,
        search: currentValue,
        ...(currentLocation && {
          lat: currentLocation.lat,
          lng: currentLocation.lng,
        }),
      });
    },
    [onResults, setIsEmpty, doSearch, request, currentLocation],
  );

  const onChange = useCallback(
    e => {
      checkChange(e.target);
    },
    [checkChange],
  );

  const itemRender = useCallback(
    ({type, description, image, display}: ExplorerSearchItem) => {
      if (type === 'label') {
        return <Text>{display}</Text>;
      }

      return (
        <>
          <Image
            className="image"
            animIn
            src={`${image}${type === 'business' ? '/100x100/cover' : ''}`}
          />
          <View className="item">
            <Text className="name">{display}</Text>
            {description && <Text className="desc">{description}</Text>}
          </View>
        </>
      );
    },
    [],
  );

  const onPressItem = useCallback(
    (item: ExplorerSearchItem) => {
      const {type, identifier} = item;

      if (type === 'business') {
        goToUrl({to: `b/:slug`, params: {slug: identifier}});
        return;
      }

      doSearch(item);
    },
    [doSearch],
  );

  const onPressClear = useCallback(
    e => {
      e.stopPropagation();

      const input =
        document.querySelector<HTMLInputElement>('#explorer_search');

      if (input) {
        input.value = '';

        // Simulate change
        filterValue.current = ' ';
        checkChange(input);

        // Little hack to wait to clear items
        setTimeout(() => {
          input.focus();
        }, 50);
      }
    },
    [filterValue, checkChange],
  );

  const onFocus = useCallback(
    e => {
      checkChange(e.target);
    },
    [checkChange],
  );

  const {explorerSearchText} = uiStore;
  const currentSearchText = explorerSearchText();

  useEffect(() => {
    const input = document.querySelector<HTMLInputElement>('#explorer_search');
    const {explorerIs} = uiStore;

    if (input && explorerIs) {
      input.value = currentSearchText;
      //filterValue.current = currentSearchText;
      setIsEmpty(!currentSearchText);
    }
  }, [filterValue, setIsEmpty, currentSearchText]);

  const onKeyDown = useCallback(
    ({keyCode, target}) => {
      const {value} = target as HTMLInputElement;
      //const input = document.querySelector<HTMLInputElement>('#explorer_search');

      if (!value && keyCode === 13) {
        if (doSearch(null) === false) {
          goToUrl({to: 'explore'});
        }
      }
      //console.log('input', e);
    },
    [doSearch],
  );

  return (
    <Form
      className="search-input-form"
      onSubmitHandler={e => {
        e.preventDefault();
      }}>
      {() => {
        return (
          <>
            <Autocomplete<ExplorerSearchItem>
              items={items}
              name="explorer_search"
              className={classNames('explorer-search-input', {
                'is-empty': isEmpty,
                'has-items': !!items.length,
              })}
              onKeyDown={onKeyDown}
              onChange={onChange}
              onFocus={onFocus}
              itemRender={itemRender}
              onPressItem={onPressItem}
              optional
              placeholder="explorer.search_placeholder"
              value={filterValue.current}
              //defaultValue={currentSearchText}
              maxLength={64}>
              <View onClick={onPressClear} className="clear-button" />
            </Autocomplete>
          </>
        );
      }}
    </Form>
  );
};

export default SearchInput;
