import './ForBusiness.scss';

import {useState, useEffect, ReactNode} from 'react';
import classNames from 'classnames';

import {GET} from 'utils/Http';

import Page from 'components/Page';
import Text from 'components/Common/Text';
import Locale from 'components/Common/Locale';
import Price from 'components/Common/Price';
import View from 'components/Common/View';
import Link from 'components/Common/Link';

import {CurrencyTag} from 'types/Currency';
import {MembershipType, MembershipPrices} from 'types/Membership';
import {ReactComponent as WhatsappIcon} from './Assets/WhatsappIcon.svg';
import Br from 'components/Common/Br';

const {USD} = CurrencyTag;
const {Free, Premium} = MembershipType;

type MembershipBlockProps = {
  children: ReactNode;
  type: MembershipType;
};

type BenefitProps = {
  children: ReactNode;
};

const ForBusiness = () => {
  return (
    <Page noAuth title="for_business.title" padding>
      {/* Main headline */}
      <View className="landing-for-business">
        <View className="headline main">
          <Text className="top">
            <Locale text="for_business.headline" />
          </Text>
          <Text className="bottom">
            <Locale text="for_business.subheadline" />
          </Text>
        </View>

        {/* Blocks */}
        <View className="blocks">
          {/* FREE Block */}
          <MembershipBlock type={Free}>
            <View className="benefits">
              <Benefit>
                <Locale text="for_business.free_benefits.line1" />
              </Benefit>
              <Benefit>
                <Locale text="for_business.free_benefits.line2" />
              </Benefit>
              <Benefit>
                <Locale text="for_business.free_benefits.line3" />
              </Benefit>
              <Benefit>
                <Locale text="for_business.free_benefits.line4" />
              </Benefit>
              <Benefit>
                <Locale text="for_business.free_benefits.line5" />
              </Benefit>
              <Benefit>
                <Locale text="for_business.free_benefits.line6" />
              </Benefit>
            </View>

            <Link
              className="choose-button"
              to="b/create"
              label="for_business.choose_button"
            />
          </MembershipBlock>

          {/* PREMIUM Block */}
          <MembershipBlock type={Premium}>
            <View className="benefits">
              <Benefit>
                <Locale text="for_business.premium_benefits.line1" />
              </Benefit>
              <Benefit>
                <Locale text="for_business.premium_benefits.line2" />
              </Benefit>
              <Benefit>
                <Locale text="for_business.premium_benefits.line3" />
              </Benefit>
              <Benefit>
                <Locale text="for_business.premium_benefits.line4" />
              </Benefit>
              <Benefit>
                <Locale text="for_business.premium_benefits.line5" />
              </Benefit>
            </View>

            <Link
              className="choose-button"
              to="b/create"
              label="for_business.choose_button"
            />
          </MembershipBlock>
        </View>

        {/* Request DEMO */}
        <View className="headline">
          <Text className="top">
            <Locale text="for_business.demo_headline" />
          </Text>
          <Text className="bottom full-width">
            <Locale text="for_business.demo_subheadline" />
          </Text>
        </View>

        <a
          className="demo-button demo"
          target="_blank"
          rel="noreferrer"
          href="https://wa.me/+13056993648">
          <WhatsappIcon className="icon" />
          <Locale text="for_business.demo_request_button" />
        </a>

        {/* Request DEMO */}
        <View className="headline main">
          <Text className="bottom full-width">
            <Locale text="for_business.help_line1" />
          </Text>
          <Text className="bottom full-width">
            <Locale text="for_business.help_line2" />
          </Text>

          <a
            className="contact-button"
            target="_blank"
            rel="noreferrer"
            href="mailto:hi@reserby.com">
            <Locale text="for_business.contact_button" />
          </a>
        </View>

        <View className="help-text">
          <Locale text="for_business.help_text_1" />
          <Br />
          <Locale text="for_business.help_text_2" />
        </View>
      </View>
    </Page>
  );
};

const Benefit = ({children}: BenefitProps) => {
  return <Text className="benefit">{children}</Text>;
};

const MembershipBlock = ({children, type}: MembershipBlockProps) => {
  // TOOD: Get dinamically from backend
  const [currency, setCurrency] = useState<CurrencyTag | null>(null);

  const renderPrice = () => {
    if (type === Free) {
      return <Price amount={0} />;
    }

    if (type !== Premium || !currency) {
      return '...';
    }

    const price =
      MembershipPrices[currency in MembershipPrices ? currency : USD];

    return (
      <>
        <Price amount={price} currency={currency} explicit />
        {' / '}
        <Text className="monthly">
          <Locale text="membership.monthly" />
        </Text>
      </>
    );
  };

  useEffect(() => {
    const loadCurrency = async () => {
      const {currency} = await GET<{currency: CurrencyTag}>('currencies/me');

      setCurrency(currency);
    };

    loadCurrency();
  }, [setCurrency]);

  return (
    <View className={classNames('block', type)}>
      <Text className="type">
        <Locale text={`membership.type_${type}`} />
      </Text>

      <Text className="price">{renderPrice()}</Text>

      {children}
    </View>
  );
};

export default ForBusiness;
