import {UserStaffInfo} from './User';

export enum StaffRole {
  Owner = 'owner',
  Manager = 'manager',
  Employee = 'employee',
}

export interface StaffInfo {
  id: string;
  member: UserStaffInfo;
  role: StaffRole;
  first_name: string;
  last_name: string;
}

export default StaffInfo;
