import './Services.scss';

import {useMemo, useEffect} from 'react';

import View from 'components/Common/View';
import Text from 'components/Common/Text';
import Locale from 'components/Common/Locale';

import CategoriesHeader from './CategoriesHeader';
import CategoryItem from './CategoryItem';

import {BusinessInfo} from 'types/Business';
import {CategoryServices} from 'types/Category';

import {formatCategories} from 'utils/Category';

type ServicesProps = {
  business: BusinessInfo;
};

const Services = ({business}: ServicesProps) => {
  const {categories: allCategories, services: allServices} = business;

  const categories = useMemo<CategoryServices[]>(() => {
    return formatCategories(allCategories, allServices).filter(
      category => category.services.length,
    );
  }, [allCategories, allServices]);

  // Restore scroll
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!categories.length) {
    return (
      <Text className="services-not-available center">
        <Locale text="booking.services_not_available" />
      </Text>
    );
  }

  return (
    <View className="booking-categories-list ">
      <CategoriesHeader categories={categories} />

      <View className="categories-container horizontal-padding">
        {categories.map(category => (
          <CategoryItem
            key={`parent-${category.category.id}`}
            category={category}
          />
        ))}
      </View>
    </View>
  );
};

export default Services;
