import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';
import HoursSelector from 'components/Staff/HoursSelector';

const Hours = () => {
  return (
    <Page title="staff.settings.hours.title" padding>
      <PageTitle className="staff-title center" />

      <HoursSelector />
    </Page>
  );
};

export default Hours;
