import './Image.scss';

import {useState, useCallback} from 'react';
import classNames from 'classnames';

export type ImageProps = {
  className?: string;
  src?: string;
  alt?: string;
  params?: string[];
  animIn?: boolean;
};

// TODO: Implement <picture> and <source> support

const Image = ({alt = '', className, src, params, animIn}: ImageProps) => {
  const [loaded, setLoaded] = useState(false);
  const onLoad = useCallback(() => {
    setLoaded(true);
  }, [setLoaded]);
  return (
    <img
      onLoad={animIn ? onLoad : undefined}
      className={classNames(className, {
        loaded: animIn && loaded,
        'image-anim-in': animIn,
      })}
      alt={alt}
      src={`${src}${params ? `/${params.join('/')}` : ''}`}
    />
  );
};

export default Image;
