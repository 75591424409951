import classNames from 'classnames';

import ButtonBase, {ButtonProps} from 'components/Common/Button';

export type ButtonOptionProps = ButtonProps;

const Button = ({className, ...props}: ButtonOptionProps) => {
  return (
    <ButtonBase
      iconPosition="after"
      className={classNames('header-button', className)}
      {...props}
    />
  );
};

export default Button;
