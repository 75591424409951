import {createStore} from 'core/storex';

import {
  UserStore,
  BusinessStore,
  BookingStore,
  UIStore,
  CacheStore,
} from 'types/Store';

const initStore = () => {
  createStore(new UIStore(), 'ui');
  createStore(new UserStore(), 'user');
  createStore(new CacheStore(), 'cache');
  createStore(new BookingStore(), 'booking');
  createStore(new BusinessStore(), 'business');
};

export default initStore;
