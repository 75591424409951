import './Appointment.scss';

import {ReactNode} from 'react';

import {useSourceData} from 'hooks';

import Page from 'components/Page';
import Text from 'components/Common/Text';
import DateTime from 'components/Common/DateTime';
import Hr from 'components/Common/Hr';
import View from 'components/Common/View';
import Spacer from 'components/Common/Spacer';
import BlockInfo from 'components/Common/Display/BlockInfo';

import CancelButton from 'components/Appointment/CancelButton';
import ReviewButton from 'components/Appointment/ReviewButton';
import NewButton from 'components/Appointment/NewButton';
import ChangeTime from 'components/Appointment/ChangeTime';
import BusinessDetails from 'components/Business/Details';
import ServicesList from 'components/Appointment/ServicesList';
import AppointmentHeader from 'components/Appointment/Header';

import AppointmentInfo from 'types/Appointment';

const ViewPage = () => {
  const appointment = useSourceData<AppointmentInfo>();

  const {notes, date, price_total, snapshots, business} = appointment;

  const {name} = business;

  const renderButton = (button: ReactNode) => {
    if (!button) {
      return null;
    }

    return (
      <>
        {button}
        <Spacer size={10} />
      </>
    );
  };

  return (
    <Page title={['appointments.view_title', {name}]} padding>
      {<AppointmentHeader appointment={appointment} />}

      <View className="max-500 center column fill">
        <View className="block-gradient padding fill">
          <BusinessDetails
            business={business}
            showOpenAddressButton
            showPhoto
            showRating={false}
          />

          <Spacer size={25} />

          <Hr />

          <Spacer size={25} />

          <BlockInfo label="booking.date_label">
            <Text className="block-text">
              <DateTime date={date} />
            </Text>
          </BlockInfo>

          {notes && (
            <>
              <Spacer size={15} />
              <BlockInfo label="booking.notes_label">
                <Text className="block-desc">{notes}</Text>
              </BlockInfo>
            </>
          )}

          <Spacer size={15} />

          <BlockInfo label="booking.services_label">
            <ServicesList totalPrice={price_total} services={snapshots} />
          </BlockInfo>
        </View>

        <Spacer size={20} />

        <View className="appointment-actions">
          {renderButton(<ReviewButton appointment={appointment} />)}
          {renderButton(<ChangeTime appointment={appointment} />)}
          {renderButton(<NewButton appointment={appointment} />)}
          {renderButton(<CancelButton appointment={appointment} />)}
        </View>
      </View>
    </Page>
  );
};

export default ViewPage;
