import {useRef, useState, useEffect, useCallback} from 'react';

import {getContext} from 'core/storex';

const componentWillMount = (element: any, stores: any) => {
  stores.forEach((state: any) => state.__store_refs.push(element));
};

const componentDidUnmount = (element: any, stores: any) => {
  stores.forEach((state: any) => {
    const idx = state.__store_refs.indexOf(element);
    if (idx !== -1) {
      state.__store_refs.splice(idx, 1);
    }
  });
};

const parseProps = (
  props: any,
  params: any,
  paramsRelation: any,
  stores: any,
) => {
  if (props.length === 1) {
    params[props[0].__store_name] = props[0];
    paramsRelation[props[0].__store_name] = props[0].__store_name;
    stores.push(props[0]);
  } else {
    for (let idx = 1, maxIndex = props.length; idx < maxIndex; idx++) {
      const prop = props[idx];

      params[prop] = props[0][prop];
      paramsRelation[prop] = props[0].__store_name;
      stores.push(props[0]);
    }
  }
};

const storeNotify = (
  ref: any,
  state: any,
  values: any,
  paramsRelation: any,
) => {
  if (paramsRelation[state.__store_name] !== undefined) {
    return true;
  }

  for (const i in values) {
    if (paramsRelation[i] === state.__store_name) {
      return true;
    }
  }
};

const findStore = (...allArgs: any) => {
  const params: any = {};
  const stores: any = [];
  const paramsRelation: any = {};

  let args: any = [...allArgs];
  let context: any = false;

  if (args.length === 0) {
    context = getContext();
    args = context;
  } else if (typeof args[0] === 'function') {
    context = getContext();
    args = args[0](context);
  }

  if (Array.isArray(args) === false) {
    // State expression
    if (args.__store_name !== undefined) {
      params[args.__store_name] = args;
      paramsRelation[args.__store_name] = args.__store_name;
      stores.push(args);
    } else {
      for (const idx in args) {
        const store = args[idx];
        if (Array.isArray(store) === true) {
          parseProps(store, params, paramsRelation, stores);
        } else {
          params[idx] = store;
          paramsRelation[store.__store_name] = idx;
          stores.push(store);
        }
      }
    }
  } else {
    // Array expression
    if (typeof args[0] === 'string') {
      const allStores = context || getContext();
      for (const i in args) {
        const prop = args[i];

        if (allStores[prop] !== undefined) {
          params[prop] = allStores[prop];
          paramsRelation[prop] = allStores[prop].__store_name;
          stores.push(allStores[prop]);
        } else {
          for (const ii in allStores) {
            if (allStores[ii][prop] !== undefined) {
              params[prop] = allStores[ii][prop];
              paramsRelation[prop] = allStores[ii].__store_name;
              stores.push(allStores[ii]);
            }
          }
        }
      }
    } else if (typeof args[1] === 'string') {
      parseProps(args, params, paramsRelation, stores);
    } else {
      for (let i = 0, max = args.length; i < max; i++) {
        const store = args[i];
        if (Array.isArray(store) === true) {
          parseProps(store, params, paramsRelation, stores);
        } else {
          params[store.__store_name] = store;
          paramsRelation[store.__store_name] = store.__store_name;
          stores.push(store);
        }
      }
    }
  }

  return {stores, params, paramsRelation};
};

const getStore = <T = any>(...args: any): T => {
  const params = findStore(...args).params;

  if (params && Object.keys(params).length === 1) {
    return params[Object.keys(params)[0]] as T;
  } else {
    return params as T;
  }
};

const useStore = <T>(...args: any): T => {
  const ref = useRef<'init' | 'active' | (() => void)>('init');
  const refPending = useRef(false);

  const [, updateState] = useState<any>();
  const forceUpdate = useCallback(() => updateState({}), []);

  const storeValue = findStore(...args);

  const doEffect = useCallback(() => {
    const refElement = {
      __store_notify: (state: any, values: any) => {
        const requireNotify = storeNotify(
          refElement,
          state,
          values,
          storeValue.paramsRelation,
        );

        if (requireNotify) {
          if (typeof ref.current === 'function') {
            refPending.current = true;
            //console.log('Adding to pending!', args);
          } else {
            //console.log('doing effect forced', args);
            forceUpdate();
          }
        }
      },
    };

    componentWillMount(refElement, storeValue.stores);

    return () => {
      componentDidUnmount(refElement, storeValue.stores);
    };
  }, [forceUpdate, storeValue, refPending, ref]);

  useEffect(() => {
    if (typeof ref.current === 'function') {
      const unmountHandler = ref.current;
      //console.log('doing effect check', args, {...storeValue});
      ref.current = 'active';

      if (refPending.current) {
        refPending.current = false;
        forceUpdate();
      }

      return unmountHandler;
    }

    //console.log('doing effect update', args);

    return doEffect();
  }, [doEffect, forceUpdate, ref, refPending]);

  // Hook at first
  if (ref.current === 'init') {
    ref.current = doEffect();
  }

  if (Object.keys(storeValue.params).length === 1) {
    return storeValue.params[Object.keys(storeValue.params)[0]] as T;
  } else {
    return storeValue.params as T;
  }
};

export {useStore, getStore, getContext};
