import {DateTime} from 'luxon';

import {DateTimeAny, TIME_DISPLAY} from 'types/Date';

import {convertToDateTime} from 'utils/DateTime';

type Props = {
  date?: DateTimeAny;
  timezone?: string;
  minutes?: number;
  format?: string;
};

const Time = ({date, timezone, minutes = 0, format = TIME_DISPLAY}: Props) => {
  if (!date) {
    date = DateTime.now().startOf('day').plus({minutes});
  } else {
    date = convertToDateTime(date, timezone);
  }

  return <>{date.toFormat(format)}</>;
};

export default Time;
