import './BottomBar.scss';

import {useRef, useEffect, useMemo, useCallback, useState} from 'react';
import {DateTime} from 'luxon';
import classNames from 'classnames';

import {POST} from 'utils/Http';

import {DATE_TIME_FORMAT} from 'types/Date';

import {useStore, getStore} from 'core/hooks';

import {parseErrorInfo} from 'utils/Error';

import {
  useStaffUrl,
  useIsUserProfileCompleted,
  useBusiness,
  useLocation,
  useBusinessUrl,
} from 'hooks';

import AppointmentInfo from 'types/Appointment';
import {BookingPages} from 'types/Booking';
import {BookingStore} from 'types/Store';

import View from 'components/Common/View';
import Link from 'components/Common/Link';
import Button from 'components/Common/Button';
import Text from 'components/Common/Text';
import Locale from 'components/Common/Locale';
import Price from 'components/Common/Price';
import Loading from 'components/Common/Loading';

import {goToUrl} from 'hooks';
import {useSetNotification} from 'components/App/Notifications';

const bookingStore = getStore<BookingStore>('booking');

const clearBooking = (booking: BookingStore, clearServices?: boolean) => {
  booking.isFromStaff = false;
  booking.userName = null;
  booking.userId = null;
  booking.clientId = null;
  booking.date = null;
  booking.forcedDate = false;
  booking.notes = null;

  if (clearServices) {
    booking.services.splice(0, booking.services.length);
  }
};

const BottomBar = () => {
  const setNotification = useSetNotification();
  const staffUrl = useStaffUrl();
  const businessUrl = useBusinessUrl();
  const isUserProfileCompleted = useIsUserProfileCompleted();
  const {state} = useLocation<{
    userId: string;
    clientId: string;
    userName: string;
    directDate: string;
    isFromStaff: boolean;
    isFromLogin: boolean;
    isFromCompleteSettings: boolean;
  }>();
  const firstRender = useRef(false);
  const [sending, setSending] = useState(false);
  const business = useBusiness();
  const {booking, logged} = useStore<{booking: BookingStore; logged: boolean}>(
    'booking',
    'logged',
  );
  const {services: businessServices} = business;
  const {
    view,
    date,
    forcedDate,
    services,
    isFromStaff,
    userId,
    clientId,
    notes,
  } = booking;
  const calcPrice = () =>
    services.reduce((price, service) => price + service.price, 0);

  const nextView = useMemo(() => {
    if (view === 'services') {
      if (forcedDate) {
        return 'confirm';
      } else {
        return 'time';
      }
    }

    if (view === 'time') {
      return 'confirm';
    }

    return 'services';
  }, [view, forcedDate]);

  const confirmationUrl = useMemo(
    () => ({
      base: businessUrl({to: 'booking/confirm'}),
    }),
    [businessUrl],
  );

  const onPressConfirm = useCallback(async () => {
    if (!date) {
      return;
    }

    if (!logged || !isUserProfileCompleted) {
      if (!logged) {
        goToUrl({
          to: 'login',
          redirect: {...confirmationUrl, manual: true},
          state: {
            isFromBooking: true,
            loginUrl: confirmationUrl,
          },
        });
      } else {
        goToUrl({
          to: 'settings/complete',
          redirect: {
            ...confirmationUrl,
            state: {
              isFromCompleteSettings: true,
            },
          },
          state: {
            isFromBooking: true,
          },
        });
      }
      return;
    }

    setSending(true);
    const params = {
      date: date.toFormat(DATE_TIME_FORMAT),
      services: services.map(service => ({id: service.id, addons: []})),
      ...(clientId && {client_id: clientId}),
      ...(userId && {user_id: userId}),
      ...(notes && {notes}),
      ...(isFromStaff && !userId && !clientId && {do_not_notify: true}),
      ...(forcedDate && {forced_date: true}),
    };

    try {
      const {id} = await POST<AppointmentInfo>(`appointments`, params);

      if (isFromStaff || clientId || userId) {
        goToUrl({
          base: staffUrl({
            to: 'appointments/:id',
            params: {id},
          }),
        });
      } else {
        goToUrl({to: 'appointments/:id', params: {id}});
      }

      clearBooking(booking, true);
      return;
    } catch (e) {
      const {msg} = parseErrorInfo(e);

      setNotification({
        type: 'error',
        msg: msg,
      });
    }

    setSending(false);
  }, [
    date,
    forcedDate,
    clientId,
    userId,
    notes,
    booking,
    services,
    isUserProfileCompleted,
    staffUrl,
    logged,
    isFromStaff,
    setNotification,
    confirmationUrl,
  ]);

  // Check if service changed from business
  useEffect(() => {
    services.forEach((s1, idx) => {
      if (!businessServices.some(s2 => s1.id === s2.id)) {
        services.splice(idx, 1);
        if (booking.date) {
          booking.date = null;
        }
      }
    });
  }, [businessServices, booking, services, services.length]);

  // Check if is coming from login
  useEffect(() => {
    if (!logged || (!state?.isFromLogin && !state?.isFromCompleteSettings)) {
      return;
    }

    if (isUserProfileCompleted) {
      onPressConfirm();
    } else {
      goToUrl({
        to: 'settings/complete',
        redirect: {
          ...confirmationUrl,
          state: {
            isFromCompleteSettings: true,
          },
        },
        state: {
          isFromBooking: true,
        },
      });
    }
  }, [logged, state, isUserProfileCompleted, confirmationUrl, onPressConfirm]);

  // Set staff clientId or userId
  useEffect(() => {
    if (view !== 'services' || firstRender.current) {
      return;
    }

    firstRender.current = true;

    clearBooking(booking);

    if (state?.isFromStaff) {
      if (state.directDate) {
        booking.date = DateTime.fromISO(state.directDate, {
          zone: 'utc',
        });
        booking.forcedDate = true;
      }

      if (state.userId || state.clientId) {
        if (state.clientId) {
          booking.clientId = state.clientId;
        }

        if (state.userId) {
          booking.userId = state.userId;
        }

        booking.userName = state.userName;
      }

      booking.isFromStaff = true;
    }
  }, [view, state, booking]);

  const nextButton = useMemo(() => {
    const nextUrl = {
      base: businessUrl({
        to: `booking${nextView !== 'services' ? `/${nextView}` : ''}`,
      }),
    };

    if (view === 'confirm') {
      return !sending ? (
        <Button
          onPress={onPressConfirm}
          className="btn primary"
          label={`booking.button_${view}`}
        />
      ) : (
        <Loading size="small" />
      );
    }

    return (
      <Link
        {...nextUrl}
        className="btn primary"
        label={`booking.button_${view}`}
      />
    );
  }, [view, businessUrl, nextView, onPressConfirm, sending]);

  const isActive = () => {
    if (!view) {
      return false;
    }

    if (view === 'time' || (view === 'services' && !services.length)) {
      return false;
    }

    return true;
  };

  return (
    <View className={classNames('booking-bottom-bar', {active: isActive()})}>
      <View className="container base-size">
        <Text className="services">
          <Text className="count">
            <Locale
              text="booking.services_count"
              params={{count: services.length}}
            />
          </Text>
          <Text className="price">
            <Price useFree amount={calcPrice()} />
          </Text>
        </Text>
        {nextButton}
      </View>
    </View>
  );
};

export const setBookingView = (view: BookingPages = null) => {
  bookingStore.view = view;
};

export default BottomBar;
