import {useState, useCallback, useEffect} from 'react';
import {DateTime} from 'luxon';

import {GET} from 'utils/Http';
import {getStore} from 'core/hooks';

import {useIsMounted} from 'hooks';

import View from 'components/Common/View';

import BusinessInfo from 'types/Business';
import AppointmentInfo, {AppointmentStatus} from 'types/Appointment';
import {DATE_TIME_FORMAT} from 'types/Date';
import {UIStore} from 'types/Store';

import {convertToDateTime} from 'utils/DateTime';

import AppointmentItem from './AppointmentItem';
import NewAppointmentCursor from './NewAppointmentCursor';

import {UseCalendarLoadData} from './index';

const uiStore = getStore<UIStore>('ui');

const {Confirmed, Completed} = AppointmentStatus;

type AppointmentsProps = {
  date: DateTime;
  business: BusinessInfo;
};

/*const createSampleAppointment = (
  baseAppointment: any,
  startHours: number,
  endHours: number,
): AppointmentInfo => {
  baseAppointment.id += Math.random();
  baseAppointment.date = DateTime.fromISO(baseAppointment.date)
    .setZone('utc')
    .startOf('day')
    .plus({hours: startHours})
    .toISO();

  baseAppointment.date_end = DateTime.fromISO(baseAppointment.date_end)
    .setZone('utc')
    .startOf('day')
    .plus({hours: endHours})
    .toISO();

  return {...baseAppointment};
};*/

/*const generateSampleData = (
  baseAppointment: AppointmentInfo,
): AppointmentInfo[] => {
  const baseHour = 14.6;
  const appointments: AppointmentInfo[] = [
    createSampleAppointment(baseAppointment, baseHour - 1, baseHour + 1),
    createSampleAppointment(baseAppointment, baseHour, baseHour + 0.5),
    createSampleAppointment(baseAppointment, baseHour + 0.3, baseHour + 1.3),
    createSampleAppointment(baseAppointment, baseHour + 0.6, baseHour + 1.8),
    createSampleAppointment(baseAppointment, baseHour + 1.3, baseHour + 2.2),
    createSampleAppointment(baseAppointment, baseHour + 1.4, baseHour + 2.7),
    createSampleAppointment(baseAppointment, baseHour + 2.0, baseHour + 3.0),
    createSampleAppointment(baseAppointment, baseHour + 2.5, baseHour + 3.5),
    createSampleAppointment(baseAppointment, baseHour + 3.0, baseHour + 4.0),
  ];

  return appointments;
};*/

const Appointments = ({date, business}: AppointmentsProps) => {
  const isMounted = useIsMounted();
  const [appointments, setAppointments] = useState<AppointmentInfo[]>([]);
  const {id: businessId} = business;

  const loadData = useCallback(
    async (clear = false) => {
      if (!isMounted()) {
        return;
      }

      if (clear) {
        setAppointments([]);
      }

      const newAppointments = await GET<AppointmentInfo[]>(
        `businesses/${businessId}/appointments`,
        {
          from_date: date.toFormat(DATE_TIME_FORMAT),
          status: `${Confirmed},${Completed}`,
        },
      );

      if (!isMounted()) {
        return;
      }

      // Debug sample
      /*if (newAppointments.length) {
      return setAppointments(generateSampleData(newAppointments[0]));
    }*/

      setAppointments(
        newAppointments.sort(
          (a, b) =>
            convertToDateTime(a.date).toSeconds() -
            convertToDateTime(b.date).toSeconds(),
        ),
      );
    },
    [date, isMounted, setAppointments, businessId],
  );

  uiStore.loadCalendar = loadData;

  useEffect(() => {
    loadData(true);
  }, [loadData]);

  return (
    <View className="appointments-day">
      <NewAppointmentCursor date={date} />
      {appointments.map(appointment => (
        <AppointmentItem
          key={appointment.id}
          business={business}
          appointment={appointment}
          appointments={appointments}
        />
      ))}
    </View>
  );
};

export const useLoadCalendar = (): UseCalendarLoadData => {
  return uiStore.loadCalendar;
};

export default Appointments;
