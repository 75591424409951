import './Features.scss';

import {useEffect, useCallback, useState} from 'react';
import classNames from 'classnames';

import {GET} from 'utils/Http';

import {useIsMounted} from 'hooks';

import View from 'components/Common/View';
import Text from 'components/Common/Text';
import Locale from 'components/Common/Locale';

import BusinessInfo from 'types/Business';
import {DateTimeRange, DATE_TIME_FORMAT} from 'types/Date';

import {getUTCFromTimezone} from 'utils/DateTime';

import {ReactComponent as IconTime} from './Assets/IconTime.svg';
import {ReactComponent as IconConfirmation} from './Assets/IconConfirmation.svg';
import {ReactComponent as IconPaymentInBusiness} from './Assets/IconPaymentInBusiness.svg';

type Props = {
  business: BusinessInfo;
};

const Features = ({business}: Props) => {
  const isMounted = useIsMounted();
  const {id, timezone} = business;
  const [availability, setAvailability] = useState<DateTimeRange[] | null>(
    null,
  );

  const loadAvailability = useCallback(async () => {
    const fromDate = getUTCFromTimezone(timezone);

    const response = await GET<DateTimeRange[]>(`availability/${id}`, {
      from_date: fromDate.toFormat(DATE_TIME_FORMAT),
    });

    if (!isMounted()) {
      return;
    }

    setAvailability(response);
  }, [id, timezone, setAvailability, isMounted]);

  useEffect(() => {
    loadAvailability();
  }, [loadAvailability]);

  const renderIsOpen = () => {
    let availabilityLabel = '...';
    let availabilityClass = '';

    if (availability) {
      if (availability.length) {
        availabilityClass = 'open';
        availabilityLabel = 'business.features.open_now';
      } else {
        availabilityClass = 'closed';
        availabilityLabel = 'business.features.closed_now';
      }

      // TODO: Implement more complex open/closing warning
    }

    return (
      <>
        {availabilityClass === 'open' && (
          <View className="feature">
            <IconTime className="icon" />
            <Text className={classNames('name', 'status', availabilityClass)}>
              <Locale text={availabilityLabel} />
            </Text>
          </View>
        )}
      </>
    );
  };

  const renderConfirmation = () => {
    return (
      <View className="feature">
        <IconConfirmation className="icon" />
        <Text className="name">
          <Locale text="business.features.instant_confirmation" />
        </Text>
      </View>
    );
  };

  const renderPaymentInBusiness = () => {
    return (
      <View className="feature">
        <IconPaymentInBusiness className="icon" />
        <Text className="name">
          <Locale text="business.features.payment_in_business" />
        </Text>
      </View>
    );
  };

  return (
    <View className="business-features">
      {renderIsOpen()}
      {renderConfirmation()}
      {renderPaymentInBusiness()}
    </View>
  );
};

export default Features;
