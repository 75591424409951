import './Display.scss';

import {ReactNode} from 'react';
import classNames from 'classnames';

import View from 'components/Common/View';
import Text from 'components/Common/Text';
import Locale from 'components/Common/Locale';

type AnyProps = {[name: string]: any};

type Props = {
  className?: string;
  label?: string;
  labelParams?: AnyProps;
  children?: ReactNode;
};

const BlockInfo = ({label, labelParams, children, className}: Props) => {
  return (
    <View className={classNames('block-info', className)}>
      {label && (
        <Text className="label">
          <Locale text={label} params={labelParams} />
        </Text>
      )}
      {children && <View className="content">{children}</View>}
    </View>
  );
};

export default BlockInfo;
