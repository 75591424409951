import ServiceSnapshotInfo from './ServiceSnapshot';
import ReviewInfo from './Review';
import BusinessInfo from './Business';
import {CurrencyTag} from './Currency';
import {ClientUserStaffInfo} from './BusinessClient';

export enum AppointmentStatus {
  Pending = 'pending',
  Confirmed = 'confirmed',
  Cancelled = 'cancelled',
  Completed = 'completed',
}

export enum AppointmentSource {
  Staff = 'staff',
  User = 'user',
}

export interface AppointmentHoursRequest {
  date: Date;
  date_end: Date;
  notes: string;
}

export interface AppointmentInfo {
  id: string;
  identifier: number;
  minutes_total: number;
  price_total: number;
  date: Date;
  date_end: Date;
  notes: string;
  snapshots: ServiceSnapshotInfo[];
  business: BusinessInfo;
  review: ReviewInfo;
  currency: CurrencyTag;
  client: ClientUserStaffInfo;
  source: AppointmentSource;
  status: AppointmentStatus;
}

export default AppointmentInfo;
