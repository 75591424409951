import './Spacer.scss';

import View from 'components/Common/View';

type Props = {
  size?: string | number;
};

const Spacer = ({size = 20}: Props) => {
  return (
    <View
      className="spacer"
      style={{height: typeof size === 'number' ? `${size}px` : size}}
    />
  );
};

export default Spacer;
