import styles from './Modal.module.scss';

import {useEffect, ReactNode} from 'react';

import {useStore} from 'core/hooks';

import View from 'components/Common/View';

const Modals = () => {
  const modals = useStore<Map<string, ReactNode>>('modals');

  const blockScroll = !!modals.size;
  useEffect(() => {
    document.body.style.overflow = blockScroll ? 'hidden' : 'auto';
  }, [blockScroll]);

  return (
    <>
      {!!modals.size && (
        <View className={styles['modals-list']}>
          {Array.from(modals).map(([key, value]) => (
            <View key={key}>{value}</View>
          ))}
        </View>
      )}
    </>
  );
};

export default Modals;
