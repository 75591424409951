import {HTMLProps} from 'react';

import InputRender, {InputRenderProps} from './Render';

type InputProps<T> = HTMLProps<HTMLInputElement> & InputRenderProps<T>;

const Input = <T,>(props: InputProps<T>) => {
  return <InputRender<T> {...props} render={props => <input {...props} />} />;
};

export default Input;
