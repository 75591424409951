import {useParams as useParamsHook} from 'react-router-dom';

import {usePageParams} from 'components/App/Modal/Page';

const useParams = <T>(): T => ({
  ...useParamsHook<T>(),
  ...usePageParams<T>(),
});

export default useParams;
