import {useCallback, Dispatch, SetStateAction} from 'react';

import {isGroup, doLogout} from 'core/session';

import {useStore} from 'core/hooks';

import UserFullName from 'components/User/FullName';
import UserPhoto from 'components/User/Photo';
import View from 'components/Common/View';
import Text from 'components/Common/Text';
import Link from 'components/Common/Link';
import Button from 'components/Common/Button';
import WithLove from 'components/App/Footer/WithLove';
import Spacer from 'components/Common/Spacer';

import {UserStore} from 'types/Store';

import {ReactComponent as CloseDark} from './Assets/CloseDark.svg';

type MenuProps = {
  setMenuStatus: Dispatch<SetStateAction<boolean>>;
};

const Menu = ({setMenuStatus}: MenuProps) => {
  const user = useStore<UserStore>('user');
  const {
    logged,
    userStatus: {businesses_count},
  } = user;

  const onPressClose = useCallback(() => {
    setMenuStatus(state => !state);
  }, [setMenuStatus]);

  const onPressLogout = useCallback(() => {
    doLogout();
    onPressClose();
  }, [onPressClose]);

  return (
    <View id="app-menu">
      <View className="content base-size horizontal-padding">
        <Button onPress={onPressClose} className="close-button">
          <CloseDark className="image" />
        </Button>

        {logged && (
          <>
            <View className="user-info">
              <UserPhoto user={user.userData} size="large" />
              <Text className="full-name">
                <UserFullName user={user.userData} />
              </Text>
            </View>

            <View className="links">
              {businesses_count !== 0 && (
                <Link
                  onPress={onPressClose}
                  className="link"
                  to="b/me"
                  label="menu.businesses_button_plural"
                  labelParams={{count: businesses_count}}
                />
              )}
              <Link
                onPress={onPressClose}
                className="link"
                to="appointments"
                label="menu.appointments_button"
              />

              <Link
                onPress={onPressClose}
                className="link"
                to="settings"
                label="menu.settings_button"
              />

              {isGroup('admin') && (
                <Link
                  onPress={onPressClose}
                  className="link"
                  to="admin"
                  label="menu.admin_button"
                />
              )}

              <Spacer size={30} />

              {businesses_count === 0 && (
                <Link
                  onPress={onPressClose}
                  className="link"
                  to="b/create"
                  label="menu.create_business"
                />
              )}
            </View>
          </>
        )}

        {logged && (
          <Button
            className="logout-button"
            onPress={onPressLogout}
            label="menu.logout_button"
          />
        )}

        {!logged && (
          <View className="links">
            <Link
              onPress={onPressClose}
              className="link"
              to="explore"
              label="menu.join_user"
            />

            <Spacer size={30} />

            <Link
              onPress={onPressClose}
              className="link"
              to="for-business"
              label="menu.join_business"
            />
          </View>
        )}

        <View className="terms-links">
          <Link
            className="link"
            onPress={onPressClose}
            to="for-business"
            label="footer.for_business"
          />
          <Link
            onPress={onPressClose}
            className="link"
            target="_blank"
            to="static/legal/terms.pdf"
            label="legal.terms_link"
          />
          <Link
            onPress={onPressClose}
            className="link"
            target="_blank"
            to="static/legal/privacy.pdf"
            label="legal.privacy_link"
          />
        </View>

        <View className="footer">
          <WithLove />
        </View>
      </View>
    </View>
  );
};

export default Menu;
