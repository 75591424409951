import './NotFound.scss';

import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';
import Link from 'components/Common/Link';
import Spacer from 'components/Common/Spacer';
import Text from 'components/Common/Text';
import Locale from 'components/Common/Locale';

const NotFound = () => {
  return (
    <Page title="pages.not_found" noAuth padding className="center expand">
      <PageTitle />
      <Text>
        <Locale text="pages.not_found_msg" />
      </Text>
      <Spacer size={20} />
      <Link className="not-found-link" to="/" label="pages.not_found_button" />
    </Page>
  );
};

export default NotFound;
