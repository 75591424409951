import {useCallback} from 'react';

import {doLogin} from 'core/session';

import {
  getIdToken,
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
} from 'firebase/auth';

import Button from 'components/Common/Button';

import {ReactComponent as GoogleLogo} from './Assets/GoogleLogo.svg';

const GoogleSignInButton = () => {
  const onPress = useCallback(async () => {
    try {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const accessToken = await getIdToken(result.user);
      await doLogin(accessToken);
    } catch (e) {
      console.error(e);
    }
  }, []);

  return (
    <Button
      className="login-button google"
      onPress={onPress}
      label="login.google_button"
      icon={<GoogleLogo className="icon" />}
    />
  );
};

export default GoogleSignInButton;
