import {useRef, useState, useEffect, useCallback} from 'react';
import classNames from 'classnames';

import {useIsMobile} from 'hooks';

import View from 'components/Common/View';
import Button from 'components/Common/Button';
import Text from 'components/Common/Text';

import {CategoryServices} from 'types/Category';

type CategoriesHeaderProps = {
  categories: CategoryServices[];
};

const BASE_HEADER_EXTRA = 50 + 75;

const isInViewport = (el: Element) => {
  const {top, /*left,*/ bottom /*right*/} = el.getBoundingClientRect();

  if (
    top - BASE_HEADER_EXTRA < window.innerHeight &&
    bottom - BASE_HEADER_EXTRA >= 0
  ) {
    return true;
  }

  /*return (
    top >= 0 &&
    left >= 0 &&
    bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    right <= (window.innerWidth || document.documentElement.clientWidth)
  );*/
};

const CategoriesHeader = ({categories}: CategoriesHeaderProps) => {
  const isMobile = useIsMobile();
  const isScrollingRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [active, setActive] = useState<string | null>(
    categories.length ? categories[0].category.id : null,
  );
  const scrollRef = useRef<HTMLDivElement | null>(null);

  const onPressLeft = useCallback(() => {
    if (!scrollRef.current) {
      return;
    }

    scrollRef.current.scrollBy({
      top: 0,
      left: -500,
      behavior: 'smooth',
    });
  }, [scrollRef]);
  const onPressRight = useCallback(() => {
    if (!scrollRef.current) {
      return;
    }

    scrollRef.current.scrollBy({
      top: 0,
      left: 500,
      behavior: 'smooth',
    });
  }, [scrollRef]);

  const onPress = useCallback(
    e => {
      if (isScrollingRef.current) {
        clearTimeout(isScrollingRef.current);
      }

      const currentTarget = e.currentTarget;
      const id = currentTarget.dataset.id;
      setActive(id);

      const categoriesHeaderEl = document.querySelector(`.categories-header`);
      if (!categoriesHeaderEl) {
        return;
      }

      isScrollingRef.current = setTimeout(() => {
        const categoryItemEl: HTMLDivElement | null = document.querySelector(
          `#category-item-${id}`,
        );

        if (categoryItemEl) {
          isScrollingRef.current = setTimeout(() => {
            isScrollingRef.current = null;
          }, 500);

          /*
            const marginTop = parseInt(
              window
                .getComputedStyle(categoryItemEl)
                .getPropertyValue('scroll-margin-top'),
            );

            window.scrollTo({
              top: categoryItemEl.offsetTop + marginTop,
              behavior: 'smooth',
            });
          */

          categoryItemEl.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
          });
        } else {
          isScrollingRef.current = null;
        }
      }, 300);

      categoriesHeaderEl.scrollTo({
        left: currentTarget.offsetLeft,
        behavior: 'smooth',
      });
    },
    [setActive],
  );

  const onScroll = useCallback(
    e => {
      if (isScrollingRef.current) {
        return;
      }

      const categoriesHeaderEl = document.querySelector(`.categories-header`);

      if (!categoriesHeaderEl) {
        return;
      }

      for (const {
        category: {id},
      } of categories) {
        const categoryItemEl = document.querySelector(`#category-item-${id}`);

        if (categoryItemEl && isInViewport(categoryItemEl)) {
          const headerCategoryEl: HTMLDivElement | null =
            document.querySelector(`#category-header-button-${id}`);

          if (headerCategoryEl) {
            if (id === active) {
              break;
            }
            setActive(id);
            categoriesHeaderEl.scrollTo({
              left: headerCategoryEl.offsetLeft,
              behavior: 'smooth',
            });
            break;
          }
        }
      }
    },
    [categories, active],
  );

  useEffect(() => {
    document.addEventListener('scroll', onScroll, {passive: true});
    return () => document.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  return (
    <View className={classNames('categories-header-container', 'expand')}>
      {!isMobile && (
        <View onClick={onPressLeft} className={classNames('arrow', 'left')} />
      )}
      <View ref={scrollRef} className="categories-header">
        {categories.map(({category: {id, name}}) => {
          return (
            <Button
              id={`category-header-button-${id}`}
              data-id={id}
              onPress={onPress}
              key={name}
              className={classNames('button', {active: id === active})}>
              <Text className="name">{name}</Text>
            </Button>
          );
        })}
      </View>
      {!isMobile && (
        <View onClick={onPressRight} className={classNames('arrow', 'right')} />
      )}
    </View>
  );
};

export default CategoriesHeader;
