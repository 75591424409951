import {useLocation as useLocationHook} from 'react-router-dom';
import {Location} from 'history';

import {usePageState} from 'components/App/Modal/Page';

const useLocation = <T>(): Location<T> => {
  const modalPageState = usePageState<T>();

  return {
    ...useLocationHook<T>(),
    ...(modalPageState && {state: modalPageState}),
  };
};

export default useLocation;
