import './SpecialDates.scss';

import classNames from 'classnames';

import {useBusiness, useStaffUrl} from 'hooks';

import Text from 'components/Common/Text';
import ItemsList from 'components/Common/ItemsList';
import * as Grid from 'components/Common/Grid';
import Link from 'components/Common/Link';
import DateTime from 'components/Common/DateTime';
import Locale from 'components/Common/Locale';

import BusinessSpecialDateInfo from 'types/BusinessSpecialDate';

type SpecialDatesListRenderProps = {
  specialDate: BusinessSpecialDateInfo;
};

const SpecialDatesList = () => {
  const business = useBusiness();
  const {id: businessId} = business;

  return (
    <ItemsList<BusinessSpecialDateInfo>
      noItemsLabel="staff.settings.special_dates.no_results"
      className="staff-special-dates-list"
      useInfiniteScroll
      source={`special-dates/${businessId}`}>
      {specialDate => <SpecialDatesListRender specialDate={specialDate} />}
    </ItemsList>
  );
};

const SpecialDatesListRender = ({specialDate}: SpecialDatesListRenderProps) => {
  const staffUrl = useStaffUrl();
  const {id, status, date_from, date_to} = specialDate;

  return (
    <Link base={staffUrl({follows: id})} className="special-date-item">
      <Grid.Field className="info status">
        <Text className="label">
          <Locale text="staff.settings.special_dates.field_status" />
        </Text>
        <Text className={classNames('status', status)}>
          <Locale text={`staff.settings.special_dates.${status}_label`} />
        </Text>
      </Grid.Field>

      <Grid.Field className="info from">
        <Text className="label">
          <Locale text="staff.settings.special_dates.field_date_from" />
        </Text>
        <Text className="date">
          <DateTime excludeTime date={date_from} />
        </Text>
      </Grid.Field>

      <Grid.Field className="info from">
        <Text className="label">
          <Locale text="staff.settings.special_dates.field_date_to" />
        </Text>
        <Text className="date">
          <DateTime excludeTime date={date_to} />
        </Text>
      </Grid.Field>
    </Link>
  );
};

export default SpecialDatesList;
