import './ClientsList.scss';

import {useState, useCallback} from 'react';

import {DELETE} from 'utils/Http';

import {
  useFormatUserFullName,
  useBusiness,
  useBusinessUrl,
  useIsBusinessDisabled,
} from 'hooks';

import View from 'components/Common/View';
import Text from 'components/Common/Text';
import ItemsList from 'components/Common/ItemsList';
import * as Grid from 'components/Common/Grid';
import Form from 'components/Form';
import Input from 'components/Form/Input';
import Connect from 'components/Form/Connect';
import DateTime from 'components/Common/DateTime';
import Locale from 'components/Common/Locale';
import Link from 'components/Common/Link';
import DropdownButton, {
  ButtonOption,
  LinkOption,
} from 'components/Common/DropdownButton';

import BusinessInfo from 'types/Business';
import BusinessClientInfo from 'types/BusinessClient';
import {useSetNotification} from 'components/App/Notifications';

type ClientListRenderProps = {
  business: BusinessInfo;
  client: BusinessClientInfo;
};

const ClientsList = () => {
  const business = useBusiness();
  const {id: businessId} = business;

  return (
    <Connect>
      <Form className="filter-form">
        {() => {
          return (
            <>
              <Input
                name="search"
                className="search"
                optional
                label="staff.clients.search_input"
                placeholder="staff.clients.search_placeholder"
                maxLength={40}
              />
            </>
          );
        }}
      </Form>
      <ItemsList<BusinessClientInfo>
        noItemsLabel="staff.clients.no_results"
        className="staff-clients-list"
        useInfiniteScroll
        source={`clients/${businessId}`}>
        {client => <ClientListRender business={business} client={client} />}
      </ItemsList>
    </Connect>
  );
};

const ClientListRender = ({
  business,
  client: clientData,
}: ClientListRenderProps) => {
  const setNotification = useSetNotification();
  const isBusinessDisabled = useIsBusinessDisabled();
  const businessUrl = useBusinessUrl();
  const formatUserFullName = useFormatUserFullName();
  const {id, client_since, client} = clientData;
  const {id: businessId} = business;
  const {type, id: userId, dob, email, phone, phone_prefix} = client;
  const [removed, setRemoved] = useState(false);

  const onPressRemove = useCallback(async () => {
    setRemoved(true);

    await DELETE(`clients/${businessId}/${id}`);

    setNotification({
      type: 'success',
      msg: 'staff.clients.delete_success_msg',
    });
  }, [id, businessId, setRemoved, setNotification]);

  if (removed) {
    return null;
  }

  const clientName = formatUserFullName(client, {includeGender: true});

  return (
    <View className="client-item">
      <Grid.Field className="client">
        <Text className="name">{clientName}</Text>
        {phone && (
          <Text className="phone">
            {phone_prefix} {phone}
          </Text>
        )}
        {email && <Text className="email">{email}</Text>}
      </Grid.Field>

      <Grid.Field className="info">
        <View className="item">
          <Text className="label">
            <Locale text="staff.clients.client_since_label" />:
          </Text>
          <Text className="value">
            <DateTime shortMonth excludeTime date={client_since} />
          </Text>
        </View>
        {dob && (
          <View className="item">
            <Text className="label">
              <Locale text="staff.clients.birthdate_label" />:
            </Text>
            <Text className="value">
              <DateTime shortMonth excludeTime date={dob} />
            </Text>
          </View>
        )}
      </Grid.Field>
      <Grid.Field className="options-field">
        {!isBusinessDisabled && (
          <Link
            className="btn normal reserve-link"
            base={businessUrl({to: 'booking'})}
            label="staff.clients.book_button"
            state={{
              isFromStaff: true,
              userName: clientName,
              [type === 'user' ? 'userId' : 'clientId']: userId,
            }}
          />
        )}
        <DropdownButton icon="options">
          {!isBusinessDisabled && (
            <LinkOption
              follows=":id"
              params={{id}}
              label="staff.clients.edit_button"
            />
          )}
          <ButtonOption
            dialog={{
              title: 'staff.clients.delete_dialog_title',
              ...(type === 'client' && {
                subtitle: 'staff.clients.delete_dialog_subtitle',
              }),
              label: 'staff.clients.delete_dialog_button',
            }}
            label="staff.clients.remove_button"
            onPress={onPressRemove}
          />
        </DropdownButton>
      </Grid.Field>
    </View>
  );
};

export default ClientsList;
