import {useEffect} from 'react';

import {
  useBusiness,
  useTranslation,
  useIsMobile,
  setHeaderLeft,
  setHeaderGradientContent,
  setHeaderContent,
  setHeaderRight,
} from 'hooks';

import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';
import BackButton from 'components/App/Header/BackButton';
import View from 'components/Common/View';
import Spacer from 'components/Common/Spacer';
import Hr from 'components/Common/Hr';
import Locale from 'components/Common/Locale';
import Text from 'components/Common/Text';
import BusinessDetails from 'components/Business/Details';
import BusinessFeatures from 'components/Business/Features';
import BusinessReviews from 'components/Business/Reviews';
import BusinessBottomBar from 'components/Business/BottomBar';
import BusinessPhotosSlider from 'components/Business/PhotosSlider';
import BusinessReserveButton from 'components/Business/ReserveButton';
//import BusinessAddressMap from 'components/Business/AddressMap';

const Profile = () => {
  const isMobile = useIsMobile();
  const {t} = useTranslation();
  const business = useBusiness();
  const {active: isBusinessActive, name, type} = business;

  useEffect(() => {
    if (!isMobile) {
      return;
    }

    setHeaderLeft(<BackButton urlParams={{to: '/'}} />);
    setHeaderRight(() => null);
    setHeaderContent(() => null);

    return () => {
      setHeaderLeft();
      setHeaderRight();
      setHeaderContent();
    };
  }, [isMobile]);

  useEffect(() => {
    if (!isMobile) {
      return;
    }

    setHeaderGradientContent(<BusinessPhotosSlider business={business} />);

    return () => setHeaderGradientContent();
  }, [isMobile, business]);

  const pageTitle = `${name}${
    type ? ` - ${t(`business_types.${type.type}`)}` : ''
  }`;

  const renderNotActive = () => {
    return (
      <Text className="business-not-avaiable">
        <Locale text="business.not_available" />
      </Text>
    );
  };

  const renderMobile = () => {
    return (
      <>
        <PageTitle gradient={`gray gradient-200 over`} empty />

        <View className="base-size padding column">
          <BusinessDetails
            showDescription
            showOpenAddressButton
            business={business}
          />

          <Spacer size={24} />

          <BusinessFeatures business={business} />

          <Spacer size={22} />

          {!isBusinessActive && (
            <>
              {renderNotActive()}
              <Spacer size={22} />
            </>
          )}

          <Hr />

          <Spacer size={20} />

          <BusinessReviews business={business} />

          <Spacer size={30} />
        </View>

        {isBusinessActive && <BusinessBottomBar business={business} />}
      </>
    );
  };

  const renderDesktop = () => {
    return (
      <>
        <View className="blue-background">
          <View className="base-size padding column">
            <Spacer size={40} />
            <View className="row">
              <View className="right-padding column expand">
                <View>
                  <BusinessDetails
                    showDescription
                    showOpenAddressButton
                    business={business}
                  />

                  <Spacer size={24} />

                  <BusinessFeatures business={business} />

                  <Spacer size={24} />

                  {isBusinessActive ? (
                    <BusinessReserveButton business={business} />
                  ) : (
                    renderNotActive()
                  )}

                  {/*<BusinessAddressMap business={business} />*/}
                </View>
              </View>
              <BusinessPhotosSlider business={business} />
            </View>
          </View>
        </View>

        <View className="base-size padding column">
          <Spacer size={20} />

          <BusinessReviews business={business} />

          <Spacer size={30} />
        </View>
      </>
    );
  };

  return (
    <Page title={pageTitle} noTrans className="business-landing-page" noAuth>
      {isMobile ? renderMobile() : renderDesktop()}
    </Page>
  );
};

export default Profile;
