import Text from 'components/Common/Text';
import Locale from 'components/Common/Locale';
import Spacer from 'components/Common/Spacer';

import Button from './Button';

const Admin = () => {
  return (
    <>
      <Spacer size={30} />

      <Text className="name">Admin Panel</Text>

      <Spacer size={35} />

      <Text className="header-label">
        <Locale text="Admin" />
      </Text>

      <Button className="calendar no-border" to="/" label="Home" />

      <Button className="clients" to="users" label="Users" />

      <Button className="appointments" to="businesses" label="Businesses" />

      <Spacer size={30} />

      <Text className="header-label">
        <Locale text="Settings" />
      </Text>
      <Button className="settings no-border" to="settings" label="Settings" />

      <Spacer size={20} />
    </>
  );
};

export default Admin;
