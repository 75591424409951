import {useEffect, useCallback} from 'react';

import {useStore} from 'core/hooks';
import {setHeaderStatus, goToUrl} from 'hooks';

import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';

import Form from 'components/Form';
import Spacer from 'components/Common/Spacer';
import Actions from 'components/Form/Actions';
import Submit from 'components/Form/Submit';
import Redirect from 'components/Common/Redirect';

import {UserProfileInfo, UserStatus} from 'types/User';

import {loadUserStatus} from 'utils/User';
import {doRedirect} from 'utils/Url';

import {
  renderFirstNameField,
  renderLastNameField,
  renderEmailField,
  renderGenderField,
  renderDobField,
  renderPhoneField,
} from './General';

type CompleteProfileFormProps = {
  hideHeader?: boolean;
};

const CompleteProfileForm = ({hideHeader}: CompleteProfileFormProps) => {
  const userStatus = useStore<UserStatus>('userStatus');
  const {fields_to_update} = userStatus;
  //const fields_to_update = ['first_name'];

  const onSuccess = useCallback(() => {
    loadUserStatus();

    userStatus.fields_to_update = [];

    if (!doRedirect()) {
      goToUrl({to: '/'});
    }
  }, [userStatus]);

  useEffect(() => {
    if (!hideHeader) {
      return;
    }

    setHeaderStatus(false);

    return () => setHeaderStatus(true);
  }, [hideHeader]);

  if (!fields_to_update) {
    return null;
  }

  if (!fields_to_update.length) {
    return <Redirect />;
  }

  return (
    <Form<UserProfileInfo> onSuccess={onSuccess} source="user/profile">
      {() => (
        <>
          {fields_to_update.indexOf('first_name') !== -1 &&
            renderFirstNameField()}
          {fields_to_update.indexOf('last_name') !== -1 &&
            renderLastNameField()}
          {fields_to_update.indexOf('email') !== -1 && renderEmailField()}
          {fields_to_update.indexOf('gender') !== -1 && renderGenderField()}
          {fields_to_update.indexOf('dob') !== -1 && renderDobField()}
          {fields_to_update.indexOf('phone') !== -1 && renderPhoneField()}

          {fields_to_update.length > 4 ? (
            <Actions>
              <Submit />
            </Actions>
          ) : (
            <>
              <Spacer size={25} />

              <Submit label="settings.complete_button" />
            </>
          )}
        </>
      )}
    </Form>
  );
};

const Complete = () => {
  return (
    <Page title="settings.complete_title" padding>
      <PageTitle className="center" />

      <CompleteProfileForm />
    </Page>
  );
};

export default Complete;
