import './PhotosSelector.scss';

import {useEffect, useState, useCallback, ChangeEvent} from 'react';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import className from 'classnames';

import {PUT, DELETE, POST} from 'utils/Http';

import {useBusiness, useSetBusiness} from 'hooks';

import View from 'components/Common/View';
import Form from 'components/Form';
import Actions from 'components/Form/Actions';
import Submit from 'components/Form/Submit';
import Source from 'components/Common/Source';
import Loading from 'components/Common/Loading';

import PhotoInfo from 'types/Photo';
import {BusinessStatus} from 'types/Business';

import {parseErrorInfo} from 'utils/Error';

import PhotoItem from './PhotoItem';
import {useSetNotification} from 'components/App/Notifications';

type PhotosSelectorRenderProps = {
  businessId: string;
  photos: PhotoInfo[];
};

const MAX_IMAGES = 4;

const {Pending} = BusinessStatus;

const PhotosSelector = () => {
  const {id: businessId, status} = useBusiness();

  return (
    <Source<PhotoInfo[]> source={`photos/${businessId}`}>
      {photos => (
        <Form
          className="expand"
          onSubmit={({goToUrl}) => {
            if (status === Pending) {
              goToUrl({goBack: 2, follows: 'complete'});
            } else {
              goToUrl({goBack: true});
            }
          }}>
          {() => (
            <>
              <PhotosSelectorRender businessId={businessId} photos={photos} />
              <Actions>
                <Submit label="staff.settings.photos.submit_button" />
              </Actions>
            </>
          )}
        </Form>
      )}
    </Source>
  );
};

const PhotosSelectorRender = ({
  businessId,
  photos: photosProp,
}: PhotosSelectorRenderProps) => {
  const setNotification = useSetNotification();
  const [isDragDisabled, setIsDragDisabled] = useState(false);
  const [isDragging, setIsDraging] = useState(false);
  const [photos, setPhotos] = useState<PhotoInfo[]>(
    photosProp.sort((a, b) => a.order - b.order),
  );
  const [uploading, setUploading] = useState(false);
  const setBusiness = useSetBusiness();

  const onDragStart = useCallback(() => {
    setIsDraging(true);
  }, [setIsDraging]);

  const onDragEnd = useCallback(
    async result => {
      setIsDraging(false);

      const {destination, source} = result;

      if (!destination || !source || destination.index === source.index) {
        return;
      }

      const newItems = [...photos];
      const [photo] = newItems.splice(source.index, 1);
      newItems.splice(destination.index, 0, photo);

      setPhotos(newItems);

      setIsDragDisabled(true);
      await PUT(`photos/${businessId}/${photo.id}/order`, {
        order: destination.index,
      });
      setIsDragDisabled(false);
    },
    [photos, setPhotos, businessId],
  );

  const removePhoto = useCallback(
    (photo: PhotoInfo) => {
      DELETE(`photos/${businessId}/${photo.id}`);

      setPhotos(photos => {
        const newPhotos = [...photos];

        newPhotos.splice(
          newPhotos.findIndex(p => p.id === photo.id),
          1,
        );

        return newPhotos;
      });
    },
    [businessId, setPhotos],
  );

  const addPhoto = useCallback(
    (photo: PhotoInfo) => {
      setPhotos(photos => {
        const newPhotos = [...photos, photo];

        return newPhotos;
      });
    },
    [setPhotos],
  );

  useEffect(() => {
    setBusiness(business => {
      return {...business, photo: photos[0], photos: [...photos]};
    });
  }, [setBusiness, photos]);

  const onPhotoChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files || !e.target.files.length) {
        return;
      }

      const file = e.target.files[0];

      //console.log('onPhotoChange', file);

      const reader = new FileReader();
      reader.readAsDataURL(file);

      setUploading(true);

      reader.onload = async () => {
        const data = reader.result;

        try {
          const photo = await POST(`photos/${businessId}`, {data});

          addPhoto(photo);
        } catch (e) {
          const {msg} = parseErrorInfo(e);

          setNotification({
            type: 'error',
            msg,
          });
        }

        setUploading(false);
      };
      reader.onerror = error => {
        console.log('error', error);
        setUploading(false);
      };
    },
    [businessId, setUploading, addPhoto, setNotification],
  );

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <Droppable droppableId="photos-list" direction="horizontal">
        {provided => (
          <View
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={className('photos-selector', {dragging: isDragging})}>
            {photos.map((photo, idx) => (
              <Draggable
                isDragDisabled={isDragDisabled}
                key={photo.id}
                draggableId={photo.id}
                index={idx}>
                {provided => (
                  <PhotoItem
                    provided={provided}
                    key={photo.id}
                    removePhoto={removePhoto}
                    photo={photo}
                  />
                )}
              </Draggable>
            ))}

            {!isDragging && photos.length < MAX_IMAGES && (
              <View className="photo add-button">
                {uploading && <Loading size="small" />}
                {!uploading && (
                  <label className="add" htmlFor="photo-selector">
                    <input
                      type="file"
                      id="photo-selector"
                      name="photo-selector"
                      onChange={onPhotoChange}
                      accept="image/png, image/jpeg"
                    />
                  </label>
                )}
              </View>
            )}

            {provided.placeholder}
          </View>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default PhotosSelector;
