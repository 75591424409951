import './List.scss';

import {useMemo} from 'react';

import ItemsList from 'components/Common/ItemsList';

import BusinessInfo from 'types/Business';

import BusinessItem from './BusinessItem';

const ListMe = () => {
  const content = useMemo(
    () => (
      <ItemsList<BusinessInfo>
        className="businesses-list"
        source="businesses/me"
        noItemsLabel="business.me_no_results"
        sourceParams={{relations: 'photo'}}
        useInfiniteScroll>
        {business => <BusinessItem useStaffUrl business={business} />}
      </ItemsList>
    ),
    [],
  );

  return content;
};

export default ListMe;
