import {useRef, useCallback, useEffect} from 'react';
import {DateTime} from 'luxon';
import {isGroup} from 'core/session';

import {useBusiness, useForm, useSetBusiness, useScript} from 'hooks';

import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';
import Locale from 'components/Common/Locale';
import Form from 'components/Form';
import Field from 'components/Form/Field';
import Label from 'components/Form/Label';
import Input from 'components/Form/Input';
import Description from 'components/Form/Description';
import Select from 'components/Form/Select';
import TextArea from 'components/Form/TextArea';
import Actions from 'components/Form/Actions';
import Submit from 'components/Form/Submit';
import Remove from 'components/Form/Remove';

import BusinessInfo from 'types/Business';
import {GOOGLE_MAPS_URL} from 'types/MapAddress';

type AddressFieldProps = {
  creation?: boolean;
};

export const renderNameField = () => (
  <Field name="name" label="staff.settings.general.field_name">
    <Label />
    <Input minLength={4} maxLength={64} />
  </Field>
);

export const renderDescriptionField = () => (
  <Field name="description" label="staff.settings.general.field_description">
    <Label />
    <TextArea minLength={10} maxLength={2048} />
  </Field>
);

export const AddressField = ({creation}: AddressFieldProps) => {
  const changedRef = useRef<string | null>(null);
  const placesReady = useScript(GOOGLE_MAPS_URL);
  const {setValue} = useForm();

  const onAddressFocus = useCallback(
    e => {
      if (!changedRef.current) {
        changedRef.current = e.target.value;
        return;
      }

      if (changedRef.current !== e.target.value) {
        setValue('address', '');
      }
    },
    [changedRef, setValue],
  );

  const onAddressBlur = useCallback(
    e => {
      setValue('address', changedRef.current);
    },
    [changedRef, setValue],
  );

  useEffect(() => {
    if (!placesReady) {
      return;
    }

    const input = document.querySelector('#address');

    if (!input) {
      console.log(`Input address not found`);
      return;
    }

    const google = (window as any).google;

    const options = {
      fields: [
        'name',
        'geometry',
        'address_components',
        'formatted_address',
        //'opening_hours',
        //'types',
      ],
      strictBounds: false,
      types: ['geocode', 'establishment'],
    };

    const autocomplete = new google.maps.places.Autocomplete(input, options);

    const onPlaceChange = () => {
      const place = autocomplete.getPlace();

      if (!place) {
        console.log('Returned place found');
        return;
      }

      if (!place?.geometry?.location) {
        console.log('Returned geometry found');
        return;
      }

      if (!place.address_components) {
        console.log('Returned address_components found');
        return;
      }

      //console.log('place', place);

      let country: string | null = null;

      place.address_components.forEach(
        ({short_name, types}: {short_name: string; types: string[]}) => {
          if (!short_name || !types || types.indexOf('country') === -1) {
            return;
          }

          country = short_name;
        },
      );

      if (!country) {
        console.log('Country not found');
        return;
      }

      const address = place.formatted_address;
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();

      changedRef.current = address;

      /*if (creation) {
        const {opening_hours, types} = place;
        if (types && types?.indexOf('establishment') !== -1) {
          setValue('name', place.name);
        }

        setValue(
          'opening_hours',
          opening_hours?.periods ? opening_hours.periods : null,
        );
      }*/

      setValue('address', address);
      setValue('country', country);
      setValue('map_address', {
        address,
        lat,
        lng,
      });
    };

    autocomplete.addListener('place_changed', onPlaceChange);
  }, [placesReady, creation, setValue]);

  return (
    <>
      <Field name="address" label="staff.settings.general.field_address">
        <Label />
        <Input
          placeholder="staff.settings.general.field_address_placeholder"
          onBlur={onAddressBlur}
          onFocus={onAddressFocus}
          minLength={5}
          maxLength={512}
        />
      </Field>
      {creation && (
        <Input optional valueAsObject name="opening_hours" type="hidden" />
      )}
      <Input optional name="country" type="hidden" />
      <Input optional valueAsObject name="map_address" type="hidden" />
    </>
  );
};

export const renderStatusBoolField = () => (
  <Field
    className="inline"
    name="status_bool"
    label="staff.settings.general.field_active">
    <Input type="checkbox" optional />
    <Label />
    <Description>
      <Locale text="staff.settings.general.active_description" />
    </Description>
  </Field>
);

export const renderConcurrencyField = () => (
  <Field name="concurrency" label="staff.settings.general.field_concurrency">
    <Label />
    <Input type="number" min={1} max={10} />
    <Description>
      <Locale text="staff.settings.general.concurrency_description" />
    </Description>
  </Field>
);

export const renderTimezoneField = (defaultValue?: string) => (
  <Field name="timezone" label="staff.settings.general.field_timezone">
    <Label />
    <Select
      source="timezones"
      {...{defaultValue}}
      sourceParser={(options: string[]) => {
        return options.map(option => {
          return {
            name: DateTime.now().setZone(option).toFormat('(ZZ) z'),
            value: option,
          };
        });
      }}
      noTrans
    />
  </Field>
);

const General = () => {
  const {id} = useBusiness();
  const setBusiness = useSetBusiness();

  //placesReady

  return (
    <Page title="staff.settings.general.title" padding>
      <PageTitle className="staff-title center" />

      <Form<BusinessInfo>
        onSuccess={({goToUrl, data}) => {
          setBusiness(business => ({...business, ...data}));
          goToUrl({goBack: true});
        }}
        onRemove={({goToUrl}) => goToUrl({to: 'b/me'})}
        source={`businesses/${id}`}
        sourceParams={{relations: 'city'}}>
        {() => {
          return (
            <>
              {renderStatusBoolField()}
              {renderNameField()}
              {renderDescriptionField()}
              <AddressField />
              {renderConcurrencyField()}
              {renderTimezoneField()}

              <Actions>
                <Submit />
                {isGroup('admin') && <Remove />}
              </Actions>
            </>
          );
        }}
      </Form>
    </Page>
  );
};

export default General;
