import {useTranslation} from 'hooks';

import View from 'components/Common/View';
import Text from 'components/Common/Text';

import {customValidations} from 'utils/Form';

export type ErrorMessage = {
  name: string;
  type: string;
  message: any;
};

type Props = {
  error?: ErrorMessage;
  errors?: ErrorMessage[];
};

const Errors = ({error, errors}: Props) => {
  const {t} = useTranslation();
  const formatMessage = (
    inline: boolean,
    name: string,
    type: string,
    message: any,
  ) => {
    if (customValidations[type]) {
      return t(`form.errors.${type}`, {name, label: t(`field_${name}`)});
    }

    if (type === 'remote') {
      return t([`errors.${message}`, message || 'error']);
    }

    if (type === 'local') {
      return t([`errors.${message}`, message || '']);
    }

    if (typeof message === 'function') {
      return message(inline);
    }

    return type;
  };

  const renderSingleError = () => {
    if (!error) {
      return null;
    }

    const {name, type, message} = error;

    return (
      <View className="inline-error">
        <Text className="text" key={`${name}-${type}`}>
          {formatMessage(true, name, type, message)}
        </Text>
      </View>
    );
  };

  const renderErrors = () => {
    if (!errors || errors.length) {
      return null;
    }

    return (
      <View className="errors-list">
        {errors.map(({name, type, message}) => (
          <Text className="text" key={`${name}-${type}`}>
            - {formatMessage(false, name, type, message)}
          </Text>
        ))}
      </View>
    );
  };

  return (
    <>
      {error && renderSingleError()}
      {errors && renderErrors()}
    </>
  );
};

export default Errors;
