import {useState, useCallback} from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import {POST, PUT} from 'utils/Http';

import {useTranslation, useSourceLoadData, useSetBusiness} from 'hooks';

import appConfig from 'config/app';

import View from 'components/Common/View';
import Form from 'components/Form';
import InfoBox from 'components/Common/InfoBox';
import Locale from 'components/Common/Locale';
import Spacer from 'components/Common/Spacer';
import Actions from 'components/Form/Actions';
import Submit from 'components/Form/Submit';
import CardOnFile from 'components/Membership/CardOnFile';
import CardForm from 'components/Membership/CardForm';

import BusinessInfo from 'types/Business';
import MembershipInfo, {
  MembershipStatus,
  MembershipType,
} from 'types/Membership';
import {useSetNotification} from 'components/App/Notifications';

const {Cancelled} = MembershipStatus;
const {Premium} = MembershipType;

const stripePromise = loadStripe(appConfig.stripe_key);

type PaymentFormProps = {
  onPaymentUpdated?: () => void;
  usePaymentUpdate?: boolean;
  business: BusinessInfo;
  membership: MembershipInfo;
};

const PaymentForm = (props: PaymentFormProps) => {
  const {i18n} = useTranslation();

  return (
    <Elements stripe={stripePromise} options={{locale: i18n.language as any}}>
      <PaymentFormRender {...props} />
    </Elements>
  );
};

const PaymentFormRender = ({
  business,
  membership,
  usePaymentUpdate,
  onPaymentUpdated,
}: PaymentFormProps) => {
  const setNotification = useSetNotification();
  const setBusiness = useSetBusiness();
  const stripe = useStripe();
  const elements = useElements();
  const sourceLoadData = useSourceLoadData();

  const {id: businessId} = business;
  const {card, status, used_trial, is_trial} = membership;

  const [useNewCard, setUseNewCard] = useState(usePaymentUpdate || !card);
  const onPressChangeCard = useCallback(() => {
    setUseNewCard(true);
  }, [setUseNewCard]);

  const isReactivation = !usePaymentUpdate && status === Cancelled;
  const usingTrial = !usePaymentUpdate && !used_trial && !is_trial;

  // 4242424242424242

  const startMembership = useCallback(async () => {
    try {
      await PUT(`memberships/${businessId}/activate`, {
        ...(usingTrial && {use_trial: true}),
      });

      setBusiness(business => ({...business, membership_type: Premium}));

      await sourceLoadData();
    } catch (e) {
      console.warn(e);
      setNotification({
        type: 'error',
        msg: 'membership.paying_card_error',
        duration: 7000,
      });
    }
  }, [businessId, usingTrial, setBusiness, sourceLoadData, setNotification]);

  const processCard = useCallback(async () => {
    if (!stripe || !elements) {
      setNotification({
        type: 'error',
        msg: 'membership.complete_card_error',
      });
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      setNotification({
        type: 'error',
        msg: 'Card not found',
      });
      return;
    }

    // Use your card Element with other Stripe.js APIs
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error || !paymentMethod) {
      console.warn('[error]', error);
    } else {
      console.log('[PaymentMethod]', paymentMethod);

      try {
        await POST<MembershipInfo>(`memberships/${businessId}/save-payment`, {
          pm_id: paymentMethod.id,
        });
      } catch (e) {
        console.warn(e);
        setNotification({
          type: 'error',
          msg: 'membership.adding_card_error',
          duration: 4000,
        });
        return;
      }

      if (usePaymentUpdate) {
        setNotification({
          type: 'success',
          msg: 'membership.payment_updated_msg',
          duration: 4000,
        });

        if (onPaymentUpdated) {
          onPaymentUpdated();
        }
        return;
      }

      await startMembership();

      setNotification({
        type: 'success',
        msg:
          status === Cancelled
            ? 'membership.reactivated_msg'
            : 'membership.activated_msg',
        duration: 4000,
      });
    }
  }, [
    usePaymentUpdate,
    onPaymentUpdated,
    businessId,
    startMembership,
    stripe,
    elements,
    status,
    setNotification,
  ]);

  return (
    <View className="membership-payment-form">
      <Form onSubmit={useNewCard ? processCard : startMembership}>
        {() => (
          <>
            {isReactivation && (
              <InfoBox type="info">
                <Locale text="membership.reactivation_warning" />
              </InfoBox>
            )}

            {usingTrial && (
              <InfoBox type="info">
                <Locale text="membership.init_trial_warning" />
              </InfoBox>
            )}

            <Spacer size={20} />

            {useNewCard ? (
              <CardForm />
            ) : (
              <CardOnFile
                useCheckout
                membership={membership}
                onPressChangeCard={onPressChangeCard}
              />
            )}

            <Actions>
              <Submit
                label={
                  isReactivation
                    ? 'membership.reactivate_button'
                    : usePaymentUpdate
                    ? 'membership.change_button'
                    : 'membership.init_button'
                }
              />
            </Actions>
          </>
        )}
      </Form>
    </View>
  );
};

export default PaymentForm;
