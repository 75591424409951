import './Settings.scss';

import {useBusiness} from 'hooks';

import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';
import Form from 'components/Form';
import Field from 'components/Form/Field';
import Label from 'components/Form/Label';
import Actions from 'components/Form/Actions';
import Submit from 'components/Form/Submit';
import Spacer from 'components/Common/Spacer';
import Locale from 'components/Common/Locale';
import InfoBox from 'components/Common/InfoBox';
import BusinessTypeSelector from 'components/BusinessType/Selector';

import BusinessTypeInfo from 'types/BusinessType';
import {BusinessStatus} from 'types/Business';
import {useSetNotification} from 'components/App/Notifications';

const {Pending} = BusinessStatus;

const General = () => {
  const setNotification = useSetNotification();
  const {id, status} = useBusiness();

  return (
    <Page
      title="staff.settings.types.title"
      padding
      className="base-size business-types-page">
      <PageTitle className="staff-title" />

      <InfoBox type="info">
        <Locale text="staff.settings.types.warning" />
      </InfoBox>

      <Form<{types: BusinessTypeInfo[]}, BusinessTypeInfo[]>
        onSubmit={({values: {types}}) => {
          if (!types || !types.length) {
            setNotification({
              id: 'type-selector',
              type: 'error',
              msg: 'staff.settings.types.error_select',
            });
            return false;
          }

          const main = types.find(type => type.main);

          if (!main) {
            setNotification({
              id: 'type-selector',
              type: 'error',
              msg: 'staff.settings.types.error_require_main',
            });
            return false;
          }
        }}
        onSuccess={({goToUrl}) => {
          if (status === Pending) {
            goToUrl({goBack: 2, follows: 'complete'});
          } else {
            goToUrl({goBack: true});
          }
        }}
        method="PUT"
        sourceParser={data => ({
          types: data,
        })}
        source={`types/${id}`}>
        {({data: {types}}) => (
          <>
            <Field
              name="types"
              label="staff.settings.types.field_business_types">
              <Label className="center" />
              <Spacer size={15} />
              <BusinessTypeSelector selectedTypes={types} />
            </Field>

            <Actions>
              <Submit />
            </Actions>
          </>
        )}
      </Form>
    </Page>
  );
};

export default General;
