import {UserStore} from 'types/Store';

import {getStore} from 'core/hooks';

const CITY_NAME = 'city';

const userStore = getStore<UserStore>('user');

export const getCity = (): string | null => {
  return localStorage.getItem(CITY_NAME) || null;
};

export const setCity = (city: string) => {
  userStore.currentCity = city;
  return localStorage.setItem(CITY_NAME, city);
};
