import './Br.scss';

import View from 'components/Common/View';

type Props = {
  size?: string | number;
};

const Br = ({size = 0}: Props) => {
  return (
    <View
      className="br"
      style={{height: typeof size === 'number' ? `${size}px` : size}}
    />
  );
};

export default Br;
