import {useState, useCallback} from 'react';
import {useFormState} from 'react-hook-form';
import classNames from 'classnames';

import {DELETE} from 'utils/Http';

import {useForm, useIsMounted} from 'hooks';

import Button, {ButtonProps} from 'components/Common/Button';

import {parseErrorInfo} from 'utils/Error';
import {useSetNotification} from 'components/App/Notifications';

type RemoveProps = ButtonProps;

const Remove = ({className, dialog, label, ...rest}: RemoveProps) => {
  const setNotification = useSetNotification();
  const isMounted = useIsMounted();
  const {isSubmitting} = useFormState();
  const [working, setWorking] = useState(false);
  const form = useForm();
  const {
    isCreation,
    goToUrl,
    props: {onRemove, removeMsg, source, sourceId},
  } = form;

  const sendRemoveMsg = useCallback(() => {
    if (!source) {
      return;
    }

    if (typeof removeMsg === 'string' || typeof removeMsg === 'undefined') {
      setNotification({
        type: 'error',
        msg: typeof removeMsg === 'string' ? removeMsg : 'form.changes_removed',
      });
    }
  }, [source, removeMsg, setNotification]);

  const onPress = useCallback(async () => {
    if (isSubmitting || working) {
      return;
    }

    const deleteUrl = `${source}/${sourceId ?? ''}`;

    try {
      setWorking(true);

      const result = await DELETE(deleteUrl);

      sendRemoveMsg();

      if (!isMounted()) {
        return;
      }

      setWorking(false);

      if (onRemove) {
        onRemove(form, result);
      } else {
        goToUrl({goBack: 1});
      }
    } catch (e) {
      setWorking(false);

      const {msg} = parseErrorInfo(e);
      setNotification({
        type: 'error',
        msg: msg,
      });
    }
  }, [
    isSubmitting,
    goToUrl,
    isMounted,
    form,
    onRemove,
    sendRemoveMsg,
    source,
    sourceId,
    setWorking,
    working,
    setNotification,
  ]);

  if (!source || isCreation) {
    return null;
  }

  return (
    <Button
      className={classNames('form-button', 'remove-button', className)}
      label={label || 'form.button_remove'}
      onPress={onPress}
      working={working}
      dialog={{
        label: label || 'form.button_remove',
        labelClass: 'btn remove',
        title: 'form.confirm_remove',
        ...dialog,
      }}
      {...rest}
    />
  );
};

export default Remove;
