import './Grid.css';

import {ReactNode, CSSProperties} from 'react';
import classNames from 'classnames';

import View from 'components/Common/View';

type GridProps = {
  mode?: 'vertical' | 'horizontal';
  children?: ReactNode;
  style?: CSSProperties;
  className?: string;
};

type ItemProps = {
  children?: ReactNode;
  style?: CSSProperties;
  className?: string;
};

type FieldProps = {
  children?: ReactNode;
  style?: CSSProperties;
  className?: string;
};

export const Container = ({
  mode = 'horizontal',
  children,
  style,
  className = '',
}: GridProps) => {
  return (
    <View
      style={style}
      className={`grid grid-container grid-mode-${mode} ${className}`}>
      {children}
    </View>
  );
};

export const Item = ({children, style, className = ''}: ItemProps) => {
  return (
    <View style={style} className={classNames(`grid grid-item`, className)}>
      {children}
    </View>
  );
};

export const Field = ({children, style, className = ''}: FieldProps) => {
  return (
    <View style={style} className={`grid grid-field ${className}`}>
      {children}
    </View>
  );
};
