import {UserStore} from 'types/Store';
import {ExplorerLocation} from 'types/Explorer';

import {getStore} from 'core/hooks';

const LOCATION_INFO = 'location';

const userStore = getStore<UserStore>('user');

export const getLocation = (): ExplorerLocation | null => {
  const location = localStorage.getItem(LOCATION_INFO);

  if (location) {
    try {
      const storedLocation = JSON.parse(location);

      if (
        typeof storedLocation.lat === 'number' &&
        typeof storedLocation.lng === 'number'
      ) {
        return JSON.parse(location);
      }
    } catch (e) {
      console.warn('Error decoding location', e);
    }

    setLocation(null);
  }
  return null;
};

export const setLocation = (location: ExplorerLocation | null) => {
  userStore.currentLocation = location;

  if (!location) {
    localStorage.removeItem(LOCATION_INFO);
    return;
  }

  return localStorage.setItem(LOCATION_INFO, JSON.stringify(location));
};
