import './BusinessType.scss';

import {useEffect, useCallback, useState} from 'react';

import {getStore} from 'core/hooks';

import {useForm} from 'hooks';

import Source from 'components/Common/Source';
import View from 'components/Common/View';
import Input from 'components/Form/Input';

import BusinessTypeInfo, {BusinessTypeResponse} from 'types/BusinessType';

import BusinessTypeItem from './BusinessTypeItem';
import {useSetNotification} from 'components/App/Notifications';

const businessTypesStore = getStore<BusinessTypeResponse[]>('businessTypes');

type SelectorProps = {
  name?: string;
  selectedTypes?: BusinessTypeInfo[];
};

type ListRenderProps = SelectorProps & {
  types: BusinessTypeResponse[];
  selectedTypes?: BusinessTypeInfo[];
};

const Selector = (props: SelectorProps) => {
  return (
    <Source<BusinessTypeResponse[]>
      data={businessTypesStore.length ? businessTypesStore : undefined}
      source="businesses/types">
      {types => <SelectorRender types={types} {...props} />}
    </Source>
  );
};

const SelectorRender = ({
  name,
  selectedTypes: selectedTypesInit,
  types,
}: ListRenderProps) => {
  const setNotification = useSetNotification();
  const {setValue} = useForm();
  const [selectedTypes, setSelectedTypes] = useState<BusinessTypeInfo[]>(
    selectedTypesInit || [],
  );

  const onPress = useCallback(
    type => {
      const newTypes = [...selectedTypes];

      const found = newTypes.findIndex(({type: t}) => t === type);

      if (found !== -1) {
        newTypes.splice(found, 1);
      } else {
        if (newTypes.length >= 4) {
          setNotification({
            id: 'type-selector',
            type: 'error',
            msg: 'staff.settings.types.error_max_types',
          });
          return;
        }

        newTypes.push({
          type,
          main: false,
        });
      }

      newTypes.forEach((type, idx) => {
        type.main = !idx;
      });

      setSelectedTypes(newTypes);
      setValue('types', [...newTypes]);
    },
    [setSelectedTypes, selectedTypes, setValue, setNotification],
  );

  useEffect(() => {
    if (!businessTypesStore.length) {
      businessTypesStore.push(...types);
    }
  }, [types]);

  return (
    <View className="business-types-list">
      {types
        .sort((a, b) => a.order - b.order)
        .map(type => (
          <BusinessTypeItem
            key={type.name}
            onPress={onPress}
            selected={selectedTypes.some(({type: t}) => t === type.name)}
            type={type}
          />
        ))}
      <Input optional valueAsObject name="types" type="hidden" />
    </View>
  );
};

export default Selector;
