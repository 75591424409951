import React /*, {useState, useEffect}*/ from 'react';

//import {GET} from 'core/http';
//import {useStore} from 'core/hooks';

import {useIsMobile} from 'hooks';

import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';
import Locale from 'components/Common/Locale';
import Link from 'components/Common/Link';
import View from 'components/Common/View';
import FloatingButtons, {LinkOption} from 'components/Common/FloatingButton';

//import {UserStatus} from 'types/User';

import BusinessesListMe from 'components/Business/List/Me';

const Me = () => {
  const isMobile = useIsMobile();
  /*const [forced, setForced] = useState(false);
  const {businesses_count} = useStore<UserStatus>('userStatus');

  useEffect(() => {
    if (businesses_count !== 1) {
      return;
    }

    (async () => {
      const businesses = await GET<{slug: string}[]>('businesses/me');

      if (!businesses.length) {
        setForced(true);
        return;
      }

      const [business] = businesses;

      goToUrl({base: staffUrl({}, business)});
    })();
  }, [businesses_count]);

  if (!forced && businesses_count === 1) {
    return null;
  }*/

  return (
    <Page
      authParams={{
        isFromMyBusinesses: true,
      }}
      title="staff.businesses.title"
      padding>
      <PageTitle gradient="gradient-20 dark-blue">
        <View className="expand">
          <Locale text="staff.businesses.title" />
        </View>
        {!isMobile && (
          <Link
            to="/b/create"
            className="btn dark-blue rounded header"
            label="staff.businesses.create"
          />
        )}
      </PageTitle>

      <BusinessesListMe />

      {isMobile && (
        <FloatingButtons>
          <LinkOption to="/b/create" icon="plus" />
        </FloatingButtons>
      )}
    </Page>
  );
};

export default Me;
