import axios from 'axios';

type MethodTypes = 'get' | 'post' | 'delete' | 'patch' | 'put';

type Params = {[name: string]: any};

type RequestResult<T> = {data: T; [name: string]: any};

const doRequest = async <T = any>(
  path: string,
  method: MethodTypes,
  params?: Params,
): Promise<RequestResult<T>> => {
  try {
    const response = await axios[method](path, params);
    return Promise.resolve(response.data as RequestResult<T>);
  } catch (error: any) {
    const errorMsg = (error.response?.data?.error ?? error).toString();
    console.warn(errorMsg, error?.response?.data?.errors);
    return Promise.reject({
      error: errorMsg,
      ...error?.response?.data,
      request: error,
    });
  }
};

export const GET = async <T>(path: string, params?: Params): Promise<T> => {
  return (await GETRaw<T>(path, params)).data;
};

export const GETRaw = async <T>(
  path: string,
  params?: Params,
): Promise<RequestResult<T>> => {
  return await doRequest<T>(path, 'get', params && {params});
};

export const POST = async <T = any>(
  path: string,
  params?: Params,
): Promise<T> => {
  return (await POSTRaw<T>(path, params)).data;
};

export const POSTRaw = async <T = any>(
  path: string,
  params?: Params,
): Promise<RequestResult<T>> => {
  return await doRequest<T>(path, 'post', params);
};

export const DELETE = async <T = any>(
  path: string,
  params?: Params,
): Promise<T> => {
  return (await doRequest<T>(path, 'delete', params)).data;
};

export const PUT = async <T = any>(
  path: string,
  params?: Params,
): Promise<T> => {
  return (await PUTRaw(path, params)).data;
};

export const PUTRaw = async <T = any>(
  path: string,
  params?: Params,
): Promise<RequestResult<T>> => {
  return await doRequest<T>(path, 'put', params);
};

export const PATCH = async <T = any>(
  path: string,
  params?: Params,
): Promise<T> => {
  return (await PATCHRaw(path, params)).data;
};

export const PATCHRaw = async <T = any>(
  path: string,
  params?: Params,
): Promise<RequestResult<T>> => {
  return await doRequest<T>(path, 'patch', params);
};
