import {Route, Switch} from 'react-router-dom';

import {useParams} from 'hooks';

import Auth from 'components/Auth';
import Source from 'components/Common/Source';
import View from 'components/Common/View';

import NotFound from 'pages/NotFound';

import {STAFF_PATH} from 'types/Business';

import ViewPage from './View';
import Time from './Time';

const Appointment = () => {
  const {appointment_id} = useParams<{appointment_id: string}>();

  return (
    <Auth>
      <View className="base-size expand">
        <Source source={`appointments/${appointment_id}`}>
          {() => (
            <Switch>
              <Route
                exact
                path={`${STAFF_PATH}/(appointments|calendar/appointment)/:appointment_id`}
                component={ViewPage}
              />
              <Route
                exact
                path={`${STAFF_PATH}/(appointments|calendar/appointment)/:appointment_id/time`}
                component={Time}
              />
              <Route component={NotFound} />
            </Switch>
          )}
        </Source>
      </View>
    </Auth>
  );
};

export default Appointment;
