import './PhotosSlider.scss';

import Image from 'components/Common/Image';

import PhotoInfo from 'types/Photo';

type Props = {
  photo: PhotoInfo;
};

const PhotoItem = ({photo}: Props) => {
  const {url} = photo;

  return <Image animIn className="photo-item" src={url} />;
};

export default PhotoItem;
