import {useRef, useState, useEffect} from 'react';
//import {DateTime} from 'luxon';
import classNames from 'classnames';

import {useStaffUrl} from 'hooks';

import View from 'components/Common/View';
import Link from 'components/Common/Link';
import Text from 'components/Common/Text';
import UserFullName from 'components/User/FullName';
//import Modal from 'components/Modal';

//import {renderAppointmentContent} from 'pages/Businesses/Business/Staff/Appointments/Appointment/View';

import BusinessInfo from 'types/Business';
import {TIME_DISPLAY_SHORT} from 'types/Date';
import AppointmentInfo from 'types/Appointment';
import {convertToDateTime, getUTCFromTimezone} from 'utils/DateTime';

import {dateToPosition} from './index';

type AppointmentItemProps = {
  business: BusinessInfo;
  appointment: AppointmentInfo;
  appointments: AppointmentInfo[];
};

const AppointmentItem = ({
  business,
  appointment,
  appointments,
}: AppointmentItemProps) => {
  const staffUrl = useStaffUrl();
  const timerRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const [, setUpdate] = useState(false);
  //const [visible, setVisible] = useState(false);

  const {timezone} = business;
  const {id, client, status, date, date_end} = appointment;

  const fromDate = convertToDateTime(date);
  const toDate = convertToDateTime(date_end);

  const nowDate = getUTCFromTimezone(timezone);

  /*console.log(
    '\n',
    fromDate.toString(),
    '\n',
    toDate.toString(),
    '\n',
    nowDate.toString(),
  );*/

  let myAppointments: AppointmentInfo[] = [];
  let type: 'past' | 'current' | 'future' = 'future';
  let startIndex = 0;
  let adjacentItems = 0;
  let startOffset = 0;

  const pastAppointments = () =>
    appointments.filter(({date_end}) => {
      const toDate = convertToDateTime(date_end);

      return toDate < nowDate;
    });

  const currentAppointments = () =>
    appointments.filter(({date, date_end}) => {
      const fromDate = convertToDateTime(date);
      const toDate = convertToDateTime(date_end);

      return fromDate <= nowDate && toDate >= nowDate;
    });

  const futureAppointments = () =>
    appointments.filter(({date}) => {
      const fromDate = convertToDateTime(date);

      return fromDate > nowDate;
    });

  // Past
  if (toDate < nowDate) {
    const currentApps = currentAppointments();
    const futureApps = futureAppointments();

    type = 'past';

    if (futureApps.length || currentApps.length) {
      myAppointments = pastAppointments().reverse();
    } else {
      myAppointments = pastAppointments().reverse();
    }

    if (currentApps.length) {
      if (!futureApps.length) {
        startOffset =
          (100 / (currentApps.length + myAppointments.length)) *
          currentApps.length;
      } else {
        startOffset =
          (100 / (currentApps.length + futureApps.length)) * currentApps.length;
      }
    }
  }

  // Current
  if (fromDate <= nowDate && toDate >= nowDate) {
    const futureApps = futureAppointments();
    const pastApps = pastAppointments();

    type = 'current';
    adjacentItems = futureApps.length || pastApps.length;

    myAppointments = currentAppointments();
  }

  // Future
  if (fromDate > nowDate) {
    const currentApps = currentAppointments();

    type = 'future';
    startIndex = currentApps.length;
    adjacentItems = currentApps.length;

    myAppointments = futureAppointments();
  }

  /*console.log(
    pastAppointments.length,
    currentAppointments.length,
    futureAppointments.length,
  );*/

  //const totalCount = myAppointments.length;

  const index = myAppointments.findIndex(e => e.id === appointment.id);
  const rowCount = myAppointments.length;

  const top = dateToPosition(fromDate);
  const bottom = dateToPosition(toDate);
  const height = bottom - top;

  const baseWidth = (100 - startOffset) / (rowCount + adjacentItems);

  //console.log('rowCount', baseWidth, startOffset);

  const left = (startIndex + index) * baseWidth;

  //console.log('info', {index, rowCount, totalCount, left});

  const styles = {
    top: `${top}px`,
    height: `${height}px`,

    width: `${baseWidth}%`,
    left: `${left + startOffset}%`,
  };

  useEffect(() => {
    timerRef.current = setInterval(() => setUpdate(state => !state), 1000);
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [timerRef]);

  return (
    <>
      <View
        style={styles}
        className={classNames('appointment-item', type, status)}>
        <Link
          base={staffUrl({to: 'calendar/appointment/:id', params: {id}})}
          className="content">
          <Text className="time">
            {fromDate.toFormat(TIME_DISPLAY_SHORT)} -{' '}
            {toDate.toFormat(TIME_DISPLAY_SHORT)}
          </Text>
          <Text className="label">
            <UserFullName includeGender user={client} />
          </Text>
        </Link>
      </View>
      {/*visible && (
        <Modal setClosed={setVisible}>
          {renderAppointmentContent(appointment)}
        </Modal>
      )*/}
    </>
  );
};

export default AppointmentItem;
