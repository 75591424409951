import {Days} from './Date';

export enum BusinessHoursStatus {
  Closed = 'closed',
  Opened = 'opened',
}

export interface BusinessHoursInfo {
  id: string;
  day: Days;
  start_minute: number;
  end_minute: number;
}

export default BusinessHoursInfo;
