import {useIsMobile} from 'hooks';

import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';
import FloatingButtons, {LinkOption} from 'components/Common/FloatingButton';
import Locale from 'components/Common/Locale';
import Link from 'components/Common/Link';
import View from 'components/Common/View';
import SpecialDatesList from 'components/Staff/SpecialDatesList';

const Items = () => {
  const isMobile = useIsMobile();

  return (
    <Page title="staff.settings.special_dates.title" horizontalPadding>
      <PageTitle className="staff-title top-padding">
        <View className="expand">
          <Locale text="staff.settings.special_dates.title" />
        </View>
        {!isMobile && (
          <Link
            follows="add"
            className="btn dark-blue rounded header"
            label="staff.settings.special_dates.new_date"
          />
        )}
      </PageTitle>
      {isMobile && (
        <FloatingButtons>
          <LinkOption icon="plus" follows="add" />
        </FloatingButtons>
      )}
      <SpecialDatesList />
    </Page>
  );
};

export default Items;
