import './Text.scss';

import {forwardRef, ReactNode, CSSProperties} from 'react';
import classNames from 'classnames';

import View from 'components/Common/View';

export type TextProps = {
  children?: ReactNode;
  style?: CSSProperties;
  className?: string;

  color?: string;
  italic?: boolean;
  overline?: boolean;
  underline?: boolean;

  // Props for font weight
  thin?: boolean;
  light?: boolean;
  regular?: boolean;
  semiBold?: boolean;
  bold?: boolean;
  extraBold?: boolean;
  black?: boolean;

  // Font sizes
  small?: boolean;
  xsmall?: boolean;
  medium?: boolean;
  xmedium?: boolean;
  large?: boolean;
  xlarge?: boolean;
  huge?: boolean;
  xhuge?: boolean;

  // Props for align
  center?: boolean;
  auto?: boolean;
  right?: boolean;
  justify?: boolean;

  // Custom sets
  contentTitle?: boolean;
};

const Text = forwardRef<HTMLDivElement, TextProps>(
  (
    {
      children,
      style,
      className = '',

      color,
      italic,
      overline,
      underline,

      small,
      xsmall,
      medium,
      xmedium,
      large,
      xlarge,
      huge,
      xhuge,

      thin,
      light,
      regular,
      semiBold,
      bold,
      extraBold,
      black,

      center,
      auto,
      right,
      justify,

      contentTitle,

      ...props
    },
    ref,
  ) => {
    const styles = {
      ...style,
    };

    if (color) styles.color = color;
    if (italic) styles.color = 'italic';
    if (italic) styles.fontStyle = 'italic';
    if (overline) styles.textDecoration = 'overline';
    if (underline) styles.textDecoration = 'underline';

    if (thin) styles.fontWeight = 300;
    if (regular) styles.fontWeight = 400;
    if (semiBold) styles.fontWeight = 500;
    if (bold) styles.fontWeight = 700;
    if (extraBold) styles.fontWeight = 800;
    if (black) styles.fontWeight = 900;

    if (small) styles.fontSize = '12px';
    if (xsmall) styles.fontSize = '14px';
    if (medium) styles.fontSize = '16px';
    if (xmedium) styles.fontSize = '18px';
    if (large) styles.fontSize = '18px';
    if (xlarge) styles.fontSize = '20px';
    if (huge) styles.fontSize = '24px';
    if (xhuge) styles.fontSize = '26px';

    if (center) styles.textAlign = 'center';
    if (right) styles.textAlign = 'right';
    if (justify) styles.textAlign = 'justify';

    return (
      <View
        ref={ref}
        className={classNames('text', className, {
          'content-title': contentTitle,
        })}
        style={styles}
        {...props}>
        {children}
      </View>
    );
  },
);

export default Text;
