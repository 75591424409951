import './Reviews.scss';

import {useMemo} from 'react';

import ItemsList from 'components/Common/ItemsList';

import View from 'components/Common/View';
import Text from 'components/Common/Text';
import Locale from 'components/Common/Locale';

import BusinessInfo from 'types/Business';
import ReviewInfo from 'types/Review';

import ReviewItem from './ReviewItem';

type Props = {
  excludeTitle?: boolean;
  business: BusinessInfo;
};

const Reviews = ({business, excludeTitle}: Props) => {
  const {id, rating_count} = business;

  const reviewsContent = useMemo(
    () =>
      rating_count && (
        <ItemsList<ReviewInfo>
          className="reviews-list"
          source={`businesses/${id}/reviews`}
          useInfiniteScroll>
          {review => <ReviewItem review={review} />}
        </ItemsList>
      ),
    [rating_count, id],
  );

  if (!rating_count) {
    return null;
  }

  return (
    <View className="business-reviews">
      {!excludeTitle && (
        <Text className="title">
          <Locale
            text="business.reviews_title"
            params={{count: rating_count}}
          />
        </Text>
      )}
      {reviewsContent}
    </View>
  );
};

export default Reviews;
