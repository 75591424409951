import {Route, Switch} from 'react-router-dom';

import NotFound from 'pages/NotFound';

import List from './List';
import User from './User';

import {ADMIN_PATH} from 'types/Admin';

const Users = () => {
  return (
    <Switch>
      <Route exact path={`${ADMIN_PATH}/users`} component={List} />
      <Route path={`${ADMIN_PATH}/users/:id`} component={User} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Users;
