export enum CurrencyTag {
  USD = 'USD',
  MXN = 'MXN',
  PEN = 'PEN',
  COL = 'COL',
  CLP = 'CLP',
  EUR = 'EUR',
}

export type CurrencyFormat = 'short' | 'explicit';
