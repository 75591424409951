import './Header.scss';

import {useEffect, useMemo, useState, useCallback} from 'react';
import classNames from 'classnames';

import {useLocation} from 'hooks';

import {useStore, getStore} from 'core/hooks';

import NodeRender, {NodeType} from 'components/Common/NodeRender';
import View from 'components/Common/View';
import Button from 'components/Common/Button';
import Link from 'components/Common/Link';
import SearchForm from 'components/Explorer/SearchForm';
import SearchButton from 'components/Explorer/SearchButton';

import {useIsMobile} from 'hooks';
import {UIStore} from 'types/Store';

import {ReactComponent as LogoDark} from './Assets/LogoDark.svg';
import {ReactComponent as LogoLight} from './Assets/LogoLight.svg';
import {ReactComponent as MenuDark} from './Assets/MenuDark.svg';
import {ReactComponent as MenuLight} from './Assets/MenuLight.svg';

import Menu from './Menu';

type HeaderTheme = 'light' | 'dark';

const uiStore = getStore<UIStore>('ui');

const TOP_OFFSET = 20;

const Header = () => {
  const isMobile = useIsMobile();
  const {pathname} = useLocation();
  const [menuStatus, setMenuStatus] = useState(false);
  const [isOntop, setIsOnTop] = useState(false);

  const {
    logged,
    loaded,
    headerContent,
    headerLeft,
    headerRight,
    headerTransparent,
    headerClassName,
    headerGradient,
    headerGradientContent,
    headerTheme,
    hideHeaderCount,
  } = useStore(
    'logged',
    'loaded',
    'headerContent',
    'headerLeft',
    'headerRight',
    'headerTransparent',
    'headerClassName',
    'headerGradient',
    'headerGradientContent',
    'headerTheme',
    'hideHeaderCount',
  );

  const onScroll = useCallback(
    e => {
      setIsOnTop(window.pageYOffset < TOP_OFFSET);
    },
    [setIsOnTop],
  );

  useEffect(() => {
    document.addEventListener('scroll', onScroll, {passive: true});

    setIsOnTop(window.pageYOffset < TOP_OFFSET);

    return () => document.removeEventListener('scroll', onScroll);
  }, [onScroll, setIsOnTop]);

  //const isHome = pathname === '/';

  useEffect(() => {
    setMenuStatus(state => (state ? false : state));
  }, [pathname, setMenuStatus]);

  const onPressMenu = useCallback(() => {
    setMenuStatus(state => !state);
  }, [setMenuStatus]);

  const renderHeaderContent = useCallback(() => {
    if (headerContent !== null) {
      return <NodeRender node={headerContent} />;
    }

    return null;
  }, [headerContent]);

  const renderHeaderLeft = useCallback(() => {
    if (headerLeft !== null) {
      return <NodeRender node={headerLeft} />;
    }

    return (
      <>
        <Link className="logo">
          <LogoDark className="image dark" />
          <LogoLight className="image light" />
        </Link>
        {!isMobile && <SearchForm />}
      </>
    );
  }, [isMobile, headerLeft]);

  const renderHeaderRight = useCallback(() => {
    if (headerRight !== null) {
      return <NodeRender node={headerRight} />;
    }

    return (
      <View className="header-right">
        {!headerContent && (
          <View className="links">
            {!logged && (
              <Link className="link" to="login" label="menu.login_button" />
            )}
          </View>
        )}

        {isMobile && <SearchButton />}

        <Button className="menu-button" onPress={onPressMenu}>
          <MenuDark className="image dark" />
          <MenuLight className="image light" />
        </Button>
      </View>
    );
  }, [logged, isMobile, headerContent, headerRight, onPressMenu]);

  const content = useMemo(
    () => (
      <>
        {headerGradient && (
          <View
            className={classNames('header-gradient show-in', {
              [headerGradient]: headerGradient,
            })}>
            <NodeRender node={headerGradientContent} />
          </View>
        )}
        <View
          id="app-header"
          className={classNames({
            transparent: headerTransparent,
            [headerTheme]: headerTheme,
            [headerClassName]: !!headerClassName,
            'is-not-on-top': !isOntop,
          })}>
          <View className="content base-size">
            {renderHeaderLeft()}
            {renderHeaderContent()}
            {loaded && renderHeaderRight()}
          </View>
        </View>
        {menuStatus && <Menu setMenuStatus={setMenuStatus} />}
      </>
    ),
    [
      loaded,
      menuStatus,
      setMenuStatus,
      renderHeaderContent,
      renderHeaderLeft,
      renderHeaderRight,
      headerTransparent,
      headerTheme,
      headerClassName,
      headerGradient,
      headerGradientContent,
      isOntop,
    ],
  );

  if (hideHeaderCount) {
    return null;
  }

  return <>{content}</>;
};

export const setHeaderContent = (element: NodeType = null) => {
  uiStore.headerContent = element;
};

export const setHeaderLeft = (element: NodeType = null) => {
  uiStore.headerLeft = element;
};

export const setHeaderRight = (element: NodeType = null) => {
  uiStore.headerRight = element;
};

export const setHeaderTransparent = (status = false) => {
  uiStore.headerTransparent = status;
};

export const setHeaderTheme = (theme: HeaderTheme = 'dark') => {
  uiStore.headerTheme = theme;
};

export const setHeaderClassName = (className = '') => {
  uiStore.headerClassName = className;
};

export const setHeaderGradient = (gradient: string | null = null) => {
  uiStore.headerGradient = gradient;
};

export const setHeaderGradientContent = (content: NodeType = null) => {
  uiStore.headerGradientContent = content;
};

export const setHeaderStatus = (status = false) => {
  if (status) {
    uiStore.hideHeaderCount++;
  } else {
    uiStore.hideHeaderCount--;
  }
};

export default Header;
