import {useEffect} from 'react';

import {useIsMobile, setHeaderLeft, setHeaderContent} from 'hooks';

import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';
import View from 'components/Common/View';
import ExplorerList from 'components/Explorer/List';
import BackButton from 'components/App/Header/BackButton';

import SearchInput from 'components/Explorer/SearchInput';
import LocationSelector from 'components/Explorer/LocationSelector';

const Explore = () => {
  const isMobile = useIsMobile();
  //const {t} = useTranslation();

  useEffect(() => {
    if (!isMobile) {
      return;
    }

    setHeaderLeft(<BackButton urlParams={{to: '/'}} />);
    setHeaderContent(
      <View className="explorer-header">
        <LocationSelector />
      </View>,
    );

    return () => {
      setHeaderContent();
      setHeaderLeft();
    };
  }, [isMobile]);

  return (
    <Page noAuth title="explore.title" horizontalPadding>
      {isMobile && (
        <PageTitle gradient={`blue gradient-20 no-style`}>
          <View className="explorer-header">
            <SearchInput />
          </View>
        </PageTitle>
      )}

      <ExplorerList />
    </Page>
  );
};

export default Explore;
