import {useEffect, useMemo} from 'react';
import {getAnalytics, logEvent} from 'firebase/analytics';

import {useLocation} from 'hooks';

const AnalyticsTracker = () => {
  if (process.env.NODE_ENV === 'test') {
    return null;
  }

  return <AnalyticsTrackerRender />;
};

const AnalyticsTrackerRender = () => {
  const analytics = useMemo(() => getAnalytics(), []);
  const {pathname, search} = useLocation();

  const reportPath = `${pathname}${search}`;

  useEffect(() => {
    logEvent(analytics, 'screen_view');
  }, [reportPath, analytics]);

  return null;
};

export default AnalyticsTracker;
