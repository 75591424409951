import styles from './Modal.module.scss';

import {createContext, useContext, useCallback, ReactNode} from 'react';
import {useParams, useLocation} from 'react-router-dom';

import {useSetModalClosed} from 'hooks';

import {UseSetModalClosed} from 'components/App/Modal';

import Modal from 'components/App/Modal';
import View from 'components/Common/View';
import Button from 'components/Common/Button';

export type PageContextProps = {
  params: any;
  state: any;
};

const PageContext = createContext<PageContextProps>({
  params: {},
  state: undefined,
});

type PageRenderProps = {
  children?: ReactNode;
};

type PageProps = PageRenderProps & {
  setClosed?: UseSetModalClosed;
};

const Page = ({setClosed, ...props}: PageProps) => {
  const params = useParams<any>();
  const {state} = useLocation<any>();

  const onSetClosed = useCallback(
    (result: boolean) => {
      if (setClosed) {
        setClosed(result);
      }
    },
    [setClosed],
  );

  return (
    <Modal className="modal-page-container" setClosed={onSetClosed}>
      <PageContext.Provider value={{params, state}}>
        <PageRender {...props} />
      </PageContext.Provider>
    </Modal>
  );
};

const PageRender = ({children}: PageRenderProps) => {
  const setModalClosed = useSetModalClosed();

  const onPressClose = useCallback(() => {
    if (setModalClosed) {
      setModalClosed();
    }
  }, [setModalClosed]);

  return (
    <View className="modal-page">
      <View className="header">
        <Button
          onPress={onPressClose}
          className={styles['close-button']}
          label="common.modal_close"
        />
      </View>
      <View className={styles.content}>{children}</View>
    </View>
  );
};

export const usePageParams = <T,>(): T => {
  return useContext(PageContext).params as T;
};

export const usePageState = <T,>(): T => {
  return useContext(PageContext).state as T;
};

export default Page;
