import {useState, useEffect, useCallback} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {Provider} from 'react-redux';
import {initializeApp} from 'firebase/app';
import classNames from 'classnames';

import 'utils/Lang';

import {initSession} from 'core/session';
import appConfig from 'config/app';

import {useStore} from 'core/hooks';
import {useIsMobile} from 'hooks';

import Home from 'pages';

import Header from 'components/App/Header';
import Footer from 'components/App/Footer';
import Modals from 'components/App/Modal/Modals';
import Error from 'components/Common/Error';
import ErrorBoundary from 'components/Common/Error/Boundary';
import View from 'components/Common/View';
import GoToUrl from 'components/Common/GoToUrl';
import Notifications from 'components/App/Notifications';
import UserStatusCheck from 'components/User/StatusCheck';
import AnalyticsTracker from 'components/AnalyticsTracker';

import store from 'store';

// Init Firebase
initializeApp(appConfig.firebase);

// Init Google Analytics
//ReactGA.initialize(appConfig.ga_id);

const App = () => {
  const isMobile = useIsMobile();
  const loaded = useStore<{loaded: boolean}>('loaded');
  const [errorInfo, setErrorInfo] = useState<any | null>(null);

  const initApp = useCallback(async () => {
    try {
      await initSession();
    } catch (e) {
      setErrorInfo(e);
    }
  }, [setErrorInfo]);

  useEffect(() => {
    if (!loaded) {
      initApp();
    }
  }, [loaded, initApp]);

  return (
    <Provider store={store}>
      <ErrorBoundary>
        <BrowserRouter>
          <View
            id="app"
            className={classNames({mobile: isMobile, desktop: !isMobile})}>
            <Notifications />
            {loaded && <UserStatusCheck />}
            <GoToUrl />
            <AnalyticsTracker />

            <Header />
            <View id="app-content">
              {errorInfo ? (
                <Error info={errorInfo} />
              ) : (
                loaded && (
                  <Switch>
                    <Route exact path="/*" component={Home} />
                  </Switch>
                )
              )}
            </View>
            <Footer />

            <Modals />
          </View>
        </BrowserRouter>
      </ErrorBoundary>
    </Provider>
  );
};

export default App;
