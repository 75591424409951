import './List.scss';

import {useIsMobile, useStaffUrl} from 'hooks';

import View from 'components/Common/View';
import Text from 'components/Common/Text';
import ItemsList from 'components/Common/ItemsList';
import * as Grid from 'components/Common/Grid';
import Form from 'components/Form';
import Input from 'components/Form/Input';
import Field from 'components/Form/Field';
import Label from 'components/Form/Label';
import Select from 'components/Form/Select';
import Connect from 'components/Form/Connect';
import DateTime from 'components/Common/DateTime';
import Image from 'components/Common/Image';
import Price from 'components/Common/Price';
import Locale from 'components/Common/Locale';
import Link from 'components/Common/Link';
import Status from 'components/Appointment/Status';
import UserFullName from 'components/User/FullName';

import AppointmentInfo, {AppointmentStatus} from 'types/Appointment';
import {BUSINESS_PHOTO_LIST_SIZE} from 'types/Business';

const {Cancelled, Completed, Confirmed} = AppointmentStatus;

type Props = {
  source?: string;
  showAsStaff?: boolean;
};

const List = ({source, showAsStaff}: Props) => {
  const staffUrl = useStaffUrl();
  const isMobile = useIsMobile();

  const renderUser = ({client}: AppointmentInfo) => {
    const {phone, phone_prefix, email} = client;

    return (
      <>
        {showAsStaff && (
          <Grid.Field className="client">
            <Text className="name">
              <UserFullName includeGender={showAsStaff} user={client} />
            </Text>
            {phone && (
              <Text className="phone">
                {phone_prefix} {phone}
              </Text>
            )}
            {email && <Text className="email">{email}</Text>}
          </Grid.Field>
        )}
      </>
    );
  };

  const renderBusiness = ({
    business: {name, photo, address},
  }: AppointmentInfo) => (
    <>
      {!showAsStaff && (
        <Grid.Field className="business">
          {photo && (
            <Image
              className="photo"
              src={photo.url}
              params={BUSINESS_PHOTO_LIST_SIZE}
            />
          )}
          <View className="column">
            <Text className="name">{name}</Text>
            {address && <Text className="address">{address}</Text>}
          </View>
        </Grid.Field>
      )}
    </>
  );

  const renderStatus = (appointment: AppointmentInfo) => {
    const {date} = appointment;

    return (
      <Grid.Field className="date-status">
        <Text className="date">
          <DateTime shortMonth date={date} />
        </Text>
        <Status showAsTag excludeIcon appointment={appointment} />
      </Grid.Field>
    );
  };

  const renderInfo = ({snapshots, price_total}: AppointmentInfo) => (
    <Grid.Field className="info">
      <View className="item">
        <Text className="label">
          <Locale text="appointments.services_label" />:
        </Text>
        <Text className="count">{snapshots.length}</Text>
      </View>
      <View className="item">
        <Text className="label">
          <Locale text="appointments.total_label" />:
        </Text>
        <Text className="total">
          <Price useFree amount={price_total} />
        </Text>
      </View>
    </Grid.Field>
  );

  const renderItems = () => {
    return (
      <ItemsList<AppointmentInfo>
        noItemsLabel="appointments.no_results"
        className="appointments-list"
        useInfiniteScroll
        source={source || 'appointments'}>
        {appointment => {
          const {id} = appointment;

          return (
            <Link
              className="appointment-item"
              {...(showAsStaff && {
                base: staffUrl({to: 'appointments/:id', params: {id}}),
              })}
              {...(!showAsStaff && {to: `appointments/:id`, params: {id}})}>
              {isMobile ? (
                <>
                  {renderStatus(appointment)}

                  {renderUser(appointment)}

                  {renderBusiness(appointment)}

                  {renderInfo(appointment)}
                </>
              ) : (
                <>
                  {renderUser(appointment)}

                  {renderStatus(appointment)}

                  {renderBusiness(appointment)}

                  {renderInfo(appointment)}
                </>
              )}
            </Link>
          );
        }}
      </ItemsList>
    );
  };

  return (
    <>
      {!showAsStaff ? (
        renderItems()
      ) : (
        <Connect>
          {showAsStaff && (
            <Form className="filter-form">
              {() => {
                return (
                  <>
                    <Input
                      className="search"
                      name="search"
                      optional
                      label="staff.appointments.search_input"
                      placeholder="staff.appointments.search_placeholder"
                      maxLength={40}
                    />

                    <Field name="status">
                      <Label label="common.filter_by" />
                      <Select
                        options={[
                          {
                            name: 'appointments.select_status_confirmed',
                            value: Confirmed,
                          },
                          {
                            name: 'appointments.select_status_completed',
                            value: Completed,
                          },
                          {
                            name: 'appointments.select_status_cancelled',
                            value: Cancelled,
                          },
                        ]}
                      />
                    </Field>
                  </>
                );
              }}
            </Form>
          )}
          {renderItems()}
        </Connect>
      )}
    </>
  );
};

export default List;
