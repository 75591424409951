import View from 'components/Common/View';
import Link from 'components/Common/Link';
import Field from 'components/Form/Field';
import Input from 'components/Form/Input';
import Description from 'components/Form/Description';
import Label from 'components/Form/Label';
import Select from 'components/Form/Select';

import {PhonePrefix} from 'types/Phone';

import {resolveFlagEmoji} from 'utils/Intl';

type PhoneFieldsProps = {
  useFakeFields?: boolean;
  useVerification?: boolean;
  required?: boolean;
};

const PhoneFields = ({
  required: requiredProp = true,
  useFakeFields = false,
}: PhoneFieldsProps) => {
  const required = useFakeFields ? false : requiredProp;

  return (
    <View className="expand column">
      <Label htmlFor="phone" label="settings.field_phone" />
      <View className="column expand">
        <Field name="phone_prefix">
          <Select
            readOnly={useFakeFields}
            required={required}
            source="user/phone-prefixes"
            sourceParser={(options: PhonePrefix[]) => {
              const displayNames = new (Intl as any).DisplayNames([], {
                type: 'region',
              });

              return options.map(({code, prefix}) => {
                const countryName = displayNames.of(code);
                return {
                  name: `${countryName} ${resolveFlagEmoji(code)} ${prefix}`,
                  value: prefix,
                };
              });
            }}
            noTrans
          />
        </Field>
        <Field name="phone" label="settings.field_phone">
          <Input
            readOnly={useFakeFields}
            required={required}
            fieldRelation={['phone_prefix']}
          />

          {useFakeFields && (
            <Description>
              <Link
                className="link large"
                to="settings/change-phone"
                label="settings.change_phone_link"
              />
            </Description>
          )}
        </Field>
      </View>
    </View>
  );
};

export default PhoneFields;
