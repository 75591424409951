import {useIsMobile, useIsBusinessDisabled} from 'hooks';

import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';
import StaffList from 'components/Staff/StaffList';
import FloatingButtons, {LinkOption} from 'components/Common/FloatingButton';
import Locale from 'components/Common/Locale';
import Link from 'components/Common/Link';
import View from 'components/Common/View';

const Items = () => {
  const isBusinessDisabled = useIsBusinessDisabled();
  const isMobile = useIsMobile();

  return (
    <Page title="staff.staff.title" horizontalPadding>
      <PageTitle className="staff-title top-padding">
        <View className="expand">
          <Locale text="staff.staff.title" />
        </View>
        {!isMobile && !isBusinessDisabled && (
          <Link
            follows="add"
            className="btn dark-blue rounded header"
            label="staff.staff.new_staff"
          />
        )}
      </PageTitle>
      {isMobile && !isBusinessDisabled && (
        <FloatingButtons>
          <LinkOption icon="plus" follows="add" />
        </FloatingButtons>
      )}
      <StaffList />
    </Page>
  );
};

export default Items;
