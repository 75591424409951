import {DateTime} from 'luxon';

import {DateTimeAny} from 'types/Date';

import {convertToDateTime} from 'utils/DateTime';

export type DateValue = {
  filter_date: DateTimeAny;
  value: string | number;
};

export type DateItem = {
  name: string;
  date: DateTime;
  value: string | number;
};

type FormatDateColumnsProps = {
  fromDate: DateTime;
  toDate: DateTime;
  dates: DateValue[];
};

export const formatDailyDateColumns = ({
  fromDate,
  toDate,
  dates,
}: FormatDateColumnsProps): DateItem[] => {
  const allDates: DateItem[] = [];

  let cursor = fromDate;

  const isSameDay = ({filter_date}: DateValue) =>
    convertToDateTime(filter_date).hasSame(cursor, 'day');

  do {
    const dateValue = dates.find(isSameDay);

    const name = `${cursor.day}/${cursor.month}${
      fromDate.year !== toDate.year ? `/${cursor.year}` : ''
    }`;

    allDates.push({
      name,
      date: cursor,
      value: dateValue?.value ? +dateValue?.value : 0,
    });

    cursor = cursor.plus({days: 1});
  } while (cursor < toDate);

  return allDates;
};

export const formatMonthlyDateColumns = ({
  fromDate,
  toDate,
  dates,
}: FormatDateColumnsProps): DateItem[] => {
  const allDates: DateItem[] = [];

  let cursor = fromDate;

  const isSameMonth = ({filter_date}: DateValue) =>
    convertToDateTime(filter_date).hasSame(cursor, 'month');

  do {
    const dateValue = dates
      .filter(isSameMonth)
      .reduce(
        (prev, dateValue) =>
          dateValue?.value ? prev + +dateValue?.value : prev,
        0,
      );

    const name = `${cursor.month}/${cursor.toFormat('yy')}`;

    allDates.push({
      name,
      date: cursor,
      value: dateValue,
    });

    cursor = cursor.plus({days: 1});
  } while (cursor < toDate);

  return allDates;
};
