import {UserStore} from 'types/Store';

import {getStore} from 'core/hooks';
import {GETRaw, POSTRaw, DELETE} from 'utils/Http';
import {getAuth, signOut} from 'firebase/auth';

const TOKEN_NAME = 'app_token';

const user = getStore<UserStore>('user');

export const getToken = (): string | null => {
  return localStorage.getItem(TOKEN_NAME);
};

export const removeToken = () => {
  localStorage.removeItem(TOKEN_NAME);
};

const setToken = (token: string) => {
  localStorage.setItem(TOKEN_NAME, token);
};

export const doLogin = async (loginToken: string) => {
  const {token, is_new, data} = await POSTRaw('auth', {token: loginToken});

  setToken(token);
  setUserInfo(data, is_new);

  await signOut(getAuth());
};

export const isGroup = (group: number | 'user' | 'admin'): boolean => {
  // TODO: Improve and connect with types
  switch (group) {
    case 'user': {
      group = 0;
      break;
    }
    case 'admin': {
      group = 255;
      break;
    }
  }

  return user.group === group;
};

export const isUserId = (userId: string): boolean => {
  return user.id === userId;
};

const setUserInfo = (info?: any, isNew?: any) => {
  if (info) {
    user.id = info.id || null;
    user.group = info.group || null;
    user.logged = true;
    user.is_new = isNew || false;
    user.userData = info;
  } else {
    user.id = null;
    user.group = null;
    user.logged = false;
    user.is_new = false;
    user.userData = null;
  }

  user.loaded = true;
};

export const doLogout = async () => {
  try {
    await DELETE('auth');
  } catch (e) {
    console.error(e);
  }

  removeToken();

  setUserInfo(null);

  await signOut(getAuth());
};

export const initSession = async (): Promise<any> => {
  if (user.loaded) {
    return Promise.resolve(true);
  }

  const token = getToken();

  if (!token) {
    user.loaded = true;
    return Promise.resolve(true);
  }

  try {
    const {data} = await GETRaw('auth');

    setUserInfo(data);
    return Promise.resolve(true);
  } catch (e: any) {
    const {error} = e;
    if (error === 'auth/invalid-authorization-token') {
      await doLogout();
      return Promise.resolve(true);
    } else {
      return Promise.reject(e);
    }
  }
};
