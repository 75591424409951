import './Business.scss';

import {createContext, useContext, Dispatch, SetStateAction} from 'react';
import {Route, Switch} from 'react-router-dom';

import {getStore} from 'core/hooks';

import {useIsModal, useParams} from 'hooks';

import Source, {useSource} from 'components/Common/Source';

import NotFound from 'pages/NotFound';

import {UserStore, BusinessStore} from 'types/Store';
import {UserGroups} from 'types/User';
import {StaffRole} from 'types/Staff';
import BusinessInfo, {
  BusinessStatus,
  STAFF_PATH,
  BUSINESS_PATH,
} from 'types/Business';

import Staff from './Staff';
import Booking from './Booking';
import Profile from './Profile';

const {Disabled} = BusinessStatus;

type UseSetBusiness = Dispatch<SetStateAction<BusinessInfo>>;
type UseLoadBusiness = () => void;

const userStore = getStore<UserStore>('user');
const businessStore = getStore<BusinessStore>('business');

export type BusinessContextProps = {
  business: Partial<BusinessInfo>;
  setBusiness: UseSetBusiness;
  loadBusiness: UseLoadBusiness;
};

const BusinessContext = createContext<BusinessContextProps>({
  business: {},
  setBusiness: () => {
    /* do nothing*/
  },
  loadBusiness: () => {
    /* do nothing*/
  },
});

const Business = () => {
  const {id} = useParams<{id: string}>();

  return (
    <Source<BusinessInfo>
      source={`businesses/${id}`}
      sourceParams={{
        relations: 'type,photo,photos,services,categories',
      }}>
      {() => <BusinessRender />}
    </Source>
  );
};

const BusinessRender = () => {
  const {
    data: business,
    setData: setBusiness,
    loadData: loadBusiness,
  } = useSource<BusinessInfo>();

  console.log('business loaded', business.name);

  const businessContext = {business, setBusiness, loadBusiness};
  businessStore.context = businessContext;

  return (
    <BusinessContext.Provider value={businessContext}>
      <Switch>
        <Route exact path={BUSINESS_PATH} component={Profile} />
        <Route path={`${BUSINESS_PATH}/booking`} component={Booking} />
        <Route path={STAFF_PATH} component={Staff} />
        <Route component={NotFound} />
      </Switch>
    </BusinessContext.Provider>
  );
};

export const useBusinessContext = (): BusinessContextProps => {
  const isModal = useIsModal();
  const context = useContext(BusinessContext);

  if (isModal) {
    return businessStore.context as BusinessContextProps;
  }

  return context;
};

export const useBusiness = (): BusinessInfo => {
  return useBusinessContext().business as BusinessInfo;
};

export const useIsBusinessDisabled = (): boolean => {
  const {status} = useBusiness();
  return status === Disabled;
};

export const useRole = (): StaffRole => {
  return useBusinessContext().business?.role?.role as StaffRole;
};

export const useHasRole = (roles?: StaffRole[]): boolean => {
  const role = useRole();

  return (
    (role && roles ? roles.indexOf(role) !== -1 : !!role) ||
    userStore.group === UserGroups.Admin
  );
};

export const useSetBusiness = (): UseSetBusiness => {
  return useBusinessContext().setBusiness;
};

export const useLoadBusiness = () => {
  return useBusinessContext().loadBusiness;
};

export default Business;
