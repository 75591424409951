import {
  useBusiness,
  useBusinessUrl,
  useIsModal,
  useLocation,
  useParams,
  useFormatUserFullName,
} from 'hooks';

import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';
import Locale from 'components/Common/Locale';
import Spacer from 'components/Common/Spacer';
import Form from 'components/Form';
import Field from 'components/Form/Field';
import Label from 'components/Form/Label';
import TextArea from 'components/Form/TextArea';
import Description from 'components/Form/Description';
import Actions from 'components/Form/Actions';
import Submit from 'components/Form/Submit';

import BusinessClientInfo from 'types/BusinessClient';

import {
  renderFirstNameField,
  renderLastNameField,
  renderEmailField,
  renderGenderField,
  renderDobField,
  renderPhoneField,
} from 'pages/Settings/General';

const ClientsForm = () => {
  const {id} = useBusiness();
  const isModal = useIsModal();
  const businessUrl = useBusinessUrl();
  const {state} = useLocation<{
    isFromQuickBooking: boolean;
    date: string;
    initialName: string;
  }>();
  const {client_id} = useParams<{client_id: string}>();
  const formatUserFullName = useFormatUserFullName();
  const date = state?.date;
  const isFromQuickBooking = state?.isFromQuickBooking || false;
  const initialName = state?.initialName || '';

  return (
    <Page
      title={`staff.clients.${client_id ? 'edit' : 'add'}_client`}
      {...(isModal ? {horizontalPadding: true} : {padding: true})}>
      <PageTitle className="staff-title center" />

      <Form<BusinessClientInfo>
        onSuccess={({goToUrl, data: {id, client}}) => {
          if (isFromQuickBooking) {
            goToUrl({
              base: businessUrl({to: 'booking'}),
              state: {
                isFromStaff: true,
                userName: formatUserFullName(client),
                clientId: id,
                directDate: date,
              },
            });
            return;
          }

          goToUrl({goBack: true});
        }}
        onRemove={({goToUrl}) => goToUrl({goBack: true})}
        sourceId={client_id}
        source={`clients/${id}`}>
        {({data}) => {
          const type = data?.client?.type;

          return (
            <>
              {type !== 'user' && (
                <>
                  {renderFirstNameField(true, initialName.split(' ')[0])}
                  {renderLastNameField(true, initialName.split(' ')[1])}
                  {renderPhoneField(false)}
                  {renderEmailField(false)}
                  {renderGenderField(false)}
                  {renderDobField(false)}
                </>
              )}

              <Spacer size={30} />

              <Field name="notes" optional label="staff.clients.notes_field">
                <Label />
                <TextArea optional maxLength={4096} />
                <Description>
                  <Locale text="staff.clients.notes_field_desc" />
                </Description>
              </Field>

              <Actions>
                <Submit />
              </Actions>
            </>
          );
        }}
      </Form>
    </Page>
  );
};

export default ClientsForm;
