import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';
import InfoBox from 'components/Common/InfoBox';
import Locale from 'components/Common/Locale';
import PhotosSelector from 'components/Staff/PhotosSelector';

const Photos = () => {
  return (
    <Page title="staff.settings.photos.title" padding>
      <PageTitle className="staff-title center" />

      <InfoBox type="info">
        <Locale text="staff.settings.photos.warning" />
      </InfoBox>
      <PhotosSelector />
    </Page>
  );
};

export default Photos;
