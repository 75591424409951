import {useState, useCallback} from 'react';
import {DateTime} from 'luxon';

import {goToUrl, useIsMobile, useStaffUrl} from 'hooks';

import View from 'components/Common/View';
import Text from 'components/Common/Text';

import {TIME_DISPLAY} from 'types/Date';

import {HOUR_HEIGHT, positionToDate} from './index';

type NewAppointmentCursorProps = {
  date: DateTime;
};

const NewAppointmentCursor = ({date}: NewAppointmentCursorProps) => {
  const isMobile = useIsMobile();
  const staffUrl = useStaffUrl();
  const [newAppointmentPosition, setNewAppointmentPosition] = useState(-1);

  const onMouseLeave = useCallback(() => {
    setNewAppointmentPosition(-1);
  }, [setNewAppointmentPosition]);

  const markerHeight = HOUR_HEIGHT / 2;
  const calculateNewOffset = useCallback(
    (clientY: number): number => {
      const target = document.querySelector<HTMLDivElement>('.new-appointment');

      if (!target) {
        return 0;
      }

      const {top} = target.getBoundingClientRect();

      const y = clientY - top;

      let newPosition = y - (y % markerHeight);

      if (newPosition < 0) {
        newPosition = 0;
      } else if (newPosition > target.offsetHeight) {
        newPosition = target.offsetHeight - markerHeight;
      }

      return newPosition;
    },
    [markerHeight],
  );

  const onMouseMove = useCallback(
    e => {
      const {clientY} = e;
      const newPosition = calculateNewOffset(clientY);

      if (isMobile) {
        // Delay order
        setTimeout(() => {
          setNewAppointmentPosition(newPosition);
        }, 100);
      } else {
        setNewAppointmentPosition(newPosition);
      }
    },
    [setNewAppointmentPosition, calculateNewOffset, isMobile],
  );

  const onPress = useCallback(
    ({clientY}) => {
      const positionDate = positionToDate(date, newAppointmentPosition);

      goToUrl({
        base: staffUrl({
          to: 'calendar/appointment/new',
        }),
        state: {
          date: positionDate.toISO(),
        },
      });
    },
    [staffUrl, newAppointmentPosition, date],
  );

  const renderMarker = () => {
    if (newAppointmentPosition < 0) {
      return null;
    }

    const positionDate = positionToDate(date, newAppointmentPosition);
    return (
      <View
        className="marker"
        onClick={onPress}
        style={{
          top: newAppointmentPosition,
          height: `${markerHeight}px`,
        }}>
        <Text className="label">{positionDate.toFormat(TIME_DISPLAY)}</Text>
      </View>
    );
  };

  return (
    <View
      className="new-appointment"
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}>
      {renderMarker()}
    </View>
  );
};

export default NewAppointmentCursor;
