import './Link.scss';
import 'components/Common/Button/Button.scss';

import {forwardRef, MouseEvent} from 'react';
import classNames from 'classnames';

import {goToUrl} from 'hooks';

import Locale from 'components/Common/Locale';
import Text from 'components/Common/Text';
import Icon from 'components/Common/Icon';
import {ButtonBaseProps} from 'components/Common/Button';

import {UrlProps} from 'types/Url';

import {useUrl} from 'hooks';

export type ClickCallback = (event: MouseEvent) => boolean | void;

export type LinkProps = UrlProps &
  ButtonBaseProps & {
    onPress?: ClickCallback;
    target?: string;
  };

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      id,
      className = '',
      target,
      onPress,
      children,

      label,
      labelParams,

      icon,
      iconPosition = 'before',

      to,
      base,
      goBack,
      follows,
      replace,
      params,
      full,

      ...props
    },
    ref,
  ) => {
    const urlProps = {
      to,
      base,
      full,
      params,
      follows,
      goBack,
      replace,
      ...props,
    };
    const url = useUrl(urlProps);

    const onClick = (e: MouseEvent) => {
      if (target) {
        return;
      }

      e.preventDefault();

      if (onPress && onPress(e) === false) {
        return;
      }

      if (url) {
        goToUrl(urlProps);
      }
    };

    const renderContent = () => {
      return (
        <>
          {iconPosition === 'before' && <Icon icon={icon} />}
          {children ||
            (label ? (
              <Text>
                <Locale text={label} params={labelParams} />
              </Text>
            ) : (
              label
            ))}
          {iconPosition === 'after' && <Icon icon={icon} />}
        </>
      );
    };

    return (
      <a
        ref={ref}
        id={id}
        className={classNames(className)}
        onClick={onClick}
        href={url}
        target={target}
        {...props}>
        {renderContent()}
      </a>
    );
  },
);

export default Link;
