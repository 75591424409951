import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';
import {Table} from 'components/Common/ItemsList';
import Text from 'components/Common/Text';
import * as Grid from 'components/Common/Grid';
import Form from 'components/Form';
import Input from 'components/Form/Input';
import Connect from 'components/Form/Connect';
import Link from 'components/Common/Link';

import {UserInfoExtra, UserGroups} from 'types/User';

const List = () => {
  return (
    <Page title="Users" padding>
      <PageTitle className="staff-title" />

      <Connect>
        <Form className="filter-form">
          {() => {
            return (
              <Input
                optional
                name="search"
                placeholder="Search..."
                maxLength={40}
              />
            );
          }}
        </Form>
        <Table<UserInfoExtra>
          className="table-data"
          headerLabels={['Name', 'Email', 'Phone', 'Status']}
          source="users">
          {({id, group, first_name, last_name, email, phone, inactive}) => {
            return (
              <Link follows={id} className="expand">
                {/*<Grid.Field>
                  <Text>{id.substr(0, 4)}</Text>
                </Grid.Field>*/}
                <Grid.Field>
                  <Text semiBold italic={!(first_name && last_name)}>
                    {group === UserGroups.Admin ? (
                      <Text className="badge">Admin</Text>
                    ) : null}{' '}
                    {first_name && last_name
                      ? `${first_name} ${last_name}`
                      : 'N/A'}
                  </Text>
                </Grid.Field>
                <Grid.Field>
                  <Text semiBold italic={!email}>
                    {email || 'N/A'}
                  </Text>
                </Grid.Field>
                <Grid.Field>
                  <Text semiBold italic={!phone}>
                    {phone || 'N/A'}
                  </Text>
                </Grid.Field>
                <Grid.Field>
                  <Text semiBold>{inactive ? 'inactive' : 'active'}</Text>
                </Grid.Field>
              </Link>
            );
          }}
        </Table>
      </Connect>
    </Page>
  );
};

export default List;
