import './Calendar.scss';

import {useState, useCallback} from 'react';
import {DateTime} from 'luxon';

import View from 'components/Common/View';

import BusinessInfo from 'types/Business';

import Header from './Header';
import CalendarDay from './CalendarDay';

type CalendarProps = {
  business: BusinessInfo;
};

const Calendar = ({business}: CalendarProps) => {
  const [date, setDate] = useState<DateTime | null>(null);

  const onDateChange = useCallback(
    (date: DateTime) => {
      setDate(date);
    },
    [setDate],
  );

  return (
    <View className="staff-calendar">
      <Header onDateChange={onDateChange} business={business} />
      {date && <CalendarDay date={date} business={business} />}
    </View>
  );
};

export default Calendar;
