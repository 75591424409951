import {useRef, useCallback} from 'react';
import {DateTime} from 'luxon';
import classNames from 'classnames';

import {DateTimeRange, DATE_FORMAT} from 'types/Date';

import {useIsMobile} from 'hooks';

import View from 'components/Common/View';
import Text from 'components/Common/Text';
import Day from 'components/Common/DateTime/Day';
import Month from 'components/Common/DateTime/Month';

type DatesListProps = {
  setDate: (date: DateTime) => void;
  date: DateTime;
  fromDate: DateTime;
  toDate: DateTime;
  slots: Map<string, DateTimeRange[] | null>;
};

type DayButtonProps = {
  setDate: (date: DateTime) => void;
  date: DateTime;
  selected: boolean;
  available: boolean;
};

const DayButton = ({setDate, date, selected, available}: DayButtonProps) => {
  const onClick = useCallback(
    e => {
      if (!selected) {
        setDate(date);
      }

      const currentTarget = e.currentTarget;

      currentTarget.scrollIntoView({
        block: 'nearest',
        inline: 'start',
        behavior: 'smooth',
      });
    },
    [selected, setDate, date],
  );

  return (
    <View
      onClick={onClick}
      className={classNames('date-day', {
        available,
        selected,
      })}>
      <Text className="weekday">
        <Day date={date} />
      </Text>
      <Text className="day">{date.day.toString().padStart(2, '0')}</Text>
    </View>
  );
};

const DatesList = ({
  fromDate,
  toDate,
  setDate,
  date,
  slots,
}: DatesListProps) => {
  const isMobile = useIsMobile();
  const scrollRef = useRef<HTMLDivElement | null>(null);

  const onPressLeft = useCallback(() => {
    if (!scrollRef.current) {
      return;
    }

    scrollRef.current.scrollBy({
      top: 0,
      left: -500,
      behavior: 'smooth',
    });
  }, [scrollRef]);
  const onPressRight = useCallback(() => {
    if (!scrollRef.current) {
      return;
    }

    scrollRef.current.scrollBy({
      top: 0,
      left: 500,
      behavior: 'smooth',
    });
  }, [scrollRef]);

  const renderDays = () => {
    const items = [];
    let days = [];
    let cursor = fromDate;
    let lastCursor = fromDate;

    do {
      const dateId = cursor.toFormat(DATE_FORMAT);
      const slot = slots.get(dateId);

      //<Month date={date}
      if (!cursor.hasSame(lastCursor, 'month')) {
        items.push(
          <View key={`${dateId}-month`} className={classNames('date-month')}>
            <Text className="month">
              <Month key={`${dateId}-month-name`} date={lastCursor} />
            </Text>
            <View className="days">{days}</View>
          </View>,
        );
        lastCursor = cursor;
        days = [];
      }

      days.push(
        <DayButton
          key={dateId}
          date={cursor}
          selected={date.hasSame(cursor, 'day')}
          setDate={setDate}
          available={!slot || slot.length ? true : false}
        />,
      );

      cursor = cursor.plus({
        days: 1,
      });
    } while (cursor < toDate);

    return items;
  };

  return (
    <View className="dates-list-container">
      {!isMobile && (
        <View onClick={onPressLeft} className={classNames('arrow', 'left')} />
      )}
      <View ref={scrollRef} className="dates-list">
        {renderDays()}
      </View>
      {!isMobile && (
        <View onClick={onPressRight} className={classNames('arrow', 'right')} />
      )}
    </View>
  );
};

export default DatesList;
