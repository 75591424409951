import './Tabbar.scss';

import View from 'components/Common/View';

import Button from './Button';

const Tabbar = () => {
  return (
    <View className="tabbar-container">
      <Button icon="calendar" to="/" label="Home" />
      <Button icon="clients" to="users" label="Users" />
      <Button icon="appointments" to="businesses" label="Businesses" />
      <Button icon="settings" to="settings" label="staff.menu.settings" />
    </View>
  );
};

export default Tabbar;
