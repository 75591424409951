import './PhotosSelector.scss';

import {useCallback} from 'react';

import View from 'components/Common/View';
import Image from 'components/Common/Image';
import Button from 'components/Common/Button';

import PhotoInfo from 'types/Photo';
import {useSetNotification} from 'components/App/Notifications';

type Props = {
  removePhoto: (photo: PhotoInfo) => void;
  photo: PhotoInfo;

  provided: any;
};

const PhotoItem = ({photo, removePhoto, provided}: Props) => {
  const setNotification = useSetNotification();
  const {url} = photo;

  const onPressRemove = useCallback(() => {
    removePhoto(photo);

    setNotification({
      type: 'success',
      msg: 'staff.settings.photos.delete_success_msg',
    });
  }, [removePhoto, photo, setNotification]);

  return (
    <View
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className="photo">
      <Image
        /*{...dragProvided.dragHandleProps}*/ className="image"
        src={url}
      />
      <View className="bottom">
        <Button
          dialog={{
            title: 'staff.settings.photos.delete_dialog_title',
            label: 'staff.settings.photos.delete_dialog_button',
          }}
          onPress={onPressRemove}
          className="remove"
        />
      </View>
    </View>
  );
};

export default PhotoItem;
