import {useEffect} from 'react';

import {getStore} from 'core/hooks';
import {useBusiness, setBookingView} from 'hooks';

import Title from 'components/Page/Title';
import ConfirmView from 'components/Booking/Confirm';
import Redirect from 'components/Common/Redirect';

import {BookingStore} from 'types/Store';

const booking = getStore<BookingStore>('booking');

const Confirm = () => {
  const business = useBusiness();

  useEffect(() => {
    setBookingView('confirm');

    return () => {
      setBookingView();
    };
  }, []);

  if (!booking.date) {
    return <Redirect goBack />;
  }

  return (
    <>
      <Title title="booking.step_confirm" />
      <ConfirmView business={business} />
    </>
  );
};

export default Confirm;
