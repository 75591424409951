import './Confirm.scss';

import {useEffect} from 'react';

import {getStore} from 'core/hooks';

import BusinessInfo from 'types/Business';
import {BookingStore} from 'types/Store';

import View from 'components/Common/View';
import Text from 'components/Common/Text';
import Hr from 'components/Common/Hr';
import Spacer from 'components/Common/Spacer';
import BlockInfo from 'components/Common/Display/BlockInfo';
import DateTime from 'components/Common/DateTime';

import BusinessDetails from 'components/Business/Details';
import ServicesList from 'components/Appointment/ServicesList';
import NotesInput from 'components/Booking/NotesInput';

const booking = getStore<BookingStore>('booking');

type ConfirmProps = {
  business: BusinessInfo;
};

const Confirm = ({business}: ConfirmProps) => {
  const {date, services} = booking;

  // Restore scroll
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <View className="booking-confirm-page padding">
        <BusinessDetails business={business} inline />

        <Spacer size={25} />

        <Hr />

        <Spacer size={25} />

        {date && (
          <BlockInfo label="booking.date_label">
            <Text className="block-text">
              <DateTime date={date} />
            </Text>
          </BlockInfo>
        )}

        <Spacer size={15} />

        <BlockInfo label="booking.services_label">
          <ServicesList services={services} />
        </BlockInfo>
      </View>

      <Spacer size={25} />

      <NotesInput />
    </>
  );
};

export default Confirm;
