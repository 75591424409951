import {useEffect, useContext} from 'react';

import {TitleContextProps, PageContext, TitleParams} from 'components/Page';

type TitleProps = {
  title?: TitleParams;
};

export const Title = ({title}: TitleProps) => {
  const {setTitle} = useTitle();
  useEffect(() => setTitle(title), [title, setTitle]);
  return null;
};

export const useTitle = (): TitleContextProps => {
  return useContext(PageContext);
};

export default Title;
