import './FloatingButton.scss';

import {
  createContext,
  useEffect,
  useContext,
  useState,
  useCallback,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
import classNames from 'classnames';

import {useSetModalClosed} from 'hooks';

import View from 'components/Common/View';
import Modal from 'components/App/Modal';
import Button, {ButtonProps} from 'components/Common/Button';
import Link, {LinkProps} from 'components/Common/Link';

type FloatingButtonsProps = {
  children: ReactNode;
};

type ButtonOptionProps = ButtonProps;

type FloatingButtonProps = ButtonOptionProps;

type UseSetContent = Dispatch<SetStateAction<ReactNode | null>>;

type FloatingButtonsContextProps = {
  setContent: UseSetContent;
};

const FloatingButtonsContext = createContext<FloatingButtonsContextProps>({
  setContent: () => {
    /* do nothing */
  },
});

export const ButtonOption = ({
  onPress: onPressProp,
  className,
  ...props
}: ButtonOptionProps & ButtonProps) => {
  const setModalClosed = useSetModalClosed();
  const onPress = useCallback(
    e => {
      if (onPressProp) {
        onPressProp(e);
      }

      if (setModalClosed) {
        setModalClosed();
      }
    },
    [onPressProp, setModalClosed],
  );

  return (
    <Button
      iconPosition="after"
      className={classNames('floating-button', 'option-button', className)}
      onPress={onPress}
      {...props}
    />
  );
};

export const LinkOption = ({
  onPress: onPressProp,
  className,
  ...props
}: ButtonOptionProps & LinkProps) => {
  const setModalClosed = useSetModalClosed();
  const onPress = useCallback(
    e => {
      if (onPressProp) {
        onPressProp(e);
      }

      if (setModalClosed) {
        setModalClosed();
      }
    },
    [onPressProp, setModalClosed],
  );

  return (
    <Link
      iconPosition="after"
      className={classNames('floating-button', 'option-button', className)}
      onPress={onPress}
      {...props}
    />
  );
};

export const FloatingButton = ({
  onPress: onPressProp,
  className,
  children,
  ...props
}: FloatingButtonProps) => {
  const setContent = useSetContent();
  const onPress = useCallback(
    e => {
      if (onPressProp) {
        onPressProp(e);
        return;
      }

      setContent(children);
    },
    [onPressProp, setContent, children],
  );

  return (
    <>
      <Button
        iconPosition="after"
        onPress={onPress}
        className={classNames('floating-button', 'modal-button', className)}
        {...props}
      />
    </>
  );
};

export const FloatingButtons = ({children}: FloatingButtonsProps) => {
  const [content, setContent] = useState<ReactNode | null>();
  const [visible, setVisible] = useState(false);

  const setClosed = useCallback(() => {
    setVisible(false);
    setContent(null);
  }, [setContent]);

  useEffect(() => {
    setVisible(!!content);
  }, [content]);

  return (
    <FloatingButtonsContext.Provider value={{setContent}}>
      <View
        className={classNames(
          'floating-buttons',
          'floating-container',
          'padding',
          {
            'modal-visible': !!content,
          },
        )}>
        {children}
      </View>

      {visible && (
        <Modal setClosed={setClosed}>
          <View className="floating-buttons padding">{content}</View>
        </Modal>
      )}
    </FloatingButtonsContext.Provider>
  );
};

export const FloatingLink = ({className, ...props}: LinkProps) => {
  return (
    <Link
      iconPosition="after"
      className={classNames(
        'floating-buttons',
        'padding',
        'floating-button',
        className,
      )}
      {...props}
    />
  );
};

const useSetContent = (): UseSetContent => {
  return useContext(FloatingButtonsContext).setContent;
};

export default FloatingButtons;
