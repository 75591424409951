import AdminMenu from './Admin';

import {BaseMenu} from 'components/Staff/Menu';

export const Menu = () => {
  return (
    <BaseMenu>
      <AdminMenu />
    </BaseMenu>
  );
};

export default Menu;
