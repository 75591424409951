import './BusinessType.scss';

import {useCallback} from 'react';
import classNames from 'classnames';

import Image from 'components/Common/Image';
import Link from 'components/Common/Link';
import Button from 'components/Common/Button';
import Text from 'components/Common/Text';
import Locale from 'components/Common/Locale';

import {BusinessTypeResponse} from 'types/BusinessType';

import BusinessTypeImages from './BusinessTypeImages';

type Props = {
  type: BusinessTypeResponse;
  selected?: boolean;
  onPress?: (name: string) => void;
};

const BusinessTypeItem = ({type: {name, order}, selected, onPress}: Props) => {
  const _onPress = useCallback(() => {
    if (onPress) {
      onPress(name);
    }
  }, [onPress, name]);

  const className = classNames('business-type-item', {
    selected,
    selectable: !!onPress,
  });

  if (onPress) {
    return (
      <Button className={className} onPress={_onPress}>
        <Image animIn className="image" src={BusinessTypeImages[name]} />
        <Text className="name">
          <Locale text={`business_types.${name}`} />
        </Text>
      </Button>
    );
  }

  return (
    <Link
      className={className}
      to="explore"
      query={{
        type: name,
      }}>
      <Image animIn className="image" src={BusinessTypeImages[name]} />
      <Text className="name">
        <Locale text={`business_types.${name}`} />
      </Text>
    </Link>
  );
};

export default BusinessTypeItem;
