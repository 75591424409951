import './Footer.scss';

import {useStore, getStore} from 'core/hooks';

import {UIStore} from 'types/Store';

import View from 'components/Common/View';
import Link from 'components/Common/Link';

//import {ReactComponent as LinkedIn} from './Assets/LinkedIn.svg';
//import {ReactComponent as Facebook} from './Assets/Facebook.svg';
//import {ReactComponent as Twitter} from './Assets/Twitter.svg';
import {ReactComponent as Instagram} from './Assets/Instagram.svg';

import WithLove from './WithLove';

const uiStore = getStore<UIStore>('ui');

const Footer = () => {
  const hideFooterCount = useStore<number>('hideFooterCount');

  if (hideFooterCount) {
    return null;
  }

  return (
    <View id="app-footer">
      <View className="base-size top padding">
        <View className="links">
          <Link
            className="link"
            to="for-business"
            label="footer.for_business"
          />
          <Link
            className="link"
            target="_blank"
            to="static/legal/terms.pdf"
            label="legal.terms_link"
          />
          <Link
            className="link"
            target="_blank"
            to="static/legal/privacy.pdf"
            label="legal.privacy_link"
          />
        </View>

        <View className="social-links">
          {/*<Link className="link" target="_blank">
            <LinkedIn />
          </Link>
          <Link className="link" target="_blank">
            <Facebook />
          </Link>
          <Link className="link" target="_blank">
            <Twitter />
          </Link>*/}
          <a
            className="link"
            href="https://www.instagram.com/reserby/"
            rel="noreferrer"
            target="_blank">
            <Instagram />
          </a>
        </View>
      </View>
      <WithLove />
    </View>
  );
};

export const setFooterStatus = (status = false) => {
  console.log('hideFooterCount', status, uiStore.hideFooterCount);

  if (status) {
    uiStore.hideFooterCount++;
  } else {
    uiStore.hideFooterCount--;
  }
};

export default Footer;
