import {DateTimeAny} from 'types/Date';

import Locale from 'components/Common/Locale';

import Time from './Time';
import Month from './Month';

import {convertToDateTime} from 'utils/DateTime';

type Props = {
  date: DateTimeAny;
  excludeYear?: boolean;
  excludeTime?: boolean;
  shortMonth?: boolean;
  timezone?: string;
};

const DateTime = ({
  date,
  excludeYear,
  excludeTime,
  shortMonth,
  timezone,
}: Props) => {
  date = convertToDateTime(date, timezone);

  return (
    <>
      <Month short={shortMonth} date={date} /> {date.day}
      {!excludeTime && (
        <>
          {' '}
          <Locale text="time_at" /> <Time date={date} />
        </>
      )}
      {!excludeYear ? `, ${date.year}` : ''}
    </>
  );
};

export default DateTime;
