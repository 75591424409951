export const locales = {
  home: {
    desktop: {
      page_title_1: 'Reserva los servicios que quieras,',
      page_title_2: 'los 365 días del año.',
    },
    mobile: {
      page_title_1: 'Reserva los servicios',
      page_title_2: 'que quieras,',
      page_title_3: 'los 365 días del año.',
    },
  },
  for_business: {
    title: 'Reserby para negocios',

    headline: 'Encuentra el mejor plan para tu negocio',
    subheadline:
      'Administra tus reservaciones, aumenta la productividad y sube tus ventas en un solo lugar.',

    free_benefits: {
      line1: 'Perfil en Reserby.',
      line2: 'Reservaciones ilimitadas desde panel administrativo',
      line3: 'Administración de reservaciones del panel administrativo',
      line4: 'Notificaciones a tus clientes por correo electrónico',
      line5: 'Cancelaciones y reagendamiento',
      line6: 'Sin comisiones por servicio',
    },

    premium_benefits: {
      line1: 'Todo lo incluído en el plan LITE más:',
      line2: 'Exposición en buscador y directorio de Reserby',
      line3: 'Reservaciones ILIMITADAS online desde tu perfil de Reserby 24/7',
      line4: 'Notificaciones a tus clientes por SMS',
      line5: 'Página directa y exclusiva de reservaciones para tu negocio',
    },

    choose_button: 'Elegir plan',

    demo_headline: '¿Te gustaría agendar un Demo? ',
    demo_subheadline:
      'Te explicamos a detalle cómo funciona Reserby cuando tú quieras.',

    demo_request_button: 'WhatsApp',

    help_line1: '¿Eres Jefa de Familia y estás en una condición vulnerable?',
    help_line2: 'Nos encantará apoyarte con Reserby Premium.',

    contact_button: 'Contáctanos aquí.',

    help_text_1:
      'Si no puedes dar de alta tu negocio debido a una tecnología de asistencia incompatible o una discapacidad, comunícate con nosotros a hi@reserby.com. Haremos todo lo posible para responder a tu solicitud de asistencia lo antes posible y ayudarte.',
    help_text_2:
      'Es muy importante para nosotros crear una experiencia accesible e inclusiva. Por favor déjanos saber si hay algún ajuste que podamos hacer para darte la mejor experiencia en Reserby.',
  },
  business: {
    not_available:
      'Por el momento este negocio no tienen disponibles las reservas online. Te sugerimos lo contactes vía telefónica.',

    open_address_label: 'Abrir dirección',

    me_no_results: 'No se encontraron negocios',
    no_results: 'No se encontraron negocios',

    status_pending: 'Pendiente',
    status_active: 'Activo',
    status_inactive: 'Inactivo',
    status_disabled: 'Desactivado',

    create: {
      title: 'Crear negocio',
      button: 'Crear negocio',

      field_name: 'Nombre del negocio',
    },

    available_services: '{{count}} servicio disponible',
    available_services_plural: '{{count}} servicios disponibles',
    book_now_button: 'Reservar ahora',
    reviews_title: 'Reseñas ({{count}})',
    reviews: 'reseña',
    reviews_plural: 'reseñas',
    features: {
      closed_now: 'Cerrado ahora',
      open_now: 'Abierto ahora',
      instant_confirmation: 'Confirmación inmediata',
      payment_in_business: 'Pago en el local',
    },
  },
  common: {
    free: 'Gratis',
    dropdown_close: 'Cerrar',
    modal_close: 'Cerrar',
    dialog_close: 'Cerrar',
    dialog_confirm: 'Confirmar',

    categories: 'Categorías',

    filter_by: 'Filtrar por:',
  },
  user: {
    no_name: 'Sin nombre',
  },
  menu: {
    appointments_button: 'Mis reservas',
    settings_button: 'Configuración',
    admin_button: 'Admin Panel',

    businesses_button: 'Mi negocio',
    businesses_button_plural: 'Mis negocios',
    create_business: 'Crear negocio',

    login_button: 'Ingresar',
    logout_button: 'Cerrar sesión',

    join_user: 'Reservar un servicio',
    join_business: 'Quiero mi negocio en Reserby',
  },
  pages: {
    not_found: '404 - Página no encontrada',
    not_found_msg: 'La página que estaba buscando no existe.',
    not_found_button: 'Regresar al inicio',
  },

  membership: {
    type_free: 'Lite',
    type_premium: 'Premium',

    monthly: 'Mensual',

    current_membership: 'Membresía actual',

    payments_history: 'Historial de pagos',

    change_card: 'Cambiar tarjeta',

    cancel_dialog_title: '¿Deseas cancelar la membresía?',
    cancel_dialog_subtitle:
      'Podrá seguirla usando hasta el último día de la fecha de expiración.',
    cancel_dialog_button: 'Cancelar membresía',
    cancelled_msg: 'Membresía cancelada',
    cancelling_error:
      'Ocurrió un error cancelando la membresía, intente más tarde',

    activate_button: 'Activar membresía premium',
    reactivate_button: 'Reactivar membresía',

    cancel_button: 'Cancelar membresía',
    delete_button: 'Borrar membresía (IGNORAR)',

    next_payment: 'Próximo pago:',
    no_payment: 'Membresía sin costo',

    cancellation_warning:
      'Su membresía se encuentra cancelada y podrá seguir haciendo uso de ella hasta',

    complete_card_error: 'Completa los datos de la tarjeta',
    adding_card_error:
      'No logramos validar su tarjeta, intente con otra tarjeta',
    paying_card_error: 'No logramos procesar su pago, intente con otra tarjeta',
    activated_msg: '¡Felicidades! Membresía Premium activada.',

    reactivated_msg: '¡Felicidades! Membresía Premium reactiva.',
    reactivated_error: '¡Felicidades! Membresía Premium reactiva.',

    payment_updated_msg: 'Tarjeta de crédito actualizada',

    reactivated_warning:
      'Reactivaremos su membresía sin ningún cargo a su tarjeta hasta el próximo ciclo.',
    init_trial_warning:
      'Inicia tu membresía sin ningún costo por 30 días. No se cobrará en este momento, solo verificaremos tu tarjeta y puedes cancelarla en cualquier momento.',
    init_button: 'Iniciar membresía',
    change_button: 'Actualizar tarjeta',
  },

  staff: {
    businesses: {
      title: 'Administrar negocios',

      create: 'Crear negocio',
    },

    share: {
      title: 'Compartir',
      description: 'Comparte los links de reserva con tus clientes',

      share_link_title: 'Link a la página del negocio',
      share_link_sub: 'Comparte el link a todos los detalles de tu negocio',

      share_booking_link_title: 'Link directo a las reservas',
      share_booking_link_sub:
        'Comparte el link directo a reservar los servicios del negocio',

      activate_warning_1: 'Activa tu membresía',
      activate_warning_2: 'PREMIUM',
      activate_warning_3:
        'para que tus clientes puedan reservar de forma online.',

      activate_link: 'Activar membresía',

      copied_msg: 'Link copiado',
    },

    complete: {
      title: 'Completa el perfil de {{name}}',

      hours_title: 'Horarios de atención',
      photos_title: 'Sube fotos de tu negocio',
      types_title: 'Categoría y tipo de tu negocio',

      activate_label: 'Activar mi negocio',
      configure_label: 'Configurar',

      warning:
        'Podrás agregar cambios dentro de tu perfil cuando lo necesites posteriormente.',
    },

    clients: {
      title: 'Clientes',

      new_client: 'Nuevo cliente',

      client_since_label: 'Cliente desde',
      birthdate_label: 'Cumpleaños',

      book_button: 'Reservar',
      edit_button: 'Editar',
      remove_button: 'Eliminar',

      notes_field: 'Notas del cliente',
      notes_field_desc: 'Las notas solo son visibles para los los empleados',

      search_input: 'Buscar',
      search_placeholder: 'Buscar por nombre, email o teléfono del cliente',

      no_results: 'No se encontraron clientes',

      delete_dialog_title: '¿Deseas eliminar el cliente?',
      delete_dialog_subtitle:
        'Se eliminará todo el historial de reservas para este cliente.',
      delete_dialog_button: 'Sí quiero eliminarlo',

      delete_cannot_delete_error_msg: 'No puedes puedes eliminarte a ti mismo.',
      delete_success_msg: 'Cliente eliminado.',

      add_client: 'Agregar cliente',
      edit_client: 'Editar cliente',
    },

    menu: {
      business_label: 'Negocio',
      settings_label: 'Ajustes',

      calendar: 'Calendario',
      appointments: 'Reservas',
      services: 'Servicios',
      clients: 'Clientes',
      staff: 'Staff',
      share: 'Compartir',
      settings: 'Configuración',
    },

    calendar: {
      title: 'Calendario',

      today_label: 'Hoy',
    },

    appointments: {
      title: 'Reservas',

      appointment_title: 'Reserva de {{name}}',

      new_appointment: 'Nueva reserva',

      time_success_msg: 'Nueva fecha de reserva asignada.',
      time_error_same_date_msg: 'Selecciona un horario diferente al actual.',

      search_input: 'Buscar',
      search_placeholder: 'Buscar por nombre, email o teléfono del cliente',

      status_label: 'Estado',
      client_label: 'Cliente',

      new: {
        title: 'Nueva reserva',

        add_client: 'Agregar "{{name}}" como cliente',
        add_new_client: 'Agregar nuevo cliente',
        date_field: 'Fecha',
        client_field: 'Cliente',
        client_placeholder: 'Buscar cliente',
      },
    },

    staff: {
      title: 'Staff',
      description: 'Administra el staff del negocio',

      warning: 'Se le enviará un SMS con instrucciones de como acceder.',

      new_staff: 'Agregar staff',

      remove_button: 'Eliminar',

      search_input: 'Buscar',
      search_placeholder: 'Buscar por nombre, email o teléfono del staff',

      no_results: 'No se encontraron miembros del staff',

      delete_dialog_title: '¿Deseas eliminar el miembro del staff?',
      delete_dialog_subtitle: 'Se le eliminará el acceso de inmediato.',
      delete_dialog_button: 'Sí quiero eliminarlo',
      delete_success_msg: 'Staff eliminado.',

      add_staff: 'Agregar staff',
      edit_staff: 'Editar staff',

      edit_button: 'Editar',

      role_field: 'Rol',
      role_desc: 'Tipos de roles',

      role_desc_owner: 'Tiene todo el control sobre el negocio.',
      role_desc_manager: 'Puede administrar reservas, clientes y servicios.',
      role_desc_employee:
        'Puede administrar reservas y clientes sin acceso a la información personal de los clientes como teléfono e email.',

      role_label: 'Rol',

      role_owner: 'Dueño',
      role_manager: 'Manager',
      role_employee: 'Empleado',
    },

    services: {
      title: 'Servicios',
      description: 'Configura los servicios que ofrece el negocio',

      add_category: 'Crear categoría',
      edit_category: 'Editar categoría',
      field_category_name: 'Nombre',
      field_category: 'Categoría',

      add_service_title_category: 'Agregar servicio a {{name}}',
      add_service_title: 'Agregar servicio',
      add_service: 'Agregar servicio',
      edit_service: 'Editar servicio',

      no_categories: 'Crea tu primera categoría.',
      no_categories_help: '¿Qué tal una de Promociones?',

      field_service_name: 'Nombre',
      field_service_notes: 'Descripción',
      field_service_price: 'Precio',
      field_service_minutes: 'Minutos',
    },

    settings: {
      title: 'Configuración',

      membership: {
        title: 'Membresía',
        description: 'Activa, renueva o cancela tu membresía',

        checkout_title: 'Activa tu membresía',
        checkout_reactivate_title: 'Reactiva tu membresía',

        change_payment_title: 'Actualizar tarjeta',
      },

      general: {
        title: 'Información general',
        description: 'Nombre, descripción, dirección, zona horaria, etc',

        field_active: 'Estado del negocio',
        active_description:
          'Si desactivas esta casilla no aperecerás en las búsquedas de Reserby pero podrás seguir creando reservas de modo manual.',

        field_name: 'Nombre del negocio',
        field_description: 'Descripción del negocio',
        field_timezone: 'Zona horaria',
        field_address: 'Dirección del negocio',
        field_address_placeholder: 'Ingresa la dirección exacta del negocio',

        field_concurrency: 'Concurrencia',
        concurrency_description:
          'Estas son las reservaciones que puedes recibir al mismo tiempo.',
      },

      photos: {
        title: 'Galería',
        description: 'Sube y administra las fotos del negocio',

        submit_button: 'Listo',

        delete_dialog_title: '¿Deseas eliminar la foto?',
        delete_dialog_button: 'Eliminar',
        delete_success_msg: 'Foto eliminada.',

        warning:
          'Sube 4 fotos de tu negocio como mínimo. Sin textos. Procura que tengan buena iluminación y calidad. Si tu foto no cumple con la calidad que requiere Reserby te enviaremos una notificación para que agregues una foto diferente.',

        //error_max_photos: 'No puede subir más fotos',
      },

      hours: {
        title: 'Horarios',
        description: 'Configura los horarios de trabajo',

        success_msg: 'Horarios guardados',

        disabled: 'Cerrado',
      },

      types: {
        title: 'Categoría del negocio',
        description: 'Categorías donde el negocio ofrece servicios',

        field_business_types: 'Seleccionar categorías',
        error_max_types: 'Solo puedes seleccionar 4 categorías',
        error_select: 'Seleccione una categoría',
        error_require_main: 'Seleccione una categoría como principal',

        warning:
          'Elige la categoría de tu negocio. Puedes seleccionar hasta 4 categorías según los servicios de tu negocio. Si necesitas más categorías podemos habilitarlo para ti si nos escribes a hi@reserby.com',
      },

      special_dates: {
        title: 'Días especiales',
        description:
          'Cierra o habilita disponibilidad días diferentes a los de los horarios de la semana.',

        new_date: 'Agregar fecha',
        add_special_date: 'Agregar día especial',
        edit_special_date: 'Editar día especial',

        closed_label: 'Cerrado',
        open_label: 'Abierto',

        no_results: 'No hay fechas especiales creadas',

        field_status: 'Acción',
        field_date_from: 'Desde',
        field_date_to: 'Hasta',
      },
    },
  },
  login: {
    title: 'Ingresar',
    title_booking: 'Ingresar para reservar',
    title_phone: 'Continuar con mi teléfono',
    title_phone_business: 'Acceder al negocio',

    field_code: 'Ingresar código',
    field_code_label: 'Código enviado a: {{phone}}',

    continue_with: 'Continuar con',
    google_button: 'Continuar con Google',
    apple_button: 'Continuar con Apple',
    phone_button: 'Continuar con mi teléfono',
    send_code_button: 'Enviar código SMS',
    verify_code_button: 'Verificar código',

    terms: {
      line1: 'Al continuar acepto los',
      line2: 'y he leído el',
    },
  },
  settings: {
    general_title: 'Configuración',
    complete_title: 'Completar perfil',
    complete_button: 'Completar perfil',

    field_first_name: 'Nombre',
    field_last_name: 'Apellido',
    field_email: 'Email',

    field_gender: 'Pronombre',

    gender_male: 'Él',
    gender_female: 'Ella',
    gender_they: 'Ellxs',
    gender_other: 'Otro',

    field_dob: 'Fecha de nacimiento',

    field_phone: 'Número de teléfono',

    field_notifications_email: 'Recibir notificaciones por email',
    field_notifications_sms: 'Recibir notificaciones por SMS',

    change_phone_link: 'Cambiar número de teléfono',

    change_phone: {
      title: 'Cambiar teléfono',

      field_code_label: 'Código enviado a: {{phone}}',
      field_code: 'Ingresar código',

      send_code_button: 'Enviar código SMS',
      verify_code_button: 'Verificar código',
      changed_msg: 'Nuevo número verificado',
    },
  },
  appointments: {
    home_title: 'Mis reservas',
    view_title: 'Mi reserva en {{name}}',

    time_title: 'Cambiar reserva de {{name}}',
    time_subtitle: 'Cambiar reserva',
    time_success_msg: 'Nueva fecha de reserva asignada.',
    time_error_same_date_msg: 'Selecciona un horario diferente al actual.',

    review_title: 'Calificar tu experiencia en {{name}}',
    review_subtitle: 'Calificar el servicio',
    review_success_msg: '¡Gracias! Tu calificación ha sido enviada.',
    review_error_msg: 'Seleccione una calificación.',
    review_comment_field: 'Comentario',
    review_comment_placeholder: 'Ingresar un comentario (opcional)',

    time_button: 'Reagendar',
    review_button: 'Calificar servicio',
    change_review_button: 'Cambiar calificación',
    cancel_button: 'Cancelar',
    complete_button: 'Servicio completado',
    new_button: 'Hacer otra reserva',

    services_label: 'Servicios',
    total_label: 'Total',

    cancel_dialog_title:
      '¿Deseas cancelar la cita que ya habías confirmado previamente?',
    cancel_dialog_subtitle: 'De ser así, se cancelará.',
    cancel_dialog_button: 'Sí quiero cancelar',
    cancel_success_msg: 'Reserva cancelada.',

    complete_dialog_title: '¿Ya realizaste el servicio al cliente?',
    complete_dialog_subtitle:
      'Aparecerá en tu agenda como completada (color gris) y se enviará un recibo al cliente de que el servicio ha sido entregado.',
    complete_dialog_button: 'Completar servicio',
    complete_success_msg: 'Reserva completada.',

    status_tagline_pending: 'Reserva pendiente',
    status_tagline_confirmed: 'Reserva confirmada',
    status_tagline_cancelled: 'Reserva cancelada',
    status_tagline_completed: 'Reserva completada',

    status_pending: 'Pendiente',
    status_confirmed: 'Confirmada',
    status_cancelled: 'Cancelada',
    status_completed: 'Completada',

    select_status_pending: 'Pendientes',
    select_status_confirmed: 'Confirmadas',
    select_status_cancelled: 'Canceladas',
    select_status_completed: 'Completadas',

    no_results: 'No se encontraron reservas',
  },
  form: {
    changes_saved: 'Cambios guardados',
    changes_added: 'Agregado',
    changes_removed: 'Eliminado',

    select: 'Seleccionar',
    button_save: 'Guardar',
    button_add: 'Agregar',
    button_create: 'Crear',
    button_edit: 'Editar',
    button_delete: 'Eliminar',
    button_remove: 'Eliminar',
    confirm_remove: 'Confirmar eliminación',
    errors: {
      required: '{{label}} es requerido',
      min: '{{label}} debe ser mayor a {{value}}',
      max: '{{label}} debe ser menor a {{value}}',
      minLength: '{{label}} es muy corto (mínimo {{value}} caracteres)',
      maxLength: '{{label}} es muy largo (máximo {{value}} caracteres)',
      pattern: '{{label}} error de patrón',
      validate: '{{label}} error de validación',

      required_inline: 'Campo requerido',
    },
  },
  booking: {
    services_count: '{{count}} servicio',
    services_count_plural: '{{count}} servicios',
    button_landing: 'Reservar',
    button_services: 'Siguiente',
    button_time: 'Siguiente',
    button_confirm: 'Confirmar',

    steps_headline: 'de',
    step_services: 'Selecciona los servicios',
    step_time: 'Selecciona la hora y fecha',
    step_confirm: 'Revisa y confirma',

    notes_field: 'Añadir notas a la reserva (opcional)',
    notes_placeholder:
      'Incluir comentarios o solicitudes a tu reserva (opcional)',

    services_not_available: 'No hay servicios disponibles.',
    time_not_avaialble: 'No hay horarios disponibles este día',

    notes_label: 'Notas',
    date_label: 'Fecha',
    services_label: 'Servicios',
  },

  time_at: 'a las',
  days: {
    sunday: 'Domingo',
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sábado',
  },
  days_short: {
    sunday: 'Dom',
    monday: 'Lun',
    tuesday: 'Mar',
    wednesday: 'Mié',
    thursday: 'Jue',
    friday: 'Vie',
    saturday: 'Sáb',
  },
  months: {
    january: 'Enero',
    february: 'Febrero',
    march: 'Marzo',
    april: 'Abril',
    may: 'Mayo',
    june: 'Junio',
    july: 'Julio',
    august: 'Agosto',
    september: 'Septiembre',
    october: 'Octubre',
    november: 'Noviembre',
    december: 'Diciembre',
  },
  months_short: {
    january: 'Ene',
    february: 'Feb',
    march: 'Mar',
    april: 'Abr',
    may: 'May',
    june: 'Jun',
    july: 'Jul',
    august: 'Ago',
    september: 'Sep',
    october: 'Oct',
    november: 'Nov',
    december: 'Dic',
  },

  legal: {
    terms_link: 'Términos y condiciones',
    privacy_link: 'Aviso de privacidad',
  },

  footer: {
    made: 'Hecho con',
    by: 'por Reserby',

    for_business: 'Reserby para negocios',
  },

  business_types: {
    beauty_salon: 'Salón de belleza',
    hair_salon: 'Cortes',
    nail_salon: 'Salón de uñas',
    eyebrows_lashes: 'Pestañas y cejas',
    massage: 'Masajes',
    spa: 'Spa',
    barbershop: 'Barbería',
    therapy_center: 'Centro de terapia',
    dental_health: 'Salud dental',

    beauty_salon_plural: 'Salones de belleza',
    hair_salon_plural: 'Cortes',
    nail_salon_plural: 'Salones de uñas',
    eyebrows_lashes_plural: 'Pestañas y cejas',
    massage_plural: 'Masajes',
    spa_plural: 'Spa',
    barbershop_plural: 'Barberías',
    therapy_center_plural: 'Centros de terapia',
    dental_health_plural: 'Salud dental',

    no_results: 'No se encontraron negocios para esta categoría',
  },

  explore: {
    title: 'Buscar y encontrar negocios',
  },

  explorer: {
    type_business: 'Negocios',
    type_business_type: 'Categorías',
    type_service: 'Servicios',

    finding_location_label: 'Detectando ubicación...',

    near_me_label: 'Usar mi ubicación actual',

    search_placeholder: 'Buscar un servicio o establecimiento',
    location_placeholder: 'Ingresar tu ubicación',

    searching_label: 'Buscando...',
    search_no_results:
      'No se encontraron negocios en {{location}}, intente con otra búsqueda',
    search_results_label: '{{count}} resultado en {{location}}',
    search_results_label_plural: '{{count}} resultados en {{location}}',
  },

  pagination: {
    next: 'Siguiente',
    previous: 'Anterior',
    load_more: 'Ver más',
    no_results: 'No hay resultados',
    result_count: '{{count}} resultado',
    result_count_plural: '{{count}} resultados',
  },

  errors: {
    unknown: 'Ocurrió un error, intente más tarde.',
    error_400: 'Error en solicitud',
    error_404: 'El recurso no fue encontrado.',
    error_401: 'No tiene acceso a este recurso.',
    error_403: 'Ocurrió un error procesando la solicitud.',
    error_500: 'Ocurrió un error procesando la solicitud.',

    'business/not-found': 'El negocio no se encontró',

    // Files
    'file/not-allow': 'Archivo no permitido',
    'file/invalid-data': 'Datos inválidos',
    'file/empty-file': 'Archivo vacío',
    'file/error-uploading': 'Error subiendo archivo',
    'file/error-deleting': 'Error eliminando archivo',

    // Booking
    'appointments/business-is-not-active':
      'El negocio no esta aceptando reservas por el momento',
    'appointments/business-is-not-activated':
      'El negocio no se encuentra activado',

    // Generic
    'form/select': 'Seleccione una opción',

    // Staff
    'staff/exists': 'Ya tienes este teléfono como miembro del staff',

    // User profile
    'user/phone-or-email-needed': 'Email o teléfono es requerido',
    'user/phone-needed': 'Teléfono es requerido',
    'user/username-already-exists': 'Nombre de usuario en uso',
    'user/invalid-username': 'Nombre de usuario inválido',
    'user/email-already-exists': 'Email en uso',
    'user/invalid-email': 'Email inválido',
    'user/invalid-phone': 'Teléfono inválido',
    'auth/phone-number-already-exists': 'Teléfono ya en uso',
    'user/phone-already-exists': 'Teléfono en uso',

    // Firebase
    'auth/invalid-phone-number': 'Número de teléfono inválido',
    'auth/invalid-verification-code': 'Código de verificación incorrecto',
    'auth/phone-already-registered': 'Teléfono ya registrado',
    'auth/code-expired': 'Código expirado',
    'auth/too-many-requests': 'Demasiados intentos, intente más tarde',

    // Auth
    'auth/already-authenticated': 'Ya se encuentra identificado.',
    'auth/identifier-not-found': 'Identificador no encontrado',
    'auth/email-already-registered': 'Email ya registrado',
    'auth/unknown-already-registered': 'Usuario ya registro',
    'auth/unknown': 'Error desconocido',

    // Staff
    'business/country-not-available':
      'Reserby no se encuentra disponible la dirección especificada.',
    'business/cannot-change-country':
      'La dirección ingresada no coincide con el país de creación. Si desea cambiar el país contáctenos a hi@reserby.com.',

    // Rate limiting
    'rate-limiting/max-active-appointments':
      'Ha alcanzado el máximo de reservas activas al mismo tiempo.',

    'rate-limiting/max-recent-appointments':
      'Ha alcanzado el máximo de reservas en 24 horas',

    'rate-limiting/max-appointment-changes':
      'No puede hacer más cambios a esta reserva, por favor haga una nueva reserva.',

    'rate-limiting/max-staff-members':
      'Ha alcanzado el máximo número de miembros, contáctanos a hi@reserby.com si necesitas ayuda.',

    'rate-limiting/max-businesses':
      'Ha alcanzado el máximo número de negocios, contáctanos a hi@reserby.com si necesitas crear más negocios.',

    // Phone verification
    'user/phone-is-same': 'El nuevo teléfono debe ser diferente al actual.',
    'phone/no-verification-code': 'El código de verificación ha expirado.',
    'phone/incorrect-verification-code': 'Código ingresado incorrecto.',

    // Special dates
    'special-dates/date-already-in-use':
      'Ya existe una fecha especial en ese rango',
    'special-dates/start-time-after-end-time':
      'La fecha de inicio debe ser antes de la fecha de fin',
  },
};

export default locales;
