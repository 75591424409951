import './HoursSelector.scss';

import {useState, useCallback, ChangeEvent} from 'react';
import {DateTime} from 'luxon';
import classNames from 'classnames';

import View from 'components/Common/View';
import Button from 'components/Common/Button';
import Text from 'components/Common/Text';

import BusinessHoursInfo from 'types/BusinessHours';
import {Days} from 'types/Date';
import {TIME_DISPLAY} from 'types/Date';

import {ReactComponent as AddIcon} from './Assets/Add.svg';
import {ReactComponent as RemoveIcon} from './Assets/Remove.svg';

import {MINUTES_IN_DAY} from './Day';

type HourProps = {
  day: Days;
  hour: BusinessHoursInfo;
  prevHour?: BusinessHoursInfo;
  nextHour?: BusinessHoursInfo;
  setHour: (hour: BusinessHoursInfo) => void;
  removeHour: (hour: BusinessHoursInfo) => void;
  action: 'add' | 'remove';
};

const Hour = ({
  day,
  hour,
  prevHour,
  nextHour,
  setHour,
  removeHour,
  action,
}: HourProps) => {
  const {start_minute: start_minute_prop, end_minute: end_minute_prop} = hour;
  const [start_minute, setStartMinute] = useState(start_minute_prop);
  const [end_minute, setEndMinute] = useState(end_minute_prop);

  const isInactive =
    action === 'add' && MINUTES_IN_DAY - hour.end_minute < 120 ? true : false;

  const onPressAction = useCallback(() => {
    if (action === 'add') {
      if (isInactive) {
        return;
      }

      setHour({
        id: 'new_' + Date.now(),
        day,
        start_minute: end_minute + 60,
        end_minute: end_minute + 120,
      });
    } else if (action === 'remove') {
      const newHour = {...hour};
      removeHour(newHour);
    }
  }, [hour, action, isInactive, day, end_minute, setHour, removeHour]);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const type = e.target.dataset.type;
      const value = e.target.value;

      const newHour = {...hour};
      const newMinute = +value;

      if (type === 'start') {
        setStartMinute(newMinute);
        newHour.start_minute = newMinute;
      } else {
        setEndMinute(newMinute);
        newHour.end_minute = newMinute;
      }

      setHour(newHour);
    },
    [setStartMinute, setEndMinute, hour, setHour],
  );

  const renderTimes = (min = 0, max = MINUTES_IN_DAY) => {
    const endTime = DateTime.now().startOf('day').plus({minutes: max});

    const startTime = DateTime.now().startOf('day');
    let currentTime = startTime.plus({minutes: min});

    const items = [];

    do {
      const minutes = Math.ceil(currentTime.diff(startTime, 'minutes').minutes);

      items.push(
        <option key={minutes} value={minutes}>
          {currentTime.toFormat(TIME_DISPLAY)}
        </option>,
      );

      currentTime = currentTime.plus({minutes: 5});
    } while (currentTime <= endTime);

    return items;
  };

  const renderSelect = (type: 'start' | 'end') => {
    const selected = type === 'start' ? start_minute : end_minute;

    const min =
      type === 'start'
        ? prevHour
          ? prevHour.end_minute + 5
          : 0
        : start_minute + 5;

    const max =
      type === 'start'
        ? end_minute - 5
        : nextHour
        ? nextHour.start_minute - 5
        : MINUTES_IN_DAY;

    return (
      <select
        data-type={type}
        onChange={onChange}
        className={`time ${type}`}
        defaultValue={selected}>
        {renderTimes(min, max)}
      </select>
    );
  };

  return (
    <View className="hour">
      {renderSelect('start')}
      <Text className="dash-separator">-</Text>
      {renderSelect('end')}

      <Button
        className={classNames('button', {inactive: isInactive})}
        onPress={onPressAction}>
        {action === 'add' ? (
          <AddIcon className="icon" />
        ) : (
          <RemoveIcon className="icon" />
        )}
      </Button>
    </View>
  );
};

export default Hour;
