import {DateTime} from 'luxon';

import {useBusiness, useParams} from 'hooks';

import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';
import Form from 'components/Form';
import Field from 'components/Form/Field';
import Label from 'components/Form/Label';
import Calendar from 'components/Form/Calendar';
import Select from 'components/Form/Select';
import Actions from 'components/Form/Actions';
import Submit from 'components/Form/Submit';
import Remove from 'components/Form/Remove';

import BusinessSpecialDateInfo from 'types/BusinessSpecialDate';
import {BusinessHoursStatus} from 'types/BusinessHours';

const {Closed, Opened} = BusinessHoursStatus;

const SpecialDateForm = () => {
  const {id} = useBusiness();
  const {date_id} = useParams<{date_id: string}>();

  const minDate = DateTime.now().minus({months: 1});
  const maxDate = DateTime.now().plus({months: 3});

  return (
    <Page
      title={`staff.settings.special_dates.${
        date_id ? 'edit' : 'add'
      }_special_date`}
      padding>
      <PageTitle className="staff-title center" />

      <Form<BusinessSpecialDateInfo>
        onSuccess={({goToUrl}) => goToUrl({goBack: true})}
        sourceId={date_id}
        source={`special-dates/${id}`}>
        {({data}) => {
          return (
            <>
              <Field
                name="status"
                label="staff.settings.special_dates.field_status">
                <Label />
                <Select
                  options={[
                    {
                      name: 'staff.settings.special_dates.closed_label',
                      value: Closed,
                    },
                    {
                      name: 'staff.settings.special_dates.open_label',
                      value: Opened,
                    },
                  ]}
                />
              </Field>

              <Field
                name="date_from"
                label="staff.settings.special_dates.field_date_from">
                <Label />
                <Calendar minDate={minDate} maxDate={maxDate} />
              </Field>

              <Field
                name="date_to"
                label="staff.settings.special_dates.field_date_to">
                <Label />
                <Calendar minDate={minDate} maxDate={maxDate} />
              </Field>

              <Actions>
                <Submit />
                <Remove />
              </Actions>
            </>
          );
        }}
      </Form>
    </Page>
  );
};

export default SpecialDateForm;
