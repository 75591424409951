import {Redirect as RedirectRouter} from 'react-router-dom';

import {useUrl} from 'hooks';

import {UrlProps} from 'types/Url';

import {saveRedirect} from 'utils/Url';

type Props = UrlProps;

const Redirect = ({redirect, state, replace = false, ...props}: Props) => {
  const url = useUrl(props);

  if (redirect) {
    saveRedirect(redirect);
  }

  return (
    <RedirectRouter
      to={{
        pathname: url.split('?')[0],
        search: url.split('?')[1],
        state,
      }}
      push={!replace}
    />
  );
};

export default Redirect;
