import {Route, Switch} from 'react-router-dom';

import NotFound from 'pages/NotFound';

import {STAFF_PATH} from 'types/Business';

import Items from './Items';
import SpecialDateForm from './SpecialDateForm';

const SpecialDates = () => {
  return (
    <Switch>
      <Route
        exact
        path={`${STAFF_PATH}/settings/special-dates`}
        component={Items}
      />
      <Route
        exact
        path={`${STAFF_PATH}/settings/special-dates/add`}
        component={SpecialDateForm}
      />
      <Route
        exact
        path={`${STAFF_PATH}/settings/special-dates/:date_id`}
        component={SpecialDateForm}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

export default SpecialDates;
