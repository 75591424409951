import {Route, Switch} from 'react-router-dom';

import NotFound from 'pages/NotFound';

import Edit from './Edit';

import {ADMIN_PATH} from 'types/Admin';

const User = () => {
  return (
    <Switch>
      <Route exact path={`${ADMIN_PATH}/users/:id`} component={Edit} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default User;
