import './Hr.scss';

import View from 'components/Common/View';

type Props = {
  color?: string;
  size?: string | number;
};

const Hr = ({size = 1, color}: Props) => {
  return (
    <View
      className="hr"
      style={{
        height: typeof size === 'number' ? `${size}px` : size,
        color,
      }}
    />
  );
};

export default Hr;
