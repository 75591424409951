import './Share.scss';

import {useCallback, MouseEvent} from 'react';

import {useUrl, useBusiness, useBusinessUrl, useStaffUrl} from 'hooks';

import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';
import InfoBox from 'components/Common/InfoBox';
import Spacer from 'components/Common/Spacer';
import View from 'components/Common/View';
import Text from 'components/Common/Text';
import Link from 'components/Common/Link';
import Locale from 'components/Common/Locale';

import {MembershipType} from 'types/Membership';
import {useSetNotification} from 'components/App/Notifications';

const {Free} = MembershipType;

const Share = () => {
  const setNotification = useSetNotification();
  const {slug, membership_type} = useBusiness();
  const businessUrl = useBusinessUrl();
  const staffUrl = useStaffUrl();
  const fullBusinessUrl = useUrl({base: businessUrl(), full: true}).replace(
    /(^\w+:|^)\/\//,
    '',
  );
  const fullBusinessBookingUrl = useUrl({
    to: 'r/:slug',
    params: {slug},
    full: true,
  }).replace(/(^\w+:|^)\/\//, '');

  //copied_msg
  const onPressInput = useCallback(
    (e: MouseEvent<HTMLInputElement>) => {
      const target = e.target as any;
      target.select();
      target.setSelectionRange(0, 99999);
      document.execCommand('copy');

      setNotification({
        type: 'success',
        msg: 'staff.share.copied_msg',
      });
    },
    [setNotification],
  );

  const renderShare = () => {
    return (
      <>
        <View className="share-item">
          <View className="headline">
            <Text className="title">
              <Locale text="staff.share.share_link_title" />
            </Text>
            <Text className="sub">
              <Locale text="staff.share.share_link_sub" />
            </Text>
          </View>

          <input
            onClick={onPressInput}
            className="share-input"
            readOnly
            value={fullBusinessUrl}
          />
        </View>

        <Spacer size={40} />

        <View className="share-item">
          <View className="headline">
            <Text className="title">
              <Locale text="staff.share.share_booking_link_title" />
            </Text>
            <Text className="sub">
              <Locale text="staff.share.share_booking_link_sub" />
            </Text>
          </View>

          <input
            onClick={onPressInput}
            className="share-input"
            readOnly
            value={fullBusinessBookingUrl}
          />
        </View>
      </>
    );
  };

  const renderWarning = () => {
    return (
      <InfoBox type="info">
        <Text>
          <Locale text="staff.share.activate_warning_1" />{' '}
          <Text bold>
            <Locale text="staff.share.activate_warning_2" />
          </Text>{' '}
          <Locale text="staff.share.activate_warning_3" />{' '}
          <Link
            className="link"
            base={staffUrl({to: 'settings/membership/checkout'})}
            label="staff.share.activate_link"
          />
        </Text>
      </InfoBox>
    );
  };

  const isFree = membership_type === Free;

  return (
    <Page title="staff.share.title" padding>
      <PageTitle className="staff-title" />

      {isFree && renderWarning()}

      <View className="staff-share">{renderShare()}</View>
    </Page>
  );
};

export default Share;
