import {useRef, useEffect, useCallback} from 'react';

const useIsMounted = () => {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => void (mounted.current = false);
  }, [mounted]);

  return useCallback(() => mounted.current, []);
};

export default useIsMounted;
