import {formatTimeTag} from 'utils/DateTime';

type Props = {
  minutes: number;
};

const TimeTag = ({minutes}: Props) => {
  return <>{formatTimeTag(minutes)}</>;
};

export default TimeTag;
