import {configureStore} from '@reduxjs/toolkit';

import notifications from 'store/Slices/Notifications';

export const store = configureStore({
  reducer: {
    notifications,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({}),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
