import CategoryInfo, {CategoryServices} from 'types/Category';
import ServiceInfo from 'types/Service';

export const formatCategories = (
  categories: CategoryInfo[],
  services: ServiceInfo[],
): CategoryServices[] =>
  categories
    .sort((a, b) => a.order - b.order)
    .map(category => formatCategory(category, services));

export const formatCategory = (
  category: CategoryInfo,
  services: ServiceInfo[],
): CategoryServices => ({
  category,
  services: services
    .filter(service => service.category_id === category.id)
    .sort((a, b) => a.order - b.order),
});
