import styles from './Modal.module.scss';

import {
  createContext,
  useMemo,
  useContext,
  useRef,
  useState,
  useEffect,
  useCallback,
  ReactNode,
  MouseEvent,
  Dispatch,
  SetStateAction,
} from 'react';
import classNamesBase from 'classnames';
import classNamesBind from 'classnames/bind';

import {getStore} from 'core/hooks';

import {useIsMounted} from 'hooks';

import View from 'components/Common/View';

const classNames = classNamesBind.bind(styles);

export type UseSetModalClosed =
  | Dispatch<SetStateAction<boolean>>
  | ((e: boolean) => void);

export type UseSetModalClosedReturn = ((animation?: boolean) => void) | null;

export type ModalProps = {
  children?: ReactNode;
  className?: string;
  setClosing?: () => void;
  setClosed: UseSetModalClosed;
};

export type ModalContextProps = {
  setClosed: ((animation?: boolean) => void) | null;
};

const ModalContext = createContext<ModalContextProps>({
  setClosed: null,
});

const randomId = () => '_' + Math.random().toString(36).substr(2, 9);

const modalsStore = getStore<Map<string, ReactNode>>('modals');

const removeModal = (modalId: string) => {
  if (modalsStore.has(modalId)) {
    modalsStore.delete(modalId);
  }
};

const Modal = ({
  children,
  className,
  setClosed: setClosedProp,
  setClosing: setClosingProp,
}: ModalProps) => {
  const isMounted = useIsMounted();
  const modalId = useMemo(() => randomId(), []);
  const modalRef = useRef(modalId);
  const containerRef = useRef(null);

  const [closing, setClosing] = useState(false);

  const doModalFadeOut = useCallback(
    (animation?: boolean) => {
      setTimeout(
        () => {
          removeModal(modalRef.current);

          if (setClosedProp && isMounted()) {
            setClosedProp(false);
          }
        },
        animation ? 250 : 0,
      );
    },
    [isMounted, setClosedProp],
  );

  const setClosed = useCallback(
    (animation?: boolean) => {
      if (isMounted()) {
        setClosing(true);
        doModalFadeOut(animation);
      } else {
        removeModal(modalRef.current);
      }
    },
    [setClosing, doModalFadeOut, isMounted],
  );

  const onClick = useCallback(
    (e: MouseEvent) => {
      if (closing || e.target !== containerRef.current) {
        return;
      }

      setClosed(true);

      if (setClosingProp && isMounted()) {
        setClosingProp();
      }
    },
    [setClosed, closing, setClosingProp, isMounted],
  );

  const setModal = useCallback(
    (forceClosing?: boolean) => {
      const closingState = closing || forceClosing || !isMounted();
      const content = (
        <ModalContext.Provider value={{setClosed}}>
          <View
            ref={containerRef}
            onClick={onClick}
            className={`${classNamesBase('modal', className?.split(' '), {
              closing: closingState,
            })} ${classNames('modal', {
              closing: closingState,
            })}`}>
            {children}
          </View>
        </ModalContext.Provider>
      );

      modalsStore.set(modalRef.current, content);
    },
    [className, closing, onClick, containerRef, setClosed, children, isMounted],
  );

  useEffect(() => {
    setModal(closing);
  }, [setModal, closing]);

  useEffect(() => {
    const modalId = modalRef.current;
    return () => {
      removeModal(modalId);
    };
  }, []);

  return null;
};

export const useSetModalClosed = (): UseSetModalClosedReturn => {
  return useContext(ModalContext).setClosed;
};

export const useIsModal = (): boolean => {
  return !!useContext(ModalContext).setClosed;
};

export default Modal;
