import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';
import AppointmentsList from 'components/Appointment/List';

const Items = () => {
  return (
    <Page title="appointments.home_title" padding>
      <PageTitle gradient="gradient-50 gray" />

      <AppointmentsList />
    </Page>
  );
};

export default Items;
