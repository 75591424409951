import {
  useState,
  useContext,
  createContext,
  Dispatch,
  SetStateAction,
  HTMLProps,
} from 'react';

type Props = HTMLProps<HTMLDivElement>;

type ConnectContextProps = {
  values: null | any;
  setValues: null | Dispatch<SetStateAction<any>>;
};

const ConnectContext = createContext<null | ConnectContextProps>({
  values: null,
  setValues: null,
});

const Connect = ({children}: Props) => {
  const [values, setValues] = useState({});

  return (
    <ConnectContext.Provider value={{values, setValues}}>
      {children}
    </ConnectContext.Provider>
  );
};

export const useConnect = <T extends ConnectContextProps>(): T => {
  return useContext(ConnectContext) as T;
};

export default Connect;
