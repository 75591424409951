import {Route, Switch} from 'react-router-dom';

import {useStore} from 'core/hooks';

import {useLocation} from 'hooks';

import NotFound from 'pages/NotFound';

import Redirect from 'components/Common/Redirect';

import {UrlProps} from 'types/Url';

import {removeRedirect, getRedirect} from 'utils/Url';

import Options from './Options';
import Phone from './Phone';

const Login = () => {
  const {state} = useLocation<{loginUrl: UrlProps}>();
  const logged = useStore<boolean>('logged');

  if (logged) {
    if (state?.loginUrl) {
      removeRedirect();
      return <Redirect {...state.loginUrl} state={{isFromLogin: true}} />;
    }

    const baseRedirect = getRedirect();
    if (baseRedirect && baseRedirect.manual) {
      return <Redirect {...baseRedirect} state={{isFromLogin: true}} />;
    }

    return <Redirect state={{isFromLogin: true}} />;
  }

  return (
    <Switch>
      <Route exact path="/login" component={Options} />
      <Route exact path="/login/phone" component={Phone} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Login;
