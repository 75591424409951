import {Route, Switch} from 'react-router-dom';

import NotFound from 'pages/NotFound';

import General from './General';
import Complete from './Complete';
import ChangePhone from './ChangePhone';

const Appointments = () => {
  return (
    <Switch>
      <Route exact path="/settings" component={General} />
      <Route exact path="/settings/complete" component={Complete} />
      <Route exact path="/settings/change-phone" component={ChangePhone} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Appointments;
