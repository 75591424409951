import {useLocation} from 'hooks';

import Page from 'components/Page';
import PageTitle from 'components/Page/PageTitle';
import PhoneSignInForm from 'components/Login/PhoneSignInForm';

const Phone = () => {
  const {state} = useLocation<{isFromMyBusinesses: boolean}>();
  let pageTitle = 'login.title_phone';

  if (state?.isFromMyBusinesses) {
    pageTitle = 'login.title_phone_business';
  }

  return (
    <Page title={pageTitle} noAuth padding>
      <PageTitle className="center padding" />

      <PhoneSignInForm />
    </Page>
  );
};

export default Phone;
